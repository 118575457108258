import React, {useState, useEffect} from 'react';

//component import
import axios from 'axios';
import ViewLoader from '../Common/ViewLoader';
import Table from '../Common/Table';
import NoContent from '../Common/NoContent';
import Select from '../Common/Select';
import DateFormat from "../Utils/DateFormat";
import Tooltip from "../Common/Tooltip";
import ProgressBar from "../Utils/ProgressBar";
import { GET_SERVICE, PUT_SERVICE, POST_SERVICE, DELETE_SERVICE, POST_FILE } from "../Services/Backend";
import Modal from "../Common/Modal";

//styles import 
import styles from "../Contractor/css/view.module.css";

//resources import
import agency from "../../Assets/agency-green.svg";
import delete_icon from "../../Assets/delete_icon.svg"
import download_icon from "../../Assets/download_icon.svg";
import upload from "../../Assets/upload.svg";
import send_icon from "../../Assets/send_green.svg";
import send_icon_gray from "../../Assets/send_grey.svg";
import view from "../../Assets/view.svg";
import upload_t from "../../Assets/upload-t.svg";
import error_icon from "../../Assets/caution.svg"
import file_icon from "../../Assets/file.svg"


const Timesheet = (props) => {
    const [loading, setLoading] = useState(false)
    const [contractorLoading, setContractorLoading] = useState(false)
    const [agencyTimesheet, setAgencyTimesheet] = useState([])
    const [pageTimesheetCount, setPageTimesheetCount] = useState()
    const [pageTimesheetNumber, setPageTimesheetNumber] = useState(1)
    const [timesheetElement, setTimesheetElement] = useState(10)
    const [totalTimesheetElement, setTotalTimesheetElement] = useState(10)
    const [ timesheetContractor, setTimesheetContractor] = useState()
    const [showAgencyTimesheetContractor, setShowAgencyTimesheetContractor] = useState(false)
    const [pageTimesheetContractorCount, setPageTimesheetContractorCount] = useState()
    const [pageTimesheetContractorNumber, setPageTimesheetContractorNumber] = useState(1)
    const [timesheetContractorElement, setTimesheetContractorElement] = useState(10)
    const [totalTimesheetContractorElement, setTotalTimesheetContractorElement] = useState(10)
    const [contractorId, setContractorId] = useState()
  const [showProgress, setShowProgress] = useState(false)
  const [progress, setProgress] = useState(0);
  const [cancelBulk, setCancelBulk] = useState(false)
  const [bulkLoading, setBulkLoading] = useState(false)
  const [allAgency, setAllAgency] = useState([])
  const [allUmbrella, setAllUmbrella] = useState([])
  const [listUmbrella, setListUmbrella] = useState([]);
  const [umbrellaBulk, setUmbrellaBulk] = useState("")
  const [umbrellaBulkId, setUmbrellaBulkId] = useState("")
  const [rateBulk, setRateBulk] = useState("")
  const [payPeriodBulk, setPayPeriodBulk] = useState("")
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState("No file selected");
  const [responseSheet, setResponseSheet] = useState([])
  const [date, setDate] = useState("");
    const [bulk, setBulk] = useState(false)


    const [dateCreated, setDateCreated] = useState(null)
    const [emailStatus, setEmailStatus] = useState(null)
   
    const [payPeriod, setPayPeriod] = useState(null)
    const [umbrella, setUmbrella] = useState("")

    const [umbrellaId, setUmbrellaId] = useState(null)
    const timesheetHeaders = [
        { key: 'fullName', label: 'Pay Period' },
        { key: 'niNumber', label: 'Umbrella' },
        { key: 'emailAddress', label: 'Contractors' },
        { key: 'gender', label: 'Date and Time' },
        { key: 'gender', label: 'Email Timeline' },
      ]
      const contractorHeaders = [
        { key: 'fullName', label: 'Contractors Name' },
        { key: 'niNumber', label: 'Pay Period' },
        { key: 'emailAddress', label: 'Contractors Email' },
        { key: 'gender', label: 'Total Shift' },
        { key: 'agencies', label: 'Email Reminder' },
        { key: 'actions', label: 'Action' },
      ]
      const handleFileSelect = (event) => {
        setSelectedFile(event?.target?.files[0]);
        setFilename(event?.target?.files[0]?.name);
      };
      const sendEmailTimesheet =async (row)=>{
        const endpoint = `/time-sheets-file/${row.timeSheetFileId}/send-mail`
        try {
          const response = await GET_SERVICE(endpoint);
          if (response.status === 200) {
           props.success(response.data)
            getTimesheet()
          }
          else {
           props.error(response.data)
          }
        } catch (err) {
    
          return err.response;
        }
    
      }

      const deleteTimesheet = async (timesheet)=>{

        const endpoint=`/time-sheets-file/${timesheet}`
        try {
          const response = await DELETE_SERVICE(endpoint)
    
          if (response.status === 200) {
             props.success(response.data)
            getTimesheet()
          }
          else {
           props.error(response.data)
          }
    
    
        }
        catch (e) {
          return e.response;
        }
       
    
      }

      const getTimesheet = async (page) => {
        const endpoint = `/agencies/${props.id}/timesheet-files?page=${page || 0}&size=10`
    setLoading(true)
        try {
          const response = await GET_SERVICE(endpoint)
          if (response.status === 200) {
            setAgencyTimesheet(response.data.data.singleTimeSheetDTOS)
            setTotalTimesheetElement(response.data.data.totalElement); 
            setPageTimesheetNumber(page || 0)
            setPageTimesheetCount(response.data.data.totalPages)
            setPayPeriod(null)
          }
          setLoading(false)
        }
        catch (e) {
          return e.response;
        }
      }

      const getMoreTimesheet = async (option) => {
        setLoading(true)
        const endpoint = `/agencies/${props.id}/contractors?page=${pageTimesheetNumber|| 0}&size=${option}`
        try {
          const response = await GET_SERVICE(endpoint);
          setAgencyTimesheet(response.data.data.contractors)
          setTimesheetElement(option)
          setLoading(false)
        } catch (err) {
          return err.response;
        }
      }

      const nextTimesheetPage = (e) => {
        let page = e.selected
        getTimesheet(page)
      }

      const uploadSheet = async (event) => {
        setBulkLoading(true);
        setProgress(0);
        setShowProgress(true)
        const interval = setInterval(() => {
          setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 0));
        }, 200);
        setResponseSheet([])
        event.preventDefault();
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
          const response = await POST_FILE(
            `/time-sheets-file/upload?&umbrellaId=${umbrellaBulkId}&payPeriod=${DateFormat(payPeriodBulk)}&rate=${rateBulk}`,
            formData
          );
      
          if (response.status === 200 || response.status ===201) {
            setResponseSheet(response.data.data)
            clearInterval(interval);
            setShowProgress(false)
            props.success(response.data)
            setFilename("No file selected")
            setUmbrellaBulk("")    
            setUmbrellaBulkId("")
            setRateBulk("")
            setPayPeriodBulk("")       
            setBulkLoading(false)

          } else {
            setResponseSheet(response.data.data)
            clearInterval(interval);
            setBulkLoading(false) 
            setShowProgress(false)
            props.error(response.data)
          }
        } catch (error) {
    
        }
      }
      const cancelUpload = () => {
        setUmbrellaBulk("")
        setProgress(0)
        setShowProgress(false)
        setUmbrellaBulkId("")
        setRateBulk("")
        setPayPeriodBulk("")
        setBulk(false)
        setSelectedFile()
        setFilename("No File Selected")
        setResponseSheet([])
        setCancelBulk(false)
   
      }
    
   

    
      const getAgencyTimesheetUmbrella = async () => {
    
        try {
          const response = await GET_SERVICE(`/agencies/umbrellas`);
    
          setAllUmbrella(response.data.data)
        } catch (err) {
          return err.response;
        }
      }
      const getAgencyUnderUmbrella = async (agency) => {
        try {
          const response = await GET_SERVICE(`/umbrellas/${agency.id}/agencies-linked`);
         
          if (response.status===200){
            setAllAgency(response?.data?.data)
          }
          else {
            setAllAgency([])
          }
    
        
    
        } catch (err) {
          return err.response;
        }
      }

      const getTimesheetContractor = async (page) => {
        setContractorLoading(true)
    
        try {
          const response = await GET_SERVICE(`/time-sheets-file/contractors/${contractorId}?page=${page||0}&size=10`);
    
          if (response.status===200){
            setPageTimesheetContractorNumber(page || 0)
            setPageTimesheetContractorCount(response.data.data.page )
            setTimesheetContractor(response?.data?.data?.contractorTimeSheetFileInformation);
            setTotalTimesheetContractorElement(response.data.data.size)
            setContractorLoading(false)
            setPayPeriod(null)
          }
          else{
            setTimesheetContractor([])
            setContractorLoading(false)
          }
       
        } catch (err) {
    
          return err.response;
        }
      };
 
      const downloadSheet = async (row)=>{
        const endpoint = `"https://payrolleasy-api-b7a634266a4c.herokuapp.com/api/v1/time-sheets-file/download/${row.timeSheetFileId}`
        const token = sessionStorage.getItem("token")
        const headers = {  
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }      
        try {

          const response = await axios.get(endpoint, {
            responseType: 'arraybuffer',
            headers: headers,
          }); 
          const byteArray = new Uint8Array(response.data);
          const blob = new Blob([byteArray], { type: 'application/octet-stream' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = row.agencyName+"_"+row.umbrellaName+"_"+row.payPeriod+".xlsx"
          link.click();
         
        } catch (error) {
          console.error('Error downloading file:', error);
          
        }
         }
 

      const getMoreTimesheetContractor = async (option) => {
        setContractorLoading(true)
        try {
          const response = await GET_SERVICE(`/time-sheets-file/contractors/${contractorId}?page=${pageTimesheetContractorNumber||0}&size=${option}`);
      
          if (response.status===200){
            setTimesheetContractor(response.data.data.singleTimeSheetDTOS);
         
            setPageTimesheetContractorCount(response.data.data.totalPages)
          setTimesheetContractorElement(option)
          setContractorLoading(false)
          }
          else{
            setTimesheetContractor([])
            setContractorLoading(false)
          }
         
        
        } catch (err) {
    
          return err.response;
        }
      };

      const nextTimesheetContractorPage =(e)=>{  
        let page = e.selected    
        getTimesheetContractor(page)
       }

       const sendEmail =async (row)=>{
        if (row.emailStatus!=="COMPLETED"){
          const endpoint = `/time-sheets-file/${row.contractorTimeSheetFileInfold}/send-mail`
          try {
            const response = await GET_SERVICE(endpoint);
            if (response.status === 200) {
              props.success(response.data)
              getTimesheetContractor()
            }
            else {
          props.error(response.data)
            }
      
           
          
          } catch (err) {
      
            return err.response;
          }
      
        }
      }

      

      useEffect(() => {
        getTimesheet()
        getAgencyTimesheetUmbrella()
      
      }, [])

      useEffect(()=>{
          getTimesheetContractor()
      }, [contractorId])
      
      const filterTimesheet = async () => {
   
        const body = {
          "agencyId": props.id,
          "umbrellaId": null,
          "payPeriod": payPeriod===null? null : DateFormat(payPeriod),
          "dateCreated": null,
          "emailStatus": null
    
        }
        const endpoint = '/time-sheets-file/filter'
        setLoading(true)
        try {
    
          const response = await POST_SERVICE(endpoint, body)
          console.log(response)
          console.log(body)
    
          if (response.status === 200) {
    
            if (response.data.data.singleTimeSheetDTOS.length > 0) {
              setAgencyTimesheet(response.data.data.singleTimeSheetDTOS);
       
            }
    
    
          }
     
          setLoading(false);
        }
        catch (err) {
          return err.response
        }
      }
    
    return (
        <>
        {!showAgencyTimesheetContractor ?
        agencyTimesheet?.length > 0 ?
         loading === true ?
          <ViewLoader  />
          :
          <Table
            columns={timesheetHeaders}
            action="Bulk Upload"
            show_add={true}
            showSearchBar={true}
            add_image={upload}
            action_button={() => setBulk(!bulk)}
            body={
              agencyTimesheet?.map((row, index) =>
                <tr key={index}>
                  <td>
                    {row.payPeriod}

                  </td>
                  <td>
                    {row.umbrellaName}

                  </td>
                  <td>
                    {row.ContractorsCount}

                  </td>
                  <td>
                    {row.date}{", "}      {row.time}

                  </td>
             
                  <td>
                    {row.emailStatus === "COMPLETED" &&

                      <span style={{ color: "#85CE54", fontWeight: "600" }}>{row.emailStatus}</span>

                    }
                    {row.emailStatus === "FAILED" &&

                      <span style={{ color: "#DB504A", fontWeight: "600" }}>{row.emailStatus}</span>

                    }
                      {row.emailStatus==="NOT_SENT" &&
           <span onClick={()=>sendEmailTimesheet(row)} style={{color:"#56AACA", fontWeight:"600", cursor:"pointer"}}>SEND EMAIL</span>
            }

                  </td>

                  <td className={styles.action_icons}>
                    <div
                    >
                      <span>

                        <Tooltip text="View Contractors">
                          <img
                            className={styles.view_icon}
                            src={view}
                            alt={view}
                          onClick={()=> {
                        setContractorId(row.timeSheetFileId)
                       
                        setShowAgencyTimesheetContractor(true)
                            
                          }}

                          />
                        </Tooltip>

                      </span>
                      <span >
                        <Tooltip text={"Download Timesheet"}>
                          <img

                            src={download_icon}
                            alt={download_icon}
                           onClick ={()=>downloadSheet(row)}
                          />
                        </Tooltip>

                      </span>
                      <span >
                        <Tooltip text={"Delete Timesheet"}>
                          <img

                            src={delete_icon}
                            alt={delete_icon}
                           onClick={()=>deleteTimesheet(row.timeSheetFileId)}
                          />
                        </Tooltip>

                      </span>
                    </div>
                  </td>
                </tr>
              )
            }

            row={timesheetElement}
            row_list={
              totalTimesheetElement <= 30 && totalTimesheetElement >= 20
                ? [10, 20, 30]
                : totalTimesheetElement <= 20 && totalTimesheetElement >= 10
                  ? [10, 20]
                  : totalTimesheetElement <= 10
                    ? [10]
                    : []
            }
            row_item_click={(option) => getMoreTimesheet(option)}
            pageCount={pageTimesheetCount}
            pageChange={nextTimesheetPage}
            pageNumber={pageTimesheetNumber}
           filterContent={

                <div>
                  <div className={styles.filter}>


                  
                
                    <div className={styles.form_input}>
                      <label>Pay Period</label>
                      <input type="date" value={payPeriod} onChange={(e) => setPayPeriod(e.target.value)} />

                    </div>
              

                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} >
                      Cancel
                    </button>
                    <button className={styles.solid} onClick={filterTimesheet}>
                      Save
                    </button>
                  </div>

                </div>




              }
          />
        :
        loading === true ?
          <ViewLoader />
          :
          <>
            <NoContent
              image={agency}
              text="No Timesheet Available"
              hide={true}
              change={{ margin: "5% 0" }}

            />
          </>
          :
          timesheetContractor?.length > 0 ?
          contractorLoading === true ?
            <ViewLoader show={contractorLoading} />
            :
            <Table
              columns={contractorHeaders}
              showSearchBar={true}
              header={
                <h3 style={{ color: "#9D9D9D",  fontWeight: "600" }}>
                <span onClick={() => {
                  setShowAgencyTimesheetContractor(!showAgencyTimesheetContractor)
                  setContractorId(undefined)
                }} style={{ cursor: "pointer" }}>Timesheet</span>{" "}
                <span style={{ color: "#313131" }}>/ Contractors</span>
              </h3>
    
              }
              filterContent={

                <div>
                  <div className={styles.filter}>


                  
                
                    <div className={styles.form_input}>
                      <label>Pay Period</label>
                      <input type="date" value={payPeriod} onChange={(e) => setPayPeriod(e.target.value)} />

                    </div>
              

                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} >
                      Cancel
                    </button>
                    <button className={styles.solid} onClick={filterTimesheet}>
                      Save
                    </button>
                  </div>

                </div>




              }
              body={
                timesheetContractor?.map((row, index) =>
                <tr key={index}>

                <td>
                   {row.name}
     
             </td>
             <td>
                   {row.payPeriod}

             </td>
             <td>
                   {row.contractorEmail}

             </td>
             <td>
                   {row.numberOfShifts}
     
             </td>
             <td>
               {row.emailStatus ==="COMPLETED" &&

               <span style={{color:"#85CE54", fontWeight:"600"}}>SENT</span>

               }
                {row.emailStatus==="NOT_SENT"&&
              <span style={{color:"#56AACA", fontWeight:"600"}}>SEND</span>

               }
                        {row.emailStatus==="FAILED"&&
              <span style={{color:"#DB504A", fontWeight:"600"}}>FAILED</span>

               }
             </td>
           
             <td className={styles.action_icons}>
               <div
               >
                 <span>

                   <Tooltip text={row.emailStatus ==="COMPLETED" ?"sent": "Send Email"}>
                     <img
                       className={styles.view_icon}
                       src={row.emailStatus !=="COMPLETED"  ?send_icon: send_icon_gray}
                       alt={view}
                       onClick={()=>sendEmail(row)}
                   
                     />
                   </Tooltip>
           
                 </span>
              
              
               </div>
             </td>

           </tr>
                )
              }

              row={timesheetContractorElement}
              row_list={
                totalTimesheetContractorElement <= 30 && totalTimesheetContractorElement >= 20
                  ? [10, 20, 30]
                  : totalTimesheetContractorElement <= 20 && totalTimesheetContractorElement >= 10
                    ? [10, 20]
                    : totalTimesheetContractorElement <= 10
                      ? [10]
                      : []
              }
              row_item_click={(option) => getMoreTimesheetContractor(option)}
              pageCount={pageTimesheetContractorCount}
              pageChange={nextTimesheetContractorPage}
              pageNumber={pageTimesheetContractorNumber}
            
            />
          :
          contractorLoading === true ?
            <ViewLoader />
            :
            <>
              <NoContent
                image={agency}
                text="No Timesheet Available"
                hide={true}
                change={{ margin: "5% 0" }}

              />
            </>
        }
        {bulk&&
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <h3>Timesheets Bulk Upload</h3>
                <div className={styles.select_input}>
                  <label>
                    Umbrella

                  </label>
                  <div className={styles.custom_select_container}>
                    <Select
                      disabled=""


                      value={umbrellaBulk}
                      onChange={(option) => {
                        setUmbrellaBulk(option)
                        const selectedUmbrella = listUmbrella?.find((item) => item.name === option);
                        if (selectedUmbrella) {
                          setUmbrellaBulkId(selectedUmbrella?.id);
                          getAgencyUnderUmbrella(selectedUmbrella)
                        }
                      }}
                      options={listUmbrella?.map((agency) =>
                        agency.name
                      )}
                    />

                  </div>
                </div>



                <div className={styles.form_input}>
                  <label>
                    Select Rate<span style={{ color: "#DB504A" }}>*</span>

                  </label>
                  <Select
                    disabled={""}
                    value={rateBulk}
                    onChange={(option) => setRateBulk(option)}
                    options={["Standard Hourly Rate", "Standard Day Rate"]}

                  />
                  {/* <span>{props.error_one}</span> */}
                </div>

                <div className={styles.form_input}>
                  <label>
                    Pay Period

                  </label>
                  <input
                    type="date"
                    value={payPeriodBulk}

                    onChange={(e) => setPayPeriodBulk(e.target.value)}

                  />
                  {/* <span>{props.error_one}</span> */}
                </div>
                <span style={{display:"flex", alignItems:"center", columnGap:"5px", marginBottom: "10px"}}><img style={{width:"20px", height:"20px"}}src={file_icon} alt ={file_icon}/><p style={{  fontSize: "15px", fontWeight: "400" , margin:"5px"}}>{filename}</p></span>

                <button className={styles.upload} >
                  <label htmlFor="file" className={styles.addFile}>
                    <span>Upload Excel Template</span><img src={upload_t} alt={upload_t} />
                    <input

                      type="file"
                      name="file"
                      id="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileSelect}
                    />
                  </label>


                </button>

                {showProgress &&
                  <ProgressBar progress={progress} />

                }



              {responseSheet?.length>0 &&
                  <div style={{ maxHeight: "100px", overflowY: "scroll", marginTop: "10px", marginBottom: "10px" }}>
                  <h3  style={{fontWeight:"600", margin:0}}>Process Log Information</h3>
                  <p style={{color:"#A5A5A5", fontSize:"12px", margin:0}}>Various flags recorded while template was being processed</p>
                  {responseSheet.map((sheet) =>

<span style={{display:"flex", alignItems:"center", columnGap:"10px"}}><img style={{width:"20px", height:"20px"}}src={error_icon} alt ={error_icon}/><p style={{ color: "#DB504A", fontSize: "15px", fontWeight: "500" , margin:"5px"}}>{sheet}</p></span>

                  )

                  }
                </div>
              }
                <div className={styles.buttons}>
                  <button className={styles.clear} onClick={()=>setCancelBulk(true)}>
                    Cancel
                  </button>
                  
                  { bulkLoading ?
                                      <button className="loading_button"><div /></button>
                                      :
                  filename !== ""  && umbrellaBulkId !== "" && payPeriodBulk !== "" && rateBulk !== "" &&
                
                    <button className={styles.solid} onClick={uploadSheet}>
                      Save
                    </button>

                  }

                </div>

              </div>



            </div>





          }
             <Modal open={cancelBulk}
          header="Warning message."
          content={<span>  Are you sure you want to cancel upload?
          </span>}
          show_yes={true}
          cancel={() => setCancelBulk(false)}
          yes={cancelUpload}


        />
      </>
    );
};

export default Timesheet;
import React, { useEffect, useState } from 'react';

//component imports
import Form from '../Common/Form';
import { GET_SERVICE, PUT_SERVICE, DELETE_SERVICE } from '../Services/Backend';
import ViewLoader from "../Common/ViewLoader"
import ViewButton from '../Common/ViewButton';

//import styles
import styles from "./css/view.module.css";

//import resources
import addIcon from "../../Assets/add-square.svg";

const Agency = (props) => {
  

  const [componentCount, setComponentCount] = useState();
  const [secondComponentCount, setSecondComponentCount] = useState(0)
  const [agencyUmbrella, setAgencyUmbrella] = useState()
  const [agency, setAgency] = useState("");
  const [umbrella, setUmbrella] = useState("");
  const [agencyList, setAgencyList] = useState([])
  const [agencyId, setAgencyId] = useState([])
  const [listUmbrella, setListUmbrella] = useState([])
  const [listUmbrellaId, setListUmbrellaId] = useState([])
  const [imageVisible, setImageVisible] = useState([true]);
  const [loading, setLoading] = useState(false)
  const [element, setElement] = useState(0)
  const [loadAgency, setLoadAgency] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const toggleImage = (index) => {
    setImageVisible(prevVisible => {
      const updatedVisible = [...prevVisible];
      updatedVisible[index] = !updatedVisible[index];
      return updatedVisible;
    });
  };

  const getAgencyLinkedUmbrella = async () => {
    const endpoint = `/contractors/${props.id}/agency-data`
    setLoading(true)
    try {
      const response = await GET_SERVICE(endpoint);

      setComponentCount(response?.data?.data?.length)
      setAgencyUmbrella(response.data.data)
    
      setLoading(false)

    } catch (err) {
      return err.response;
    }
  }
  const getUmbrella = async (id) => {

    try {
      const response = await GET_SERVICE(`/agencies/${id}/umbrellas`);
      setListUmbrella(response?.data?.data); // Update with umbrella objects containing name and ID
    } catch (err) {
      return err.response;
    }
  };


  const deleteUmbrella = async (agency) => {
    if (props.editable === false) {
      const selectedAgency = agencyUmbrella.find((item) => item.name === agency);
      let agencyId = selectedAgency.id
      const endpoint = `/contractors/agency-data/${agencyId}`


      try {
        const response = await DELETE_SERVICE(endpoint);

        if (response.status === 200) {

          props.success(response.data)
          getAgencyLinkedUmbrella()
        }
        else {
          props.error(response.data)

        }

      } catch (err) {
        return err.response;
      }
    }


  }
  const getAgency = async () => {

    try {
      const response = await GET_SERVICE("/agencies/name-and-id?page=0&size=10");
      if(response.status===200){
        setAgencyList(response.data.data.agencies);
        setElement(response.data.data.totalElement)

        if(response.data.data.agencies.length < response.data.data.totalElement ){
          setLoadAgency(true)
  
         }
      }
    

    } catch (err) {
      return err.response;
    }
  };
  const createForm = () => {

    if (props.editable === false && secondComponentCount < agencyList?.length - componentCount) {
      setSecondComponentCount(prevCount => prevCount + 1);
    }
  };
  const deleteForm = () => {
    setSecondComponentCount((prevCount) => prevCount - 1)
    if (agency.length !== 0) {


      agencyId.pop()
      agency.pop()
      listUmbrellaId.pop()
      umbrella.pop()
    }

  }
  const linkAgencyUmbrella = async (umbrella, agency) => {
    const endpoint = `/contractors/agency-data/${props.id}`

    const body = {
      "agencyId": agency,
      "umbrellaId": umbrella
    }
    try {
      const response = await PUT_SERVICE(endpoint, body)


      if (response.status === 200 || response.status === 201) {
        props.success(response.data)
        getAgencyLinkedUmbrella()
        deleteForm()

      }
      else {
        props.error(response.data)
        setLoading(false)
      }


    }
    catch (e) {
      return e.response;
    }

  }
  const getMoreAgencies = async (option) => {
  
    try {
      if (agencyList.length < element) {
         const nextPage = pageNumber + 1
 
      const response = await GET_SERVICE(`/agencies/name-and-id?page=${nextPage}&size=10`);
      
      if (response.status === 200) {
      
      
        const newAgencies = response.data.data.agencies.filter(
          agency => !agencyList.some(existingAgency => existingAgency.id === agency.id)
        );
     
        setAgencyList(prevAgencyList => [...prevAgencyList, ...newAgencies]);
        setPageNumber(nextPage)
     
 
      

      }
     
    }
    else{
      setLoadAgency(false)
    }
    } catch (err) {
      return err.response;
    }
  };

  useEffect(() => {
    getAgencyLinkedUmbrella()
    getAgency()
  }, [])
  return (
    <>{
      loading === false ?
        <>

          {Array.from({ length: componentCount }).map((_, index) => (
            <Form
              show_header={true}
              header={index >= 1 &&
                <span>Agency Information {index + 1}</span>
              }
              line={index >= 1 && true}
              input_type="select"
              show_one={true}
              label_one="Agency Name"
              select_one_value={
                index < agencyUmbrella?.length
                  ? agencyUmbrella[index].agencyName
                  : ""

              }


              select_one={[]}
              placeholder_one="Agency"
              disabled={"disabled"}
              input_type_two="input"
              show_two={true}
              label_two="Umbrella linked"
              placeholder_two={
                index < agencyUmbrella?.length
                  ? agencyUmbrella[index].umbrellaName
                  : ""
              }

              disabled_two={"disabled"}
              place={"place"}


              clearButton={!props.editable && agencyUmbrella?.length > 1 && true}

              clear={() => deleteUmbrella(index < agencyUmbrella.length
                ? agencyUmbrella[index].name
                : "")}
            />
          ))}
 
          {Array.from({ length: secondComponentCount }).map((_, index) => (
            <Form
              show_header={true}
              header={
                <span>Agency Information {componentCount + 1 + index}</span>
              }
              line={true}
              input_type="select"
              show_one={true}
              label_one="Agency"
              select_one_value={agency[index] || ""} // Use umbrella[index] for the corresponding form
              disabled=""
              select_one={agencyList?.map((agency) => agency.name)}
              select_change={(option) => {
                setAgency((prevAgency) => {
                  const updatedAgency = [...prevAgency];
                  updatedAgency[index] = option; // Set umbrella name at the corresponding index
                  return updatedAgency;
                });
                const selectedAgency = agencyList.find((item) => item.name === option);

                if (selectedAgency) {
                  setAgencyId((prevIds) => {
                    const updatedIds = [...prevIds];
                    getUmbrella(selectedAgency.id)
                    updatedIds[index] = selectedAgency.id; // Set umbrella ID at the corresponding index
                    return updatedIds;
                  });
                }
              }}
              input_type_two="select"
              show_two={true}
              label_two="Umbrella"
              select_two_value={umbrella[index] || ""}
              select_two={listUmbrella?.map((umbrellas) =>
                umbrellas.name
              )}
              disabled_two={agencyId?.length !== 0 ? "" : "disabled"}
              select_change_two={(option) => {
                setUmbrella((prevUmbrella) => {
                  const updatedUmbrella = [...prevUmbrella];
                  updatedUmbrella[index] = option; // Set umbrella name at the corresponding index
                  return updatedUmbrella;
                });
                const selectedUmbrella = listUmbrella?.find((item) => item.name === option);
                if (selectedUmbrella) {
                  setListUmbrellaId((prevIds) => {
                    const updatedIds = [...prevIds];
                    updatedIds[index] = selectedUmbrella.id; // Set umbrella ID at the corresponding index
                    return updatedIds;
                  });
                }
              }}
              saveButton={true}
              imagedel={true}
              loadMore={loadAgency}
              click={getMoreAgencies}
              save={() =>
                linkAgencyUmbrella(listUmbrellaId[index], agencyId[index])
              }

              del={() => {
                deleteForm()
              }}
            />
          ))}

          {!props.editable &&
            <>
              <button className={styles.bulk} onClick={() => {
                createForm();
                toggleImage(0);
              }}>
                <span>Add Agency </span>
                <img src={addIcon} alt={addIcon} />
              </button>
              <ViewButton
                show={true}
                cancel={() => {
                  props.cancel();
                  deleteForm();
                }}
              />
            </>

          }


        </>
        :
        <ViewLoader />
    }
    </>

  );
};

export default Agency;
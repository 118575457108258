import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from 'axios';


//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, DELETE_SERVICE, POST_SERVICE } from "../Services/Backend";
import Loader from "../Common/Loader";
import Tooltip from "../Common/Tooltip";
import Select from "../Common/Select";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import Modal from "../Common/Modal";
import DateFormat from "../Utils/DateFormat";
//import styles
import styles from "../Agency/css/agency.module.css";



//import resources

import NoContent from "../Common/NoContent";
import agency_image from "../../Assets/timesheet_green.svg";
import Table from "../Common/Table";
import add from "../../Assets/add-icon.png";
import delete_icon from "../../Assets/delete_icon.svg"
import view from "../../Assets/view.svg";
import download from "../../Assets/download_icon.svg";



const Timesheet = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [timesheet, setTimesheet] = useState([]);
  const [loading, setLoading] = useState(false)
  const [dateCreated, setDateCreated] = useState(null)
  const [emailStatus, setEmailStatus] = useState(null)
  const [search, setSearch] = useState("")
  const [pageCount, setPageCount] = useState(0)
  const [element, setElement] = useState(10)
  const [totalElement, setTotalElement] = useState(10)
  const [agency, setAgency] = useState('')
  const [payPeriod, setPayPeriod] = useState(null)
  const [umbrella, setUmbrella] = useState("")
  const [allAgency, setAllAgency] = useState([])
  const [allUmbrella, setAllUmbrella] = useState([])
  const [agencyId, setAgencyId] = useState(null)
  const [umbrellaId, setUmbrellaId] = useState(null)
  const [allPayPeriod, setAllPayPeriod] = useState([])
  const [deleteToggle, setDeleteToggle] = useState(false)
  const [text, setText] = useState("")
  const [timesheetId, setTimesheetId] = useState()
  const [loadAgency, setLoadAgency] = useState(false)
  const [pageNumberAgency, setPageNumberAgency] = useState(0)
  const [elementAgency, setElementAgency] = useState(0)
  const columns = [
    { key: 'fullName', label: 'Agency Name' },
    { key: 'niNumber', label: 'Pay Period' },
    { key: 'emailAddress', label: 'Umbrella' },
    { key: 'gender', label: 'Contractors' },
    { key: 'gender', label: 'Date and Time' },
    { key: 'agencies', label: 'Email Timeline' },

  ];

  const navigate = useNavigate();

  const [toggle, setToggle] = useState();
  const [pageNumber, setPageNumber] = useState()

  const getTimesheet = async (page) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/time-sheets-file?page=${page || 0}&size=10`);

      if (response.status === 200) {
        setTimesheet(response.data.data.singleTimeSheetDTOS);
        setPageNumber(page || 0)
        setPageCount(response.data.data.totalPages)
        setTotalElement(response.data.data.totalElement)


        setLoading(false)
      }
      else {
        setTimesheet([])
        setLoading(false)
      }


    } catch (err) {

      return err.response;
    }
  };
  const deleteTimesheetModal = (row) => {
    setTimesheetId(row)
    setDeleteToggle(!deleteToggle)
    setText("delete")

  }
  const getAgency = async () => {

    try {
      const response = await GET_SERVICE("/agencies/name-and-id?page=0&size=10");
      if(response.status===200){
        setAllAgency(response.data.data.agencies);
        setElementAgency(response.data.data.totalElement)

        if(response.data.data.agencies.length < response.data.data.totalElement ){
          setLoadAgency(true)
  
         }
      }
    

    } catch (err) {
      return err.response;
    }
  };
  const getMoreAgencies = async (option) => {
  
    try {
      if (allAgency.length < elementAgency) {
         const nextPage = pageNumberAgency + 1
 
      const response = await GET_SERVICE(`/agencies/name-and-id?page=${nextPage}&size=10`);
      
      if (response.status === 200) {
      
      
        const newAgencies = response.data.data.agencies.filter(
          agency => !allAgency.some(existingAgency => existingAgency.id === agency.id)
        );
     
        setAllAgency(prevAgencyList => [...prevAgencyList, ...newAgencies]);
        setPageNumberAgency(nextPage)
     
 
      

      }
     
    }
    else{
      setLoadAgency(false)
    }
    } catch (err) {
      return err.response;
    }
  };
  const getUmbrella = async () => {
    try {
      const response = await GET_SERVICE(`/umbrellas`)
      setAllUmbrella(response.data.data)
    }
    catch (err) {
      return err.response
    }
  }
  const getMoreSheets = async (option) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/time-sheets-file?page=${pageNumber || 0}&size=${option}`);

      if (response.status === 200) {
        setTimesheet(response.data.data.singleTimeSheetDTOS);

        setPageCount(response.data.data.totalPages)
        setElement(option)

        setLoading(false)
      }
      else {
        setTimesheet([])
        setLoading(false)
      }


    } catch (err) {

      return err.response;
    }
  };
  const nextPage = (e) => {

    getTimesheet(e.selected)



  }

  useEffect(() => {
    getTimesheet();
    getAgency()
    getUmbrella()
  }, []);


  const deleteTimesheet = async (timesheet) => {

    const endpoint = `/time-sheets-file/${timesheet}`
    try {
      const response = await DELETE_SERVICE(endpoint)

      if (response.status === 200) {
        setSuccess(true);
        getTimesheet()
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);


        }, 2000);

      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);

        }, 5000);
      }

     setTimesheetId(null)
     setText("")
     setDeleteToggle(false)
    }
    catch (e) {
      return e.response;
    }


  }
  const filterTimesheet = async () => {
   
    const body = {
      "agencyId": agencyId || null,
      "umbrellaId":umbrellaId || null,
      "payPeriod": payPeriod===null? null : DateFormat(payPeriod),
      "dateCreated": dateCreated===null? null : DateFormat(dateCreated),
      "emailStatus": emailStatus || null

    }
    const endpoint = '/time-sheets-file/filter'
    setLoading(true)
    try {

      const response = await POST_SERVICE(endpoint, body)
      console.log(response)
      console.log(body)

      if (response.status === 200) {

        if (response.data.data.singleTimeSheetDTOS.length > 0) {
          setTimesheet(response.data.data.singleTimeSheetDTOS);
          setPageCount(response.data.data.totalPages)
          setTotalElement(response.data.data.totalElement);
        }


      }
 
      setLoading(false);
    }
    catch (err) {
      return err.response
    }
  }

  //  const downloadSheet = async (row)=>{
  // const endpoint = `/time-sheets-file/download/${row.timeSheetFileId}`
  // try {
  //   const response = await GET_SERVICE(endpoint);
  //   console.log(response)
  //   if (response.status === 200) {
  //     const byteData = response.data
  //     const fileName = 'timesheet.xlsx'; // Replace 'file_name.bin' with the desired file name and extension.
  //     downloadFile(byteData, fileName);

  //     setSuccess(true);

  //     setSuccessMessage(response.data.message);
  //     setTimeout(() => {
  //       setSuccess(false);


  //     }, 5000);

  //   }
  //   else {
  //     setError(true);
  //     setErrorMessage(response.data.message);
  //     setTimeout(() => {
  //       setError(false);
  //     }, 5000);
  //   }



  // } catch (err) {

  //   return err.response;
  // }


  //  }
  const downloadSheet = async (row) => {

      const endpoint = `https://payrolleasy-api-b7a634266a4c.herokuapp.com/api/v1/time-sheets-file/download/${row.timeSheetFileId}`
      const token = sessionStorage.getItem("token")
      const headers = {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`

      }


      try {
        console.log(row)
        const response = await axios.get(endpoint, {
          responseType: 'arraybuffer',
          headers: headers,
        });


        const byteArray = new Uint8Array(response.data);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = row.agencyName + "_" + row.umbrellaName + "_" + row.payPeriod + ".xlsx"
        link.click();


      } catch (error) {
        console.error('Error downloading file:', error);

      }



    }
    const sendEmail = async (row) => {
      const endpoint = `/time-sheets-file/${row.timeSheetFileId}/send-mail`
      try {
        const response = await GET_SERVICE(endpoint);
        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            getTimesheet()
          }, 5000);

        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
          }, 5000);
        }



      } catch (err) {

        return err.response;
      }

    }


    const toggler = () => {
      setToggle(!toggle);
    };

    return (

      <MainContainer

        content={
          <>
            <ErrorNotification show={error} content={errorMessage} />
            <SuccessNotification show={success} content={successMessage} />
            {timesheet?.length > 0 ? (
              loading === true ? (
                <Loader show={loading} />
              ) :

                <Table
                  show_add={true}
                  add_image={add}
                  header="Timesheets"
                  columns={columns}
                  search={search}
                  showSearchBar={true}
                  // searchInput={(e)=>getSearch(e)}
                  action="Add Timesheet"
                  action_button={() => navigate("/timesheet/add")}
                  body={timesheet.map((row, index) =>
                    <tr key={index}>

                      <td>
                        {row.agencyName}

                      </td>
                      <td>
                        {row.payPeriod}

                      </td>
                      <td>
                        {row.umbrellaName}

                      </td>
                      <td>
                        {row.ContractorsCount}

                      </td>
                      <td>
                        {row.date}{", "}{row.time}

                      </td>

                      <td>
                        {row.emailStatus === "COMPLETED" &&

                          <span style={{ color: "#85CE54", fontWeight: "600" }}>{row.emailStatus}</span>

                        }
                        {row.emailStatus === "FAILED" &&

                          <span style={{ color: "#DB504A", fontWeight: "600" }}>{row.emailStatus}</span>
 
                        }
                        {row.emailStatus === "NOT_SENT" &&
                          <span onClick={() => sendEmail(row)} style={{ color: "#56AACA", fontWeight: "600", cursor: "pointer" }}>SEND EMAIL</span>
                        }

                      </td>

                      <td className={styles.action_icons}>
                        <div
                        >
                          <span>

                            <Tooltip text="View Contractors">
                              <img
                                className={styles.view_icon}
                                src={view}
                                alt={view}
                                onClick={() => navigate("/timesheet/contractors", {
                                  state: {
                                    id: row.timeSheetFileId
                                  }
                                })}

                              />
                            </Tooltip>

                          </span>
                          <span >
                            <Tooltip text={"Download Timesheet"}>
                              <img

                                src={download}
                                alt={download}
                                onClick={() => downloadSheet(row)}
                              />
                            </Tooltip>

                          </span>
                          <span >
                            <Tooltip text={"Delete Timesheet"}>
                              <img

                                src={delete_icon}
                                alt={delete_icon}
                                onClick={() => deleteTimesheetModal(row.timeSheetFileId)}
                              />
                            </Tooltip>

                          </span>
                        </div>
                      </td>

                    </tr>




                  )

                  }
                  row={element}
                  row_list={
                    totalElement >= 30
                    ? [10, 20, 30]
                    : totalElement >= 20
                    ? [10, 20]
                    : totalElement >= 10
                    ? [10]
                    : []
                  }
                  row_item_click={(option) => getMoreSheets(option)}
                  pageCount={pageCount}
                  pageNumber={pageNumber}
                  pageChange={nextPage}
                  filterContent={

                    <div>
                      <div className={styles.filter}>


                        <div className={styles.select_input}>
                          <label>
                            Agency

                          </label>
                          <div className={styles.custom_select_container}>
                            <Select
                              disabled=""
                              value={agency}
                              loadMore={loadAgency}
                              click={getMoreAgencies}


                              onChange={(option) => {
                                setAgency(option)
                                const selectedAgency = allAgency.find((item) => item.name=== option);
                                if (selectedAgency) {
                                  setAgencyId(selectedAgency?.id);

                                }
                              }}
                              options={allAgency.map((agency) =>
                                agency.name
                              )}
                            />


                          </div>
                        </div>
                        <div className={styles.select_input}>
                          <label>
                            Umbrella

                          </label>
                          <div className={styles.custom_select_container}>
                            <Select
                              // disabled={agencyId!==""? "": "disabled"}
                              value={umbrella}
                              onChange={(option) => {
                                setUmbrella(option)
                                const selectedUmbrella = allUmbrella.find((item) => item.name === option);
                                if (selectedUmbrella) {
                                  setUmbrellaId(selectedUmbrella?.id)
                                }

                              }}
                              options={allUmbrella.map((umbrella) =>
                                umbrella.name
                              )}
                            />

                          </div>
                        </div>
                        <div className={styles.form_input}>
                          <label>Pay Period</label>
                          <input type="date" value={payPeriod} onChange={(e) => setPayPeriod(e.target.value)} />

                        </div>
                        <div className={styles.form_input}>
                          <label>Date Created</label>
                          <input type="date" value={dateCreated} onChange={(e) => setDateCreated(e.target.value)} />

                        </div>
                        <div className={styles.select_input}>
                          <label>
                            Email Status

                          </label>
                          <div className={styles.custom_select_container}>
                            <Select
                              disabled=""
                              value={emailStatus}
                              onChange={(option) => setEmailStatus(option)}
                              options={["COMPLETED", "SEND EMAIL"]}
                            />

                          </div>
                        </div>

                      </div>
                      <div className={styles.buttons}>
                        <button className={styles.clear} >
                          Cancel
                        </button>
                        <button className={styles.solid} onClick={filterTimesheet}>
                          Save
                        </button>
                      </div>

                    </div>




                  }
                />
            ) : (
              loading === true ? (
                <Loader show={loading} />
              ) :
                <div className="no_content">
                  <NoContent
                    image={agency_image}
                    header="No Timesheet Added"
                    text="Add a timesheet by clicking the button below"
                    buttonText="Add Timesheet"
                    next="/timesheet/add"
                  />
                </div>
            )
            }
             <Modal open={deleteToggle}
        header="Warning message."
        content={<span>  Are you sure you want to {text} this Timesheet
        </span>}
        show_yes={true}
        cancel={() => setDeleteToggle(false)}
        yes={() => deleteTimesheet(timesheetId)}
      />
          </>
        }
      />
    );
  };

  export default Timesheet;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import styles
import styles from "../Contractor/css/view.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, PUT_SERVICE, POST_SERVICE, DELETE_SERVICE, POST_FILE } from "../Services/Backend";
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import AgencyInformation from "./AgencyInformation";
import Payroll from "./Payroll";
import Template from "./Template";
import AgencyContractor from "./AgencyContractor";
import Timesheet from "./Timesheet";
import Modal from "../Common/Modal";
import AgencyPaySlips from "./AgencyPaySlips";


//import resources
import InfoCard from "../Common/InfoCard";
import Toggle from "../Common/Toogle";
import envelope from "../../Assets/envelop.svg";
import user from "../../Assets/user.svg";
import payslip from "../../Assets/payslip.svg";
import timesheet from "../../Assets/group.svg";
import phone_icon from "../../Assets/phone.svg";

const View = () => {

  const [agencyDetails, setAgencyDetails] = useState({})
  const [blockAgency, setBlockAgency] = useState(false)
  const [switchLive, setSwitchLive] = useState(false)
  const [switchBlock, setSwitchBlock] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  
  

  const location = useLocation()

  const [agencyOtherDetails, setAgencyOtherDetails] = useState({})

  const [contractorLoading, setContractorLoading] = useState(false)

  const [agencyUmbrella, setAgencyUmbrella] = useState()

  const [viewPlayslips, setViewPlayslips] = useState(false)
  const [viewRtis, setRtis] = useState(false)

  const [templateLoading, setTemplateLoading] = useState(false)

  const [componentCount, setComponentCount] = useState();
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [contractorCount, setContractorCount] = useState()
  const [cancelUpdateAgency, setCancelUpdateAgency] = useState(false)
  const [cancelUpdatePay, setCancelUpdatePay] = useState(false)
  const [cancelUpdateTemplate, setCancelUpdateTemplate] = useState(false)
  const [secondComponentCount, setSecondComponentCount] = useState(0)

  const [viewLoading, setViewLoading] = useState(false)






  const handleSuccess=(message)=>{
    setSuccess(true);
    setEditable(!editable)
    setSuccessMessage(message.message);
    setTimeout(() => {
      setSuccess(false);
      setSuccessMessage("")
    }, 2000);
   }

   const handleError = (message)=>{
    setError(true);
    setErrorMessage(message.message);
    setTimeout(() => {
      setError(false);
      setErrorMessage("")
      setToggle(false)
    }, 5000);
   }


  const getContractorCount = async () => {
    setContractorLoading(true)
    try {
      const response = await GET_SERVICE(`/agencies/${location.state.id}/payslip-contractor-count`);
      setContractorLoading(false)

      setContractorCount(response.data.data)

    } catch (err) {
      return err.response;
      setContractorLoading(false)
    }
  }
  const toggleCancelUpdateAgency = () => {

    setEditable(!editable);
    setComponentCount(0);
    setCancelUpdateAgency(false)

  }
  const getAgencyUmbrella = async () => {
    try {
      const response = await GET_SERVICE(`/agencies/${location.state.id}/umbrellas`);

      setAgencyUmbrella(response.data.data)
      setComponentCount(response.data.data.length)
    } catch (err) {
      return err.response;
    }
  }





  const [editable, setEditable] = useState(true);


  const navigate = useNavigate();
  const toggleEdit = (index, activeIndex) => {
    if (index === activeIndex) {
      setEditable(false);
    }
  };

  const [toggle, setToggle] = useState(false)
  const getAgencyDetails = async () => {

    const endpoint = `/agencies/${location.state.id}`
    try {
      const response = await GET_SERVICE(endpoint);
      console.log(response, "configuration")


      setAgencyDetails(response?.data?.data)
      setBlockAgency(!response?.data?.data?.live)
      setViewPlayslips(response?.data?.data?.agencyViewSetup?.canViewTimeSheet)
      setViewPlayslips(response?.data?.data?.agencyViewSetup?.canViewPayslip)
      setRtis(response?.data?.data?.agencyViewSetup?.canViewRTI)
    } catch (err) {
      return err.response;
    }
  }
  const getAgencyOtherDetails = async () => {

    const endpoint = `/agency-payroll-template/${location.state.id}`
    try {
      const response = await GET_SERVICE(endpoint);


      setAgencyOtherDetails(response?.data?.data)
  


    } catch (err) {
      return err.response;
    }
  }



  const [merge, setMerge] = useState(false);



  useEffect(() => {
    getAgencyOtherDetails()
    getAgencyDetails()


  

  }, [])


  




  const blockAgencyAction = async () => {

    setSwitchLive(false);
    setSwitchBlock(false)


    try {
      if (blockAgency === true) {

        const response = await PUT_SERVICE(`/agencies/${location.state.id}/un-block`)
   

        if (response.status = 200) {
          getAgencyDetails()
          setToggle(false)
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
          

          }, 2000);

        }



        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);

            setToggle(false)
          }, 5000);
        }

      }
      else {
        const responseBlock = await PUT_SERVICE(`/agencies/${location.state.id}/block`)
        console.log(responseBlock.status)
        if (responseBlock.status === 200) {
       
          getAgencyDetails()
          setToggle(false)
          setSuccess(true);
          setSuccessMessage(responseBlock.data.message);
          setTimeout(() => {
            setSuccess(false);
       

          }, 2000);

        }
        else {
          
          setError(true);
          setErrorMessage(responseBlock.data.message);
          setToggle(false)
          setTimeout(() => {
            setError(false);
      
          }, 5000);
        }

      }


    }
    catch (e) {
      return e.response;
    }


  }



  useEffect(() => {
    getAgencyUmbrella()
    getContractorCount()
  }, []);

  return (
    <MainContainer
      content={
        <>
          <ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />
          <h1 style={{ color: "#9D9D9D", fontSize: "20px", fontWeight: "600" }}>
            <span onClick={() => navigate("/agency")} style={{ cursor: "pointer" }}>Agency</span>{" "}
            <span style={{ color: "#313131" }}>/ {agencyDetails?.companyName}</span>
          </h1>

          <InfoCard
            top_level_image={user}
            top_level={agencyDetails?.companyName}
            next_level_image={envelope}
            next_level={agencyDetails?.contactEmailAddress}
            bottom_level_image={phone_icon}
            bottom_level={agencyDetails.contactPhoneNumber !== undefined && agencyDetails?.contactPhoneNumber?.countryCode + agencyDetails?.contactPhoneNumber?.number}
            show_live={blockAgency}
            show_block={blockAgency}
            toggle={() => setToggle(!toggle)}
            live_label="Status:"
            live="Live"
            block="Blocked"
            showlive={switchLive}
            showblock={switchBlock}
            live_text="Block"
            block_text="Activate"
            live_action={() => setSwitchLive(!switchLive)}
            block_action={() => setSwitchBlock(!switchBlock)}
            icon_top={timesheet}
            text_top="Contractors"
            number_top={contractorCount?.contractorCount}
            icon_bottom={payslip}
            text_bottom="Paysilps"
            number_bottom={contractorCount?.payslipCount}
          />
          <Toggle
            buttonLabels={[
              "Agency Information",
              "Payroll",
              "Agency Configuration",
              "Contractors",
              "Timesheet",
              "Payslips",
            ]}
            style={{
              justifyContent:"space-between"
            }}
            onClick={toggleEdit}
            edit={blockAgency === false ? editable : ""}
            contents={[
              <AgencyInformation
              id={location.state.id}
              success={handleSuccess}
              error={handleError}
              cancel={()=>setCancelUpdateAgency(true)}   
              editable={editable}
              />
              ,
              <Payroll
               id ={location.state.id}
               success={handleSuccess}
               error={handleError}
               cancel={()=>setCancelUpdatePay(true)}
               editable={editable}
              />,
           <Template 
             id ={location.state.id}
             success={handleSuccess}
             error={handleError}
             cancel={()=>setCancelUpdateTemplate(true)}
             editable={editable}
             templateLoading={templateLoading}
             viewLoading={viewLoading}
           
           />,
           
           <AgencyContractor 
            id={location.state.id}
            
            />,
            <Timesheet
            id={location.state.id}
            success={handleSuccess}
            error={handleError}
     

            />
,
<AgencyPaySlips
     id={location.state.id}
     name={agencyDetails?.companyName}
/>
             ,
            ]}
          />

          <Modal open={cancelUpdateAgency}
            header="Warning message."
            content={<span>  Are you sure you want to cancel update?
            </span>}
            show_yes={true}
            cancel={() => setCancelUpdateAgency(false)}
            yes={toggleCancelUpdateAgency}


          />
          <Modal open={cancelUpdatePay}
            header="Warning message."
            content={<span>  Are you sure you want to cancel update?
            </span>}
            show_yes={true}
            cancel={() => setCancelUpdatePay(false)}
            yes={() => { setEditable(!editable); setCancelUpdatePay(false) }}


          />

          <Modal open={cancelUpdateTemplate}
            header="Warning message."
            content={<span>  Are you sure you want to cancel update?
            </span>}
            show_yes={true}
            cancel={() => setCancelUpdateTemplate(false)}
            yes={() => { setEditable(!editable); setCancelUpdateTemplate(false) }}


          />
          <Modal open={toggle}
            header="Warning message."
            content={<span>  Are you sure you want to {blockAgency ? "activate" : "block"} this agency
            </span>}
            show_yes={true}
            cancel={() => setToggle(false)}
            yes={blockAgencyAction}


          />


      

        </>
      }
    />
  );
};

export default View;

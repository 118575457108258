import React, {useState, useEffect} from 'react';

//component imports
import ViewLoader from '../Common/ViewLoader';
import Table from '../Common/Table';
import NoContent from '../Common/NoContent';
import Loader from '../Common/Loader'
import Tooltip from '../Common/Tooltip';
import axios from 'axios';
import DateFormat from '../Utils/DateFormat';

//styles import
import styles from "../Contractor/css/view.module.css";
import style from '../Payroll/css/payslip.module.css'
import { GET_SERVICE, POST_SERVICE } from "../Services/Backend";


//resource import
import contractorIcon from "../../Assets/contractor-green.svg";
import view from "../../Assets/view.svg";

import download from "../../Assets/download_icon.svg";

const PaySlips = (props) => {
    const [payslip, setPayslip] = useState([])
    const [loading, setLoading] = useState(false)
    const [payslipElement, setPayslipsElement] = useState(10)
    const [totalPayslipsElement, setTotalPayslipsElement] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageCount, setPageCount] = useState()
    const [showPayslip, setShowPayslip] = useState(false)
    const [date, setDate] = useState("")

    const [id, setId] = useState("")
 
    const [payslipData, setPayslipData] = useState([])
      const payslipHeaders = [
        { key: 'fullName', label: 'Period No' },
        { key: 'niNumber', label: 'Pay Period' },
        { key: 'emailAddress', label: 'Date Updated' },
        { key: 'gender', label: 'Gross' },
        { key: 'agencies', label: "Net" },

        {key :'actions', label:"Actions"}
      ];
      const getPayslipData =async()=>{
        setLoading(true)
  
        try {
            const response = await GET_SERVICE(`/payslips/${id}`);
            if (response.status === 200) {
            
   
              setPayslipData(response?.data?.data);
              setLoading(false)
              console.log(response)
              
            }
      
      
      
          } catch (err) {
            return err.response
          }

    }
    const clearField = ()=>{
        setDate("")
    
    
    
    
      }
    const openPayslip =(row)=>{
      setId(row.id)
 
      setShowPayslip(!showPayslip)
    }
   
     useEffect(()=>{
      getPayslipData()
     }, [id])
    const downloadPayslip = async (row)=>{
      setLoading(true)

      const endpoint = `https://payrolleasy-api-b7a634266a4c.herokuapp.com/api/v1/payslips/download/${row.id}`
      const token = sessionStorage.getItem("token")
      const headers = {
        
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
     
      }

         
      try {
    
        const response = await axios.get(endpoint, {
          responseType: 'arraybuffer',
          headers: headers,
        }); 

          const byteArray = new Uint8Array(response.data);
          const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = props.name+"_"+row.payPeriod+".pdf"
          link.click();
          setLoading(false)
        
        

      

  
        
       
      } catch (error) {
      
        console.error('Error downloading file:', error);
        setLoading(false)
        
      }
    
    
    
       }
  
      const getPayslips = async (page) => {
        setLoading(true)
        const endpoint = `/contractors/${props.id}/all-payslips?page=${page || 0}&size=10`
        try {
          const response = await GET_SERVICE(endpoint);
          console.log(response)
          if (response.status===200){

            setPayslip(response.data.data.payslipDaoList)
            setTotalPayslipsElement(response.data.data.size);
      
            setPageNumber(page || 0)
            setPageCount(response.data.data.page)
          }
      
          setLoading(false)
    
        } catch (err) {
          return err.response;
        }
      }

      const getMorePayslips = async (option) => {
        const endpoint = `/contractors/${props.id}/all-payslips?page=${pageNumber || 0}&size=${option}`
        setLoading(true)
        try {
          const response = await GET_SERVICE(endpoint);
          if (response.status===200){
    
            setPayslip(response.data.data.payslipDaoList)
            setPayslipsElement(option)
          setPageCount(response.data.data.page)
          }
          setLoading(false)

    
        } catch (err) {
          return err.response;
        }
      }
      useEffect(()=>{
        getPayslips()
    

      },[])
      const postFilter = async (page) => {
       
        setLoading(true)
        const url =`/payslips/contractor/filter?page=0&size=10`
        const body={
            "payPeriod": DateFormat(date),
            "contractorId": props.id
        }
   
        try {
          const response = await POST_SERVICE(url, body);
      
          
          if (response.status===200){
            setPayslip(response.data.data.payslipDaoList);
            setLoading(false)
            setPageNumber(page || 0)
            
           
       
            setPageCount(response.data.data.numberOfPages )
           
          } else{
         
            setLoading(false)
          }
     
     
    
    
     
        } catch (err) {
          return err.response;
        }
      };
          
      const nextPage = (e) => {
    
        let page = e.selected
    
    
        getPayslips(page)
      }

    return (
        <>
        {payslip.length>0 ?
          loading === true ?
            <ViewLoader  />
            :
            <Table
              columns={payslipHeaders}
              row={payslipElement}
              showSearchBar={true}
              filterContent={
                <div>
                  <div className={styles.filter}>
               
                    <div className={styles.form_input}>
                      <label>Pay Period</label>
                      <div className={styles.input_container}>
                        <input type="date" value={date} onChange={(e)=>setDate(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} onClick={clearField}>Cancel</button>
                    <button className={styles.solid} onClick={postFilter} >
                      Save
                    </button>
                  </div>
                </div>
              }
              body={
                payslip.map((row, index) =>
                  <tr key={index}>
                    <td>
                      {index+1}
                    </td>
                    <td>
                      {row.payPeriod}
                    </td>
                    <td>
                      {row.dateUpdated}
                    </td>
                    <td>
                      £{row.gross}
                    </td>
                    <td>
                      £{row.net}
                    </td>
            
                    <td className={styles.action_icons} style={{justifyContent:"flex-start"}}>
                    <div
                    >
                      <span>
  
                        <Tooltip text="View Payslip">
                          <img
                            className={styles.view_icon}
                            src={view}
                            alt={view}
                            onClick={()=>openPayslip(row)}
                          
                        
                          />
                        </Tooltip>
                
                      </span>
                      <span >
                      <Tooltip text={"Download Payslip"}>
                          <img
                
                            src={download}
                            alt={download}
                            onClick ={()=>downloadPayslip(row)}
                          />
                        </Tooltip>
                 
                      </span>
                  
                    </div>
                  </td>
                  </tr>


                )

              }
              row_list={
                totalPayslipsElement <= 30 && totalPayslipsElement >= 20
                  ? [10, 20, 30]
                  : totalPayslipsElement <= 20 && totalPayslipsElement >= 10
                    ? [10, 20]
                    : totalPayslipsElement <= 10
                      ? [10]
                      : []
              }
              row_item_click={(option) => getMorePayslips(option)}
              pageCount={pageCount}
              pageChange={nextPage}
              pageNumber={pageNumber}

            />
          :
          loading === true ?
            <ViewLoader />
            :
            <>
              <NoContent
                image={contractorIcon}
                text="No Payslips Available"
                hide={true}
                change={{ margin: "5% 0" }}

              />
            </>

        }

<div className={showPayslip ? style.container : style.hidden}  >
                    {loading === true ?
                        <Loader show={loading}/>
                    :  <div >
                    <div className={style.content} >
                        <div className={style.flex_end} onClick={()=>setShowPayslip(!showPayslip)} >
                          <span>x</span>
                      
                        
                       
                        </div>
                        <div id="page1-content">
                        <h1>Pay Advice</h1>
                        <table className={style.main_table}>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                <tr>
                                    <th>
                                        Employee No
                                    </th>
                                    <th>
                                        Employee Name
                                    </th>
                                    <th>
                                        Process Date
                                    </th>
                                    <th>
                                        NI Number
                                    </th>
                                    <th>
                                        Tax Period
                                    </th>
                                    <th>
                                        Periods Covered
                                    </th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr>
                                    {/* <td>{payslipData?.employeeNo}</td> */}
                                    <td>501104</td>
                                    <td>{payslipData?.fullName}</td>
                                    <td>{payslipData?.payPeriod}</td>
                                    <td>{payslipData?.niNumber}</td>
                                    <td>{payslipData?.taxPeriod}</td>
                                    {/* <td>{payslipData?.shiftDate}</td> */}
                                    <td>1</td>
                                </tr>
                            </tbody>
        
        
                        </table>
                        <div className={style.flex}>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>
                                            Payments
                                        </th>
                                        <th>
                                            Units
                                        </th>
                                        <th>
                                            Rate
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
        
                                <tbody >
                                    
                                        <tr >
                                              <td>Salary Paid</td>
                                            <td>
                                                {payslipData?.salaryPaidUnit}
                                            </td>
                                            <td>
                                                {payslipData?.salaryPaidRate}
                                            </td>
                                            <td>
                                                £{payslipData?.salaryPaidAmount}
                                            </td>
                                        </tr>
                                     
                                        <tr >
                                              <td>Additional Holiday Pay</td>
                                            <td>
                                              1
                                            </td>
                                            <td>
                                                {payslipData?.holidayPay}
                                            </td>
                                            <td>
                                                £{payslipData?.holidayPay}
                                            </td>
                                        </tr>
                                   
                                     {/* <tr>
                                     
                                        <td>12/05/2023</td>
                                        <td>1.5</td>
                                        <td>$19.00</td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>
                                            Deductions
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                 
                                        <tr >
                                          
                                            <td>
                                         Tax
                                            </td>
                                            <td>
                                                £{payslipData?.tax}
                                            </td>
                                        </tr>
                                        
                                        <tr >
                                          
                                            <td>
                                         NI Contribution
                                            </td>
                                            <td>
                                            £{payslipData?.employeeNiOrNiContribution}
                                            </td>
                                        </tr>
                                   
                                        
                                        <tr >
                                          
                                            <td>
                                         Pension Contribution
                                            </td>
                                            <td>
                                            £{payslipData?.employeePensionOrPensionContribution}
                                            </td>
                                        </tr>
                                   
                                  
        
                                </tbody>
                            </table>
                        </div>
                        <div className={style.flex}>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Employee</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>{payslipData?.fullName}</td>
                              
                                    </tr>
                                    <tr>
                                    <td>{payslipData?.contractorAddress?.homeAddressOne}</td>
                                   
                                    </tr>
                                    <tr>
                                    <td>{payslipData?.contractorAddress?.postCode}</td>
                                   
                                    </tr>
                                    <tr>
                                    <td>{payslipData?.contractorAddress?.country}</td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Payments this period</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                       Total Gross pay
                                        </td>
                                        <td>
                                        £{payslipData?.totalGrossPay}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Gross for Tax</td>
                                        <td>  £{payslipData?.grossForTax} </td>
                                    </tr>
                                    <tr>
                                        <td>Gross for NI</td>
                                        <td>  £{payslipData?.grossForNi} </td>
                                    </tr>
                                    <tr>
                                        <td>Total deductions</td>
                                        <td>  £{payslipData?.totalDeductions} </td>
                                    </tr> 
                                    <tr>
                                        <td>Employer NI</td>
                                        <td>  £{payslipData?.employerNi} </td>
                                    </tr>
                                    <tr>
                                    <td>
                                    <hr/>
                                    </td>
                                    <td>
                                    <hr/>
                                    </td>
                                        </tr> 
                                        <tr>
                                        <td>Tax Code </td>
                                        <td>  {payslipData?.contractorTaxCode} </td>
                                    </tr> 
                                     
                                    <tr>
                                        <td>Payment Method</td>
                                        <td>  BACS </td>
                                    </tr>
                                    <tr>
                                        <td>Tax Basis</td>
                                        <td>  {payslipData?.taxBasis} </td>
                                    </tr>
                                    <tr>
                                        <td>Hours in Period</td>
                                        <td>  {payslipData?.salaryPaidUnit} </td>
                                    </tr>                                                                                 
                                </tbody>
                            </table>
                            <div className={style.table_div}>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Year to Date</th>
                                        <th></th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Gross for tax</td>
                                        <td>£{payslipData?.contractorYearToDate?.grossForTax}</td>
                                    </tr>
                                    <tr>
                                        <td>Gross for Ni</td>
                                        <td>£{payslipData?.contractorYearToDate?.grossForNi}</td>
                                    </tr>
                                    <tr>
                                        <td>Tax Deducted</td>
                                        <td>£{payslipData?.contractorYearToDate?.taxDeducted}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee NI</td>
                                        <td>£{payslipData?.contractorYearToDate?.employeeNi}</td>
                                    </tr>
                                    <tr>
                                        <td>Employer NI</td>
                                        <td>£{payslipData?.contractorYearToDate?.employerNi}</td>
                                    </tr>  
                                                              
                                </tbody>
                               
                            </table>
                            <div className={style.defaultBox} style={{borderColor:payslipData?.paySlipColor}}>
                                        <h2 style={{paySlipColor: payslipData?.paySlipColor}}>NET PAY</h2>
                                        <h3 style={{paySlipColor: payslipData?.paySlipColor}}>£{payslipData?.netPay}</h3>
        
                                    </div>
                                    </div>
        
                        </div>
                        </div>
                  
                        <div style={{ pageBreakAfter: 'always' }} id="page2-content">
                        <h1>Pay Advice: Reconlication Statement</h1>
                        <table className={style.main_table}>
                        <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                <tr>
                                    <th>
                                        Employee No
                                    </th>
                                    <th>
                                        Employee Name
                                    </th>
                                    <th>
                                        Process Date
                                    </th>
                                    <th>
                                        NI Number
                                    </th>
                                    <th>
                                        Tax Period
                                    </th>
                                    <th>
                                        Periods Covered
                                    </th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr>
                                    {/* <td>{payslipData?.employeeNo}</td> */}
                                    <td>501104</td>
                                    <td>{payslipData?.fullName}</td>
                                    <td>{payslipData?.payPeriod}</td>
                                    <td>{payslipData?.niNumber}</td>
                                    <td>{payslipData?.payPeriod}</td>
                                    {/* <td>{payslipData?.shiftDate}</td> */}
                                    <td>1</td>
                                </tr>
                            </tbody>
        
        
                        </table>
                        <div className={style.flex}>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>
                                            Company Receipts
                                        </th>
                                        <th>
                                            Week End Date
                                        </th>
                                        <th>
                                            Units
                                        </th>
                                        <th>
                                            Rate
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
        
                                <tbody >
                                    {payslipData?.timeSheetDataList?.map((item, index) =>
                                        <tr key={index}>
                                            <td>
                                          Standard Hourly Rate
                                            </td>
                                            <td>
        
                                            </td>
                                            <td>
                                                {item.units}
                                            </td>
                                            <td>
                                                {item.payRate}
                                            </td>
                                            <td>
                                                £{item.gross}
                                            </td>
                                        </tr>
                                    )
                                 
        
                                    }
                                 
                                 <tr className={style.defaultItem} style={{backgroundColor:payslipData?.paySlipColor}}> 
                                 <td>
                                    Total
                                            </td>
                                            <td>
        
                                            </td>
                                            <td>
                                                
                                            </td>
                                            <td>
                                              
                                            </td>
                                            <td >
                                            £{payslipData?.totalGross}
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>
                                            Company Costs
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                             
                                        <tr >
                                          
                                            <td>
                                              
                                           Margin
                                            </td>
                                            <td>
                                                
                                                £{payslipData?.margin}
                                            </td>
                                        </tr>
                                   
                                        <tr >
                                          
                                          <td>
                                            
                                          Holiday Pay
                                          </td>
                                          <td>
                                              
                                              £{payslipData?.holidayPay}
                                          </td>
                                      </tr>
                                      <tr >
                                          
                                          <td>
                                            
                                          Employer NI
                                          </td>
                                          <td>
                                              
                                              £{payslipData?.employerNi}
                                          </td>
                                      </tr>
                                      <tr >
                                          
                                          <td>
                                            
                                          Employer Pension Contribution
                                          </td>
                                          <td>
                                              
                                              £{payslipData?.employerPensionContribution}
                                          </td>
                                      </tr>
                                      <tr className={style.defaultItem} style={{backgroundColor:payslipData?.paySlipColor}} >
                                          
                                          <td>
                                            
                                          Total
                                          </td>
                                          <td>
                                              
                                              £{payslipData?.companyCostTotal}
                                          </td>
                                      </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={style.flex}>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Breakdown</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Gross</td>
                                        <td>£{payslipData?.gross || 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Holiday Pay</td>
                                        <td>£{payslipData?.holidayPay}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Gross Pay</td>
                                        <td>£{payslipData?.totalGrossPay}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Holiday Pay Entitlement</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>BroughtForward</td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Accured this Period</td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Taken this Period</td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Carried Forward</td>
                                        <td>£0.00</td>
                                    </tr>
                                </tbody>
                                
                            </table>
        
        
                        </div>
                        <h3>Expenses Breakdown</h3>
                        <div className={style.flex}>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Expenses Incurred</th>
                                        <th>Units</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>total</td>
                                        <td>units</td>
                                        <td>rates</td>
                                        <td>£0.00</td>
                                    </tr>
                                 
                                    
                                </tbody>
                            </table>
                            <table>
                            <thead className={style.default} style={{backgroundColor:payslipData?.paySlipColor}}>
                                    <tr>
                                        <th>Expenses</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <td>BroughtForward</td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Accured this Period</td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Taken this Period</td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Carried Forward</td>
                                        <td>£0.00</td>
                                    </tr>
                                </tbody>
                            </table>
        
        
                        </div>
                        </div>
                        </div>
                    </div>

                    }
       
        </div>

      </>
    );
};

export default PaySlips;

import React, { useState, useRef, useEffect } from "react";
//component imports
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const MainContainer = (props) => {
  const initialValue = localStorage.getItem("toggle");
  const [toggle, setToggle] = useState(initialValue === "true" ? true : false);
  const handleToggle = () => {
    if (toggle !== initialValue) {
      setToggle(!toggle);
      localStorage.setItem("toggle", toggle);
      
    } else {
      setToggle(toggle);
    }
  };

  useEffect(() => {
    handleToggle();
  }, []);

  return (
    <>
      <Navbar toggle={toggle} toggler={handleToggle} />
      <div className="main_container">
        <Sidebar toggle={toggle} />
        <div className={toggle ? "full_container" : "subContainer"}>
          {props.content}
        </div>
      </div>
    </>
  );
};

export default MainContainer;

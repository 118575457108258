import React from 'react';

//style import
import styles from './css/view_button.module.css'

const ViewButton = (props) => {
    return (
      
            <div className={styles.buttons}>
            <button className={styles.clear} onClick={props.cancel}>
              Cancel
            </button>
                {!props.show ?
                        <div>
                        {props.loader ?
                
                <button className={styles}><div /></button>
                :
                <button className={styles.solid} onClick={props.save}>
                  <span>Save</span> 
                </button>
              }
                        </div>
                        :
                        <></>

                }
          </div>
    );
};

export default ViewButton;
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

//import styles
import styles from "../Contractor/css/add.module.css";

//import resources
import upload from "../../Assets/upload.svg";
import process_bar from "../../Assets/process-bar-two.svg";


//component import
import MainContainer from "../Common/MainContainer";
import Form from "../Common/Form";
import Modal from '../Common/Modal'
import { POST_SERVICE, GET_SERVICE,POST_FILE } from "../Services/Backend"
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import countryData from '../Utils/country.json'
import countryCode from '../Utils/country_code.json'
import EmailValidate from "../Utils/EmailValidate";
import Bulk from "../Common/Bulk";
import ProgressBar from "../Utils/ProgressBar";
import error_icon from "../../Assets/caution.svg"
import file_icon from "../../Assets/file.svg"

 
const Add = () => {
  const [bulk, setBulk] = useState(false)
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(true)
  const [showProgress, setShowProgress] = useState(false)
  const [progress, setProgress] = useState(0);
  const [responseSheet, setResponseSheet] = useState([])
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [nameError, setNameError] = useState(false)
  const [companyNo, setCompanyNo] = useState("");
  const [companyNoHead, setCompanyNoHead] = useState("+44")
  const [town, setTown] = useState("");
  const [townError, setTownError] = useState(false)
  const [date, setDate] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [homeAddressError, setHomeAddressError] = useState(false)
  const [homeAddressTwo, setHomeAddressTwo] = useState("");
  const [postcode, setPostcode] = useState("");
  const [postcodeError, setPostcodeError] = useState(false)
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false)
  const [phone, setPhone] = useState("");
  const [phoneHead, setPhoneHead] = useState("+44")
  const [phoneError, setPhoneError] = useState(false)
  const [mobile, setMobile] = useState("");
  const [mobileHead, setMobileHead] = useState("+44")
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false)
  const [umbrella, setUmbrella] = useState("");
  const [listUmbrella, setListUmbrella] = useState([]);
  const [listUmbrellaId, setListUmbrellaId] = useState([])
  const [emailErrorText, setEmailErrorText] = useState("")
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState("No file selected");
  const [bulkLoading, setBulkLoading] = useState(false)


  const navigate = useNavigate();
  const tableRef = useRef(null)

  const [toggle, setToggle] = useState();

  const toggler = () => {
    setToggle(!toggle);
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event?.target?.files[0]);
    setFilename(event?.target?.files[0]?.name);
  };

  const [componentCount, setComponentCount] = useState(1);
  const [isImageVisible, setIsImageVisible] = useState(true);

  const createForm = () => {
    if (componentCount < listUmbrella.length){
      setComponentCount((prevCount) => prevCount + 1);
    }
  };
  const deleteForm = () => {
    setComponentCount((prevCount) => prevCount - 1)
    
   if(listUmbrellaId.length!==0){
    listUmbrellaId.pop()
    umbrella.pop()
   }

  }

  const toggleImage = () => {
    setIsImageVisible((prevValue) => !prevValue);
  };



  const goNext = async () => {

    const body = {
      "companyName": name,
      "companyNumber": companyNoHead+companyNo,

      "town": town,
      "primaryAddressOne": homeAddress,
      "primaryAddressTwo": homeAddressTwo,
      "postCode": postcode,
      "country": country,
      "contactPhoneNumber": {
        "countryCode": phoneHead,
        "number": phone
      },
      "contactMobileNumber": {
        "countryCode": mobileHead,
        "number": mobile
      },
      "contactEmailAddress": email,
      "umbrellaId": listUmbrellaId

    };
   
    const endpoint = "/agencies"

    let hasError = false;

    if (homeAddress === "") {
      setHomeAddressError(true);
      hasError = true;
    } else {
      setHomeAddressError(false);
    }

    if (postcode === "") {
      setPostcodeError(true);
      hasError = true;
    } else {
      setPostcodeError(false);
    }

    if (country === "") {
      setCountryError(true);
      hasError = true;
    } else {
      setCountryError(false);
    }

    if (name === "") {
      setNameError(true);
      hasError = true;
    } else {
      setNameError(false);
    }

    if (phone === "") {
      setPhoneError(true);
      hasError = true;
    } else {
      setPhoneError(false);
    }

    if (town === "") {
      setTownError(true);
      hasError = true;
    } else {
      setTownError(false);
    }

    if (email === "") {
      setEmailError(true);
      setEmailErrorText("Please fill in your email address")
      hasError = true;
    } else {
      if (EmailValidate(email)){
        setEmailError(false)
        setEmailErrorText("")
      }
      else{
        setEmailError(true)
        setEmailErrorText("Please enter a valid email")
        setEmail("")
        hasError = true;
      }
    }

    if (!hasError) {
      setLoading(true);
      try {
   
        
        const response = await POST_SERVICE(endpoint, body)
        console.log(response)
        if (response.status === 201) {
   
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            setLoading(false)
            navigate("/agency/next", {
              state: {
                id: response.data.data,
              },
            });
          }, 2000);

        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setLoading(false)
          }, 5000);
        }


      }
      catch (e) {
        return e.response;
             setLoading(false)
      }

    }
  };
  const getUmbrella = async () => {
    

    try {
      const response = await GET_SERVICE("/umbrellas");

      setListUmbrella(response.data.data); 
      setImageLoading(false)

    } catch (err) {
      return err.response;
    }
  };
 const closeError = ()=>{
  setHomeAddressError(false);
  setPostcodeError(false);
  setCountryError(false);
  setNameError(false);
  setPhoneError(false);
  setTownError(false);
  setEmailError(false);
  setEmailErrorText("");
 }
  
 const uploadSheet = async (event) => {
  setBulkLoading(true);
  setProgress(0);
  setShowProgress(true)


  const interval = setInterval(() => {
    setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 0));
  }, 200);
  setResponseSheet([])
  event.preventDefault();
  const formData = new FormData();
  formData.append("file", selectedFile);
  try {
    const response = await POST_FILE(
      `/agencies/bulk-upload`,
      formData
    );

    if (response.status === 200 || response.status ===201) {
      setResponseSheet(response.data.data)
      clearInterval(interval);
      setShowProgress(false)
       setSuccessMessage("Agency List uploaded Successfully")
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false);
        setFilename("No file selected")
        setBulk(false)

 
    
        setBulkLoading(false)
      }, 2000);



    } else {

      setError(response.data.message)
      setResponseSheet(response.data.data)
      clearInterval(interval);
      
    
      setShowProgress(false)
      setError(true);
      setErrorMessage("Upload failed")
      setTimeout(() => {
        setError(false);
        setBulkLoading(false)
      }, 5000);
    }
  } catch (error) {
    console.log(error);


  }
}
  useEffect(() => {
    getUmbrella();
  }, []);
  useEffect(()=>{
    window.addEventListener('keydown', closeError);
  }, [])
  return (
    <MainContainer
      content={
        <>
          <ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />
          <div className={styles.content_header}>
            <div>
              <h1>New Agency</h1>
              <p>Please fill out the forms below to create add a new Agency</p>
            </div>
            <img src={process_bar} alt={process_bar} />
          </div>
          <div className={styles.form_container}>
            <Form
              show_header={true}
              header={
                <div style={{ color: "#FF0000" }}>
                  <p>Umbrella Selection</p>
                </div>
              }
              bulk="Bulk Upload"
              show_bulk={true}
              line={true}
              bulk_icon={upload}
              input_type="select"
              bulk_action={() => setBulk(!bulk)}

            />

            {Array.from({ length: componentCount }).map((_, index) => (
              <Form
                input_type="select"
                show_one={true}
                label_one="Select Umbrella for Agency Portal"
                value_one="Company Name"
                select_one_value={umbrella[index] || ""} // Use umbrella[index] for the corresponding form
                disabled=""
                select_change={(option) => {
                  setUmbrella((prevUmbrella) => {
                    const updatedUmbrella = [...prevUmbrella];
                    updatedUmbrella[index] = option; // Set umbrella name at the corresponding index
                    return updatedUmbrella;
                  });
                  const selectedUmbrella = listUmbrella?.find((item) => item.name === option);
                  if (selectedUmbrella) {
                    setListUmbrellaId((prevIds) => {
                      const updatedIds = [...prevIds];
                      updatedIds[index] = selectedUmbrella.id; // Set umbrella ID at the corresponding index
                      return updatedIds;
                    });
                  }
                }}
                select_one={listUmbrella?.map((umbrella) => umbrella.name)} // Pass an array of umbrella names as options
                image={index === 0 && imageLoading!==true &&listUmbrella?.length!==1 ? true : false}
                imagedel={index >= 1 ? true : false}
                add={() => {
                  createForm();
                  toggleImage();
                }}
                del={() => {
                  deleteForm()
                }}
              />
            ))}
            <Form
              show_header={true}
              header="Contact Details"

              error_one="Please fill in your company name"
              error_one_true={nameError}

              show_bulk={false}
              line={true}
              compulsory_one={true}
              input_type="input"
              show_one={true}
              label_one="Name"
              placeholder_one="Name"
              value_one={name}
              disabled=""
              input_change={(e) => setName(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Company No"
              placeholder_two="Company No"
              type_two="number"
              value_two={companyNo}
              
            
              disabled_two=""
              input_change_two={(e) => setCompanyNo(e.target.value)}
            />
                      <Form
            
              
              compulsory_tel={true}
              show_tel={true}
              label_tel="Phone"
              disabled_tel=""
              select_tel_value={phoneHead}
              select_change_tel={(option) => setPhoneHead(option)}
              error_tel_true={phoneError}
              error_tel="Please fill in your phone number"
              select_tel={countryCode.map((code) =>
                code.code
              )}
              select_tel_two_value={mobileHead}
              select_change_tel_two={(option) => setMobileHead(option)}
              select_tel_two={countryCode.map((code) =>
                code.code
              )}
              value_tel={phone}
              placeholder_tel="Phone"
              input_change_tel={(e) => setPhone(e.target.value)}
              show_tel_two={true}
              label_tel_two="Mobile"
              disabled_tel_two=""

              value_tel_two={mobile}
              placeholder_tel_two="Mobile"
              input_change_tel_two={(e) => setMobile(e.target.value)}
            />

            <Form
              input_type="select"
              show_one={true}
              label_one="Country"
              select_one_value={country}
              error_one="Please select a country"
              error_one_true={countryError}
              compulsory_one={true}
              select_change={(option) => setCountry(option)}
              disabled=""
              value_one={country}
              select_one={countryData.map((country) =>
                country.name

              )}

            />
            <Form
              show_header={true}
              header="Primary Address"
              line={true}
              bulk_icon={upload}
              input_type="input"
              compulsory_one={true}
              error_one="Please fill in your Primary Address"
              error_one_true={homeAddressError}
              show_one={true}
              label_one="Address 1"
              placeholder_one="Home Address"
              value_one={homeAddress}
              disabled=""
              input_change={(e) => setHomeAddress(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Address 2"
              placeholder_two="Home Address"
              value_two={homeAddressTwo}
              disabled_two=""
              input_change_two={(e) => setHomeAddressTwo(e.target.value)}
            />
            <Form
              input_type="input"
              show_one={true}
              label_one="Post Code"
              placeholder_one="Post Code"
              compulsory_one={true}
              compulsory_two={true}
              value_one={postcode}
              error_one="Please fill in your post code"
              error_one_true={postcodeError}
              disabled=""
              input_change={(e) => setPostcode(e.target.value)}
              show_two={true}
              input_type_two="input"
              label_two="County"
              placeholder_two="County"
              value_two={town}
              error_two="Please fill in your county"
              error_two_true={townError}
              disabled_two=""
              input_change_two={(e) => setTown(e.target.value)}
            />
            <Form
              show_header={true}
              header="Primary Contact Information"
              line={true}
              input_type="tel"
              input_type_two="tel"
              compulsory_tel={true}
              show_tel={true}
              label_tel="Phone"
              disabled_tel=""
              select_tel_value={phoneHead}
              select_change_tel={(option) => setPhoneHead(option)}
              error_tel_true={phoneError}
              error_tel="Please fill in your phone number"
              select_tel={countryCode.map((code) =>
                code.code
              )}
              
              value_tel={phone}
              placeholder_tel="Phone"
              input_change_tel={(e) => setPhone(e.target.value)}
              select_tel_two_value={mobileHead}
              select_change_tel_two={(option) => setMobileHead(option)}
              select_tel_two={countryCode.map((code) =>
                code.code
              )}
              show_tel_two={true}
              label_tel_two="Mobile"
              disabled_tel_two=""

              value_tel_two={mobile}
              placeholder_tel_two="Mobile"
              input_change_tel_two={(e) => setMobile(e.target.value)}
            />

            <Form
              show_one={true}
              input_type="input"
              label_one="Email"
              compulsory_one={true}
              placeholder_one="Email Address"
              error_one={emailErrorText}
              error_one_true={emailError}
              value_one={email}
              disabled=""
              type_one="email"
              input_change={(e)=>setEmail(e.target.value)}
            />
  
            <div className={styles.buttons}>
              <button className={styles.clear} onClick={() => setToggle(true)}>
                Cancel
              </button>
              {loading ?
                <button className="loading_button"><div /></button>
                :
                <button className={styles.solid} onClick={goNext}>
                  <span>Next</span>{" "}
                  <div className={styles.next_icon} />

                </button>
              }
            </div>
          </div>
          <Modal open={toggle}
            header="Warning message."
            content={<span>  Are you sure you want to cancel
            </span>}
            show_yes={true}
            cancel={() => setToggle(false)}
            yes={() => navigate("/agency")}
 

          />
                   <table  ref={tableRef} style={{display: "none"}}>
                 <tbody>
                    <tr>
                    <th>UmbrellaName</th>
      <th>Name</th>
      <th>CompanyNo</th>
      <th>Country</th>
      <th>Address 1</th>
      <th>Address 2</th>
      <th>Post Code</th>
      <th>Town</th>
      <th>PhoneNumberCountryCode</th>
      <th>Phone Number</th>
      <th>MobileNumberCountryCode</th>
      <th>Mobile Number</th>
      <th>Email</th>
      <th>Holiday Pay Included</th>
      <th>Employers NI Insurance Included</th>
      <th>Employers Pension Included</th>
      <th>VAT Charged</th>
                    </tr>
                   
                  </tbody>
                </table>
          <Bulk open={bulk} name="Agency" bulk_action={() => setBulk(!bulk)} table_name="Agency_Template" tableRef={tableRef} handleFileSelect={handleFileSelect} save={uploadSheet}>
          <span style={{display:"flex", alignItems:"center", columnGap:"5px", marginBottom: "10px"}}><img style={{width:"20px", height:"20px"}}src={file_icon} alt ={file_icon}/><p style={{  fontSize: "15px", fontWeight: "400" , margin:"5px"}}>{filename}</p></span>
          {showProgress &&
                  <ProgressBar progress={progress} />

                }



              {responseSheet?.length>0 &&
                  <div style={{ maxHeight: "100px", overflowY: "scroll", marginTop: "10px", marginBottom: "10px" }}>
                  <h3  style={{fontWeight:"600", margin:0}}>Process Log Information</h3>
                  <p style={{color:"#A5A5A5", fontSize:"12px", margin:0}}>Various flags recorded while template was being processed</p>
                  {responseSheet.map((sheet) =>

<span style={{display:"flex", alignItems:"center", columnGap:"10px"}}><img style={{width:"20px", height:"20px"}}src={error_icon} alt ={error_icon}/><p style={{ color: "#DB504A", fontSize: "15px", fontWeight: "500" , margin:"5px"}}>{sheet}</p></span>

                  )

                  }
                </div>
              }
            </Bulk>
 

        </>
      }
    />
  );
};

export default Add;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import styles
import styles from "../Contractor/css/add.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import {POST_SERVICE} from '../Services/Backend'
import ColorPicker from 'react-pick-color';
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import Modal from '../Common/Modal'
import countryData from '../Utils/country.json'
import countryCode from '../Utils/country_code.json'
import EmailValidate from "../Utils/EmailValidate";



//import resources
import Form from "../Common/Form";
import upload from "../../Assets/upload.svg";


const Add = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false)
  const [companyNo, setCompanyNo] = useState("");
  const [nationality, setNationality] = useState("");
  const [countryError, setCountryError] = useState(false)
  const [town, setTown] = useState("");
  const [color, setColor] = useState('#85CE54');

  const [homeAddress, setHomeAddress] = useState("");
  const [homeAddressError, setHomeAddressError] = useState(false)
  const [homeAddressTwo, setHomeAddressTwo] = useState("");
  const [companyNoHead, setCompanyNoHead] = useState("+44")
  const [postcode, setPostcode] = useState("");
  const [postcodeError, setPostcodeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false)
  const [mobile, setMobile] = useState("");
  const [townError, setTownError] = useState(false)
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [emailErrorText, setEmailErrorText] = useState("")
  const navigate = useNavigate();
 const[phoneHead, setPhoneHead] = useState("")
 const [mobileHead, setMobileHead] = useState("")
  const [toggle, setToggle] = useState();
  const [colorShow, setColorShow] = useState(false)


  const addUmbrella = async () => {
 
     
    const body = {
      "name": name,
      "country": nationality,
      "addressOne": homeAddress,
      "addressTwo": homeAddressTwo,
      "postCode": postcode,
      "town": town,
      "contactPhoneNumber":{
        "countryCode":phoneHead,
        "number":phone
    },
    "contactMobileNumber":{
        "countryCode":mobileHead,
        "number":mobile
    },
      "contactEmail": email,
      "isPensionApplicable":"true",
      "paySlipColorCode":color,
      "companyPhoneNumber": {
        "countryCode": companyNoHead,
        "number":companyNo
    }
  }

    const endpoint ="/umbrellas"
    let hasError = false; // Flag to track if any field has an error
  
    if (name === ""){
      setNameError(true);
      hasError = true;
    } else {
      setNameError(false);
    }
    if (homeAddress === "") {
      setHomeAddressError(true);
      hasError = true;
    } else {
      setHomeAddressError(false);
    }
    if (nationality=== "") {
      setCountryError(true);
      hasError = true;
    } else {
      setCountryError(false);
    }


    if (postcode === "") {
      setPostcodeError(true);
      hasError = true;
    } else {
      setPostcodeError(false);
    }
    if (phone === "") {
      setPhoneError(true);
      hasError = true;
    } else {
      setPhoneError(false);
    }

    if (town === "") {
      setTownError(true);
      hasError = true;
    } else {
      setTownError(false);
    }
    if (email === "") {
      setEmailError(true);
      setEmailErrorText("Please fill in your email address")
      hasError = true;
    } else {
      if (EmailValidate(email)){
        setEmailError(false)
        setEmailErrorText("")
      }
      else{
        setEmailError(true)
        setEmailErrorText("Please enter a valid email")
        setEmail("")
        hasError = true;
      }
    }
    if (!hasError) {
      setLoading(true)
    try{
      const response = await POST_SERVICE(endpoint, body)
      if (response.status===201){
        setSuccess(true);
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);
          navigate("/umbrella");
          setLoading(false)
        }, 1000);
  
      }
      else{
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
          setLoading(false)
        }, 5000);
      }
  
    
     }
     catch (e) {
      return e.response;
    }
  }

}
const closeError = ()=>{
  setHomeAddressError(false);
  setPostcodeError(false);
  setCountryError(false);
  setNameError(false);
  setPhoneError(false);
  setTownError(false);
  setEmailError(false);
  setEmailErrorText("");
}
useEffect(()=>{
  window.addEventListener('keydown', closeError);
}, [])
  return (
    <MainContainer
      content={
        <>
              <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
          <div className={styles.content_header}>
            <div>
              <h1>New Umbrella</h1>
              <p>
                Please fill out the forms below to create add a new Umbrella
              </p>
            </div>
            {/* <img src={process_bar} alt={process_bar} /> */}
          </div>
          <div className={styles.form_container}>
            <Form
              show_header={true}
              header="Contact Details"
              bulk="Bulk Upload"
              show_bulk={false}
              line={true}
              bulk_icon={upload}
              compulsory_one={true}
              input_type="input"
              show_one={true}
              label_one="Umbrella Name"
              placeholder_one="Name"
              error_one="Please fill in your Umbrella name"
              error_one_true={nameError}
              value_one={name}
              disabled=""
              input_change={(e) => setName(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Company No"
              placeholder_two="Company No"
              type_two="number"
              value_two={companyNo}
             
              disabled_two=""
              input_change_two={(e) => setCompanyNo(e.target.value)}
            />
            <Form
              input_type="select"
              show_one={true}
              label_one="Country"
              compulsory_one={true}
              error_one_true={countryError}
              error_one="Please fill in your country"
              select_one_value={nationality}
              select_change={(option) => setNationality(option)}
              disabled=""
              value_one={nationality}
              select_one={countryData.map((country)=>
                country.name
                
                )}
             
            />
            <Form
              show_header={true}
              header="Primary Address"
              line={true}
              bulk_icon={upload}
              input_type="input"
              show_one={true}
              label_one="Address 1"
              placeholder_one="Home Address"
              error_one_true={homeAddressError}
              error_one="Please fill in your Primary Address"
              compulsory_one={true}
              value_one={homeAddress}
              disabled=""
              input_change={(e) => setHomeAddress(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Address 2"
              placeholder_two="Home Address"
              value_two={homeAddressTwo}
              disabled_two=""
              input_change_two={(e) => setHomeAddressTwo(e.target.value)}
            />
            <Form
              input_type="input"
              show_one={true}
              label_one="Post Code"
              placeholder_one="PostCode"
              value_one={postcode}
              error_one_true={postcodeError}
              error_one="Please fill in your Postcode"
              compulsory_one={true}
              disabled=""
              input_change={(e) => setPostcode(e.target.value)}
              show_two={true}
              input_type_two="input"
              label_two="City"
              placeholder_two="City"
              error_two_true={townError}
              error_two="Please fill in your Town"
              value_two={town}
              compulsory_two={true}
              disabled_two=""
              input_change_two={(e) => setTown(e.target.value)}
            />
            <Form
              show_header={true}
              header="Primary Contact Information"
              line={true}
              input_type="tel"
              show_tel={true}
              label_tel="Phone"
              compulsory_tel={true}
              error_tel_true={phoneError}
              error_tel="Please fill in your phone number"             
              disabled_tel=""
              select_tel_value={phoneHead}
              select_change_tel={(option)=>setPhoneHead(option)}
        
              select_tel={countryCode.map((code)=>
                code.code
                )}
              select_tel_two_value={mobileHead}
              select_change_tel_two={(option)=>setMobileHead(option)}
              select_tel_two={countryCode.map((code)=>
                code.code
                )}
              value_tel={phone}
              placeholder_tel="Phone"
              input_change_tel={(e) => setPhone(e.target.value)}
              show_tel_two={true}
              label_tel_two="Mobile"
              disabled_tel_two=""

              value_tel_two={mobile}
              placeholder_tel_two="Mobile"
              input_change_tel_two={(e) => setMobile(e.target.value)}
            />

            <Form
              show_one={true}
              input_type="input"
              label_one="Email"
              placeholder_one="Email Address"
              error_one={emailErrorText}
              compulsory_one={true}
              error_one_true={emailError}
              value_one={email}
              disabled=""
              input_change={(e) => setEmail(e.target.value)}
            />
            <p className={styles.label}>Choose Payslip Color:</p>
            <div className={styles.color_pick}  onClick={()=>setColorShow(true)}>
              <section style={{backgroundColor:color}}/>
              </div>
         {colorShow &&
           <ColorPicker color={color} onChange={color => { setColor(color.hex); setColorShow(false); }} />


         }
            <div className={styles.buttons}>
              <button className={styles.clear} onClick={()=>setToggle(true)}>
                Cancel
              </button>
              {loading ?
              <button className="loading_button"><div /></button>
              :
              <button className={styles.solid} onClick={addUmbrella} >
                Save
              </button>
      }
            </div>
          </div>
          <Modal open={toggle} 
            header="Warning message."
            content={<span>  Are you sure you want to cancel
          </span>}
            show_yes={true}
            cancel={()=>setToggle(false)}
            yes={()=>navigate("/umbrella")}


          />          
        </>
      }
    />
  );
};

export default Add;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, PUT_SERVICE } from "../Services/Backend"
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import Tooltip from "../Common/Tooltip";
import Loader from "../Common/Loader";
//import styles
import styles from "../Agency/css/agency.module.css";
import view from "../../Assets/view.svg"
import edit from "../../Assets/block.svg"
import viewdisable from "../../Assets/disss.svg"
import active from "../../Assets/activate.svg"
import Modal from "../Common/Modal";
//import resources
import NoContent from "../Common/NoContent"
import agency from "../../Assets/agency-green.svg"
import Table from "../Common/Table"
import add from "../../Assets/add-icon.png"
 

const MultiEntry = () => {
  const columns = [
    { key: 'fullName', label: 'Umbrella Name' },
    { key: 'niNumber', label: 'Date Added' },
    { key: 'emailAddress', label: 'Email' },
    { key: 'gender', label: 'Agencies' },
    { key: 'agencies', label: 'Contractors' },

  ];
  const navigate = useNavigate()
  const [umbrella, setUmbrella] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
 const [loading, setLoading] = useState(false)
 const [toggle, setToggle] = useState(false)
 const [umbrellaId, setUmbrellaId] = useState()
 const [text, setText] = useState("")


 const blockUmbrellaModal =(row)=>{
  setUmbrellaId(row)
  setToggle(!toggle)
  if (row.isLive===false){
     setText("activate")
  }
  else{
    setText("block")
  }

}
  const getUmbrella = async () => {
     setLoading(true)
    try {

      const response = await GET_SERVICE("/umbrellas")
      setUmbrella(response.data.data)
   
      setLoading(false)
    }
    catch (err) {
      return err.response
    }
  }

  const blockUmbrella = async(umbrella) => {
    if (umbrella.isLive===false){
      try {
        const response = await PUT_SERVICE(`/umbrellas/un-block/${umbrella.id}`)
     
        if (response.status = 200) {
          getUmbrella()
          setToggle(false)
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
           
  
          }, 2000);
  
        }
  
  
  
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }
  
  
      }
      catch (e) {
        return e.response;
      }
  
    }
    else{
       try {
        const response = await PUT_SERVICE(`/umbrellas/block/${umbrella.id}`)

        getUmbrella()
        setToggle(false)
        if (response.status= 200){
          setSuccess(true);
          
          setSuccessMessage(response.data.message);
          setTimeout(() => {
       

          }, 2000);

        }



        else{
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }


       }
       catch (e) {
        return e.response;
      }
    }


  }
  useEffect(() => {
    getUmbrella();
  }, [])
  const tableContent = umbrella?.map((item) => ({
    fullName: item.name,
    niNumber: item.dateAdded,
    emailAddress: item.email,
    gender: item.agenciesCount,
    agencies: item.contractors, // Replace this with the correct property from the `item` object
  }));

  return (
    <>
      <ErrorNotification show={error} content={errorMessage} />
      <SuccessNotification show={success} content={successMessage} />
      <MainContainer content={umbrella?.length > 0 ?  (
           loading ===true?
           <Loader show={loading}/>
           :
        <Table
          show_add={true}
          add_image={add}
          header="Umbrella"
          columns={columns}
          tool_two="Block Umbrella"
          tool_one="View"
          tableContent={tableContent}
          action="Add Umbrella"
          filter={true}
          action_button={()=>navigate("/umbrella/add")}
          body={
            umbrella?.map((row, index) => (
              <tr key={index}>
                <td>
                  {row.isLive===false ?
                     <span style={{color:"#9D9D9D"}}>{row.name}</span>
                      :
                      row.name

                  }
              
                </td>
                <td>
                {row.isLive===false ?
                       <span style={{color:"#9D9D9D"}}>       {row.dateAdded}</span>
                       :
                       row.dateAdded
           
                }
                </td>
                <td>
                {row.isLive===false ?
                       <span style={{color:"#9D9D9D"}}>           {row.email}</span>
                       :
                       row.email
           
                }
              
                </td>
                <td>
                {row.isLive===false ?
                       <span style={{color:"#9D9D9D"}}>                     {row.agenciesCount}</span>
                       :
                       row.agenciesCount
           
                }
              
        
                </td>
                <td>
                {row.isLive===false ?
                       <span style={{color:"#9D9D9D"}}>              {row.contractorsCount}</span>
                       :
                       row.contractorsCount
           
                }
              
              
                </td>
                <td className={styles.action_icons}>
                  <div
                    style={{
                      display: "flex",
                      gridColumnGap: "20px",
                      alignItems: "center",
                      gridRowGap: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    <span>

                      <Tooltip text={row.isLive===false?"Blocked":"View Profile"}>
                        <img
                          style={{ width: "25px" }}
                          src={row.isLive===false? viewdisable:view}
                          alt={view}
                          onClick={()=>{row.isLive===true &&navigate("/umbrella/view", {
                            state:{
                              id: row.id
                            }
                          })
                        }

                          }
                        />
                      </Tooltip>
                      {""}
                    </span>
                    <span >
                    <Tooltip text={row.isLive===false? "Activate Umbrella": "Block Umbrella"}>
                        <img
                          style={{ width: "20px" }}
                          src={row.isLive===false? active:edit}
                          alt={edit}
                          onClick={() => blockUmbrellaModal(row)}
                        />
                      </Tooltip>
                      {""}
                    </span>
                  </div>
                </td>

              </tr>

            ))
          }

          footer={true}
          
        />
      ) : (
        loading ===true?
        <Loader show={loading}/>
        :
        <div className="no_content">
          <NoContent
            image={agency}
            header="No Multi-Entry Added"
            text="Creates a new Multi-Entry by clicking the button below"
            buttonText="Create Multi-Entry"
            next="/umbrella/add"
          />
        </div>
      )}
      />
        <Modal open={toggle} 
            header="Warning message."
            content={<span>  Are you sure you want to {text} this Umbrella
          </span>}
            show_yes={true}
            cancel={()=>setToggle(false)}
            yes={()=>blockUmbrella(umbrellaId)}


          />  
    </>

  );









};

export default MultiEntry;
import React from 'react';

const ViewLoader = () => {
    return (
        <div className="view_loader_container">
          <div className='loader_container' style={{background:"none"}}>
          <div className='loader'/>
          </div>

            
      </div>
    );
};

export default ViewLoader;
 
import React, {useState} from 'react';
import {  useNavigate , useParams} from 'react-router-dom';

import { POST } from '../Services/Backend';

//import style
import styles from './css/auth.module.css'

//import Components
import AuthForm from './Common/AuthForm';
import AuthImage from './Common/AuthImage';
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';

//import resources
import loginImage from '../../Assets/reset-image.png'
import resetMobile from '../../Assets/resetMobile.png'
import passwordIcon from "../../Assets/password-icon.png"
import view from "../../Assets/view.svg"
import eye from "../../Assets/eye-slash.svg"



const PasswordReset = () => {

  const id = useParams();

  const [passwordError, setPasswordError] = useState(false)
  const  [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const navigate = useNavigate();


  const onSubmit = async (e) => {
    setLoading(false)
    e.preventDefault();

      
    let hasError = false; 

    if (password === "") {
      setPasswordError(true);
      hasError = true;
 
    }
    else{
      setNewPasswordError(false)
    }
    if (newPassword === "") {
        setNewPasswordError(true);
        hasError = true;
   
      }
      else{
        setPasswordError(false)
      }
  
    const endpoint = "/users/reset-password";
    const body = {
      "userId": id.user,
      "newPassword":password,
      "otp": id.id
  };
    if (!hasError) {
    try {
      const response = await POST(endpoint, body);
      if (response.status === 200) {
       
      
        setSuccess(true);
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
          navigate("/");
        }, 5000);
      } else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
          setLoading(false);
        }, 5000);
      }
    } catch (e) {
      return e.response;
    }
  }
  };
  
    return (
        <div className={styles.container}>
          <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
            <AuthForm 
                header="Password Reset" 
                subText="Please enter your new password and confirm it"    
               hide={true}
               buttonText="Reset Password"
               image={resetMobile}  
                click={onSubmit}
               loading ={loading}
               showOtp={true}
               send_text="Login"
               otp={()=>navigate("/")}
                password={
                    <>
                      <div className={styles.formControl}>
                    <label htmlFor="password">
                    New Password
               
                    </label>
                    <div className={passwordError?styles.errorInput:styles.formInput}>
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", gridColumnGap:"10px", width:"90%"}}>
                        <img src={passwordIcon} alt="icon"/>
                      <input type={showPassword?"text":"password"} name="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                            </div>
                            <img  onClick={()=>setShowPassword(!showPassword)}style={{marginLeft: "10px"}} src ={showPassword? eye:view} alt ={showPassword? eye:view}/>
                  
                    </div>
                  {passwordError &&
                    <p className="error_message"> Please fill in your password</p>

                  }
                  </div>
                  <div className={styles.formControl}>
                    <label htmlFor="password">
                    Confirm Password
               
                    </label>
                    <div className={passwordError?styles.errorInput:styles.formInput}>
                    <div className={styles.formInputTwo}>
                        <img src={passwordIcon} alt="icon"/>
                      <input type={showNewPassword?"text":"password"}name="password" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
                            </div>
                            <img onClick={()=>setShowNewPassword(!showNewPassword)} style={{marginLeft: "10px"}} src ={showNewPassword? eye:view} alt ={showNewPassword? eye:view}/>
                  
                    </div>
                  {newPasswordError &&
                    <p className="error_message"> Please confirm your password</p>

                  }
                  </div>


                    </>
                  
                  
                }   
            />
            <AuthImage 
         
              background={loginImage}
            />
            
        </div>
    );
};

export default  PasswordReset;
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'

//import external resources
//import styles
import styles from "./css/dashboard.module.css";

//component imports
import LineChart from "./Common/LineChart";
import Donut from "./Common/Donut";
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE } from "../Services/Backend"
import Loader from "../Common/Loader";

//import resources
import Card from "./Common/Card";
import agency from "../../Assets/agency.png";
import contractor from "../../Assets/contractor.png";
import payslip from "../../Assets/payslip.png";
import umbrella from "../../Assets/umbrella.png";
import payslip_icon from "../../Assets/payslip_icon.svg";
import caret from "../../Assets/caret-down.png"
import activity from "../../Assets/timesheet_green.svg";

import report_icon from "../../Assets/no_report.svg";

import gray from "../../Assets/gray_dot.svg"
import green from "../../Assets/green_dot.svg"
import red from "../../Assets/red_dot.svg"
import report from "../../Assets/report.svg"
import green_arrow from "../../Assets/arrow-green.svg"
import red_arrow from "../../Assets/arrow-red.svg"


const Dashboard = () => {


  const [date] = useState(new Date())

  const startDate = new Date(date.getFullYear(), 0, 1);
  var days = Math.floor((date - startDate) /
    (24 * 60 * 60 * 1000));

  var weekNumber = Math.ceil(days / 7);
  const [reportLoading, setReportLoading] = useState(false)
  const [reports, setReports] = useState([])
  const [activityLoading, setActivityLoading] = useState(false)
  const [activities, setActivities] = useState([])
  const [cardDetails, setCardDetails] = useState()
  const [showYears, setShowYears] = useState(false)
  const [years, setYears] = useState([])
  const [currentYear, setCurrentYear]= useState(new Date().getFullYear())



  const getReports = async () => {

    setReportLoading(true)
    try {
      const response = await GET_SERVICE(`/tickets?page=${0}&size=5`);

      if (response.status === 200) {

        setReportLoading(false)
        setReports(response?.data?.data?.allTickets)
      }
      else {
        setReports([])

        setReportLoading(false)

      }
    } catch (err) {
      return err.response;
    }

  }
  const getCardDetails = async () => {
    try {

      const response = await GET_SERVICE(`/general/dashboard-data-count`);

      if (response.status === 200) {

        setCardDetails(response?.data?.data)
      }
      else {
        setCardDetails()

      }
    } catch (err) {
      return err.response;
    }
  }
  const getYearsList = () => {
    const currentYear = new Date().getFullYear();
    const targetYear = 2023;
    const yearsList = [];
  
    for (let year = currentYear; year >= targetYear; year--) {
      yearsList.push(year);
    }
    setYears(yearsList)
  
    
  };
  const getActivities = async () => {

    setActivityLoading(true)
    try {
      const response = await GET_SERVICE(`/activity-timelines/latest`);
      console.log(response)

      if (response.status === 200) {

        setActivityLoading(false)
        setActivities(response?.data?.data)
      }
      else {
        setActivities([])

        setActivityLoading(false)

      }
    } catch (err) {
      setActivityLoading(false)
      return err.response;
    }





  }

  useEffect(() => {
    getReports()
    getCardDetails()
    getYearsList()


    getActivities()
  }, [])

  return (
    <MainContainer content={
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <div>
            <h1>Welcome, {localStorage.getItem("lastname")}</h1>
            <p>Ready to generate payslips?</p>
          </div>
          <div>
            <h3>
              Week {weekNumber}, Month {date.getMonth() + 1}, Bi-Weekly 11 - 12,
              <br /> 4-weekly 9-12
            </h3>
          </div>
        </div>
        <div className={styles.cardContainer}>
          <Card icon={umbrella} number={cardDetails?.umbrella || 0} text="Umbrella Groups" />
          <Card icon={agency} number={cardDetails?.agency || 0} text="Agencies" />
          <Card icon={contractor} number={cardDetails?.contractors || 0} text="Contractor" />
          <Card icon={payslip} number={cardDetails?.payslips || 0} text="Payslips" />
        </div> 
        {/* <div>
          <div className={styles.graph}>
            <div className={styles.graph_header}>
              <div>
                <h1>Payslips generated per month</h1>
                <p>Overview of accumulated payslips generated per month for year selected</p>
              </div>
         <div className={styles.filter}>
         <button onClick={()=>setShowYears(!showYears)}>
                <span>{currentYear}</span><img src={caret} alt={caret} />
              </button>
              {showYears &&
                <ul className={styles.list_of_years}>{
              years.map((year)=>
            
               <li onClick={()=>{
                setCurrentYear(year);
                 setShowYears(false)
                }}>{year}</li>
       
              )
                }
              </ul>

              }
         </div>
            </div>
            <LineChart year={currentYear} />


          </div>
        </div> */}

        <div className={styles.bottom_content}>

          <div className={styles.activity_card}>
            <div className={styles.activity_container}>
              <h1>Activity Timeline</h1>
              <p>Activity of various Payslips generated</p>
              {activityLoading === true ?
                <div className={styles.no_content}>
                  <Loader show={activityLoading} height={{ height: "fit-content" }} />
                </div>
                :
                activities?.length <= 0 ?
                  <div className={styles.no_content}>
                    <div>
                      <div>
                        <img src={activity} alt={activity} />
                      </div>

                      <h6 style={{ textAlign: "center" }}>No Payslips</h6>
                    </div>
                  </div>
                  :
                  activities?.map((activity_item) =>


                    <div
                      className={styles.item}
                    >
                      <img src={payslip_icon} alt={payslip_icon} />
                      <div>
                        <h4>{activity_item.name}</h4>
                        <p>{activity_item.activity}</p>
                      </div>
                    </div>
                  )

              }



            </div>
            {activities.length !== 0 &&

              <Link to="/payroll"><span>View Payslips</span> <img src={red_arrow} alt={red_arrow} /></Link>
            }

          </div>

          <div className={styles.reports}>


            <div className={styles.report_list}>

              <div className={styles.report_container}>



                <h1>Reports</h1>
                {reportLoading === true ?
                  <div className={styles.no_content}>
                    <Loader show={reportLoading} height={{ height: "fit-content" }} />
                  </div>
                  :
                  reports?.length <= 0 ?
                    <div className={styles.no_content}>
                      <div>
                        <div style={{ background: "#F9F9F9", padding: "40px", borderRadius: "50%" }}>
                          <img src={report_icon} alt={report_icon} />
                        </div>

                        <h6 style={{ textAlign: "center" }}>No Reports</h6>
                      </div>
                    </div>
                    :
                    reports?.map((report_item) =>


                      <div
                        className={styles.item}
                      >
                        <img src={report} alt={report} />
                        <div>
                          <h4>{report_item.title}</h4>
                          <p>{report_item.message.slice(0, 80)} {report_item.message.length > 80 && "..."}</p>
                        </div>
                      </div>
                    )

                }




              </div>
              {reports.length !== 0 &&
                <Link to="/report"><span>View Reports</span> <img src={green_arrow} alt={green_arrow} /></Link>

              }
            </div>
            <div className={styles.donut}>
              <div className={styles.donut_content}>
              <h1>Timesheet</h1>
              <Donut data={["0", "0", reports.length]} />
              <h3>Access</h3>
              <span><img src={green} alt={green} /> <p>Timesheet Approved</p> <h4>0</h4></span>
              <span><img src={gray} alt={gray} /> <p>Timesheet Validated</p> <h4>0</h4></span>
              <span><img src={red} alt={red} /> <p>Reports  </p> <h4>{reports.length}</h4></span>
              </div>
            </div>
          </div>

        </div>

      </div>
    } />
  );
};

export default Dashboard;

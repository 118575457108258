import React, { useState } from 'react';


//style import
import styles from "./css/select.module.css"

const Select = ({ options, value, onChange, error, disabled, message, loadMore, click }) => {
  const [showOptions, setShowOptions] = useState(false);

  const toggle = () => {
    setShowOptions(!showOptions)
  }
  const nothing = () => {

  }
  const hideOptions = () => {
    setShowOptions(false);
  };
  const handleOptionChange = (option) => {
    onChange(option);
    setShowOptions(false);

  };
  return (
    <div className={styles.custom_select}>
      {error ?
        <>
          <div className={disabled === "disabled" ? styles.disabled_option : styles.error_input} onClick={disabled === "disabled" ? nothing : toggle}>
            {value || <span style={{ color: "#8e8e8e" }}>Select an option</span>}
          </div>
          {error &&
        <p className="error_message_select">{message}</p>
      }


          {showOptions && (
            <ul className={styles.options} onMouseLeave={hideOptions}>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionChange(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}</>
        :
        <>
          <div className={disabled === "disabled" ? styles.disabled_option : styles.selected_option} onClick={disabled === "disabled" ? nothing : toggle}>
            {value || <span style={{ color: "#8e8e8e" }}>Select an option</span>}
          </div>
      
          {showOptions && (
            <ul className={styles.options} onMouseLeave={hideOptions}>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionChange(option)}>
                  {option}
                </li>
              ))}
                {loadMore ===true &&
              <li style={{ fontWeight: "600", color:"gray", fontSize:"13px", cursor:"pointer", paddingLeft:"10px"}} onClick={click}>Load More...</li>

              }
            </ul>
          )}
        </>

      }
    
    </div>
  );
};

export default Select;
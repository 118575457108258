import React from 'react';
import { useNavigate } from 'react-router-dom';

//import styles
import styles from "./css/no_content.module.css"

const NoContext = (props) => {
  
    const navigate = useNavigate()

    return (


          <div className={styles.no_content} style={props.change}>
          <div>
              <img src={props.image} alt={props.image} />
              <h3>{props.header}</h3>
              <p>{props.text}</p>
              {props.hide 
              ?
              <div/>
              :
        
              <button onClick={() => navigate(props.next)}>{props.buttonText}</button>

              }
          
          </div>


      </div>
    
  
    );
};

export default NoContext;
import React, { useState, useEffect } from 'react';

// import styles
import styles from './css/toggle.module.css';
import edit from "../../Assets/edit_green.svg"

const Toggle = (props) => {

  const [activeIndex, setActiveIndex] = useState(props.initialActiveIndex || 0);
  const { buttonLabels, contents } = props; // Arrays of button labels and content components
  
  const handleClick = (index) => {
    setActiveIndex(index);
  };
   
  const handleImageClick = (index) => {
    setActiveIndex(index)
    props.onClick(index, activeIndex); 
   
  }; 
  useEffect(() => {
    setActiveIndex(props.initialActiveIndex || 0);
  }, [props.initialActiveIndex]);
 
  return (
    <div className={styles.toggle}>
      <div className={styles.header} style={props.style}>
        {buttonLabels.map((label, index) => (
          <div style={{ position: "relative" }} key={index}>
            {(label != "Payslips" &&label != "Payslip"&& label !=="History" && label!=="Contractors" && label!=="Timesheet" && label!=="Timesheet Validate" && label!=="Approve Paysips" ) && (
              <img
                onClick={() => handleImageClick(index)}
                className={activeIndex === index && props.edit===true ? styles.show : styles.hide}
                style={{ position: "absolute", top: "-15px", right: "-10px", width: "20px" }}
                src={edit}
                alt={edit}
              />
            )}
            <button
              onClick={() => handleClick(index)}
              style={{
                color: activeIndex === index ? '#85CE54' : '#9D9D9D',
                borderBottom: activeIndex ===index ? '2px solid #85CE54': ''
              }}
            >
              {label}
            </button>
          </div>
        ))}
      </div>

      <div style={{ marginTop: '16px' }}>{contents[activeIndex]}</div>
    </div>
  );
};

export default Toggle;

import React from "react";
import styles from "../css/dashboard.module.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Donut = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const data = {
    labels: ["Timesheet Approved", "Timesheet Validated", "Reports"],
    datasets: [
      {
        label: "Total",
        data: props.data, 
        backgroundColor: ["#85CE54", "#AEA4BF", "#DB504A"],
        borderColor: ["#85CE54", "#AEA4BF", "#DB504A"],
        borderWidth: [1, 1, 20],
      },
    ],
  };

  return (
    <div className={styles.chart}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default Donut;

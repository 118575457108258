import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import styles
import styles from "./css/contractor.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, POST_SERVICE, PUT_SERVICE, DELETE_SERVICE } from "../Services/Backend";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import Select from "../Common/Select";
import Tooltip from "../Common/Tooltip";
import Loader from "../Common/Loader";
import Modal from "../Common/Modal";
import textFormatter from '../Utils/textFormatter';

//import resources
import NoContent from "../Common/NoContent";
import delete_icon from "../../Assets/delete_icon.svg"
import contractor from "../../Assets/contractor-green.svg";
import view from "../../Assets/view.svg";
import edit from "../../Assets/block.svg";
import viewdisable from "../../Assets/disss.svg"
import active from "../../Assets/activate.svg"
import add from "../../Assets/add-icon.png";

//import resources
import Table from "../Common/Table";

const Contractor = () => {
  const [allAgency, setAllAgency] = useState([])
  const [contractors, setContractors] = useState([]);
  const [allUmbrella, setAllUmbrella] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [allStatus] = useState(["Blocked", "Active"])
  const [umbrella, setUmbrella] = useState("")
  const [agency, setAgency] = useState("")
  const [statusText, setStatusText] = useState(null)
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false);
  const [contractorId, setContractorId] = useState()
  const [toggle, setToggle] = useState(false)
  const [deleteToggle, setDeleteToggle] = useState(false)
  const [text, setText] = useState(false)
  const [agencyId, setAgencyId] = useState(null)
  const [pageCount, setPageCount] = useState()
  const [element, setElement] = useState(10);
  const [totalElement, setTotalElement] = useState(10);
  const [pageNumber, setPageNumber] = useState(1)
  const [umbrellaId, setUmbrellaId] = useState(null)
  const [loadAgency, setLoadAgency] = useState(false)
  const [pageNumberAgency, setPageNumberAgency] = useState(0)
  const [elementAgency, setElementAgency] = useState(0)

  const getContractors = async (page) => {
    setLoading(true);
    try {
      const response = await GET_SERVICE(`/contractors?page=${page || 0}&size=10`);
  
      if (response.status === 200) {
        setContractors(response.data.data.contractors);
        setPageNumber(page || 0)
        setPageCount(response.data.data.totalPages)
        setTotalElement(response.data.data.totalElement)
        setLoading(false);

      }
      else {
        setContractors([])
        setLoading(false)
      }
    } catch (err) {
      return err.response;
    }
  };

  const getMoreContractors = async (option) => {
    console.log(option)
    setLoading(true);
    try {
      const response = await GET_SERVICE(`/contractors?page=${pageNumber || 0}&size=${option}`);
      if (response.status === 200) {
        setContractors(response.data.data.contractors);
        setElement(option);
        setPageCount(response.data.data.totalPages) 
        setLoading(false);
      }
      else {
        setContractors([])
        setLoading(false)
      }
    } catch (err) {
      return err.response;
    }
  };
  const deleteContractor = async (contractor) => {

    const endpoint = `/contractors/${contractor}`
    try {
      const response = await DELETE_SERVICE(endpoint)

      if (response.status === 200) {
        setSuccess(true);
        getContractors()
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);


        }, 2000);

      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);

        }, 5000);
      }
      setText("")
      setContractorId(null)
      setDeleteToggle(false)

    }
    catch (e) {
      return e.response;
    }


  }
  

  const nextPage = (e) => {
    let page = e.selected
    getContractors(page)
  }

  const blockContractorModal = (row) => {
    setContractorId(row)
    setToggle(!toggle)
    if (row.isLive === false) {
      setText("activate")
    }
    else {
      setText("block")
    }

  }
  const deleteContractorModal = (row) => {
    setContractorId(row)
    setDeleteToggle(!deleteToggle)
    setText("delete")

  }
  const getSearch = async (e) => {

    e.preventDefault()
    try {
      const response = await GET_SERVICE(`/contractors/search?page=0&size=10&propertySortedBy=createdAt&searchBy=${search}`)
      
      setContractors(response.data.data.contractors);
    }
    catch (err) {
      return err.response;
    }

  }

  const getAgency = async () => {

    try {
      const response = await GET_SERVICE("/agencies/name-and-id?page=0&size=10");
      if(response.status===200){
        setAllAgency(response.data.data.agencies);
        setElementAgency(response.data.data.totalElement)

        if(response.data.data.agencies.length < response.data.data.totalElement ){
          setLoadAgency(true)
  
         }
      }
    

    } catch (err) {
      return err.response;
    }
  };
  const getMoreAgencies = async (option) => {
  
    try {
      if (allAgency.length < elementAgency) {
         const nextPage = pageNumberAgency + 1
 
      const response = await GET_SERVICE(`/agencies/name-and-id?page=${nextPage}&size=10`);
      
      if (response.status === 200) {
      
      
        const newAgencies = response.data.data.agencies.filter(
          agency => !allAgency.some(existingAgency => existingAgency.id === agency.id)
        );
     
        setAllAgency(prevAgencyList => [...prevAgencyList, ...newAgencies]);
        setPageNumberAgency(nextPage)
     
 
      

      }
     
    }
    else{
      setLoadAgency(false)
    }
    } catch (err) {
      return err.response;
    }
  };
const getUmbrella = async ()=>{
  try{
  const response = await GET_SERVICE (`/umbrellas`)
  setAllUmbrella(response.data.data)
  }
  catch (err){
    return err.response
  }
}
  useEffect(() => {
    getContractors();
    getAgency()
    getUmbrella()
  }, []);

const clearField = ()=>{
  setAgency("")
  setAgencyId(null)
  setUmbrella("")
  setUmbrellaId(null)
  setStatusText(null)
}
const filterContractor = async ()=>{

  const body ={
    "agencyId": agencyId,
    "umbrellaId": umbrellaId,
    "status": statusText
  }
  const endpoint = `/contractors/filter?page=0&size=10`
  setLoading(true)
  try{ 
    const response = await POST_SERVICE(endpoint, body)
   
    if (response.status === 200) {
      console.log(response)
      if (response.data.data.contractors.length>0){

        setContractors(response.data.data.contractors);
        setPageCount(response.data.data.totalPages)
        setTotalElement(response.data.data.totalElement)
        clearField("")
      }
   
    setLoading(false);
  }
  else {

    setLoading(false)
  }
} catch (err) {
  return err.response;
}
}

  const columns = [
    { key: 'fullName', label: 'Full Name' },
    { key: 'niNumber', label: 'NI Number' },
    { key: 'emailAddress', label: 'Email' },
    { key: 'gender', label: 'Gender' },
    { key: 'agencies', label: 'Agency' },
 
  ];

  const navigate = useNavigate();

  const blockContractor = async (contractorList) => {
    if (contractorList.isLive === true) {
      try {
        const response = await PUT_SERVICE(`/contractors/block/${contractorList.id}`);
        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setToggle(false)
          getContractors();
      
          setTimeout(() => {
            setSuccess(false);
          
          }, 2000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
      
          setToggle(false)
          setTimeout(() => {
            setError(false);
           
          }, 5000);
        }
        setText("")
        setContractorId(null)
      } catch (e) {
        return e.response;
      }
    } else {
      try {
        const response = await PUT_SERVICE(
          `/contractors/un-block/${contractorList.id}`
        );

        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setToggle(false)               
          getContractors();
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
          setToggle(false)
          setTimeout(() => {
            setError(false);
        
          }, 5000);
        }
        setText("")
        setContractorId(null)
      } catch (e) {
        return e.response;
      }
    }
  };
  return (
    <>
      <ErrorNotification show={error} content={errorMessage} />
      <SuccessNotification show={success} content={successMessage} />
      <MainContainer
        content={
          contractors.length > 0 ? (
            loading === true ? (
              <Loader show={loading} />
            ) :
              <Table
                show_add={true}
                add_image={add}
                header="Contractors"
                columns={columns}
                search={search}
                searchInput={(e)=>setSearch(e.target.value)}
                searchAction={(e) => getSearch(e)}
                action="Add Contractor"
                action_button={() => navigate("/contractor/add")}
                body={
                  contractors.map((row, index) => (
                 
                      <tr key={index}>
                        <td>
                          {row.isLive === false ?
                            <span style={{ color: "#9D9D9D" }}>{row.fullName}</span>
                            :
                            row.fullName
 
                          }

                        </td>
                        <td>
                          {row.isLive === false ?
                            <span style={{ color: "#9D9D9D" }}>       {row.niNumber}</span>
                            :
                            row.niNumber

                          }
                        </td>
                        <td>
                          {row.isLive === false ?
                            <span style={{ color: "#9D9D9D" }}>           {row.emailAddress}</span>
                            :
                            row.emailAddress

                          }

                        </td>
                        <td>
                          {row.isLive === false ?
                            <span style={{ color: "#9D9D9D" }}>           {               textFormatter(row.gender)}</span>
                            :
                            textFormatter(row.gender)

                          }

                        </td>
                        <td>
                          {row?.isLive === false ? (
                            <span style={{ color: "#9D9D9D" }}>{row?.agencies}</span>
                          ) : (
                            row?.agencies?.map((agency, index) => (
                              <span key={agency}>
                                {agency}
                                {index !== row?.agencies.length - 1 ? ", " : ""}
                              </span>
                            ))
                          )}
                        </td>
                     
 
                        <td className={styles.action_icons}>
                          <div
                            style={{
                              display: "flex",
                              gridColumnGap: "20px",
                              alignItems: "center",
                              gridRowGap: "20px",
                              flexWrap: "wrap",
                            }}
                          >
                            <span>

                              <Tooltip text={row.isLive === false? "Blocked":"View Profile"}>
                              <img
                                style={{ width: "25px" }}
                                src={row.isLive === false ? viewdisable : view}
                                alt={view}
                                onClick={() => {
                                  if (row.isLive !== false) {
                                    navigate("/contractor/view", {
                                      state: {
                                        id: row.id,
                                        umbrella : row.umbrellas,
                                        agency: row.agencies
                                      }
                                    });
                                  }
                                }}
                              />
                              </Tooltip>
                              {""}
                            </span>
                            <span >
                              <Tooltip text={row.isLive === false ? "Activate " : "Block "}>
                                <img
                                  style={{ width: "20px" }}
                                  src={row.isLive === false ? active : edit}
                                  alt={edit}
                                  onClick={() => blockContractorModal(row)}
                                />
                              </Tooltip>
                              {""}
                            </span>
                            <span >
                            <Tooltip text={"Delete Contractor"}>
                              <img

                                src={delete_icon}
                                alt={delete_icon}
                                onClick={() => deleteContractorModal(row.id)}
                              />
                            </Tooltip>

                          </span>
                          </div>
                        </td>

                      </tr>

                 

                  ))
                  
                }
                row={element}
                row_list={
                  totalElement >= 30
                  ? [10, 20, 30]
                  : totalElement >= 20
                  ? [10, 20]
                  : totalElement >= 10
                  ? [10]
                  : []
                }
                row_item_click={(option) => getMoreContractors(option)}
                pageCount={pageCount}
                pageChange={nextPage}
                pageNumber={pageNumber}
                filterContent={
                  <div>
                    <div className={styles.filter}>
                      <div className={styles.select_input}>
                        <label>
                          Agency

                        </label>
                        <div className={styles.custom_select_container}>
                          <Select
                            disabled=""
                            value={agency}
                            loadMore={loadAgency}
                            click={getMoreAgencies}
                       
                          
                            onChange={(option) => {
                              setAgency(option)
                              const selectedAgency = allAgency.find((item) => item.name === option);
                              if (selectedAgency) {
                                setAgencyId(selectedAgency?.id);
                       
                              }
                            }}
                            options={allAgency.map((agency) =>
                              agency.name
                            )}
                          />

                        </div>
                      </div>
                      <div className={styles.select_input}>
                        <label>
                          Umbrella

                        </label>
                        <div className={styles.custom_select_container}>
                          <Select
                            // disabled={agencyId!==""? "": "disabled"}
                            value={umbrella}
                            onChange={(option) => {
                              setUmbrella(option)
                              const selectedUmbrella = allUmbrella.find((item)=> item.name === option);
                              if(selectedUmbrella){
                                setUmbrellaId(selectedUmbrella?.id)
                              }

                            }}
                            options={allUmbrella.map((umbrella)=>
                              umbrella.name
                              )}
                          />

                        </div>
                      </div>
                      <div className={styles.select_input}>
                        <label>
                          Status

                        </label>
                        <div className={styles.custom_select_container}>
                          <Select
                            disabled=""
                            value={statusText}
                            onChange={(option) => setStatusText(option)}
                            options={allStatus}
                          />

                        </div>
                      </div>

                    </div>
                    <div className={styles.buttons}>
                      <button className={styles.clear} onClick={clearField}>
                        Cancel
                      </button>
                      <button className={styles.solid}  onClick={filterContractor}>
                        Save
                      </button>
                    </div>
                  </div>



                }
              />
          ) : (
            loading === true ? (
              <Loader show={loading} />
            ) :
              <div className="no_content">
                <NoContent
                  image={contractor}
                  header="No Contractors Added"
                  text="Creates a new contractor by clicking the button below"
                  buttonText="Create Contractors"
                  next="/contractor/add"
                />
              </div>
          )
        }
      />
      <Modal open={toggle}
        header="Warning message."
        content={<span>  Are you sure you want to {text} this Contractor
        </span>}
        show_yes={true}
        cancel={() => setToggle(false)}
        yes={() => blockContractor(contractorId)}
      />
           <Modal open={deleteToggle}
        header="Warning message."
        content={<span>  Are you sure you want to {text} this Contractor
        </span>}
        show_yes={true}
        cancel={() => setDeleteToggle(false)}
        yes={() => deleteContractor(contractorId)}
      />
    </>
  );
};

export default Contractor;

import React from 'react';

const Loader = (props) => {
    return (
        <div className={props.show?"loader_container": ""} style={props.height}>
            <div className='loader'/>
            
        </div>
    );
}; 


export default Loader;
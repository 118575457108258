import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//styles import
import styles from "../Contractor/css/contractor.module.css";

//import resources
import Table from "../Common/Table";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, POST_SERVICE } from "../Services/Backend";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import contractor from "../../Assets/contractor-green.svg";
import NoContent from "../Common/NoContent";
import Select from "../Common/Select";
import Tooltip from "../Common/Tooltip";
import Loader from "../Common/Loader";
import DateFormat from '../Utils/DateFormat'

 
const ActivityTimeline = () => {
  const [activity, setActivity] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState()
  const [pageNumber, setPageNumber] = useState()
  const [date, setDate] = useState("")
  const [ act, setAct] = useState("")
 
  const [actList, setActList] = useState([])
  const [element, setElement] = useState(10)
  const columns = [
    { key: 'fullName', label: 'Name' },
    { key: 'niNumber', label: 'Activity' },
    { key: 'emailAddress', label: 'Date & Time' },


  ];

  const [toggle, setToggle] = useState();

  const toggler = () => {
    setToggle(!toggle);
  };

  const clearField = ()=>{
    setDate("")
    setAct("")


  }
  const getActivity = async (page) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/activity-timelines?size=10&page=${page || 0}`);
      if (response.status===200){
        setActivity(response.data.data.activityTimelines);
        setLoading(false)
        setPageNumber(page || 0)
       
   
        setPageCount(response.data.data.numberOfPages )
        setElement(response.data.data.numberOfElements)
      } else{
        setActivity([])
        setLoading(false)
      }
 
 


 
    } catch (err) {
      return err.response;
    }
  };
  const postActivityFilter = async (page) => {

    setLoading(true)
    const url =`/activity-timelines/filter?page=0&size=10`
    const body={
      "activity": act,
      "date": DateFormat(date)
    }
    console.log(body)
  
    try {
      const response = await POST_SERVICE(url, body);
  
      console.log(response)
      if (response.status===200){
        setActivity(response.data.data);
        setLoading(false)
        setPageNumber(page || 0)
        
       
   
        setPageCount(response.data.data.numberOfPages )
        setElement(response.data.data.numberOfElements)
      } else{
      
        setLoading(false)
      }
 
 


 
    } catch (err) {
      return err.response;
    }
  };
  const nextPage =(e)=>{
    console.log(e.selected)
    let page = e.selected 

   
    getActivity(page)
 
      
 
   }
 
  useEffect(() => {
    getActivity();
  },[]);
  return (
    <>
    
 
    <MainContainer
      content={
        activity.length > 0 ? (
          loading === true ? (
            <Loader show={loading} />
          ) : (
            <Table
            header="Activity TimeLine"
            show_text={true}
            text="View Activity done by all users"
              showSearchBar={true}
              columns={columns}
            
              body={activity.map((row, index) => (
                <tr key={index}>
                  <td>
                    {row.isLive === false ? (
                      <span style={{ color: "#9D9D9D" }}>
                        {row.name}
                      </span>
                    ) : (
                      row.name
                    )}
                  </td>
                  <td>
                    {row.isLive === false ? (
                      <span style={{ color: "#9D9D9D" }}>
                        {row.activity}
                      </span>
                    ) : (
                      row.activity
                    )}
                  </td>
                  <td>
                    {row.isLive === false ? (
                      <span style={{ color: "#9D9D9D" }}>{row.dateTime}</span>
                    ) : (
                      row.dateTime
                    )}
                  </td>
            
                
  
                </tr>
              ))}
              row="10"
              pageCount={pageCount}
              pageChange= {nextPage}
              pageNumber={pageNumber}
              filterContent={
                <div>
                  <div className={styles.filter}>
                    <div className={styles.select_input}>
                      <label>Activity</label>
                      <div className={styles.custom_select_container}>
                      <Select
                            disabled=""
                            value={act}
                       
                          
                            onChange={(option) => {
                              setAct(option)
                            
                            }}
                            options={[ "CREATE_UMBRELLA",
                            "CREATE_AGENCY",
                            "CREATE_CONTRACTOR",
                            "EDIT_AGENCY",
                            "EDIT_CONTRACTOR",
                            "EDIT_UMBRELLA",
                            "GENERATE_PAYSLIP",
                            "CREATE_TIMESHEET",
                            "UPLOAD_TIMESHEET"]}
                          />
                      </div>
                    </div>
                    <div className={styles.form_input}>
                      <label>Date</label>
                      <div className={styles.input_container}>
                        <input type="date" value={date} onChange={(e)=>setDate(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} onClick={clearField}>Cancel</button>
                    <button className={styles.solid} onClick={postActivityFilter} >
                      Save
                    </button>
                  </div>
                </div>
              }
            />
          )
        ) : (
          loading === true ? (
            <Loader show={loading} />
          ) : (
            <div className="no_content">
              <NoContent
                image={contractor}
                header="No Activity"
                hide={true}
              />
            </div>
          )
        )
      }
    />
  </>
  );
};


export default ActivityTimeline;

import React, { useState } from 'react';

//import style
import styles from './css/auth.module.css'
import { useNavigate } from 'react-router-dom';

//import Components
import AuthForm from './Common/AuthForm';
import AuthImage from './Common/AuthImage';
import { POST } from '../Services/Backend';

//import resources
import loginImage from '../../Assets/reset-image.png'
import resetMobile from '../../Assets/resetMobile.png'
import profile from "../../Assets/profile-icon.png";
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';


const ResetPassword = () => {
    const [idError, setIdError] = useState(false)
    const [staffId, setStaffId] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
  const navigate = useNavigate()
  
  const onSubmit = async (e) => {
    e.preventDefault();
  
    let hasError = false; 
    if (staffId === "") {
      setIdError(true);
      hasError = true;
    } else {
      setIdError(false);
    }

 
  
    const endpoint = "/users/initiate-reset-password";
    const body = {
      emailAddress: staffId,
     
    };

    if (!hasError) {
      setLoading(true);
    try {
      const response = await POST(endpoint, body);

  
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage("Success! Your password has been successfully reset. Please check your email for further instructions");
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
          

        }, 5000);
      } else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
          setLoading(false);
        }, 5000);
      }
    } catch (e) {
      return e.response;
    } 
    }
  };
    return (
        <div className={styles.container}>
         <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
        <AuthForm 
            header="Password Reset" 
            subText="Please enter your email to reset password"    
            label="staff-id"
            labelText="Staff Id"
            image={resetMobile}
            buttonText="Reset Password"    
            inputIcon={profile} 
            error={idError?"Please fill in your email": ""}
            errorBorder={idError? true: false}
            change={(e)=>setStaffId(e.target.value)}
            value={staffId}
            click={onSubmit}
            loading={loading}
            showOtp={true}
            send_text="Login"
            otp={()=>navigate("/")}
     
        />
        <AuthImage 
          header=""
          background={loginImage}
        />
        
    </div>
    );
};

export default ResetPassword;
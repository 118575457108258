import React, {useState, useEffect} from "react";
import { GET_SERVICE } from "../../Services/Backend"
import { Line } from "react-chartjs-2";
//import styles
import styles from "../css/dashboard.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({year}) => {
  const [lineData, setLineData] = useState([])

  const getLineData = async () => {
    try {
      const response = await GET_SERVICE(`/payslips/graph-data?year=${year}`)
      if(response.status===200){

        setLineData(response.data.data.map((data)=>
         data.numberOfPayslip
        ))
      }




    } catch (err) {
      return err.response
    }

  }

  useEffect(()=>{
    getLineData()
  },[year])
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false, 
        },
      },
      y: {
        display: true,
        grid: {
          display: false, 
        },
        min: 0, // Set the minimum value for the Y-axis
        max: 1000,
      },
   
    },
    maintainAspectRatio: false, // Disable aspect ratio
    aspectRatio: 2, // Set the aspect ratio (width:height)
    height: 200,
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Payslip Generated",
        data: lineData,
        borderColor: "#DCDCDC",
        backgroundColor: " #85CE54",
        pointRadius: 4,
        borderDash: [5, 5],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} className={styles.line} />

    </div>
  );
};

export default LineChart;

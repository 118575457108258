import React, { useState, useEffect } from "react";

//import styles
import styles from "./css/profile.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import {GET_SERVICE, PUT_SERVICE} from "../Services/Backend"
import SuccessNotification from "../Common/SuccessNotification";
import ErrorNotification from "../Common/ErrorNotification";
import Modal from "../Common/Modal";
import ViewLoader from '../Common/ViewLoader'

//import resources
import dummy from "../../Assets/dummy-photo.svg";
import edit from "../../Assets/edit_green.svg";
import email from "../../Assets/email-icon-circle.svg";
import toggle_icon from "../../Assets/caret-up.svg";
import toggle_down from "../../Assets/down-show.svg";
import eye from "../../Assets/show_password.svg"

const Profile = () => {
  const [toggle, setToggle] = useState();
  const [profileDetails, setProfileDetails] = useState([])
  const [showUpdate, setShowUpdate] = useState(false)
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [editShow, setEditShow] = useState(false)
  const [nameLoading, setNameLoading] = useState(false)
  const [loading , setLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
const [cancelName, setCancelName] = useState(false)
const [cancelPassword, setCancelPassword] = useState(false)
const [ detailsLoading, setDetailsLoading] = useState(false)
  const getUsers = async () => {
    setDetailsLoading (true)
  
    try {
        const response = await GET_SERVICE("/users")
        
     
   
        setProfileDetails(response?.data?.data)
        setDetailsLoading(false)


    }
    catch (err) {
        return err.response
    }
}
const changePassword = async()=>{
  setLoading(true)
  const body={
    "oldPassword": oldPassword,
    "newPassword":newPassword
}
const endpoint = "/users/change-password"
if (password===newPassword){
  try{
    const response = await PUT_SERVICE(endpoint, body)
    if (response.status==200){
      setSuccess(true);
      setSuccessMessage(response.data.message);
      setTimeout(() => {
        setSuccess(false);
        setLoading(false)
        setPassword("")
        setNewPassword("")
  
      }, 5000);
  
    }
    else{
      setError(true);
      setErrorMessage(response.data.message);
      setTimeout(() => {
        setError(false);
        setLoading(false)
      }, 5000);
    }
  
  
   }
   catch (e) {
    return e.response;
  }
}

}
useEffect(()=>{
  getUsers()
}, [])

const editUserDetails = async ()=>{
  setNameLoading(true)
  
   const body ={
    "firstName": firstname,
    "lastName": lastname
   }
   const endpoint ="/users"

  try{
    const response = await PUT_SERVICE(endpoint, body)
    setEditShow(false)
    if (response.status===200){
      setSuccess(true);
      setSuccessMessage(response.data.message);
      setTimeout(() => {
        getUsers()
        setSuccess(false);
        setNameLoading(false)
   
      }, 5000);

    }
    else{
      setError(true);
      setErrorMessage(response.data.message);
      setTimeout(() => {
        setError(false);
        setNameLoading(false)
      }, 5000);
    }

  
   }
   catch (e) {
    return e.response;
  }
}
  const toggler = () => {
    setToggle(!toggle);
  };
  const editUser = ()=>{
   setEditShow(!editShow)
  }
  return (
    <>
      <MainContainer
        content={
          <>
                 <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
          <div className={styles.content}>
            {detailsLoading ?
            <ViewLoader/>
            :
            <>
            <h1>Profile</h1>
            <p>Profile details of current user</p>
        
            <div className={styles.profile_card}>
              <div           style={{ alignItems: "flex-start" , display:'flex', justifyContent:'space-between'}}>
              <div
                style={{
                  display: "flex",
                  gridColumnGap: "20px",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.image_card}>
                    <img
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-5px",
                        width: "20px",
                        cursor: "pointer"
                      }}
                      src={edit}
                      alt={edit}
                      onClick={editUser}
                    />
                  <div className={styles.placeholder_image}>
                    <div className={styles.liner}>
                         <h3>
                    {profileDetails?.firstName!==undefined &&
                    Array.from(profileDetails?.firstName)[0]
                    }
                    </h3>
              
                    <h3>
                    {profileDetails?.lastName!==undefined &&
                    Array.from(profileDetails?.lastName)[0]}
                    </h3>

                    </div>
                 
                  </div>
                  {/* <img
                    className={styles.profile_image}
                    src={dummy}
                    alt={dummy}
                  /> */}
                </div>
                <div className={styles.profile_content}>
                  <h1>{profileDetails?.firstName} {profileDetails?.lastName}</h1>
                  <h3 style={{ color: "#85CE54" }}>({profileDetails?.role})</h3>
                  <h4>{profileDetails?.phoneNumber}</h4>
                  {editShow &&
                               <div className={styles.form_container}>
                               <div className={styles.formControl}>
                               <label htmlFor="firstname" style={{display:"flex", justifyContent:"space-between"}}>
                                   <span>Firstname</span>
           
                               </label>
                               <input type= "name" name="firstname" value={firstname} onChange={(e)=>setFirstname(e.target.value)}/>
                         
                             </div>
                             <div className={styles.formControl}>
                               <label htmlFor="lastname" style={{display:"flex", justifyContent:"space-between"}}>
                                   <span>Lastname</span>
                             
                               </label>
                               <input type="name"name="lastname" value={lastname} onChange={(e)=>setLastname(e.target.value)}/>
                         
                             </div>
                             </div>
                               

                  }
     
                  
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gridColumnGap: "20px",
                  alignItems: "flex-start",
                }}
              >
                <img src={email} alt={email} />
                <div>
                  <h3>EMAIL</h3>
                  <h4>{profileDetails?.emailAddress}</h4>
                </div>
              </div>
              
            </div>
            {editShow  &&
            <div className={styles.buttons}>
              <button className={styles.clear} onClick={()=>setCancelName(true)}>
                Cancel
              </button>
              {firstname!=="" && lastname!=="" ? 
               nameLoading ?
               <button className="loading_button"><div /></button>
               :
                 <button className={styles.solid}  onClick={editUserDetails}>
                 <span>Save</span>
 
               </button>
               :
               <button className={styles.gray} disabled >
               <span>Save</span>

             </button>

              }
            </div>
        }
            </div>
        
            < div className={styles.profile_card}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
              <h1 style={{ margin: "0" }}>Update Password</h1>
              <img src={showUpdate? toggle_down: toggle_icon} style={{cursor:"pointer"}} alt={showUpdate? toggle_down:toggle_icon} onClick={()=>setShowUpdate(!showUpdate)}/>
         
            </div>
            {showUpdate &&
         
           <div style={{marginTop: "20px"}}>
             <div className={styles.form_container}>
                                <div className={styles.formControl}>
                    <label htmlFor="password" style={{display:"flex", justifyContent:"space-between"}}>
                        <span>Old Password</span>
               
                    </label>
                    <div className={styles.formInput}>
              
                      <input type={showOldPassword? "name": "password"} name="password" value={oldPassword} onChange={(e)=>setOldPassword(e.target.value)}/>
                         <img src={eye} alt="icon" onClick={()=>setShowOldPassword(!showOldPassword)}/>
                    </div>
               
                  </div>
                  </div>
                  <div className={styles.form_container}>
                                <div className={styles.formControl}>
                    <label htmlFor="password" style={{display:"flex", justifyContent:"space-between"}}>
                        <span>New Password</span>

                    </label>
                    <div className={styles.formInput}>
              
                      <input type={showNewPassword? "name": "password"} name="password" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
                      <img src={eye} alt="icon" onClick={()=>setShowNewPassword(!showNewPassword)}/>
                    </div>
              
                  </div>
                  <div className={styles.formControl}>
                    <label htmlFor="password" style={{display:"flex", justifyContent:"space-between"}}>
                        <span>Confirm New Password</span>
                  
                    </label>
                    <div className={styles.formInput}>
              
                      <input type={showPassword? "name": "password"} name="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                      <img src={eye} alt="icon" onClick={()=>setShowPassword(!showPassword)}/>
                    </div>
              
                  </div>
                  </div>
                  <div className={styles.buttons}>
              <button className={styles.clear} onClick={()=>setCancelPassword(true)}>
                Cancel
              </button>
              {password!=="" && newPassword!=="" && oldPassword!=="" ? 
                loading ?
                <button className="loading_button"><div /></button>
                :

                <button className={styles.solid} onClick={changePassword}>
                <span>Save</span>

              </button>
               :
               <button className={styles.gray} disabled>
               <span>Save</span>

             </button>

              }
           
            </div>
           </div>

              }
            </div>
      
          </>

            }
        
          </div>
         
          </>
        }
      />
              <Modal open={cancelName} 
            header="Warning message."
            content={<span>  Are you sure you want to cancel?
          </span>}
            show_yes={true}
            cancel={()=>setCancelName(false)}
            yes={()=>
              {
                setCancelName(false);
                setEditShow(!editShow)
              }
            
            }


          /> 
                  <Modal open={cancelPassword} 
            header="Warning message."
            content={<span>  Are you sure you want to cancel?
          </span>}
            show_yes={true}
            cancel={()=>setCancelPassword(false)}
            yes={()=>{
              setShowUpdate(false);
              setCancelPassword(false)
            }}


          /> 
    </>
  );
};

export default Profile;

import React from 'react';

//style import
import styles from './css/bulk.module.css'

//resource import
import upload from "../../Assets/upload-t.svg";
import download from '../../Assets/download-t.svg'

//component import
import { DownloadTableExcel } from 'react-export-table-to-excel';
 
const Bulk = (props) => {
  return (
    <div className={props.open ? styles.modal : styles.hideModal}>
      <div className={styles.modalContent}>
        <h1>Download {props.name} Template</h1>
        <DownloadTableExcel
          filename={props.table_name}
          sheet="One"
          currentTableRef={props?.tableRef?.current }
        >

          <button className={styles.download}><span>{props.name} Excel Template</span><img src={download} alt={download} /></button>

        </DownloadTableExcel>


        <h1>Upload {props.name} Template</h1>
        <button className={styles.upload} onClick={props.upload}>
          <label htmlFor="file" >
            <span>Upload Excel Template</span><img src={upload} alt={upload} />
            <input

              type="file"
              name="file"
              id="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={props.handleFileSelect}
              
            />
          </label>
        </button>
        {
          props.children
        }
        <div className={styles.buttons}>
          <button className={styles.clear} onClick={props.bulk_action}>
            Cancel
          </button>
          <button className={styles.solid} disabled={props.disabled} onClick={props.save}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Bulk;
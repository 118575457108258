import React, { useState, useEffect } from 'react';

//component import
import Form from "../Common/Form"
import ViewButton from '../Common/ViewButton';
import Loader from '../Common/Loader'

import { GET_SERVICE, PUT_SERVICE } from '../Services/Backend';

//styles import
import styles from './css/margin.module.css'

const Margin = (props) => {
    const [amount, setAmount] = useState([])
    const [editable, setEditable] = useState(false)
    const [componentCount, setComponentCount] = useState(1);
    const [amountItem, setAmountItem] = useState([])
    const [amountItemId, setAmountItemId] = useState([])
    const [loading, setLoading] = useState(false)
    const getMarginAmount =async()=>{
        setLoading(true)

       try{
        const response = await GET_SERVICE('/umbrellas/margin-amount')
        if (response.status===200){
            setAmount(response.data.data)
        
            setComponentCount(response.data.data.length)
        }
           setLoading(false)
       }
       catch(err){
        return err.response
       }

    }

    const editMarginAmount = async ()=>{
        setLoading(true)
     
        try{
            for (let index = 0; index < amountItem.length; index++) {
             
                const endpoint = `/umbrellas/${amountItemId[index]}/margin-amount?marginAmount=${parseInt(amountItem[index])}`;
  
                const response = await PUT_SERVICE(endpoint);
     
                if(response.status===200){
                    props.success(response.data.message)
                    getMarginAmount()
                    setEditable(!editable)
                    setAmountItem([])
                    setAmountItemId([])
                }
                else{
                    props.error(response.data.message)
                }

            }
            setLoading(false)
        }
        catch(err){
            return err.response
        }
    }
    useEffect(()=>{
       getMarginAmount()
    }, [])
    return (
        <>
            <div className={editable  ? styles.hidden :styles.edit}>
          
                    <button onClick={() => setEditable(!editable)}><span>Edit Margins</span><div className={styles.icon} /></button>

            </div>
            <div className={styles.content}>
 
                {Array.from({ length: componentCount }).map((_, index) => (
                    <Form

                        input_type="input"
                        type_one="number"
                        show_one={true}
                        place={"place"}
                        label_one="Margin Amount"
                        value_one={
                            amountItem[index] || ""

                        }
                        input_change={
                            (e) => {
                                setAmountItem((prevCategory) => {
                                    const updatedCategory = [...prevCategory];
                                    updatedCategory[index] = e.target.value; 
                                    console.log(updatedCategory)// Set umbrella name at the corresponding 
                                    return updatedCategory;
                                }

                                );
                                const selectedCategory = amount.find((item) => item.umbrellaId === amount[index].umbrellaId);

                                if (selectedCategory) {

                                    setAmountItemId((prevIds) => {
                                        const updatedIds = [...prevIds];
                                        updatedIds[index] = selectedCategory.umbrellaId; // Set umbrella ID at the 
                                        return updatedIds;
                                    });
                                }
                            }

                        }
                        placeholder_one={ amount[index]?.marginAmount || "0"}
                        disabled={ editable? "":"disabled"}
                        input_type_two="input"
                        show_two={true}
                        label_two="Umbrella linked"
                        placeholder_two={amount[index]?.umbrellaName || ""}

                        disabled_two={"disabled"}




                    />
                ))}

                {editable===true &&
                <ViewButton cancel={()=>setEditable(!editable)}
                  save={editMarginAmount}
                />

                }






            </div>


        </>
    );
};

export default Margin;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import styles
import styles from "../Contractor/css/add.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import Modal from '../Common/Modal'
import Form from "../Common/Form";
import {POST_SERVICE} from "../Services/Backend"
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import Bulk from "../Common/Bulk";

//import resources

import upload from "../../Assets/upload.svg";
import process_bar from "../../Assets/process-bar.svg";

const Next = () => {
  const [bulk, setBulk] = useState(false)
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [fullname, setFullname] = useState("")
  const [title, setTitle] = useState("");
  const [hours, setHours] = useState("");
  const [payRate, setPayRate] = useState("");
  const [shitfDate, setShitfDate] = useState("");
  const [niNumbers, setNiNumbers] = useState("");
  const [merge, setMerge] = useState(false)
  const [holidayPayIncluded, setHolidayPayIncluded] = useState(false);
  const [employerNiInsurance, setEmployerNiInsurance] = useState(false);
  const [vatCharged, setVatCharged] = useState(false);
  const [vatReversedCharged, setVatReversedCharged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [amount, setAmount] = useState("");
  
  const navigate = useNavigate();
  const location = useLocation()
  const [toggle, setToggle] = useState();
const goToAdd=()=>{
  if(location.state===null){
    navigate("/agency/add")
  }
}
useEffect(()=>{
  goToAdd()
}, [])

  const toggler = () => {
    setToggle(!toggle);
  };
  const goNext = async() => {
    const endpoint = `/agency-payroll-template/${location.state.id}`
    setLoading(true)
   const body ={  
    "firstAndLastNameTogether":merge,
    "fullNamePosition": fullname,
    "firstNamePosition": firstname,
    "lastNamePosition": lastname,
    "hoursPosition": hours,
    "payRatePosition": payRate,
    "niNumberPosition": niNumbers,
    "shiftDatePosition": shitfDate,
    "numberOfColumns":"10",
    "holidayPayIncluded": holidayPayIncluded,
    "employerNiInsurance": employerNiInsurance,
    "vatCharged": vatCharged,
    "employerPension": vatReversedCharged,
 
    
}
try{
  const response = await POST_SERVICE(endpoint, body)
  if (response.status===200){
    setSuccess(true);
    setSuccessMessage(response.data.message);
    setTimeout(() => {
      setSuccess(false);
      navigate("/agency");
      setLoading(false)
    }, 2000);

  }
  else{
    setError(true);
    setErrorMessage(response.data.message);
    setTimeout(() => {
      setError(false);
      setLoading(false)
    }, 5000);
  }


 }
 catch (e) {
  return e.response;
}
  };
  return (
    <>
            <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>

    <MainContainer
      content={
        <>
          <div className={styles.content_header}>
            <div>
              <h1>New Agency</h1>
              <p>Please fill out the forms below to create add a new Agency</p>
            </div>
            <img src={process_bar} alt={process_bar} />
          </div>
          <div className={styles.form_container}>
            <Form
              show_header={true}
              bulk="Bulk Upload"
              bulk_action={()=>setBulk(!bulk)}
              show_bulk={true}
              bulk_icon={upload}
              header="Payroll"
              line={true}
              check={true}
              show_check_one={true}
              check_one ={holidayPayIncluded}
              check_one_change={(e)=>setHolidayPayIncluded(e.target.checked)}
              check_desc_one="Holiday pay included:"
              show_check_two={true}
              check_desc_two="Employers NI:"
              check_two ={employerNiInsurance}
              check_two_change={(e)=>{setEmployerNiInsurance(e.target.checked);
              if (e.target.checked===false){
                setAmount("")
              }
              }}
              show_check_three={true}
              check_desc_three="VAT Charged:"
              check_three ={vatCharged}
              check_three_change={(e)=>setVatCharged(e.target.checked)}
              show_check_four={true}
              check_desc_four="Employers Pension"
              check_four ={vatReversedCharged}
              check_four_change={(e)=>setVatReversedCharged(e.target.checked)}
           
            />


            <div className={styles.next_container}>
              <div className={styles.header}>
              <h3>Template Setup</h3>
              </div>
       
              <hr />


              <div className={styles.check_input}>
              <p>Are First name and Last name joined together</p>
                <label className={styles.switch}>
       
                  <input type="checkbox" checked={merge}   onChange={(e) => setMerge(e.target.checked)}/>
                  <span className={styles.custom_switch}></span>
                </label>
              </div>
              <h5>Please write the column number in which the information is placed</h5>
              <div className={styles.next_form_container}>
        
                        {merge ===false &&
                        <>
                         <div className={ styles.form_input}>
                            <label>
                            First Name<span style={{ color: "#DB504A" }}>*</span>

                            </label>
                            <input
                                   type="number"
                                value={firstname}
                                placeholder="First Name Column"
                                onChange={(e)=>setFirstname(e.target.value)}
                  
                            />
                            {/* <span>{props.error_one}</span> */}
                        </div>
                        <div className={ styles.form_input}>
                            <label>
                            Last Name<span style={{ color: "#DB504A" }}>*</span>

                            </label>
                            <input
                                   type="number"
                                value={lastname}
                                placeholder="Last Name Column"
                                onChange={(e)=>setLastname(e.target.value)}
                  
                            />
                            {/* <span>{props.error_one}</span> */}
                        </div>
                        </>

                        }
                       
                      {merge === true &&
                        <div className={ styles.form_input}>
                        <label>
                        Full Name<span style={{ color: "#DB504A" }}>*</span>

                        </label>
                        <input
                               type="number"
                            value={fullname}
                            placeholder="Full Name Column"
                            onChange={(e)=>setFullname(e.target.value)}
              
                        />
                        {/* <span>{props.error_one}</span> */}
                    </div>

                      }
                        <div className={ styles.form_input}>
                            <label>
                            Hours<span style={{ color: "#DB504A" }}>*</span>

                            </label>
                            <input
                                   type="number"
                                value={hours}
                                placeholder="Hours Column"
                                onChange={(e)=>setHours(e.target.value)}
                  
                            />
                            {/* <span>{props.error_one}</span> */}
                        </div>
                        <div className={ styles.form_input}>
                            <label>
                            Pay Rate<span style={{ color: "#DB504A" }}>*</span>

                            </label>
                            <input
                                    type="number"
                           
                                value={payRate}
                                placeholder="Pay Rate Column"
                                onChange={(e)=>setPayRate(e.target.value)}
                  
                            />
                            {/* <span>{props.error_one}</span> */}
                        </div>
                        <div className={ styles.form_input}>
                            <label>
                            Shift Date<span style={{ color: "#DB504A" }}>*</span>

                            </label>
                            <input
                                   type="number"
                                value={shitfDate}
                                placeholder="Shift Date Column"
                                onChange={(e)=>setShitfDate(e.target.value)}
                  
                            />
                            {/* <span>{props.error_one}</span> */}
                        </div>
                        <div className={ styles.form_input}>
                            <label>
                            Ni Number<span style={{ color: "#DB504A" }}>*</span>

                            </label>
                            <input
                                   type="number"
                                value={niNumbers}
                                placeholder="Ni Number Column"
                                onChange={(e)=>setNiNumbers(e.target.value)}
                  
                            />
                            {/* <span>{props.error_one}</span> */}
                        </div>
                   
              </div>
              <div className={styles.buttons}>
              <button
                className={styles.clear}
                onClick={() => setToggle(true)}
              >
                Skip
              </button>
              {loading ?
                   <button className="loading_button"><div /></button>
                   :
              <button className={styles.solid} onClick={goNext}>
                Add Agency
              </button>
      }
            </div>

            </div>
           
          </div>
          <Modal open={toggle} 
            header="Warning message."
            content={<span>  Are you sure you want to skip this process?
          </span>}
            show_yes={true}
            cancel={()=>setToggle(false)}
            yes={()=>navigate("/agency")}


          />  
               <Bulk open={bulk} name="Agency" bulk_action={()=>setBulk(!bulk)}/>
        </>
      }
    />
        </>
  );
};

export default Next;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import styles
import styles from "./css/view.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, PUT_SERVICE, DELETE_SERVICE } from "../Services/Backend";
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import PersonalInformation from "./PersonalInformation";
import TaxDetails from "./TaxDetails";
import Agency from "./Agency";
import History from "./History";
import Payslips from "./Payslips";
import Modal from "../Common/Modal";

//import resources
import InfoCard from "../Common/InfoCard";
import Toggle from "../Common/Toogle";
import agency_icon from "../../Assets/agency.svg";
import user from "../../Assets/user.svg";
import payslip from "../../Assets/payslip.svg";
import timesheet from "../../Assets/timesheet.svg";
import umbrella_icon from "../../Assets/umbrella.svg";

const View = () => {
  const [switchLive, setSwitchLive] = useState(false)
  const [switchBlock, setSwitchBlock] = useState(false)
  const [agencyUmbrella, setAgencyUmbrella] = useState()
  const [pension, setPension] = useState("Not Applicable")
  const navigate = useNavigate();
  const [contractorDetails, setContractorDetails] = useState({})
  const [toggle, setToggle] = useState();
  const location = useLocation()
  const [cancelUpadteAgency, setCancelUpdateAgency] = useState(false)
  const [timesheetData, setTimesheetData] = useState()
  const [blockContractor, setBlockContractor] = useState(false)
  const [cancelUpdateDetails, setCancelUpdateDetails] = useState(false)
  const [updateDetailsLoading] = useState(false)
  const [editable, setEditable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [cancelUpdateTaxdetails, setCancelUpdateTaxdetails] = useState(false)
  const [updateTaxDetailsLoading] = useState(false)


  const toggleEdit = (index, activeIndex) => {
    if (index === activeIndex) {
      setEditable(false)
    }
  }
 
   const handleSuccess=(message)=>{
    setSuccess(true);
    setEditable(!editable)
    setSuccessMessage(message.message);
    setTimeout(() => {
      setSuccess(false);
      setSuccessMessage("")
    }, 2000);
   }

   const handleError = (message)=>{
    setError(true);
    setErrorMessage(message.message);
    setTimeout(() => {
      setError(false);
      setErrorMessage("")
      setToggle(false)
    }, 5000);
   }

 
  const getAgencyLinkedUmbrella = async () => {
    const endpoint = `/contractors/${location.state.id}/agency-data`
    try {
      const response = await GET_SERVICE(endpoint);
      
  
      setAgencyUmbrella(response.data.data)

    } catch (err) {
      return err.response;
    }
  }
  const blockContractorAction = async () => {

    setSwitchLive(false);
    setSwitchBlock(false)

    try {
      if (blockContractor === false) {

        const response = await PUT_SERVICE(`/contractors/block/${location.state.id}`)

        if (response.status = 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          getContractorsDetails()
            
          setToggle(false)
          setTimeout(() => {
            setSuccess(false);
         

          }, 2000);
        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }
      }
      else {
        const response = await PUT_SERVICE(`/contractors/un-block/${location.state.id}`)
        if (response.status = 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          getContractorsDetails()
          setToggle(false)
          setTimeout(() => {
            setSuccess(false);
         
          }, 2000);
        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }

      }


    }
    catch (e) {
      return e.response;
    }


  }
 
  const toggleCancelUpdateAgency = () => {

    setEditable(!editable);

    setCancelUpdateAgency(false)

  }

  const getTimesheet= async () => {
    const endpoint = `/contractors/${location.state.id}/payslip-timesheet-data`
    try {
      const response = await GET_SERVICE(endpoint);

    setTimesheetData(response.data.data)

    } catch (err) {
      return err.response;
    }
  }

  const getContractorsDetails = async () => {
    const endpoint = `/contractors/${location.state.id}`
    try {
      const response = await GET_SERVICE(endpoint);

      setContractorDetails(response?.data?.data)

      setBlockContractor(!response?.data?.data?.live)

    } catch (err) {
      return err.response;
    }
  }
  useEffect(() => {
    getContractorsDetails()
    getAgencyLinkedUmbrella()
    getTimesheet()

  }, [])

 

  return (
    <MainContainer content={
      <>
        <ErrorNotification show={error} content={errorMessage} />
        <SuccessNotification show={success} content={successMessage} />
        <h1 style={{ color: "#9D9D9D", fontSize: "20px", fontWeight: "600" }}>
          <span onClick={() => navigate("/contractor")} style={{ cursor: "pointer" }}>Contractors</span>{" "}
          <span style={{ color: "#313131" }}>/ {contractorDetails.firstName=== undefined? "" : contractorDetails?.firstName + " " + contractorDetails?.lastName}</span>
        </h1>

        <InfoCard
          top_level_image={user}
          top_level={contractorDetails.firstName=== undefined? "" : contractorDetails?.firstName + " " + contractorDetails?.lastName}
          next_level_image={agency_icon}
          next_level={
            location.state.agency.map((agency, index)=>
            index !== location.state.agency.length - 1 ?
            agency+","
            :
            agency
            )}
          bottom_level_image={umbrella_icon}
          bottom_level={ location.state.umbrella.map((umbrella, index)=>
            index !== location.state.umbrella.length - 1 ?
            umbrella+","
            :
            umbrella
            )}
          show_live={blockContractor}
          show_block={blockContractor}
          toggle={() => setToggle(!toggle)}
          live_label="Status:"
          live="Live"
          block="Blocked"
          showlive={switchLive}
          showblock={switchBlock}
          live_text="Block"
          block_text="Activate"
          live_action={() => setSwitchLive(!switchLive)}
          block_action={() => setSwitchBlock(!switchBlock)}
          show_status={true}
          status_type={styles.status}
          status_label="Pension:"
          status={pension}
          icon_top={timesheet}
          text_top="Timesheets"
          number_top={timesheetData?.timeSheetCount}
          icon_bottom={payslip}
          text_bottom="Paysilps"
          number_bottom={timesheetData?.payslipCount}
        />
        <Toggle
          buttonLabels={[
            "Personal Information",
            "Tax Details",
            "Agency",
            "Payslips",
            "History",
          ]}
          style={{justifyContent:"space-between"}}
          onClick={toggleEdit}
          edit={blockContractor === false ? editable : ""}
          contents={[

            <PersonalInformation
             id ={location.state.id}
             updateDetailsLoading={updateDetailsLoading}
             cancel={()=>setCancelUpdateDetails(true)}
             success={handleSuccess}
             error={handleError}
             editable={editable}
            
            />,
            <TaxDetails
            id={location.state.id}
            updateTaxDetailsLoading={updateTaxDetailsLoading}
            cancel={()=>setCancelUpdateTaxdetails(true)}
            success={handleSuccess}
            error={handleError}
            editable={editable}
            
            />
        ,
        <Agency
         id ={location.state.id}
         success={handleSuccess}
         error={handleError}
         editable={editable}
         cancel ={()=>setCancelUpdateAgency(true)}
   
        />
          ,
 
           <Payslips
           id ={location.state.id}
           name={contractorDetails?.firstName + " " + contractorDetails?.lastName}
           />
            ,
            <History
            id={location.state.id}
            />


          ]}
        />

        <Modal open={cancelUpadteAgency}
          header="Warning message."
          content={<span>  Are you sure you want to cancel update?
          </span>}
          show_yes={true}
          cancel={() => setCancelUpdateAgency(false)}
          yes={toggleCancelUpdateAgency}


        />
        <Modal open={cancelUpdateTaxdetails}
          header="Warning message."
          content={<span>  Are you sure you want to cancel update?
          </span>}
          show_yes={true}
          cancel={() => setCancelUpdateTaxdetails(false)}
          yes={() => { setEditable(!editable); setCancelUpdateTaxdetails(false) }}


        />

        <Modal open={cancelUpdateDetails}
          header="Warning message."
          content={<span>  Are you sure you want to cancel update?
          </span>}
          show_yes={true}
          cancel={() => setCancelUpdateDetails(false)}
          yes={() => { setEditable(!editable); setCancelUpdateDetails(false) }}


        />

        <Modal open={toggle}
          header="Warning message."
          content={<span>  Are you sure you want to {blockContractor ? "activate" : "block"} this Contractor
          </span>}
          show_yes={true}
          cancel={() => setToggle(false)}
          yes={blockContractorAction}


        />
      </>
    } />
  );
};

export default View;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//import styles
import styles from "./css/add.module.css";

//import resources

import Form from "../Common/Form";
import upload from "../../Assets/upload.svg";

import MainContainer from "../Common/MainContainer";
import {POST_SERVICE} from '../Services/Backend'
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import countryCode from '../Utils/country_code.json'
import Modal from "../Common/Modal";
import EmailValidate from "../Utils/EmailValidate";

const Add = () => {
  const [firstname, setFirstname] = useState("");
  const [firstnameError, setFirstnameError] = useState(false)
  const [lastname, setLastname] = useState("");
  const [nationality, setNationality] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [phone, setPhone] = useState("");

  const [phoneError, setPhoneError] = useState(false)
  const [mobile, setMobile] = useState("");
  const[phoneHead, setPhoneHead] = useState("+44")
  const [mobileHead, setMobileHead] = useState("+44")
  const [cancel, setCancel] = useState(false)
  const [loading, setLoading] = useState(false)
const [emailErrorText, setEmailErrorText] = useState("")
  const [genderList] = useState(["Male", "Female"]);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState();

  const toggler = () => {
    setToggle(!toggle);
  };
  const goNext =async () => { 


      const body = {
        "firstName": firstname,
        "lastName": lastname,
        "emailAddress": email,
        "gender":gender,
        "phoneNumber":{
          "countryCode": phoneHead,
          "number": phone
      },
      "mobileNumber":{
        "countryCode": mobileHead,
        "number": mobile
    }
    }
      const endpoint ="/users/add-admin"
      let hasError = false; // Flag to track if any field has an error
    
      if (firstname === ""){
        setFirstnameError(true);
        hasError = true;
      } else {
        setFirstnameError(false);
      }
      if (email === "") {
        setEmailError(true);
        setEmailErrorText("Please fill in your email address")
        hasError = true;
      } else {
        if (EmailValidate(email)){
          setEmailError(false)
          setEmailErrorText("")
        }
        else{
          setEmailError(true)
          setEmailErrorText("Please enter a valid email")
          setEmail("")
          hasError = true;
        }
      }
  
      if (phone=== ""){
        setPhoneError(true);

        hasError = true;
      } else {
        setPhoneError(false);
      }
      if (!hasError) {
        setLoading(true)
      try{
        const response = await POST_SERVICE(endpoint, body)
     
        if (response.status===201){
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            setLoading(false)
            navigate("/administrators");
          }, 5000);
    
        }
        else{
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setLoading(false)
          }, 5000);
        }
    
      
       }
       catch (e) {
        return e.response;
      }
    }

  };

  return (
    <MainContainer
      content={
        <>
                      <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
                     <div className={styles.content_header}>
                <div>
                  <h1>New Admin User</h1>
                  <p>
                    Please fill out the forms below to create a new admin user
                  </p>
                </div>
              </div>
              <div className={styles.form_container}>
                <Form
                  show_header={true}
                  header="Contact Details"
                  line={true}
                  bulk_icon={upload}
                  input_type="input"
                  show_one={true}
                  label_one="First Name"
                  placeholder_one="First Name"
                  error_one ="Please fill in your first name"
                  error_one_true={firstnameError}
                  value_one={firstname}
                  disabled=""
                  input_change={(e) => setFirstname(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  label_two="Last name"
                  placeholder_two="Last Name"
                  value_two={lastname}
                  disabled_two=""
                  input_change_two={(e) => setLastname(e.target.value)}
                />
                <Form
                  input_type="select"
                  show_one={true}
                  label_one="Gender"
                  select_one_value={gender}
                  select_one={genderList}
                  value_one="Female"
                  disabled_one=""
                  select_change={(option) => setGender(option)}
                />
 <Form
              show_header={true}
              header="Primary Contact Information"
              line={true}
              input_type="tel"
              show_tel={true}
              label_tel="Phone"
              error_one={'Please fill in your phone number'}
              error_tel_true={phoneError}
              disabled_tel=""
              select_tel_value={phoneHead}
              select_change_tel={(option)=>setPhoneHead(option)}
        
              select_tel={countryCode.map((code)=>
                code.code
                )}
              select_tel_two_value={mobileHead}
              select_change_tel_two={(option)=>setMobileHead(option)}
              select_tel_two={countryCode.map((code)=>
                code.code
                )}
              value_tel={phone}
              placeholder_tel="Phone"
              input_change_tel={(e) => setPhone(e.target.value)}
              show_tel_two={true}
              label_tel_two="Mobile"
              disabled_tel_two=""

              value_tel_two={mobile}
              placeholder_tel_two="Mobile"
              input_change_tel_two={(e) => setMobile(e.target.value)}
            />
                <Form
                  input_type="input"
                  show_one={true}
                  label_one="Email"
                  type_one="email"
                  value_one={email}
                  error_one={emailErrorText}
                  error_one_true={emailError}
                  disabled=""
                  input_change={(e) => setEmail(e.target.value)}
                />

                <div className={styles.buttons}>
                  <button className={styles.clear} onClick={()=>setCancel(true)}>
                    Cancel
                  </button>
                  {loading ?
  <button className="loading_button"><div /></button>
                  :

<button className={styles.solid} onClick={goNext}>
Save
</button>

                  }
                
                </div>
                <div>
                  
                </div>
              </div>
              <Modal open={cancel} 
            header="Warning message."
            content={<span>  Are you sure you want to cancel?
          </span>}
            show_yes={true}
            cancel={()=>setCancel(false)}
            yes={()=>
              {
                setCancel(false);
               navigate("/administrators")
              }
            
            }


          /> 
        </>
      }
      
    />
  );
};

export default Add;

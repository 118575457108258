import React, {useState, useEffect} from 'react';

//component imports
import Form from '../Common/Form';
import { GET_SERVICE, PUT_SERVICE } from '../Services/Backend';
import ViewButton from '../Common/ViewButton';
import countryData from '../Utils/country.json'
import countryCode from '../Utils/country_code.json'
import nationalitiesData from '../Utils/nationalities.json'
import DateFormat from '../Utils/DateFormat';
import textFormatter from '../Utils/textFormatter';
import ViewLoader from '../Common/ViewLoader';

const PersonalInformation = (props) => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [nationality, setNationality] = useState("");
    const [gender, setGender] = useState("");
    const [date, setDate] = useState("");
    const [contractorDetails, setContractorDetails] = useState({})
    const [homeAddress, setHomeAddress] = useState("");
    const [homeAddressTwo, setHomeAddressTwo] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [mobile, setMobile] = useState("");
    const [accountName, setAccountName] = useState("");
    const [bankName, setBankName] = useState("");
    const [sortcode, setSortcode] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [phoneHead, setPhoneHead] = useState("")
    const [mobileHead, setMobileHead] = useState("")
    const [loading, setLoading] = useState(false)
    const [email, setEmail]= useState("")

    const getContractorsDetails = async () => {
      setLoading(true)
        const endpoint = `/contractors/${props.id}`
        try {
          const response = await GET_SERVICE(endpoint);
          setContractorDetails(response?.data?.data)
          setLoading(false)
    
        } catch (err) {
          return err.response;
        }
      }
      const ValidateSortcode = (e)=>{
        if (e!==undefined){
            const value = e.target.value;
    
            const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
        
         
            let formattedValue = '';
            if (sanitizedValue?.length > 2) {
              formattedValue = `${sanitizedValue.slice(0, 2)}-${sanitizedValue.slice(2, 4)}`;
              if (sanitizedValue.length > 4) {
                formattedValue += `-${sanitizedValue.slice(4)}`;
              }
            } else {
              formattedValue = sanitizedValue;
            }
            setSortcode(formattedValue);
        }
      }
      const updateContractorDetails = async () => {
       setLoading(true)
        const endpoint = `/contractors/${props.id}/update-personal-information`
        const body = {
          "firstName": firstname !== "" ? firstname : contractorDetails.firstName,
          "lastName": lastname !== "" ? lastname : contractorDetails.lastName,
          "email": email !== ""? email: contractorDetails.email,
          "nationality": nationality !== "" ? nationality : contractorDetails.nationality,
          "gender": gender !== "" ? gender : contractorDetails.gender,
          "dob": date !== "" ? DateFormat(date) : DateFormat(contractorDetails.dateOfBirth),
          "primaryAddress": {
            "homeAddressOne": homeAddress !== "" ? homeAddress : contractorDetails.primaryAddress?.homeAddressOne,
            "homeAddressTwo": homeAddressTwo !== "" ? homeAddressTwo : contractorDetails.primaryAddress?.homeAddressTwo,
            "postCode": postcode !== "" ? postcode : contractorDetails.primaryAddress?.postCode,
            "country": country !== "" ? country : contractorDetails.primaryAddress?.country
          },
          "primaryContactInformation": {
            "phoneNumber": {
              "countryCode": phoneHead !== "" ? phoneHead : contractorDetails?.primaryContactInformation?.phoneNumber?.countryCode,
              "number": phone !== "" ? phone : contractorDetails?.primaryContactInformation?.phoneNumber?.number
            },
            "mobileNumber": {
              "countryCode": mobileHead !== "" ? mobileHead : contractorDetails?.primaryContactInformation?.mobileNumber?.countryCode,
              "number": mobile !== "" ? mobile : contractorDetails?.primaryContactInformation?.mobileNumber?.number
            },
            "emailAddress": contractorDetails.primaryContactInformation.emailAddress
          },
          "bankDetails":{
            "accountName": accountName!==""? accountName :contractorDetails.bankDetails?.accountName,
            "bankName": bankName !== ""? bankName : contractorDetails.bankDetails?.bankName,
            "accountNumber": accountNumber!==""? accountName: contractorDetails.bankDetails?.accountNumber,
            "sortCode": sortcode!==""? sortcode: contractorDetails.bankDetails?.sortCode
        }
        }
         
        try {
          const response = await PUT_SERVICE(endpoint, body)
          console.log(response)
         
          if (response.status === 200) {
            setLoading(false)
            props.success(response.data)
              getContractorsDetails()

            
    
          }
          else {
            props.failure(response.data)
            setLoading(false)
          }
    
    
        }
        catch (e) {
          return e.response;
        }
      }

      

useEffect(()=>{
  getContractorsDetails()
}, [])

   
    return (
    <>
    {loading ===false  ?
            <div>
          
            <Form
                  input_type="input"
                  show_one={true}
                  label_one="First Name"
                  placeholder_one={contractorDetails.firstName}
                  value_one={firstname}
                  disabled={!props.editable ? "" : "disabled"}
                  input_change={(e) => setFirstname(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  place={"place"}
                  label_two="Last name"
                  placeholder_two={contractorDetails.lastName}
                  value_two={lastname}
                  disabled_two={!props.editable ? "" : "disabled"}
                  input_change_two={(e) => setLastname(e.target.value)}
                />
                <Form
                  input_type="select"
                  show_one={true}
                  label_one="Nationality"
                  select_one_value={nationality || contractorDetails.nationality}
                  select_change={(option) => setNationality(option)}
                  disabled={!props.editable ? "" : "disabled"}
                  value_one={nationality}
                  select_one={nationalitiesData.map((nation) =>
                    nation
                  )}
                  input_type_two="select"
                  show_two={true}
                  place={"place"}
                  label_two="Gender"
                  select_two_value={gender || textFormatter(contractorDetails.gender)}
                  select_two={["Male", "Female"]}
                  value_two={gender}
                  disabled_two={!props.editable ? "" : "disabled"}
                  select_change_two={(option) => setGender(option)}
                />
                <Form
                  input_type="input"
                  show_one={true}
                  label_one="Date"
                  type_one="date"
                  place={"place"}
                  value_one={date === "" ? contractorDetails.dateOfBirth : date}
                  disabled={!props.editable ? "" : "disabled"}
                  input_change={(e) => setDate(e.target.value)}
                />
                <Form
                  show_header={true}
                  header="Primary Address"
                  line={true}
                  input_type="input"
                  show_one={true}
                  place={"place"}
                  label_one="Home Address 1"
                  placeholder_one={contractorDetails.primaryAddress?.homeAddressOne || ""}
                  value_one={homeAddress}
                  disabled={!props.editable ? "" : "disabled"}
                  input_change={(e) => setHomeAddress(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  label_two="Home Address 2"
                  placeholder_two={contractorDetails.primaryAddress?.homeAddressTwo || ""}
                  value_two={homeAddressTwo}
                  disabled_two={!props.editable ? "" : "disabled"}
                  input_change_two={(e) => setHomeAddressTwo(e.target.value)}
                />
                <Form
                  input_type="input"
                  show_one={true}
                  label_one="Post Code"
                  placeholder_one={contractorDetails.primaryAddress?.postCode}
                  value_one={postcode}
                  disabled={!props.editable ? "" : "disabled"}
                  input_change={(e) => setPostcode(e.target.value)}
                  input_type_two="select"
                  show_two={true}
                  place={"place"}
                  label_two="Country"
                  select_two_value={country || contractorDetails?.primaryAddress?.country}
                  select_two={countryData.map((country) =>
                    country.name
                  )}
                  value_two={contractorDetails.primaryAddress?.country}
                  disabled_two={!props.editable ? "" : "disabled"}
                  select_change_two={(option) => setCountry(option)}
                />
                <Form
                  show_header={true}
                  header="Primary Contact Information"
                  line={true}
                  input_type="tel"
                  show_tel={true}
                  label_tel="Phone"
                  disabled_tel={!props.editable ? "" : "disabled"}
                  select_tel_value={phoneHead === "" ? contractorDetails?.primaryContactInformation?.phoneNumber?.countryCode : phoneHead}
                  select_change_tel={(option) => setPhoneHead(option)}
  
                  select_tel={countryCode.map((code) =>
                    code.code
                  )}
                  select_tel_two_value={mobileHead === "" ? contractorDetails?.primaryContactInformation?.mobileNumber?.countryCode : mobileHead}
                  select_change_tel_two={(option) => setMobileHead(option)}
                  select_tel_two={countryCode.map((code) =>
                    code.code
                  )}
                  value_tel={phone}
                  placeholder_tel={contractorDetails?.primaryContactInformation?.phoneNumber?.number}
                  input_change_tel={(e) => setPhone(e.target.value)}
                  show_tel_two={true}
                  label_tel_two="Mobile"
                  disabled_tel_two={!props.editable ? "" : "disabled"}
                  place={"place"}
                  value_tel_two={mobile}
                  placeholder_tel_two={contractorDetails?.primaryContactInformation?.mobileNumber?.number}
                  input_change_tel_two={(e) => setMobile(e.target.value)}
                />
                <Form
                  show_one={true}
                  input_type="input"
                  label_one="Email"
                  place={"place"}
                  placeholder_one={contractorDetails?.primaryContactInformation?.emailAddress
                  }
                  value_one={email}
                    
                  input_change={(e) => setEmail(e.target.value)}
                  disabled={!props.editable ? "" : "disabled"}
                />
  
  
                <Form
                  show_header={true}
                  header="Bank Details"
                  line={true}
                  input_type="input"
                  show_one={true}
                  label_one="Account Name"
                  placeholder_one={contractorDetails.bankDetails?.accountName}
                  value_one={accountName}
                  disabled={!props.editable ? "" : "disabled"}
                  input_change={(e) => setAccountName(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  label_two="Bank Name"
                  place={"place"}
                  placeholder_two={contractorDetails.bankDetails?.bankName}
                  value_two={bankName}
                  disabled_two={!props.editable ? "" : "disabled"}
                  input_change_two={(e) => setBankName(e.target.value)}
                />
  
                <Form
                  input_type="input"
                  show_one={true}
                  label_one="Account No"
                  type_one="number"
                  placeholder_one={contractorDetails.bankDetails?.accountNumber}
                  value_one={accountNumber}
                  disabled={!props.editable ? "" : "disabled"}
                  input_change={(e) => setAccountNumber(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  place={"place"}
                  label_two="Sort Code"
                  placeholder_two={contractorDetails.bankDetails?.sortCode}
                  value_two={sortcode}
                  max={8}
                  disabled_two={!props.editable ? "" : "disabled"}
                  type_two="text"
                  input_change_two={(e) => ValidateSortcode(e)}
                />
                {!props.editable &&
                 <ViewButton 
                 cancel = {
                   props.cancel
                 }
                 save ={updateContractorDetails}
                 loader={props.updateDetailsLoading || loading}
  
                /> 
              }
          
          </div>
          :
          <ViewLoader/>

    }
      </>

    );
};

export default PersonalInformation;
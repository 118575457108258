import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Component imports
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, PUT_SERVICE, POST_SERVICE, DELETE_SERVICE } from "../Services/Backend";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import Tooltip from "../Common/Tooltip";
import Loader from "../Common/Loader";
import Select from "../Common/Select";
import Modal from "../Common/Modal";
import countryData from '../Utils/country.json'


// Import styles
import styles from "./css/agency.module.css";
import view from "../../Assets/view.svg";
import edit from "../../Assets/block.svg";
import delete_icon from "../../Assets/delete_icon.svg"
import viewdisable from "../../Assets/disss.svg";
import active from "../../Assets/activate.svg";

// Import resources
import NoContent from "../Common/NoContent";
import agency from "../../Assets/agency-green.svg";
import Table from "../Common/Table";
import add from "../../Assets/add-icon.png";

const Agency = () => {
  const [agencyList, setAgencyList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(null)
  const [town, setTown] = useState(null)
  const [postCode, setPostCode] = useState(null)
  const [date, setDate] = useState(null)
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [agencyListId, setAgencyListId] = useState();
  const [text, setText] = useState("Block");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [deleteToggle, setDeleteToggle] = useState(false)

  const [element, setElement] = useState(10);
  const [totalElement, setTotalElement] = useState(10);

  const getAgency = async (page) => {
    setLoading(true);
    try {
      const response = await GET_SERVICE(`/agencies?page=${page || 0}&size=10`);
      if (response.status === 200) {
        setAgencyList(response.data.data.agencies);
        setPageCount(response.data.data.totalPages);
        setPageNumber(page || 0);
        setTotalElement(response.data.data.totalElement);

       

        setLoading(false);
      } else {
        setAgencyList([]);
        setLoading(false);
      }
    } catch (err) {
      return err.response;
    }
  };
  const getMoreAgency = async (option) => {
    setLoading(true);
    try {
      const response = await GET_SERVICE(
        `/agencies?page=${pageNumber || 0}&size=${option}`
      );
      if (response.status === 200) {
     
        setAgencyList(response.data.data.agencies);
        setPageCount(response.data.data.totalPages);
        setElement(option);

       

        setLoading(false);
      } else {
        setAgencyList([]);
        setLoading(false);
      }
    } catch (err) {
      return err.response;
    }
  };

  const getSearch = async (e) => {
      e.preventDefault()
    try {
      const response = await GET_SERVICE(`/agencies/search?page=0&size=10&propertySortedBy=createdAt&searchBy=${search}`);
   
      setAgencyList(response.data.data.agencies);
    } catch (err) {
      return err.response;
    }
  };
  const clearField = ()=>{
   setDate("")
   setTown("")
   setCountry("")
   setPostCode("")
  }
  const filterAgency = async ()=>{
    const body ={
      "dateCreated": date,
    "town": town,
    "postCode":postCode,
    "country": country
    }
    const endpoint = `/agencies/filter?page=0&size=10`
    setLoading(true)
    try{ 
      const response = await POST_SERVICE(endpoint, body)
   
      if (response.status === 200) {
  
        if (response.data.data.agencies.length>0){
          setAgencyList(response.data.data.agencies);
          setPageCount(response.data.data.totalPages);
          setTotalElement(response.data.data.totalElement);
        }
     
      setLoading(false);
    }
    else {
  
      setLoading(false)
    }
  } catch (err) {
    return err.response;
  }
  }
  const nextPage = (e) => {
    let page = e.selected;
    getAgency(page);
  };
  useEffect(() => {
    getAgency();
  }, []);

  const columns = [
    { key: "fullName", label: "Agency Name" },

    { key: "agencies", label: "Umbrella(s)" },
    { key: "emailAddress", label: "Country" },
    { key: "gender", label: "Town" },

    { key: "umbrellas", label: "Date Added" },
  ];

  const navigate = useNavigate();

  const blockAgencyModal = (row) => {
    setAgencyListId(row);
    setToggle(!toggle);
    if (row.isLive === false) {
      setText("activate");
    } else {
      setText("block");
    }
  };

  const deleteAgencyModal = (row) => {
    setAgencyListId(row);
    setDeleteToggle(!deleteToggle);
    setText("delete");
  };
  const deleteAgency = async (agency) => {

    const endpoint = `/agencies/${agency}`
    try {
      const response = await DELETE_SERVICE(endpoint)

      if (response.status === 200) {
        setSuccess(true);
        getAgency()
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);


        }, 2000);

      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);

        }, 5000);
      }
      setText("")
      setAgencyListId(null)
      setDeleteToggle(false)

    }
    catch (e) {
      return e.response;
    }


  }

  const blockAgency = async (agencyList) => {
    if (agencyList.isLive === true) {
      try {
        const response = await PUT_SERVICE(`/agencies/${agencyList.id}/block`);
  
        console.log(response)
        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setToggle(false);
          getAgency();
          setTimeout(() => {
            setSuccess(false);
           
          }, 2000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
          setToggle(false);
          setTimeout(() => {
            setError(false);
        
          }, 5000);
        }
        setText("")
        setAgencyListId(null)
      } catch (e) {
        return e.response;
      }
    } else {
      try {
        const response = await PUT_SERVICE(
          `/agencies/${agencyList.id}/un-block`
        );

        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setToggle(false);
          getAgency();
          setTimeout(() => {
            setSuccess(false);
        
          }, 2000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false);
          }, 5000);
        }
        setText("")
        setAgencyListId(null)
      } catch (e) {
        return e.response;
      }
    }
  };

  return (
    <>
      <ErrorNotification show={error} content={errorMessage} />
      <SuccessNotification show={success} content={successMessage} />
      <MainContainer
        content={
          agencyList?.length > 0 ? (
            loading === true ? (
              <Loader show={loading} />
            ) : (
              <Table
                show_add={true}
                add_image={add}
                header="Agency"
                search={search}
                searchInput={(e)=>setSearch(e.target.value)}
                searchAction={(e) => getSearch(e)}
                columns={columns}
                action_button={() => navigate("/agency/add")}
                action="Add Agency"
                body={agencyList.map((row, index) => (
                  <tr key={index}>
                    <td>
                      {row.isLive === false ? (
                        <span style={{ color: "#9D9D9D" }}>
                          {row.agencyName}
                        </span>
                      ) : (
                        row.agencyName
                      )}
                    </td>
                 
                    <td>
                      {row.isLive === false
                        ? row.umbrellas.map((umbrella, index) => (
                            <span style={{ color: "#9D9D9D" }} key={umbrella}>
                              {umbrella.name}
                              {index !== row.umbrellas.length - 1 ? ", " : ""}
                            </span>
                          ))
                        : row.umbrellas.map((umbrella, index) => (
                            <span key={umbrella}>
                              {umbrella.name}
                              {index !== row.umbrellas.length - 1 ? ", " : ""}
                            </span>
                          ))}
                    </td>
                    <td>
                      {row.isLive === false ? (
                        <span style={{ color: "#9D9D9D" }}>{row.country}</span>
                      ) : (
                        row.country
                      )}
                    </td>
                    <td>
                      {row.isLive === false ? (
                        <span style={{ color: "#9D9D9D" }}>{row.town}</span>
                      ) : (
                        row.town
                      )}
                    </td>

                    <td>
                      {row.isLive === false ? (
                        <span style={{ color: "#9D9D9D" }}>
                          {row.dateDateAdded}
                        </span>
                      ) : (
                        row.dateDateAdded
                      )}
                    </td>
                    <td className={styles.action_icons}>
                      <div
                        style={{
                          display: "flex",
                          gridColumnGap: "20px",
                          alignItems: "center",
                          gridRowGap: "20px",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>
                          <Tooltip text={row.isLive !== false ?"View Profile":"Blocked"}>
                            <img
                              style={{ width: "25px" }}
                              src={row.isLive === false ? viewdisable : view}
                              alt={view}
                              onClick={() =>{
                                if (row.isLive !== false) {
                                navigate("/agency/view", {
                                  state: {
                                    id: row.id,
                                  },
                                })
                              }
                            }
                            }
                            />
                          </Tooltip>
                          {""}
                        </span>
                        <span>
                          <Tooltip
                            text={
                              row.isLive === false
                                ? "Activate"
                                : "Block"
                            }
                          >
                            <img
                              style={{ width: "20px" }}
                              src={row.isLive === false ? active : edit}
                              alt={edit}
                              onClick={() => blockAgencyModal(row)}
                            />
                          </Tooltip>
                          {""}
                        </span>
                        <span >
                            <Tooltip text={"Delete Agency"}>
                              <img

                                src={delete_icon}
                                alt={delete_icon}
                                onClick={() => deleteAgencyModal(row.id)}
                              />
                            </Tooltip>

                          </span>
                      </div>
                    </td>
                  </tr>
                ))}  
                row={element}
                row_list={
                  totalElement <= 30 && totalElement >= 20
                    ? [10, 20, 30]
                    : totalElement <= 20 && totalElement >= 10
                    ? [10, 20]
                    : totalElement <= 10
                    ? [10]
                    : []
                }
                row_item_click={(option) => getMoreAgency(option)}
                pageCount={pageCount}
                pageChange={nextPage}
                pageNumber={pageNumber}
                filterContent={
                  <div>
                    <div className={styles.filter}>
                      <div className={styles.select_input}>
                        <label>Country</label>
                        <div className={styles.custom_select_container}>
                          <Select
                            disabled=""
                            value={country}
                            onChange={(option) => setCountry(option)}
                            options={countryData.map((country) =>
                              country.name)}
                          />
                        </div>
                      </div>
                      <div className={styles.form_input}>
                      <label>Town</label>
                      <input value ={town} onChange={(e)=>setTown(e.target.value)} />

                      </div>
                      <div className={styles.form_input}>
                      <label>Post Code</label>
                      <input value ={postCode} onChange={(e)=>setPostCode(e.target.value)} />

                      </div>
                      <div className={styles.form_input}>
                      <label>Date</label>
                      <input type="date" value ={date} onChange={(e)=>setDate(e.target.value)} />

                      </div>
                    </div>
                    <div className={styles.buttons}>
                      <button className={styles.clear} onClick={clearField}>Cancel</button>
                      <button className={styles.solid} onClick={filterAgency}>
                        Save
                      </button>
                    </div>
                  </div>
                }
              />
            )
          ) : loading === true ? (
            <Loader show={loading} />
          ) : (
            <div className="no_content">
              <NoContent
                image={agency}
                header="No Agencies Added"
                text="Create a new Agency by clicking the button below"
                buttonText="Create Agency"
                next="/agency/add"
              />
            </div>
          )
        }
      />
      <Modal
        open={toggle}
        header="Warning message."
        content={<span> Are you sure you want to {text} this agency</span>}
        show_yes={true}
        cancel={() => setToggle(false)}
        yes={() => blockAgency(agencyListId)}
      />
            <Modal
        open={deleteToggle}
        header="Warning message."
        content={<span> Are you sure you want to {text} this agency</span>}
        show_yes={true}
        cancel={() => setToggle(false)}
        yes={() => deleteAgency(agencyListId)}
      />
    </>
  );
};

export default Agency;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import styles
import styles from "../Contractor/css/add.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, PUT_SERVICE } from "../Services/Backend";
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import Modal from "../Common/Modal";
import ViewLoader from "../Common/ViewLoader";
import countryData from '../Utils/country.json'
import countryCode from '../Utils/country_code.json'
import ColorPicker from 'react-pick-color';
//import resources

import InfoCard from "../Common/InfoCard";
import Form from "../Common/Form";
import Toggle from "../Common/Toogle";
import envelope from "../../Assets/envelop.svg";
import user from "../../Assets/user.svg";
import payslip from "../../Assets/payslip.svg";
import timesheet from "../../Assets/group.svg";
import phone_icon from "../../Assets/phone.svg";


const View = () => {

  const [companyNo, setCompanyNo] = useState('');
  const [name, setName] = useState("")
  const [town, setTown] = useState("")
  const [nationality, setNationality] = useState("")
  const [email, setEmail] = useState("")
  const [homeAddress, setHomeAddress] = useState("")
  const [homeAddressTwo, setHomeAddressTwo] = useState("")
  const [postcode, setPostcode] = useState("")
  const [viewLoading, setViewLoading] = useState(false)
  const [country, setCountry] = useState("")
  const [phone, setPhone] = useState("")
  const [mobile, setMobile] = useState("")
  const [editable, setEditable] = useState(true)
  const navigate = useNavigate();
  const location = useLocation()
  const [umbrellaDetails, setUmbrellaDetails] = useState({})
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [toggleLive, setToggleLive] = useState(false)
  const [blockUmbrella, setBlockUmbrella] = useState(false)
  const [switchLive, setSwitchLive] = useState(false)
  const [switchBlock, setSwitchBlock] = useState(false)
  const [phoneHead, setPhoneHead] = useState("")
  const [mobileHead, setMobileHead] = useState("")
  const [toggle, setToggle] = useState(false)
  const [umbrellaStats, setUmbrellaStats] = useState()
  const [cancelEdit, setCancelEdit] = useState(false)
  const [color, setColor] = useState('');
  const [colorShow, setColorShow] = useState(false)
  const toggleEdit = (index, activeIndex) => {
    if (index === activeIndex) {
      setEditable(false)
    }
  }
  const getUmbrellaStats = async () => {
    setViewLoading(true)
    const endpoint = `/umbrellas/${location.state.id}/stats`
    try {
      const response = await GET_SERVICE(endpoint);

      setUmbrellaStats(response.data.data)

     setViewLoading(false)



    } catch (err) {
      return err.response;
    }
  }
  const getUmbrellaDetails = async () => {
    const endpoint = `/umbrellas/${location.state.id}`
    try {
      const response = await GET_SERVICE(endpoint);

      setUmbrellaDetails(response?.data?.data)
      setColor(response?.data?.data?.payslipColorCode)
      setBlockUmbrella(!response?.data?.data?.isLive)
console.log(response?.data?.data?.payslipColorCode)




    } catch (err) {
      return err.response;
    }
  }
  const openColor = ()=>{
    if (editable ===false)
{
  setColorShow(true)
}  }
  useEffect(() => {
    getUmbrellaDetails()
    getUmbrellaStats()
  }, [])

  const blockUmbrellaAction = async () => {

    setSwitchLive(false);
    setSwitchBlock(false)
    setViewLoading(true)


    try {
      if (blockUmbrella === true) {

        const response = await PUT_SERVICE(`/umbrellas/un-block/${location.state.id}`)

        if (response.status = 200) {
          getUmbrellaDetails()
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setToggle(false)
          setViewLoading(false)
          setTimeout(() => {
            setSuccess(false);
       

          }, 2000);

        }



        else {
          setError(true);
          setErrorMessage(response.data.message);
          setViewLoading(false)
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }

      }
      else {
        const response = await PUT_SERVICE(`/umbrellas/block/${location.state.id}`)
     
        if (response.status = 200) {
          getUmbrellaDetails()
          setSuccess(true);
          setToggle(false)
           setViewLoading(false)
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
    
          }, 2000);

        }



        else {
          setError(true);
          setErrorMessage(response.data.message);
          setViewLoading(false)
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }

      }


    }
    catch (e) {
      return e.response;
    }


  }
  const updateUmbrellaDetails = async () => {
    setLoading(true)
    const endpoint = `/umbrellas/${location.state.id}`
    const body = {
      "name": name !== "" ? name : umbrellaDetails.name,
      "country": country !== "" ? country : umbrellaDetails.country,
      "addressOne": homeAddress !== "" ? homeAddress : umbrellaDetails.addressOne,
      "addressTwo": homeAddressTwo !== "" ? homeAddress : umbrellaDetails.addressTwo,
      "postCode": postcode !== "" ? postcode : umbrellaDetails.postCode,
      "town": town !== "" ? town : umbrellaDetails.town,
      "contactPhoneNumber": {
        "countryCode": phoneHead !== "" ? phoneHead : umbrellaDetails.contactPhoneNumber.countryCode,
        "number": phone !== "" ? phone : umbrellaDetails.contactPhoneNumber.number
      },
      "contactMobileNumber": {
        "countryCode": mobileHead !== "" ? mobileHead : umbrellaDetails.contactMobileNumber?.countryCode,
        "number": mobile !== "" ? mobile : umbrellaDetails.contactMobileNumber?.number
      },
      "contactEmail": email !== "" ? email : umbrellaDetails.contactEmailAddress,
      "isPensionApplicable": "true",
      "paySlipColorCode": color,

      "companyPhoneNumber": {
        "countryCode": "",
        "number": companyNo !== "" ? companyNo : umbrellaDetails.companyPhoneNumber.number
      }
    }


    try {
      const response = await PUT_SERVICE(endpoint, body)
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage(response.data.message);
                getUmbrellaDetails()
          setEditable(!editable)
          setViewLoading(false)
        setTimeout(() => {
          setSuccess(false);
        
    
        }, 2000);

      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
          setViewLoading(false)
        }, 5000);
      }


    }
    catch (e) {
      return e.response;
    }
  }
  return (
    <MainContainer content={
      viewLoading ?
        <ViewLoader />
        :
        <>
          <ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />
          <h1 style={{ color: "#9D9D9D", fontSize: "20px", fontWeight: "600" }}>
            <span onClick={() => navigate("/umbrella")} style={{ cursor: "pointer" }}>Umbrella</span>{" "}
            <span style={{ color: "#313131" }}>/ {umbrellaDetails?.name}</span>
          </h1>

          <InfoCard
            top_level_image={user}
            top_level={umbrellaDetails?.name}
            next_level_image={envelope}
            next_level={umbrellaDetails?.contactEmailAddress}
            bottom_level_image={phone_icon}
            bottom_level={umbrellaDetails?.contactPhoneNumber?.countryCode + umbrellaDetails?.contactPhoneNumber?.number || ""
            }
            show_live={blockUmbrella}
            show_block={blockUmbrella}
            toggle={() => setToggle(!toggle)}
            live_label="Status:"
            live="Live"
            block="Blocked"
            showlive={switchLive}
            showblock={switchBlock}
            live_text="Block"
            block_text="Activate"
            live_action={() => setSwitchLive(!switchLive)}
            block_action={() => setSwitchBlock(!switchBlock)}


            icon_top={timesheet}
            text_top="Contractors"
            number_top={umbrellaStats?.contractorsCount}
            icon_bottom={payslip}
            text_bottom="Paysilps"
            number_bottom={umbrellaStats?.payslipCount}
          />


          <Toggle
            buttonLabels={[
              "Contact Details",

            ]}
            onClick={toggleEdit}
            edit={blockUmbrella === false ? editable : ""}
            contents={[
              <>
                <Form

                  input_type="input"
                  show_one={true}
                  label_one="Umbrella Name"
                  placeholder_one={umbrellaDetails.name}
                  value_one={name}
                  disabled={!editable ? "" : "disabled"}
                  input_change={(e) => setName(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  place={"place"}
                  label_two="Company No"
                  placeholder_two={umbrellaDetails?.companyPhoneNumber?.number}
                  value_two={companyNo}
                  disabled_two={!editable ? "" : "disabled"}
                  input_change_two={(e) => setCompanyNo(e.target.value)}

                />
                <Form

                  input_type="select"
                  show_one={true}
                  label_one="Country"
                  place={"place"}
                  select_one_value={nationality || umbrellaDetails?.country}
                  select_change={(e) => setNationality(e.target.value)}
                  disabled={!editable ? "" : "disabled"}
                  value_one="United Kingdom"
                  select_one={countryData.map((country) =>
                    country.name

                  )}



                />
                <Form
                  show_header={true}
                  header="Primary Address"
                  line={true}
                  input_type="input"
                  show_one={true}
                  label_one="Address 1"
                  place={"place"}
                  placeholder_one={umbrellaDetails?.addressOne}
                  value_one={homeAddress}
                  disabled={!editable ? "" : "disabled"}
                  input_change={(e) => setHomeAddress(e.target.value)}
                  input_type_two="input"
                  show_two={true}
                  label_two="Address 2"
                  placeholder_two={umbrellaDetails?.addressTwo}
                  value_two={homeAddressTwo}
                  disabled_two={!editable ? "" : "disabled"}
                  input_change_two={(e) => setHomeAddressTwo(e.target.value)}

                />
                <Form

                  input_type="input"
                  show_one={true}
                  label_one="Post Code"
                  placeholder_one={umbrellaDetails.postCode}
                  value_one={postcode}
                  disabled={!editable ? "" : "disabled"}
                  input_change={(e) => setPostcode(e.target.value)}
                  show_two={true}
                  input_type_two="input"
                  label_two="Town"
                  placeholder_two={umbrellaDetails.town}
                  value_two={town}
                  disabled_two={!editable ? "" : "disabled"}
                  place={"place"}
                  input_change_two={(e) => setTown(e.target.value)}

                />
                <Form
                  show_header={true}
                  header="Primary Contact Information"

                  line={true}
                  input_type="tel"
                  show_tel={true}
                  label_tel="Phone"
                  disabled_tel={!editable ? "" : "disabled"}
                  place={"place"}
                  select_tel_value={phoneHead === "" ? umbrellaDetails?.contactPhoneNumber?.countryCode : phoneHead}
                  select_change_tel={(option) => setPhoneHead(option)}
                  select_tel={countryCode.map((code) =>
                    code.code
                  )}

                  // select_tel={[umbrellaDetails?.contactPhoneNumber?.countryCode]}
                  select_tel_two_value={mobileHead === "" ? umbrellaDetails?.contactMobile?.countryCode : mobileHead}
                  select_change_tel_two={(option) => setMobileHead(option)}
                  select_tel_two={countryCode.map((code) =>
                    code.code
                  )}
                  value_tel={phone}
                  placeholder_tel={umbrellaDetails?.contactPhoneNumber?.number}
                  input_change_tel={(e) => setPhone(e.target.value)}
                  show_tel_two={true}
                  label_tel_two="Mobile"
                  disabled_tel_two={!editable ? "" : "disabled"}
                  value_select_tel_two="+234"

                  value_tel_two={mobile}
                  placeholder_tel_two={umbrellaDetails?.contactMobile?.number}
                  input_change_tel_two={(e) => setMobile(e.target.value)}




                />

                <Form
                  show_one={true}
                  input_type="input"
                  label_one="Email"
                  place={"place"}
                  placeholder_one={umbrellaDetails.contactEmailAddress}
                  value_one={email}
                  disabled={"disabled"}
                  input_change={(e) => setEmail(e.target.value)}


                />
                         <p className={styles.label}>Choose Payslip Color:</p>
            <div className={styles.color_pick}  onClick={openColor} >
              <section style={{backgroundColor:color}}/>
              </div>
              <div onMouseLeave={()=>setColorShow(false)} className={styles.colorPicker}>
              {colorShow &&
           <ColorPicker color={color} onChange={color => { setColor(color.hex);  }} />


         }
              </div>
       
                {!editable &&
                  <div className={styles.buttons}>
                    <button className={styles.clear} onClick={() => setCancelEdit(true)}>
                      Cancel
                    </button>
                    {loading ?
                      <button className="loading_button"><div /></button>
                      :
                      <button className={styles.solid} onClick={updateUmbrellaDetails}>
                        <span>Save</span>

                      </button>
                    }
                  </div>

                }
              </>
            ]}
          />
          <Modal open={cancelEdit}
            header="Warning message."
            content={<span>  Are you sure you want to cancel
            </span>}
            show_yes={true}
            cancel={() => setCancelEdit(false)}
            yes={() => {
              setCancelEdit(false);
              setEditable(!editable)
            }}


          />
          <Modal open={toggle}
            header="Warning message."
            content={<span>  Are you sure you want to {blockUmbrella ? "activate" : "block"} this Umbrella
            </span>}
            show_yes={true}
            cancel={() => setToggle(false)}
            yes={blockUmbrellaAction}


          />
        </>

    }

    />
  );
};

export default View;

import React, { useEffect, useState } from 'react';


//component import
import Form from "../Common/Form"
import { GET_SERVICE, POST_SERVICE, DELETE_SERVICE, PUT_SERVICE } from '../Services/Backend';
import Sort from '../Utils/Sort';
import ViewButton from '../Common/ViewButton';
import del from "../../Assets/delete.svg";

//styles import
import styles from './css/margin.module.css'

const Holiday = (props) => {
  const [editable, setEditable] = useState(false)
  const [employerbandName, setEmployerbandName] = useState([])
  const [minimumEmployerPension, setMinimumEmployerPension] = useState([])
  const [maximumEmployerPension, setMaximumEmployerPension] = useState([])
  const [percentageEmployerPension, setPercentageEmployerPension] = useState([])
  const [employerBandEdit, setEmployerBandEdit] = useState([])
  const [minimumEmployerPensionEdit, setMinimumEmployerPensionEdit] = useState([])
  const [maximumEmployerPensionEdit, setMaximumEmployerPensionEdit] = useState([])
  const [percentageEmployerPensionEdit, setPercentageEmployerPensionEdit] = useState([])
  const [employerPensionId, setEmployerPensionId] = useState([])
  const [employerPension, setEmployerPension] = useState([])
  const [employerTableCount, setEmployerTableCount] = useState(1)
  const [employerSecondTableCount, setEmployerSecondTableCount] = useState(0)
  
  const [editableStudentLoan, setEditableStudentLoan] = useState(false)

  const [studentLoanBandEdit, setStudentLoanBandEdit] = useState([])
  const [minimumStudentLoanEdit, setMinimumStudentLoanEdit] = useState([])
  const [percentageStudentLoanEdit, setPercentageStudentLoanEdit] = useState([])
  const [studentLoanPensionId, setStudentLoanPensionId] = useState([])
  const [studentLoanPension, setStudentLoan] = useState([])
  const [studentLoanTableCount, setStudentLoanTableCount] = useState(1)


  const [editableEmployeePension, setEditableEmployeePension] = useState(false)
  const [employeebandName, setEmployeebandName] = useState([])
  const [minimumEmployeePension, setMinimumEmployeePension] = useState([])
  const [maximumEmployeePension, setMaximumEmployeePension] = useState([])
  const [percentageEmployeePension, setPercentageEmployeePension] = useState([])
  const [employeeBandEdit, setEmployeeBandEdit] = useState([])
  const [minimumEmployeePensionEdit, setMinimumEmployeePensionEdit] = useState([])
  const [maximumEmployeePensionEdit, setMaximumEmployeePensionEdit] = useState([])
  const [percentageEmployeePensionEdit, setPercentageEmployeePensionEdit] = useState([])
  const [employeePensionId, setEmployeePensionId] = useState([])
  const [employeePension, setEmployeePension] = useState([])
  const [employeeTableCount, setEmployeeTableCount] = useState(1)
  const [employeeSecondTableCount, setEmployeeSecondTableCount] = useState(0)
  const [editableLevy, setEditableLevy] = useState(false)
  const [holidayPay, setHolidayPay] = useState("")
  const [apprenticeshipLevy, setApprenticeshipLevy] = useState("")
  const [apprenticeshipLevyValue, setApprenticeshipLevyValue] = useState("")
  const [editableEmployerPension, setEditableEmployerPension] = useState(false)
  const [value, setValue] = useState("")

  const getHolidayPay = async () => {
    try {
      const response = await GET_SERVICE(`/base-configuration/holiday-pay`);
      if (response.status === 200) {
        setHolidayPay(response?.data?.data?.value);
      }



    } catch (err) {
      return err.response
    }
  };
  const getEmployerPension = async () => {
    try {
      const response = await GET_SERVICE(`/employer-pension-band`);
      
      if (response.status === 200) {
        setEmployerPension(response?.data?.data);
        setEmployerTableCount(response?.data?.data.length)
      
      }



    } catch (err) {
      return err.response
    }
  }
  const getEmployeePension = async () => {
    try {
      const response = await GET_SERVICE(`/employee-pension-band`);
      if (response.status === 200) {
        setEmployeePension(response?.data?.data);
        setEmployeeTableCount(response?.data?.data.length)

      }



    } catch (err) {
      return err.response
    }
  }
  const getStudentLoan = async () => {
    try {
      const response = await GET_SERVICE(`/student-loan`);
      if (response.status === 200) {
        setStudentLoan(response?.data?.data);
     
        setStudentLoanTableCount(response?.data?.data.length)
        console.log(response)        
      }



    } catch (err) {
      return err.response
    }
  }
  const addSecondTable = () => {
    setEmployerSecondTableCount((prevCount) => prevCount + 1)
  }
  const addSecondEmployeeTable = () => {
    setEmployeeSecondTableCount((prevCount) => prevCount + 1)
  }

  const addEmployerRanges = async () => {
    let updatedMessageIds = [];
  
    try {
      const mergedData = employerbandName.map((item, index) => ({
        bandName: item,
        minimumTaxableIncome: minimumEmployerPension[index],
        maximumTaxableIncome: maximumEmployerPension[index],
        percentageTaxRate: percentageEmployerPension[index],
      }));
  
      if (mergedData.length > 0) {
        const endpoint = `/employer-pension-band`;
        for (let index = 0; index < mergedData.length; index++) {
          const body = {
            bandName: mergedData[index].bandName,
            minimumTaxableIncome: parseFloat(mergedData[index].minimumTaxableIncome),
            maximumTaxableIncome: parseFloat(mergedData[index].maximumTaxableIncome),
            pensionRatePercentage: parseFloat(mergedData[index].percentageTaxRate),
          };
  
          const response = await POST_SERVICE(endpoint, body);
          updatedMessageIds.push(response.status === 201);
        }
      }
      const employerPensionIdSort = Sort(employerPensionId)
      const employerBandEditSort = Sort(employerBandEdit)
      const minimumEmployerPensionEditSort = Sort(minimumEmployerPensionEdit)
      const maximumEmployerPensionEditSort = Sort(maximumEmployerPensionEdit)
      const percentageEmployerPensionEditSort = Sort(percentageEmployerPensionEdit)
      
      const mergedDataEdit = employerPensionIdSort.map((item, index) => {
        const correspondingTaxRange = employerPension.find(employerPension => employerPension.id === item);
      
        return {
          bandName: employerBandEditSort[index] !== undefined ? employerBandEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.bandName : ''),
          minimumTaxableIncome: minimumEmployerPensionEditSort[index] !== undefined ? minimumEmployerPensionEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.minimumTaxableIncome : 0),
          maximumTaxableIncome: maximumEmployerPensionEditSort[index] !== undefined ? maximumEmployerPensionEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.maximumTaxableIncome : 0),
          percentageTaxRate: percentageEmployerPensionEditSort[index] !== undefined ? percentageEmployerPensionEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.pensionRatePercentage : 0),
          id: item
        };
      });
  
    

  
      
     
    
      if (mergedDataEdit.length > 0) {
        for (let index = 0; index < mergedDataEdit.length; index++) {
          const endpoint = `/employer-pension-band/${mergedDataEdit[index].id}`;
          const body = {
            bandName: mergedDataEdit[index].bandName,
            minimumTaxableIncome: parseFloat(mergedDataEdit[index].minimumTaxableIncome),
            maximumTaxableIncome: parseFloat(mergedDataEdit[index].maximumTaxableIncome),
            pensionRatePercentage: parseFloat(mergedDataEdit[index].percentageTaxRate),
          };
  
          const response = await PUT_SERVICE(endpoint, body);
          console.log(response)
          console.log(body)
          updatedMessageIds.push(response.status === 200);
        }
      }
  
      if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
        props.error("Not all Employer Pension ranges updated successfully");
        getEmployerPension();
        setEmployerBandEdit([]);
        setEmployerbandName([]);
        setMaximumEmployerPension([]);
        setMinimumEmployerPension([]);
        setMaximumEmployerPensionEdit([]);
        setMinimumEmployerPensionEdit([]);
        setEmployerPensionId([]);
        setEditableEmployerPension(false)
        setEmployerSecondTableCount(0)
        setPercentageEmployerPension([])
        setPercentageEmployerPensionEdit([])
      }
  
      if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
        props.error("Employer Pension ranges were not updated");
      }
  
      if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
        props.success("All Employer Pension ranges updated successfully");
        getEmployerPension();
        setEmployerBandEdit([]);
        setEmployerbandName([]);
        setMaximumEmployerPension([]);
        setMinimumEmployerPension([]);
        setMaximumEmployerPensionEdit([]);
        setMinimumEmployerPensionEdit([]);
        setEmployerPensionId([]);
        setEditableEmployerPension(false)
        setEmployerSecondTableCount(0)   
        setPercentageEmployerPension([])
        setPercentageEmployerPensionEdit([])     
      }
    } catch (err) {
      return err.response;
    }
  };

  const addEmployeeRanges = async () => {
    let updatedMessageIds = [];
  
    try {
      const mergedData = employeebandName.map((item, index) => ({
        bandName: item,
        minimumTaxableIncome: minimumEmployeePension[index],
        maximumTaxableIncome: maximumEmployeePension[index],
        percentageTaxRate: percentageEmployeePension[index],
      }));
  
      if (mergedData.length > 0) {
        const endpoint = `/employee-pension-band`;
        for (let index = 0; index < mergedData.length; index++) {
          const body = {
            bandName: mergedData[index].bandName,
            minimumTaxableIncome: parseFloat(mergedData[index].minimumTaxableIncome).toFixed(2),
            maximumTaxableIncome: parseFloat(mergedData[index].maximumTaxableIncome).toFixed(2),
            pensionRatePercentage: parseFloat(mergedData[index].percentageTaxRate).toFixed(2),
          };
          console.log(body)
  
          const response = await POST_SERVICE(endpoint, body);
          updatedMessageIds.push(response.status === 201);
        }
      }
 
      const employeePensionIdSort = Sort(employeePensionId)
      const employeeBandEditSort = Sort(employeeBandEdit)
      const minimumEmployeePensionEditSort = Sort(minimumEmployeePensionEdit)
      const maximumEmployeePensionEditSort = Sort(maximumEmployeePensionEdit)
      const percentageEmployeePensionEditSort = Sort(percentageEmployeePensionEdit)
    
      
      const mergedDataEdit = employeePensionIdSort.map((item, index) => {
        const correspondingTaxRange = employeePension.find(employeePension => employeePension.id === item);
      console.log(percentageEmployeePensionEditSort[index])
        return {
          bandName: employeeBandEditSort[index] !== undefined ? employeeBandEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.bandName : ''),
          minimumTaxableIncome: minimumEmployeePensionEditSort[index] !== undefined ? minimumEmployeePensionEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.minimumTaxableIncome : 0),
          maximumTaxableIncome: maximumEmployeePensionEditSort[index] !== undefined ? maximumEmployeePensionEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.maximumTaxableIncome : 0),
          percentageTaxRate: percentageEmployeePensionEditSort[index] !== undefined ? percentageEmployeePensionEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.pensionRatePercentage : 0),
          id: item
        };
      });
  
   
   
      if (mergedDataEdit.length > 0) {
   
        for (let index = 0; index < mergedDataEdit.length; index++ ) {
  
          const endpoint = `/employee-pension-band/${mergedDataEdit[index].id}`;
      
          const body = {
            bandName: mergedDataEdit[index].bandName,
            minimumTaxableIncome: parseFloat(mergedDataEdit[index].minimumTaxableIncome),
            maximumTaxableIncome: parseFloat(mergedDataEdit[index].maximumTaxableIncome),
            pensionRatePercentage: parseFloat(mergedDataEdit[index].percentageTaxRate),
          };
           console.log(body)
          const response = await PUT_SERVICE(endpoint, body);
          console.log(response)
          updatedMessageIds.push(response.status === 200);
        }
      }
  
      if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
        props.error("Not all Employee Pension Ranges updated successfully");
        getEmployeePension();
        setEmployeeBandEdit([]);
        setEmployeebandName([]);
        setMaximumEmployeePension([]);
        setMinimumEmployeePension([]);
        setMaximumEmployeePensionEdit([]);
        setMinimumEmployeePensionEdit([]);
        setEmployeePensionId([]);
        setEditableEmployeePension(false)
        setEmployeeSecondTableCount(0)
        setPercentageEmployeePension([])
        setPercentageEmployeePensionEdit([])
      }
  
      if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
        props.error("Employee Pension ranges were not updated");
      }
  
      if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
        props.success("All Employee Pension ranges updated successfully");
        getEmployeePension();
        setEmployeeBandEdit([]);
        setEmployeebandName([]);
        setMaximumEmployeePension([]);
        setMinimumEmployeePension([]);
        setMaximumEmployeePensionEdit([]);
        setMinimumEmployeePensionEdit([]);
        setEmployeePensionId([]);
        setEditableEmployeePension(false)
        setEmployeeSecondTableCount(0)
        setPercentageEmployeePension([])
        setPercentageEmployeePensionEdit([])
      }
    } catch (err) {
      return err.response;
    }
  };
  

  const addLoanRanges = async () => {
    let updatedMessageIds = [];
  
    try {
   
     console.log(studentLoanPensionId)
      const mergedDataEdit = studentLoanPensionId.map((item, index) => ({
        bandName:
         studentLoanBandEdit[index] !== undefined
            ?studentLoanBandEdit[index]
            :studentLoanPension[index].bandName,
        minimumTaxableIncome:
          minimumStudentLoanEdit[index] !== undefined
            ? minimumStudentLoanEdit[index]
            :studentLoanPension[index].loanTakenFromIncome,
        percentageTaxRate:
          percentageStudentLoanEdit[index] !== undefined
            ? percentageStudentLoanEdit[index]
            :studentLoanPension[index].percentageTaxRate,
        id: item,
      }));
   
  
      if (mergedDataEdit.length > 0) {
        for (let index = 0; index < mergedDataEdit.length; index++) {
          const endpoint = `/student-loan/${mergedDataEdit[index].id}`;
          const body = {
            bandName: mergedDataEdit[index].bandName,
            loanTakenFromIncome: parseFloat(mergedDataEdit[index].minimumTaxableIncome),
            percentageTaxRate: parseFloat(mergedDataEdit[index].percentageTaxRate),
          };
       
  
          const response = await PUT_SERVICE(endpoint, body);
          
          updatedMessageIds.push(response.status === 200);
        }
      }
  
      if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
        props.error("Not all Student Loan ranges updated successfully");
        getStudentLoan()
        setStudentLoanBandEdit([])
        setMinimumStudentLoanEdit([])
        setPercentageStudentLoanEdit([])
        setEditableStudentLoan(false)
     
        
      }
  
      if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
        props.error("Not all Student Loan Ranges were not updated");
      }
  
      if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
        props.success("All Student Loan Ranges updated successfully");
        getStudentLoan()
        setStudentLoanBandEdit([])
        setMinimumStudentLoanEdit([])
        setPercentageStudentLoanEdit([])
        setEditableStudentLoan(false)
    
      }
    } catch (err) {
      return err.response;
    }
  };
  
  const savePayRule = async () => {
    const body = {
      "value": value
    };
    const endpoint = `/base-configuration/holiday-pay`;
    try {
      const response = await POST_SERVICE(endpoint, body);
      if (response.status === 200) {
        props.success(response.data.message)
        getHolidayPay()
        setValue("")
        setEditable(false)
      }
      else {
        props.error(response.data.message)
      }
    }
    catch (err) {
      return err.response
    }
  }
  const getApprenticeshipLevy = async () => {
    try {
      const response = await GET_SERVICE(`/base-configuration/apprenticeship-levy`);
      if (response.status === 200) {
        setApprenticeshipLevy(response?.data?.data?.value);
      }



    } catch (err) {
      return err.response
    }
  };
  const deleteEmployerPensionItem = async (index, id) => {

    if (employerPensionId[index] !== null) {
      try {
        if (id) {
          const endpoint = `/employer-pension-band/${id}`;
          const response = await DELETE_SERVICE(endpoint);
          if (response.status === 200) {
            props.success(response.data.message)
            getEmployerPension()
            setEditableEmployerPension(false)
          }
          else {
            props.error(response.data.message)
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
  const deleteEmployeePensionItem = async (index, id) => {

    if (employeePensionId[index] !== null) {
      try {
        if (id) {
          const endpoint = `/employee-pension-band/${id}`;
          const response = await DELETE_SERVICE(endpoint);
          if (response.status === 200) {
            props.success(response.data.message)
            setEditableEmployeePension(false)
            getEmployeePension()


          }
          else {
            props.error(response.data.message)
          }
        }

      } catch (e) {
        console.error(e);
      }
    }
  }

  const deleteEmployerPension= (index) => {
    if (employerbandName.length > 0) {
      employerbandName.pop()
    }
    if (minimumEmployerPension.length > 0) {
      minimumEmployerPension.pop()
    }
    if (maximumEmployerPension.length > 0) {
      maximumEmployerPension.pop()
    }
    if (percentageEmployerPension.length > 0) {
      percentageEmployerPension.pop()
    }
    setEmployerSecondTableCount((prevCount) => prevCount - 1)
  }
  const deleteEmployeePension= (index) => {
    if (employeebandName.length > 0) {
      employeebandName.pop()
    }
    if (minimumEmployeePension.length > 0) {
      minimumEmployeePension.pop()
    }
    if (maximumEmployeePension.length > 0) {
      maximumEmployeePension.pop()
    }
    if (percentageEmployeePension.length > 0) {
      percentageEmployeePension.pop()
    }
    setEmployeeSecondTableCount((prevCount) => prevCount - 1)
  }

  const saveLevy = async () => {
    const body = {
      "value": apprenticeshipLevyValue
    };
    const endpoint = `/base-configuration/apprenticeship-levy`;
    try {
      const response = await POST_SERVICE(endpoint, body);
      if (response.status === 200) {
        props.success(response.data.message)
        getApprenticeshipLevy()
        setApprenticeshipLevyValue("")
        setEditableLevy(false)
      }
      else {
        props.error(response.data.message)
      }
    }
    catch (err) {
      return err.response
    }
  }

  useEffect(() => {
    getHolidayPay()
    getEmployeePension()
    getEmployerPension()
    getStudentLoan()
    getApprenticeshipLevy()
  }, [])


  return (
    <div className={styles.content}>
      <Form show_header={true}
        header={
          <div className={styles.content_header}>
            <h1>Holiday Pay Percentage</h1>
            <p>Amount added to contractors wage for Holiday Pay</p>
          </div>
        }
        place={"place"}
        line={true}
        bulk="Edit Percentage"
        edit_option={true}
        edit_option_style={styles.edit_icon}

        show_bulk={editable === false ? true : false}
        bulk_action={() => setEditable(!editable)}

        input_type="input"
        type_one="number"
        show_one={true}
        label_one="Percentage Added"

        value_one={value}
        input_change={(e) => setValue(e.target.value)}
        placeholder_one={holidayPay !== "" ? holidayPay + "%" : "0"}
        disabled={editable ? "" : "disabled"}
      />
      {editable === true &&


        <ViewButton
          cancel={() => setEditable(!editable)}
          save={savePayRule}
        />


      }
            <Form show_header={true}
            place={"place"}
        header={
          <div className={styles.content_header}>
            <h1>Apprenticeship Levy</h1>
            <p>Amount deducted from contractors wages for Apprenticeship Levy</p>
          </div>
        }
        line={true}
        bulk="Edit Percentage"
        edit_option={true}
        edit_option_style={styles.edit_icon}

        show_bulk={editable === false ? true : false}
        bulk_action={() => setEditableLevy(!editable)}

        input_type="input"
        type_one="number"
        show_one={true}
        label_one="Percentage Removed"

        value_one={apprenticeshipLevyValue}
        input_change={(e) => setApprenticeshipLevyValue(e.target.value)}
        placeholder_one={apprenticeshipLevy !== "" ? apprenticeshipLevy + "%" : "0"}
        disabled={editableLevy ? "" : "disabled"}
      />
      {editableLevy === true &&


        <ViewButton
          cancel={() => setEditableLevy(!editableLevy)}
          save={saveLevy}
        />


      }

<Form
        show_header={true}
        place={"place"}
        header={
          <div className={styles.content_header}>
            <h1>Employer Pension</h1>
            <p>Range of Employer for each band</p>
          </div>
        }
        line={true}
        bulk="Edit Employer's Pension"
        edit_option={true}
        edit_option_style={styles.edit_icon}

        show_bulk={editable === false ? true : false}
        bulk_action={() => setEditableEmployerPension(!editableEmployerPension)}
      />
      <div className={styles.table}>
        <table>
          <tr>
            <th>
              Band
            </th>
            <th>
            Pension Taken From Income
            </th>
            <th>
            Rate
            </th>
            {editableEmployerPension && (
              <th>
                Action
              </th>

            )}
          </tr>

          {Array.from({ length: employerTableCount }).map((_, index) => (
            <tr>
              <td key={index}>
                {editableEmployerPension ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="text"
                      placeholder={employerPension[index]?.bandName || ""}
                      value={employerBandEdit[index] || ""}
                      onChange={(e) => {

                        setEmployerBandEdit((prevValue) => {
                          const updatedValue = [...prevValue];
                          updatedValue[index] = e.target.value;

                          return updatedValue;
                        }

                        )
                        if (employerPensionId[index] === undefined) {
                          const selectedEmployerPension = employerPension.find((item) => item.id === employerPension[index].id);

                          if (selectedEmployerPension) {

                            setEmployerPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployerPension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }


                    />




                  </div>
                ) : (
                  <span>{employerPension[index]?.bandName}</span>
                )}
              </td>
              <td key={index}>
                {editableEmployerPension ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={employerPension[index]?.minimumTaxableIncome || "0" }
                      value={minimumEmployerPensionEdit[index] || ""}
                      onChange={(e) => {

                        setMinimumEmployerPensionEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (employerPensionId[index] === undefined) {
                          const selectedEmployerPension = employerPension.find((item) => item.id === employerPension[index].id);

                          if (selectedEmployerPension) {

                            setEmployerPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployerPension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }

                    />

                    <span>   to   </span>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={employerPension[index]?.maximumTaxableIncome || "0" }
                      value={maximumEmployerPensionEdit[index] || ""}
                      onChange={(e) => {

                        setMaximumEmployerPensionEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (employerPensionId[index] === undefined) {
                          const selectedEmployerPension = employerPension.find((item) => item.id === employerPension[index].id);

                          if (selectedEmployerPension) {

                            setEmployerPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployerPension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }

                    />
                  </div>
                ) : (
                  <span>{employerPension[index]?.maximumTaxableIncome === undefined || !employerPension[index]?.maximumTaxableIncome ?
                    "0"
                    :
                    "£" + employerPension[index]?.minimumTaxableIncome + " to " + "£" + employerPension[index]?.maximumTaxableIncome
                  }</span>
                )}
              </td>
              <td key={index}>
                {editableEmployerPension ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={employerPension[index]?.pensionRatePercentage || "0"}
                      value={percentageEmployerPensionEdit[index] || ""}
                      onChange={(e) => {

                        setPercentageEmployerPensionEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (employerPensionId[index] === undefined) {
                          const selectedEmployerPension = employerPension.find((item) => item.id === employerPension[index].id);

                          if (selectedEmployerPension) {

                            setEmployerPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployerPension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }


                    />




                  </div>
                ) : (
                  <span>{
                    employerPension[index]?.pensionRatePercentage !== undefined ?
                      employerPension[index]?.pensionRatePercentage + "%"

                      :
                      "0"
                  }</span>
                )}
              </td>
              {editableEmployerPension && employerPension.length > 1 && (
                <td className={styles.delete_icon}>
                  <img src={del} alt="icon" onClick={() => deleteEmployerPensionItem(index, employerPension[index].id)} />
                </td>

              )}


            </tr>

          ))}
          {Array.from({ length: employerSecondTableCount }).map((_, index) => (
            <tr>
              <td key={index}>
                {editableEmployerPension && (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="text"

                      value={employerbandName[index] || ""}
                      onChange={(e) => {
                        setEmployerbandName(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />




                  </div>
                )}
              </td>
              <td key={index}>
                {editableEmployerPension && (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                    placeholder = "0"

                      value={minimumEmployerPension[index] || ""}
                      onChange={(e) => {
                        setMinimumEmployerPension(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />

                    <span>   to   </span>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder = "0"
                      value={maximumEmployerPension[index] || ""}
                      onChange={(e) => {
                        setMaximumEmployerPension(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />
                  </div>
                )}
              </td>
              <td key={index}>
                {editableEmployerPension && (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder = "0"
                      value={percentageEmployerPension[index] || ""}
                      onChange={(e) => {
                        setPercentageEmployerPension(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />




                  </div>
                )}
              </td>
              {editableEmployerPension && (
                <td className={styles.delete_icon}>
                  <img src={del} alt="icon" onClick={() => deleteEmployerPension(index)} />
                </td>

              )}


            </tr>

          ))}



        </table>
      </div>
      {editableEmployerPension && (
        <div className={styles.add} style={{ justifyContent: "flex-start" }}>
          <button onClick={addSecondTable}><span>Add Employer Pension</span></button>
        </div>
      )}
      {editableEmployerPension  === true &&
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>

          <ViewButton
            cancel={() => { setEditableEmployerPension(false); setEmployerbandName([]); setMinimumEmployerPension(); setPercentageEmployerPension(); setMaximumEmployerPension(); setEmployerSecondTableCount(0) }}
            save={addEmployerRanges}
          />
        </div>
      }

<Form
        show_header={true}
        header={
          <div className={styles.content_header}>
            <h1>Employee Pension</h1>
            <p>Range of Employee for each band</p>
          </div>
        }
        line={true}
        bulk="Edit Employee's Pension"
        edit_option={true}
        edit_option_style={styles.edit_icon}

        show_bulk={editable === false ? true : false}
        bulk_action={() => setEditableEmployeePension(!editableEmployeePension)}
      />
      <div className={styles.table}>
        <table>
          <tr>
            <th>
              Band
            </th>
            <th>
            Pension Taken From Income
            </th>
            <th>
            Rate
            </th>
            {editableEmployeePension && (
              <th>
                Action
              </th>

            )}
          </tr>

          {Array.from({ length: employeeTableCount }).map((_, index) => (
            <tr>
              <td key={index}>
                {editableEmployeePension ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="text"
                      placeholder={employeePension[index]?.bandName || ""}
                      value={employeeBandEdit[index] || ""}
                      onChange={(e) => {

                        setEmployeeBandEdit((prevValue) => {
                          const updatedValue = [...prevValue];
                          updatedValue[index] = e.target.value;

                          return updatedValue;
                        }

                        )
                        if (employeePensionId[index] === undefined) {
                          const selectedEmployeePension = employeePension.find((item) => item.id === employeePension[index].id);

                          if (selectedEmployeePension) {

                            setEmployeePensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployeePension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }


                    />




                  </div>
                ) : (
                  <span>{employeePension[index]?.bandName}</span>
                )}
              </td>
              <td key={index}>
                {editableEmployeePension ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={employeePension[index]?.minimumTaxableIncome || "0"}
                      value={minimumEmployeePensionEdit[index] || ""}
                      onChange={(e) => {

                        setMinimumEmployeePensionEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (employeePensionId[index] === undefined) {
                          const selectedEmployeePension = employeePension.find((item) => item.id === employeePension[index].id);

                          if (selectedEmployeePension) {

                            setEmployeePensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployeePension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }

                    />

                    <span>   to   </span>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={employeePension[index]?.maximumTaxableIncome || "0"}
                      value={maximumEmployeePensionEdit[index] || ""}
                      onChange={(e) => {

                        setMaximumEmployeePensionEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (employeePensionId[index] === undefined) {
                          const selectedEmployeePension = employeePension.find((item) => item.id === employeePension[index].id);

                          if (selectedEmployeePension) {

                            setEmployeePensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployeePension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }

                    />
                  </div>
                ) : (
                  <span>{employeePension[index]?.maximumTaxableIncome === undefined || !employeePension[index]?.maximumTaxableIncome ?
                    "0"
                    :
                    "£" + employeePension[index]?.minimumTaxableIncome + " to " + "£" + employeePension[index]?.maximumTaxableIncome
                  }</span>
                )}
              </td>
              <td key={index}>
                {editableEmployeePension ? (
                  <div className={styles.table_body_input}>

<input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={employeePension[index]?.pensionRatePercentage || "0"}
                      value={percentageEmployeePensionEdit[index] || ""}
                      onChange={(e) => {

                        setPercentageEmployeePensionEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (employeePensionId[index] === undefined) {
                          const selectedEmployeePension = employeePension.find((item) => item.id === employeePension[index].id);

                          if (selectedEmployeePension) {

                            setEmployeePensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedEmployeePension.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }


                    />




                  </div>
                ) : (
                  <span>{
                    employeePension[index]?.pensionRatePercentage !== undefined ?
                      employeePension[index]?.pensionRatePercentage + "%"

                      :
                      "0"
                  }</span>
                )}
              </td>
              {editableEmployeePension && employeePension.length > 1 && (
                <td className={styles.delete_icon}>
                  <img src={del} alt="icon" onClick={() => deleteEmployeePensionItem(index, employeePension[index].id)} />
                </td>

              )}


            </tr>

          ))}
          {Array.from({ length: employeeSecondTableCount }).map((_, index) => (
            <tr>
              <td key={index}>
                {editableEmployeePension && (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="text"

                      value={employeebandName[index] || ""}
                      onChange={(e) => {
                        setEmployeebandName(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />




                  </div>
                )}
              </td>
              <td key={index}>
                {editableEmployeePension && (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                    placeholder="0"

                      value={minimumEmployeePension[index] }
                      onChange={(e) => {
                        setMinimumEmployeePension(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />

                    <span>   to   </span>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder="0"
                      value={maximumEmployeePension[index] || ""}
                      onChange={(e) => {
                        setMaximumEmployeePension(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />
                  </div>
                )}
              </td>
              <td key={index}>
                {editableEmployeePension && (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder="0"
                      value={percentageEmployeePension[index] || ""}
                      onChange={(e) => {
                        setPercentageEmployeePension(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                      }}

                    />




                  </div>
                )}
              </td>
              {editableEmployeePension && (
                <td className={styles.delete_icon}>
                  <img src={del} alt="icon" onClick={() => deleteEmployeePension(index)} />
                </td>

              )}


            </tr>

          ))}



        </table>
      </div>
      {editableEmployeePension && (
        <div className={styles.add} style={{ justifyContent: "flex-start" }}>
          <button onClick={addSecondEmployeeTable}><span>Add Employee Ranges</span></button>
        </div>
      )}
      {editableEmployeePension  === true &&
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>

          <ViewButton
            cancel={() => { setEditableEmployeePension(false); setEmployeebandName([]); setMinimumEmployeePension(); setPercentageEmployeePension(); setMaximumEmployeePension(); setEmployeeSecondTableCount(0) }}
            save={addEmployeeRanges}
          />
        </div>
      }



<Form
        show_header={true}
        header={
          <div className={styles.content_header}>
            <h1>Student Loan</h1>
            <p>Range of Student Loan for each band</p>
          </div>
        }
        line={true}
        bulk="Edit Student's Loan"
        edit_option={true}
        edit_option_style={styles.edit_icon}

        show_bulk={editableStudentLoan === false ? true : false}
        bulk_action={() => setEditableStudentLoan(!editableStudentLoan)}
      />
      <div className={styles.table}>
        <table>
          <tr>
            <th>
              Band
            </th>
            <th>
            Student Loan Taken From Income
            </th>
            <th>
            Rate
            </th>
      
          </tr>

          {Array.from({ length: studentLoanTableCount }).map((_, index) => (
            <tr>
              <td key={index}>
                {editableStudentLoan ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="text"
                      placeholder={studentLoanPension[index]?.bandName || ""}
                      value={studentLoanBandEdit[index] || ""}
                      onChange={(e) => {

                        setStudentLoanBandEdit((prevValue) => {
                          const updatedValue = [...prevValue];
                          updatedValue[index] = e.target.value;

                          return updatedValue;
                        }

                        )
                      
                        if (studentLoanPensionId[index] === undefined) {
                          const selectedStudentLoan = studentLoanPension.find((item) => item.id === studentLoanPension[index].id);
             
                          if (selectedStudentLoan) {

                            setStudentLoanPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedStudentLoan.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }


                    />




                  </div>
                ) : (
                  <span>{studentLoanPension[index]?.bandName}</span>
                )}
              </td>
              <td key={index}>
                {editableStudentLoan ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={studentLoanPension[index]?.loanTakenFromIncome || "0"}
                      value={minimumStudentLoanEdit[index] || ""}
                      onChange={(e) => {

                        setMinimumStudentLoanEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        console.log(studentLoanPensionId[index])
                        if (studentLoanPensionId[index] === undefined) {
                          const selectedStudentLoan = studentLoanPension.find((item) => item.id === studentLoanPension[index].id);

                          if (selectedStudentLoan) {

                            setStudentLoanPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedStudentLoan.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }

                    />



                  </div>
                ) : (
                  <span>{
                    studentLoanPension[index]?.loanTakenFromIncome !== undefined ?
                     "£" +studentLoanPension[index]?.loanTakenFromIncome 

                      :
                      "0"
                  }</span>
                )}
              </td>
              <td key={index}>
                {editableStudentLoan ? (
                  <div className={styles.table_body_input}>

                    <input style={{ maxWidth: "120px" }}
                      type="number"
                      placeholder={studentLoanPension[index]?.percentageTaxRate || "0"}
                      value={percentageStudentLoanEdit[index] || ""}
                      onChange={(e) => {

                        setPercentageStudentLoanEdit(


                          (prevValue) => {
                            const updatedValue = [...prevValue];
                            updatedValue[index] = e.target.value;
                            return updatedValue;
                          }

                        )
                        if (studentLoanPensionId[index] === undefined) {
                          const selectedStudentLoan = studentLoanPension.find((item) => item.id === studentLoanPension[index].id);

                          if (selectedStudentLoan) {

                            setStudentLoanPensionId((prevIds) => {
                              const updatedIds = [...prevIds];
                              updatedIds[index] = selectedStudentLoan.id; // Set umbrella ID at the 
                              return updatedIds;
                            });
                          }
                        }

                      }

                      }


                    />




                  </div>
                ) : (
                  <span>{
                    studentLoanPension[index]?.percentageTaxRate !== undefined ?
                      studentLoanPension[index]?.percentageTaxRate + "%"

                      :
                      "0"
                  }</span>
                )}
              </td>
         


            </tr>

          ))}
     



        </table>
      </div>
   
      {editableStudentLoan === true &&
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>

          <ViewButton
            cancel={() => { setEditableStudentLoan(false);   }}
            save={addLoanRanges}
          />
        </div>
      }



    </div>
  );
};

export default Holiday;
import React from "react";
 
//Component imports
import ViewLoader from "../Common/ViewLoader"
//import styles
import styles from "./css/info_card.module.css";

//import resources
import Card from "../Dashboard/Common/Card";
import edit from "../../Assets/edit_green.svg";

const InfoCard = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.info}>
      <>
        <div className={styles.info_details}>
          <span>
            <img src={props.top_level_image} alt={props.top_level_image} />
             {props.show_live===false ?
                 <h1>{props.top_level}</h1>
                 : 
                 <h1 style={{color:"#9D9D9D"}}>{props.top_level}</h1>


             }
          </span>
          <span>
            <img src={props.next_level_image} alt={props.next_level_image} />
            {props.show_live===false ?
            <h3>{props.next_level}</h3>
            :
            <h3 style={{color:"#9D9D9D"}}>{props.next_level}</h3>
            }
          </span>
          <span>
            <img
              src={props.bottom_level_image}
              alt={props.bottom_level_image}
            />
                         {props.show_live===false ?
            <h3>{props.bottom_level}</h3>
            :

            <h3 style={{color:"#9D9D9D"}}>{props.bottom_level}</h3>
               }
          </span>
        </div>
        <div className={styles.info_status}>
          <div className={styles.button_container}>
            <div 
            className={props.show_live ?styles.hide : styles.live }
            >
              <label>{props.live_label}</label>
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                    width: "23px",
                  }}
                  src={edit}
                  alt={edit}
                  onClick={props.live_action}
                />
                <button>{props.live}</button>
              </div>
            </div>
            {props.showlive && (
              <div onClick={props.toggle} className={styles.tooltip_text}>
                {props.live_text}
              </div>
            )}
            <div className={props.show_live ?styles.block:   styles.hide}>
              <label>{props.live_label}</label>
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                    width: "23px",
                  }}
                  src={edit}
                  alt={edit}
                  onClick={props.block_action}
                />
                <button>{props.block}</button>
              </div>
            </div>
            {props.showblock && (
              <div onClick={props.toggle} className={styles.tooltip_text}>
                {props.block_text}
              </div>
            )}
          </div>
          <div className={styles.button_container}>
            <div
              className={props.show_status ?  styles.info_status:styles.hide }
            >
              <label>{props.status_label}</label>
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                    width: "23px",
                  }}
                  src={edit}
                  alt={edit}
                  onClick={props.status_action}
                />
                <button>{props.status}</button>
              </div>
            </div>
            {props.showstatus && (
              <div className={styles.tooltip_text} onClick={props.set_two}>
                {props.status_text}
              </div>
            )}
          </div>
        </div>
      
      </>
      </div>
     
      <div className={styles.card_container}>
        <Card
          icon={props.icon_top}
          text={props.text_top}
          number={props.number_top}
        />
        <Card
          icon={props.icon_bottom}
          text={props.text_bottom}
          number={props.number_bottom}
        />
      </div>
    </div>
  );
};

export default InfoCard;

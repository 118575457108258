import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { POST } from "../Services/Backend";

//import style
import styles from "./css/auth.module.css";

//import Components
import AuthForm from "./Common/AuthForm";
import AuthImage from "./Common/AuthImage";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";


//import resources
import loginImage from "../../Assets/login-image.png";
import profile from "../../Assets/profile-icon.png";
import passwordIcon from "../../Assets/password-icon.png";
import view from "../../Assets/view.svg";
import eye from "../../Assets/eye-slash.svg";
import loginMobile from '../../Assets/loginMobile.png'

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  function preventBack() {
    window.history.forward();
  }
  useEffect(() => {
    preventBack()
  }, [])
  const onSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
  

    let hasError = false;

    if (email === "") {
      setEmailError(true);
      hasError = true;
    } else {
      setEmailError(false);
    }

    if (password === "") {
      setPasswordError(true);
      hasError = true;
    } else {
      setPasswordError(false);
    }

    const endpoint = "/users/login";
    const body = {
      emailAddress: email,
      password: password,
    };
 
    if (!hasError) {
      try {
        const response = await POST(endpoint, body);
       
        console.log(response)
        if (response.status === 200) {
          localStorage.setItem("id", response.data.data);
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setLoading(false)
          navigate("/otp");
          setTimeout(() => {
            setSuccess(false);
         
          }, 5000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);

          setTimeout(() => {
            setError(false);
            setLoading(false)
          }, 5000);
        }
      } catch (e) {
        return e.response;
      }
    }
  };

  return (
    <div className={styles.container}>

      <ErrorNotification show={error} content={errorMessage} />
      <SuccessNotification show={success} content={successMessage} />
    

      <AuthForm
        header="Welcome Back!"
        subText="Lets create some Payslips!!!"
        label="staff-id"
        labelText="Staff ID"
        image={loginMobile}
        buttonText="Sign In"
        inputIcon={profile}
        change={(e) => setEmail(e.target.value)}
        value={email}
        click={onSubmit}
        error={emailError ? "Please fill in your email" : ""}
        errorBorder={emailError ? true : false}
        loading={loading}
        password={
          <div className={styles.formControl}>
            <label
              htmlFor="password"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span>Password</span>
              <Link to="/ResetPassword">
                {" "}
                <span
                  style={{
                    color: "#DB504A",
                    fontFamily: "'SF Pro Display', sans-serif",
                  }}
                >
                  Lost password?
                </span>
              </Link>
            </label>
            <div
              className={passwordError ? styles.errorInput : styles.formInput}
            >
              <div className={styles.formInputTwo}>
                <img src={passwordIcon} alt="icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <img
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginLeft: "10px" }}
                src={showPassword ? eye : view}
                alt={showPassword ? eye : view}
              />
            </div>
            {passwordError && (
              <p className="error_message"> Please fill in your password</p>
            )}
          </div>
        }
      />
      <AuthImage
        header={
          <h1>
            Payslips Never <span style={{ color: "#85CE54" }}>Generated</span>{" "}
            Easier
          </h1>
        }
        background={loginImage}
      />
    </div>
  );
};

export default Login;

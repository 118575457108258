import React, {useState} from 'react';

//component import
import MainContainer from '../Common/MainContainer';
import Toggle from "../Common/Toogle"
import Margin from './Margin';
import Tax from './Tax';
import Holiday from './Holiday';
import Ni from './Ni';
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';

//styles import
import styles from './css/configuration.module.css'


const Configuartion = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")

  const handleSuccess=(message)=>{
    setSuccess(true);
 
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccess(false);
      setSuccessMessage("")
    }, 2000);
   }

   const handleError = (message)=>{
    setError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setError(false);
      setErrorMessage("")
 
    }, 5000);
   }

    return (
       <MainContainer content = {
        <>
                <ErrorNotification show={error} content={errorMessage} />
        <SuccessNotification show={success} content={successMessage} />
         
         <div className={styles.content_header}>
         
              <h1>Payroll Configuration</h1>
              <p>Configure settings to manage payslip calculations, <span> Note: any changes made will affect future calculations</span></p>
 
     
          </div>
          <Toggle
           buttonLabels={[
            "Margin ",
            "Tax Configuration",
            "NI Configuration",
            "Other Configuration",
          
          ]}
          style={{justifyContent:"center", columnGap: "20%"}}
          contents={[
            <Margin success={handleSuccess} error={handleError}/>,
            <Tax success={handleSuccess} error={handleError}/>,
            <Ni  success={handleSuccess} error={handleError}/>,
            <Holiday  success={handleSuccess} error={handleError}/>,
          ]}
          />
     
        </>
       }
       />
    );
};

export default Configuartion;
import React, { useState, useEffect } from "react";
//component imports
import Form from '../Common/Form';
import { GET_SERVICE, PUT_SERVICE } from '../Services/Backend';
import ViewButton from '../Common/ViewButton';
import ViewLoader from '../Common/ViewLoader';

//styles import
import styles from "../Contractor/css/view.module.css";


const Template = (props) => {
  const [merge, setMerge] = useState(false);
  const [viewTimesheets, setViewTimesheets] = useState(false)
  const [viewPlayslips, setViewPlayslips] = useState(false)
  const [viewRtis, setRtis] = useState(false)
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [fullname, setFullname] = useState("");
  const [hours, setHours] = useState("");
  const [payRate, setPayRate] = useState("");
  const [shiftDate, setShiftDate] = useState("");
  const [niNumber, setNiNumber] = useState("");
  const [agencyOtherDetails, setAgencyOtherDetails] = useState()
  const [loading, setLoading] = useState(false)
  const [viewLoading, setViewLoading] = useState(false)

  const getAgencyDetails = async () => {
  setLoading(true)
    const endpoint = `/agencies/${props.id}`
    try {
      const response = await GET_SERVICE(endpoint);
    setLoading(false)

      setViewTimesheets(response?.data?.data?.agencyViewSetup?.canViewTimeSheet)
      setViewPlayslips(response?.data?.data?.agencyViewSetup?.canViewPayslip)
      setRtis(response?.data?.data?.agencyViewSetup?.canViewRTI)
    } catch (err) {
      return err.response;
    }
  }
  const getAgencyOtherDetails = async () => {
    setLoading(true)

    const endpoint = `/agency-payroll-template/${props.id}`
    try {
      const response = await GET_SERVICE(endpoint);

      setLoading(false)
        if (response.status===200){

      setAgencyOtherDetails(response.data.data)
      if (response?.data?.data?.firstAndLastNameTogether !== undefined) {
        setMerge(response?.data?.data?.firstAndLastNameTogether)
      }
      else {
        setMerge(false)
      }

        }


    } catch (err) {
      return err.response;
    }
  }


  const updateConfigurationDetails = async () => {
    setLoading(true)

    const endpoint = `/agency-payroll-template/configuration/${props.id}`
    const body = {
      "firstAndLastNameTogether": merge !== "" ? merge : agencyOtherDetails.firstAndLastNameTogether,

      "fullNamePosition": fullname !== "" ? parseInt(fullname) : agencyOtherDetails?.fullNamePosition,
      "firstNamePosition": firstname !== "" ? parseInt(firstname) : agencyOtherDetails?.firstNamePosition,
      "lastNamePosition": lastname !== "" ? parseInt(lastname) : agencyOtherDetails?.lastNamePosition,
      "hoursPosition": hours !== "" ? parseInt(hours) : agencyOtherDetails.hoursPosition,
      "payRatePosition": payRate !== "" ? parseInt(payRate) : agencyOtherDetails.payRatePosition,
      "niNumberPosition": niNumber !== "" ? parseInt(niNumber) : agencyOtherDetails.niNumberPosition,
      "shiftDatePosition": shiftDate !== "" ? parseInt(shiftDate) : agencyOtherDetails.shiftDatePosition,

    }
    try {
      const response = await PUT_SERVICE(endpoint, body)

      if (response.status === 200) {
        props.success(response.data)
        getAgencyOtherDetails()
      }
      else {
       props.error(response.data)
      }


    }
    catch (e) {
      return e.response;
    }
  }
  const updateViewDetails = async () => {
    setLoading(true)
    const endpoint = `/agencies/${props.id}/agency-view-setup`
    const body = {
      "canViewTimeSheet": viewTimesheets,
      "canViewPaySlip": viewPlayslips,
      "canViewRTI": viewRtis
    }
    try {
      const response = await PUT_SERVICE(endpoint, body)
      if (response.status === 200) {
        props.success(response.data)
        getAgencyDetails()
        setLoading(false)
    
      }
      else {
       props.error(response.data)
        setLoading(false)
      }


    }
    catch (e) {
      return e.response;
    }
  }
  useEffect(()=>{
 getAgencyDetails()
 getAgencyOtherDetails()
  }, [])


  return (
<>
{loading === false ?
    <>
    <Form
      show_header={true}
      header="Template Setup"
      line={true} />
    <div className={styles.next_container}>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gridColumnGap: "20px",
        }}
      >

        <div className={styles.check_input}>
          <p>Are First name and Last name joined together</p>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={merge}

              onChange={(e) => setMerge(e.target.checked)}
              disabled={!props.editable ? "" : "disabled"}
            />
            <span className={styles.custom_switch}></span>
          </label>
        </div>
      </div>


      <div className={styles.next_form_container}>
        <div className={styles.form_input}>
          <label>Shift Date</label>
          <input
            type="number"
            value={shiftDate}
            className="place"
            placeholder={agencyOtherDetails?.shiftDatePosition}
            onChange={(e) => setShiftDate(e.target.value)}
            disabled={!props.editable ? "" : "disabled"}
          />
          {/* <span>{props.error_one}</span> */}
        </div>
        {merge === false &&
          <>
            <div className={styles.form_input}>
              <label>
                First Name
                <span style={{ color: "#DB504A" }}>*</span>
              </label>
              <input
                type="number"
                value={firstname}
                className="place"
                placeholder={agencyOtherDetails?.firstNamePosition}
                onChange={(e) => setFirstname(e.target.value)}
                disabled={!props.editable ? "" : "disabled"}
              />
              {/* <span>{props.error_one}</span> */}
            </div>
            <div className={styles.form_input}>
              <label>
                Last Name<span style={{ color: "#DB504A" }}>*</span>
              </label>
              <input
                type="number"
                value={lastname}
                className="place"
                placeholder={agencyOtherDetails?.lastNamePosition}
                onChange={(e) => setLastname(e.target.value)}
                disabled={!props.editable ? "" : "disabled"}
              />
              {/* <span>{props.error_one}</span> */}
            </div>
          </>

        }
        {merge === true &&
          <div className={styles.form_input}>
            <label>
              Full Name<span style={{ color: "#DB504A" }}>*</span>
            </label>
            <input
              type="number"
              value={fullname}
              className="place"
              placeholder={agencyOtherDetails?.fullNamePosition}
              onChange={(e) => setFullname(e.target.value)}
              disabled={!props.editable ? "" : "disabled"}
            />

          </div>
        }

        <div className={styles.form_input}>
          <label>
            Hours<span style={{ color: "#DB504A" }}>*</span>
          </label>
          <input
            type="number"
            value={hours}
            className="place"
            placeholder={agencyOtherDetails?.hoursPosition}
            onChange={(e) => setHours(e.target.value)}
            disabled={!props.editable ? "" : "disabled"}
          />
          {/* <span>{props.error_one}</span> */}
        </div>
        <div className={styles.form_input}>
          <label>
            Pay Rate<span style={{ color: "#DB504A" }}>*</span>
          </label>
          <input
            type="number"
            value={payRate}
            className="place"
            placeholder={agencyOtherDetails?.payRatePosition}
            onChange={(e) => setPayRate(e.target.value)}
            disabled={!props.editable ? "" : "disabled"}
          />
          {/* <span>{props.error_one}</span> */}
        </div>

        <div className={styles.form_input}>
          <label>
            NI Number<span style={{ color: "#DB504A" }}>*</span>
          </label>
          <input
            type="number"
            value={niNumber}
            className="place"
            placeholder={agencyOtherDetails?.niNumberPosition}
            onChange={(e) => setNiNumber(e.target.value)}
            disabled={!props.editable ? "" : "disabled"}
          />
          {/* <span>{props.error_one}</span> */}
        </div>
      </div>
    </div>

                          {!props.editable &&
                    <ViewButton 
                    cancel = {
                      props.cancel
                    }
                    save ={updateConfigurationDetails}
                    loader={props.templateLoading || loading}
     
                   /> 
                 }
    <Form
      show_header={true}
      header="View Setup"
      line={true}
      smallText="Select what this agency is allowed to view in their profile"
      check={true}
      show_check_one={true}
      place={"place"}
      check_one={viewTimesheets}
      check_one_change={(e) => setViewTimesheets(e.target.checked)}
      check_desc_one="View Timesheets:"
      show_check_two={true}
      disabled_check_one={!props.editable ? "" : "disabled"}
      check_desc_two="View Pay slips:"
      check_two={viewPlayslips}
      check_two_change={(e) => setViewPlayslips(e.target.checked)}
      disabled_check_two={!props.editable ? "" : "disabled"}
      show_check_three={true}
      check_desc_three="View Real Time Information's (RTIs):"
      check_three={viewRtis}
      check_three_change={(e) => setRtis(e.target.checked)}
      disabled_check_three={!props.editable ? "" : "disabled"}

    />
                         {!props.editable &&
                    <ViewButton 
                    cancel = {
                      props.cancel
                    }
                    save ={updateViewDetails}
                    loader={ viewLoading}
     
                   /> 
                 }
  </>
  :
  <ViewLoader/>

}
</>
  );
}

export default Template;

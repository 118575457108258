import React from 'react';

const ProgressBar = ({progress}) => {
    return (
        <div className="progress-bar">
                <div className="progress_track" style={{ width: `${progress}%`, backgroundColor: '#85CE54' }} />
              </div>
    );
};

export default ProgressBar;
import React, { useState, useEffect } from 'react';

//component imports
import Form from '../Common/Form';
import ViewButton from '../Common/ViewButton';
import ViewLoader from '../Common/ViewLoader'
import { GET_SERVICE, PUT_SERVICE } from '../Services/Backend';

const TaxDetails = (props) => {

  const [payFrequency, setPayFrequency] = useState("");
  const [ninCategory, setNinCategory] = useState("");
  const [payRule, setPayRule] = useState("");
  const [tax, setTax] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [taxBasis, setTaxBasis] = useState("");
  const [employeeni, setEmployeeni] = useState("");
  const [employerni, setEmployerni] = useState("");
  const [loan, setLoan] = useState("");
  const [contractorDetails, setContractorDetails] = useState({})
  const [enrolled, setEnrolled] = useState(false)
  const [external, setExternal] = useState(false)
  const [ninumber, setNinumber] = useState("");
  const [opt, setOpt] = useState(false)
  const [levy, setLevy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [NIcategory, setNICategories] = useState([])
  const [taxCodes, setTaxCodes] = useState([])
  const setNi = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 9);
    let formattedValue = '';
    const lettersOnly = sanitizedValue.slice(0, 2).replace(/[^a-zA-Z]/g, '');
    formattedValue = lettersOnly;
    const numbersOnly = sanitizedValue.slice(2, 8).replace(/[^0-9]/g, '');
    formattedValue += numbersOnly;
    const lettersOnlyAgain = sanitizedValue.slice(8, 9).replace(/[^a-zA-Z]/g, '');
    formattedValue += lettersOnlyAgain;
    setNinumber(formattedValue);
  }
  const [studentLoan, setStudentLoan] = useState([])
  const getStudentLoan = async () => {
    try {
      const response = await GET_SERVICE(`/student-loan`);
      if (response.status === 200) {
        setStudentLoan(response?.data?.data);
          
      }



    } catch (err) {
      return err.response
    }
  }
  const getContractorsDetails = async () => {
    const endpoint = `/contractors/${props.id}`
    setLoading(true)
    try {
      const response = await GET_SERVICE(endpoint);
      setContractorDetails(response?.data?.data)
       console.log(response)
      setLoading(false)
      setEnrolled(response?.data?.data?.pensionInformation?.employeePension)

      setExternal(response?.data?.data?.pensionInformation?.pensionProviderIsExternal)
      setOpt(response?.data?.data?.pensionInformation?.studentLoan)
       setLevy(response?.data?.data?.pensionInformation?.apprenticeshipLevy)

    } catch (err) {
      return err.response;
    }
  }
  const getCategory = async () => {
    try {
        const response = await GET_SERVICE(`/ni-category`);
        setNICategories(response?.data?.data || []);

        
       
      

    } catch (err) {
        return err.response
    }
};
  const updateTaxDetails = async () => {
    setLoading(true)

    const endpoint = `/contractors/${props.id}/update-tax-settings`
    const body = {
      "taxSettings": {
        "payFrequency": payFrequency !== "" ? payFrequency : contractorDetails.taxSettings?.payFrequency,
        "niCategory": ninCategory !== "" ? ninCategory : contractorDetails.taxSettings?.niCategory,
        "niNumber": ninumber !== "" ? ninumber : contractorDetails.taxSettings?.niNumber,
        "taxCode": taxCode !== "" ? taxCode : contractorDetails.taxSettings?.taxCode,
        "taxBasis": taxBasis !== "" ? taxBasis : contractorDetails.taxSettings?.taxBasis,
        "employeeNiYearToDate": employeeni !== "" ? employeeni : contractorDetails.taxSettings?.employeeNiYearToDate,
        "employerNiYearToDate": employerni !== "" ? employerni : contractorDetails.taxSettings?.employerNiYearToDate,
        "studentLoanApplicable": loan !== "" ?studentLoan.find(loanItem => loanItem.bandName === loan)?.id : contractorDetails.taxSettings?.studentLoanApplicable,
        "holidayPayRule": payRule !== "" ? payRule : contractorDetails.taxSettings?.holidayPayRule,
        "taxYearToDate": tax !== "" ? tax : contractorDetails.taxSettings?.taxYearToDate
      },
      "pensionInformation": {
        "employeePension": enrolled!=="" ? enrolled: contractorDetails.pensionInformation?.enrolledToPension,
        "pensionProviderIsExternal": external!=="" ? external: contractorDetails.pensionInformation?.pensionProviderIsExternal,
        "studentLoan": opt!=="" ? opt: contractorDetails.pensionInformation?.studentLoan,
        "apprenticeshipLevy": levy!=="" ? levy: contractorDetails.pensionInformation?.apprenticeshipLevy
      }

    }
    

    try {
      const response = await PUT_SERVICE(endpoint, body)
      if (response.status === 200) {
        props.success(response.data)
        getContractorsDetails()
       
      }
      else {
        props.error(response.data)
        setLoading(false)
      }


    }
    catch (e) {
      return e.response;
    }
  }
  const getTaxCode = async () => {
    try {
      const response = await GET_SERVICE("/tax-codes")

      setTaxCodes(response?.data?.data);
   




    } catch (err) {
      return err.response
    }

  }
  useEffect(() => {
    getContractorsDetails()
    getCategory()
    getTaxCode()
    getStudentLoan()
  }, [])
  return (
    <>
    {loading ===false ?
     <>
     <Form
       input_type="select"
       show_one={true}
       label_one="Pay Frequency"
       disabled={!props.editable ? "" : "disabled"}
       select_one_value={payFrequency || contractorDetails.taxSettings?.payFrequency}
       select_change={(option) => setPayFrequency(option)}
       select_one={["Weekly", "Bi-Weekly", "4 Weekly", "Monthly"]}
       input_type_two="select"
       show_two={true}
       label_two="NI Category"

       disabled_two={!props.editable ? "" : "disabled"}
       select_two={NIcategory.map((item)=>
        item.categoryTitle
        )}
       select_two_value={ninCategory || contractorDetails?.taxSettings?.niCategory}
       placeholder_two="Ni Category"
       select_change_two={(option) => setNinCategory(option)}
     />

     <Form
       input_type="input"
       show_one={true}
       label_one="NI Number"
       maxLength={9}
       placeholder_one={contractorDetails.taxSettings?.niNumber}
       value_one={ninumber}
       disabled={!props.editable ? "" : "disabled"}
       input_change={(e) => setNi(e)}
       input_type_two="select"
       show_two={true}
       place={"place"}
       label_two="Tax Code"
       select_two_value={taxCode ||contractorDetails?.taxSettings?.taxCode}
       select_two={taxCodes.map((item)=>
        item.name
        )}
        placeholder_two="Tax Code"
       disabled_two={!props.editable ? "" : "disabled"}
       select_change_two={(option) => setTaxCode(option)}
     />
     <Form
       input_type="select"
       show_one={true}
       label_one="Tax Basis"
       disabled={!props.editable ? "" : "disabled"}
       select_one_value={taxBasis || contractorDetails.taxSettings?.taxBasis}
       select_change={(option) => setTaxBasis(option)}
       select_one={["Cumulative", "Week 1/ Month 1"]}
       input_type_two="input"
       place={"place"}
       show_two={true}
       label_two="Employee NI YTD"
       type_two="number"
       disabled_two={!props.editable ? "" : "disabled"}
       value_two={employeeni}
       placeholder_two={contractorDetails.taxSettings?.employeeNiYearToDate}
       input_change_two={(e) => setEmployeeni(e.target.value)}
     />
<Form
  input_type="input"
  show_one={true}
  label_one="Employer NI YTD"
  placeholder_one={contractorDetails.taxSettings?.employerNiYearToDate}
  type_one="number"
  value_one={employerni}
  disabled={!props.editable ? "" : "disabled"}
  input_change={(e) => setEmployerni(e.target.value)}
  input_type_two="select"
  show_two={true}
  label_two="Student Loan Applicable"
  select_two_value={
    studentLoan.find(item => item.id === contractorDetails.taxSettings?.studentLoanApplicable)?.bandName || loan
  }
  place={"place"}
  select_two={studentLoan.map((item) => item.bandName)}
  disabled_two={!props.editable ? "" : "disabled"}
  select_change_two={(option) => setLoan(option)}
/>
     <Form
       input_type="select"
       show_one={true}
       label_one="Holiday Pay Rule"
       disabled={!props.editable ? "" : "disabled"}
       select_one_value={payRule || contractorDetails.taxSettings?.holidayPayRule}
       select_change={(option) => setPayRule(option)}
       select_one={[
         "Rolled up on Gross Pay for Tax",
         "Retained on Gross pay for Tax",
         "Rolled up on Total Pay",
         "Retained on Total Pay",
         "Retained Average Holiday Pay",
         "No Holiday Pay",
         "Rolled Up on Total Pay",
       ] }
       input_type_two="input"
       show_two={true}
       place={"place"}
       label_two="Tax YTD"
       disabled_two={!props.editable ? "" : "disabled"}
       type_two="number"
       value_two={tax}
       placeholder_two={contractorDetails.taxSettings?.taxYearToDate}
       input_change_two={(e) => setTax(e.target.value)}
     />
 
     <Form
       show_header={true}
       header="Pension Information"
       line={true}
       check={true}
       show_check_one={true}
       check_one={enrolled}
       disabled_check_one={!props.editable ? "" : "disabled"}
       disabled_check_two={!props.editable ? "" : "disabled"}
       disabled_check_three={!props.editable ? "" : "disabled"}
       check_one_change={(e) => setEnrolled(e.target.checked)}
       check_two={external}
       check_two_change={(e) => setExternal(e.target.checked)}
       check_three={opt}
       check_four={levy}
       check_three_change={(e) => setOpt(e.target.checked)}
       check_four_change={(e) => setLevy(e.target.checked)}
       check_desc_one="Enrolled on to Pension:"
       show_check_two={true}
       check_desc_two="External Pension Provider:"
       show_check_three={true}
       check_desc_three="Student Loan:"
       show_check_four={true}
       check_desc_four="Apprenticeship Levy:"
     />
     {!props.editable &&
       <ViewButton
         cancel={
           props.cancel
         }
         save={updateTaxDetails}
         loader={props.updateTaxDetailsLoading || loading}

       />

     }
   </>
   :
   <ViewLoader/>

    }
    </>
   
  );
};

export default TaxDetails;
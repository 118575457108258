import React, {useState, useEffect} from 'react';
//component imports
import Form from '../Common/Form';
import { GET_SERVICE, PUT_SERVICE } from '../Services/Backend';
import ViewButton from '../Common/ViewButton';
import ViewLoader from '../Common/ViewLoader';

const Payroll = (props) => {
    const [amount, setAmount] = useState("");
    const [holidayPayIncluded, setHolidayPayIncluded] = useState(false);
    const [employerNiInsurance, setEmployerNiInsurance] = useState(false);
    const [vatCharged, setVatCharged] = useState(false);
    const [vatReversedCharged, setVatReversedCharged] = useState(false);
    const [agencyOtherDetails, setAgencyOtherDetails] = useState()
    const [loading, setLoading] = useState(false)
    const getAgencyOtherDetails = async () => {
        setLoading(true)

        const endpoint = `/agency-payroll-template/${props.id}`
        try {
          const response = await GET_SERVICE(endpoint);
          setLoading(false)
    
          setAgencyOtherDetails(response?.data?.data)

    
          setHolidayPayIncluded(response?.data?.data?.holidayPayIncluded)
          setEmployerNiInsurance(response?.data?.data?.employerNiInsurance)
          setVatCharged(response?.data?.data?.vatCharged)
          setVatReversedCharged(response?.data?.data?.employerPension)
    
    
        } catch (err) {
          return err.response;
        }
      }
      const updateSettingsDetails = async () => {
        setLoading(true)
    
        const endpoint = `/agency-payroll-template/settings/${props.id}`
        const body = {
          "holidayPayIncluded": holidayPayIncluded !== "" ? holidayPayIncluded : agencyOtherDetails.holidayPayIncluded,
          "employerNiInsurance": employerNiInsurance !== "" ? employerNiInsurance : agencyOtherDetails.employerNiIncluded,
          "vatCharged": vatCharged !== "" ? vatCharged : agencyOtherDetails.vatCharged,
          "employerPension": vatReversedCharged !== "" ? vatReversedCharged : agencyOtherDetails.employerPension,
     
        }
        console.log(body, "body")
        try {
          const response = await PUT_SERVICE(endpoint, body)
          if (response.status === 200) {
            props.success(response.data)
            getAgencyOtherDetails()
            setLoading(false)
          }
          else {
            props.error(response.data)
            setLoading(false)
          }
    
    
        }
        catch (e) {
          return e.response;
        }
      }
      useEffect(()=>{
        getAgencyOtherDetails()
      }, [])
    return (
    <>
    {loading===false?
            <>
            <Form
              check={true}
              show_check_one={true}
              place={"place"}
              check_one={holidayPayIncluded}
              check_one_change={(e) => setHolidayPayIncluded(e.target.checked)}
              check_desc_one="Holiday pay included:"
              show_check_two={true}
              disabled_check_one={!props.editable ? "" : "disabled"}
              check_desc_two="Employers NI:"
              check_two={employerNiInsurance}
              check_two_change={(e) => setEmployerNiInsurance(e.target.checked)}
              disabled_check_two={!props.editable ? "" : "disabled"}
              show_check_three={true}
              check_desc_three="VAT Charged:"
              check_three={vatCharged}
              check_three_change={(e) => setVatCharged(e.target.checked)}
              disabled_check_three={!props.editable ? "" : "disabled"}
              show_check_four={true}
              check_desc_four="Employer Pension:"
              disabled_check_font={!props.editable ? "" : "disabled"}
              check_four={vatReversedCharged}
              check_four_change={(e) => setVatReversedCharged(e.target.checked)}
      
            />
    
           
                            {!props.editable &&
                    <ViewButton 
                    cancel = {
                      props.cancel
                    }
                    save ={updateSettingsDetails}
                    loader={props.payLoading || loading}
     
                   /> 
                 }
          </>
          :
          <ViewLoader/>

    }
    </>
    );
};

export default Payroll;
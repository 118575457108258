import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import styles
import styles from "./css/report.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE } from "../Services/Backend";
import Loader from "../Common/Loader";
import ReactPaginate from "react-paginate";
import DateFormat from "../Utils/DateFormat";


//import resources

import contractor from "../../Assets/no_report.svg";
import bell from "../../Assets/bell.svg"
import green_right from "../../Assets/green_right.svg"
import filter from "../../Assets/filter-icon.png";
import left from "../../Assets/arrow-left.svg"
import right from "../../Assets/arrow-right.svg"




const Report = () => {
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState()
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setPageCount] = useState()
  const [pageNumber, setPageNumber] = useState()
  const [date, setDate] = useState()
  const navigate = useNavigate();
  const [toggle, setToggle] = useState();

  const toggler = () => {
    setToggle(!toggle);
  };
const  clearField = ()=>{
  setDate("")
}
  const getAllReports = async (offSet) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/tickets?page=${offSet || 0}&size=5`);
      if (response.status===200){

       setPageNumber(offSet || 0)
       setLoading(false)
       setReports(response?.data?.data?.allTickets)
       setPageCount(response?.data?.data?.totalPages)
      }
      else{
        setReports([])
        console.log(reports)
        setLoading(false)
      }






    } catch (err) {
      return err.response;
    }

  }
  const getReports = async (offSet) => {
    setLoading(true)
    try {
      let formattedDate = DateFormat(date)
      const response = await GET_SERVICE(`/tickets/filter?page=0&size=5&date=${formattedDate}`);
      console.log(response)
      if (response.status===200){

       setPageNumber(offSet || 0)
       setLoading(false)
       setReports(response?.data?.data?.allTickets)
       setPageCount(response?.data?.data?.totalPages)
      }
      else{
        setReports([])
        console.log(reports)
        setLoading(false)
      }






    } catch (err) {
      return err.response;
    }

  }
  const pageChange = (e)=>{

    const selectedPage = e.selected;
    const offSet = selectedPage ;
    getAllReports(offSet)
  }
  useEffect(() => {
    getAllReports()
  }, [])
  return (
    <>
      <MainContainer
        content={
          reports?.length ===0?
          loading === true ? 
            <Loader show={loading} />
            :
            <div className={styles.content}>
              <h1>Reports</h1>
              <p>Reports sent by various agencies are seen below</p>
              <div className={styles.no_content}>
                <div>
                  <div style={{ background: "#F9F9F9", padding: "40px", borderRadius: "50%" }}>
                    <img style={{ width: "120px" }} src={contractor} alt={contractor} />
                  </div>

                  <h3>No Reports</h3>
                </div>
              </div>
            </div>
            :
            loading === true ? 
            <Loader show={loading} />:
            <div className={styles.report_container}>
                 <button
              className={styles.filter}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filter} alt={filter} />
              <span>Filter</span>
            </button>
            {showFilter &&
                <div>
                  <div className={styles.filterDiv}>
           
                    <div className={styles.form_input}>
                      <label>Date</label>
                      <div className={styles.input_container}>
                        <input type="date" value={date} onChange={(e)=>setDate(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} onClick={clearField}>Cancel</button>
                    <button className={styles.solid} onClick={getReports} disabled={date === ""}>
                      Save
                    </button>
                  </div>
                </div>
              }
              {reports?.map((report) =>
                <div className={styles.report}>
                  <div className={styles.header}>
                    <img src={bell} alt={bell} />
                    <div>
                      <h3>{report.agencyName} 
                       {report.ticketStatus ==="OPENED" &&

                        <span style={{color:"#85CE54", marginLeft:"5px"}}> OPEN</span>
                        

                       }
                           {report.ticketStatus ==="CLOSED" &&

                          <span style={{color:"#DB504A",  marginLeft:"5px"}}>{report.ticketStatus}</span>


                          }
                      </h3>
                      <p>{report.dateTime}</p>
                      <div className={styles.report_content}>
                        <h4>{report.title}</h4>
                        <p>{report.message}</p>
                      </div>
                    </div>




                  </div>

                <div className={styles.view} onClick={()=>navigate("/report/view",{
                   state:{
                    id: report.ticketId,
                    report_name: report.agencyName,
                    report_status: report.ticketStatus
                  }
                } )}>
                    <p>View</p> <img src={green_right} alt={green_right} />
                  </div>
                </div>



              )
              }
                  <div style={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                  <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={<span className={styles.paginate_next}><img src={left} alt={left}/></span>} //
                                    onPageChange={pageChange}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount}
                                    forcePage={pageNumber}
                                    previousLabel={<span className={styles.paginate_next}><img src={right} alt={right}/></span>} 
                                    activeClassName={styles.activePage}
                                    className={styles.paginate}
                                    nextLinkClassName={styles.next}
                                    previousLinkClassName={styles.prev}
                                    activeLinkClassName={styles.activeLink}

                                />
                  </div>
            </div>
        }
      />
    </>
  );
};

export default Report;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//import style
import styles from './css/auth.module.css'

//import Components
import AuthForm from './Common/AuthForm';
import AuthImage from './Common/AuthImage';
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';


import { POST, GET } from '../Services/Backend';

//import resources
import loginImage from '../../Assets/reset-image.png'
import resetMobile from "../../Assets/resetMobile.png"

import profile from "../../Assets/password-icon.png"

const Otp = () => {
  const [idError, setIdError] = useState(false)
  const [staffId, setStaffId] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const navigate = useNavigate()
  function preventBack() {
    window.history.forward();
  }
  useEffect(() => {
    preventBack()
  }, [])

  const resendOtp = async () => {

    setLoading(true)
    try {
      const response = await GET(`/users/${localStorage.getItem("id")}/resend-otp`)
 
      if (response.status === 200) {


        setSuccess(true);
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);
          setLoading(false)

        }, 5000);
      } else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
          setLoading(false)
        }, 5000);
      }
    }
    catch (e) {
      return e.response;
    }

  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let hasError = false;
    if (staffId === "") {
      setIdError(true);

      return;
    }



    const endpoint = "/users/authorize-otp";
    const body = {

      "userId": localStorage.getItem("id"),
      "otp": staffId,

    };
    if (!hasError) {

      try {
        const response = await POST(endpoint, body)
        console.log(response)

        if (response.status === 200) {
          
          localStorage.setItem("toggle", true);
          sessionStorage.setItem("token", response.data.data.token);
          localStorage.setItem("firstname", response.data.data.userProfile.firstName)
          localStorage.setItem("lastname", response.data.data.userProfile.lastName)
          localStorage.setItem("role", response.data.data.userProfile.role)
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            setLoading(false);
            navigate("/overview");
          }, 5000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setLoading(false);
          }, 5000);
        }
      } catch (e) {
        return e.response;
      }
    }
  };
  return (
    <div className={styles.container}>
      <ErrorNotification show={error} content={errorMessage} />
      <SuccessNotification show={success} content={successMessage} />
      <AuthForm
        header="One Time Password"
        subText={<span>Please enter the one time password provided <br />in your email <span style={{ color: "#CB411E" }}> (It expires after 1 min)
        </span></span>}
        image={resetMobile}

        label="otp"
        labelText="OTP"
        buttonText="Sign In"
        inputIcon={profile}
        error={idError ? "Please fill in the one time password" : ""}
        errorBorder={idError ? true : false}
        change={(e) => setStaffId(e.target.value)}
        value={staffId}
        click={onSubmit}
        showOtp={true}
        send_text="Resend Otp"
        otp={resendOtp}
        loading={loading}


      />
      <AuthImage
        header=""
        background={loginImage}
      />

    </div>
  );
};

export default Otp;
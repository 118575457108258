import React, {useState} from 'react';


//style import
import styles from "./css/selecttel.module.css"

const SelectTel = ({ options, value, onChange ,error, disabled}) => {
    const [showOptions, setShowOptions] = useState(false);

   const toggle = ()=>{
    setShowOptions(!showOptions)
   }
   const nothing = ()=>{

   }
    const handleOptionChange = (option) => {
      onChange(option);
      setShowOptions(false);
      
    };
    const hideOptions = () => {
      setShowOptions(false);
    };
    return (
        <div className={styles.custom_select}>
   {error ?
    <>
        <div className={disabled === "disabled" ?styles.disabled_option : styles.error_input} onClick={disabled ==="disabled"? nothing: toggle}>
        {value || <span style={{color:"#8e8e8e"}}>+44</span>}
      </div>

{showOptions && (
  <ul className={styles.options} onMouseLeave={hideOptions}>
    {options.map((option) => (
      <li key={option} onClick={() => handleOptionChange(option)}>
        {option}
      </li>
    ))}
  </ul>
)}</>
      :
      <>
         <div className={disabled === "disabled" ?styles.disabled_option : styles.selected_option} onClick={disabled ==="disabled"? nothing: toggle}>
      {value || <span style={{color:"#8e8e8e"}}>+44</span>}
    </div>

{showOptions && (
  <ul className={styles.options} onMouseLeave={hideOptions}>
    {options.map((option) => (
      <li key={option} onClick={() => handleOptionChange(option)}>
        {option}
      </li>
    ))}
  </ul>
)}
</>
   
   }
      </div>
    );
};

export default SelectTel;
import React, {useState, useEffect} from 'react';

//component imports
import Form from '../Common/Form';
import { GET_SERVICE, PUT_SERVICE, DELETE_SERVICE, POST_SERVICE } from '../Services/Backend';
import countryData from '../Utils/country.json'
import countryCode from '../Utils/country_code.json'
import ViewButton from '../Common/ViewButton';
import ViewLoader from '../Common/ViewLoader';

const AgencyInformation = (props) => {
    const [agencyDetails, setAgencyDetails] = useState({})
    const [companyNo, setCompanyNo] = useState("");
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [componentCount, setComponentCount] = useState();
    const [town, setTown] = useState("");
    const [phoneHead, setPhoneHead] = useState("")
  const [mobileHead, setMobileHead] = useState("")
    const [phone, setPhone] = useState("");
    const [mobile, setMobile] = useState("");
    const [homeAddress, setHomeAddress] = useState("");
    const [homeAddressTwo, setHomeAddressTwo] = useState("");
    const [agencyUmbrella, setAgencyUmbrella] = useState()
    const [postcode, setPostcode] = useState("");
    const [secondComponentCount, setSecondComponentCount] = useState(0)
    const [listUmbrella, setListUmbrella] = useState([]);
    const [listUmbrellaId, setListUmbrellaId] = useState("")
    const [umbrella, setUmbrella] = useState("");
    const [loading, setLoading] = useState(false)
    const [allUmbrella, setAllUmbrella] = useState([])


    const createForm = () => {

         
        if (props.editable === false && secondComponentCount <= agencyUmbrella.length - componentCount) {
          setSecondComponentCount((prevCount) => prevCount + 1);
        }
      };
      const deleteForm = () => {
        if (props.editable === false ) {
    
          setSecondComponentCount((prevCount) => prevCount - 1)
    
        if ( listUmbrellaId!==""){
          listUmbrellaId.pop()
          umbrella.pop()
        }
    
    
        }
      }
      const getAgencyUmbrella = async () => {
        try {
          const response = await GET_SERVICE(`/agencies/${props.id}/umbrellas`);
          console.log(response)
    
          setAgencyUmbrella(response.data.data)
          setComponentCount(response.data.data.length)
        } catch (err) {
          return err.response;
        }
      }
    
    const getAgencyDetails = async () => {
        setLoading(true)

        const endpoint = `/agencies/${props.id}`
        try {
          const response = await GET_SERVICE(endpoint);
          setLoading(false)
          setAgencyDetails(response?.data?.data)
      
        } catch (err) {
          return err.response;
        }
      }
      const linkUmbrella = async (umbrella) => {
        console.log(umbrella)
        const endpoint = '/agencies/umbrella/link'
        const body = {
          "agencyId": props.id,
          "umbrellaId": umbrella
        }
        try {
          const response = await POST_SERVICE(endpoint, body)
          console.log(body)
          console.log(response)
    
          if (response.status === 200) {
            props.success(response.data)
            getAgencyUmbrella()
            setSecondComponentCount(0)
          }
          else {
           props.error(response.data)
          }
    
    
        }
        catch (e) {
          return e.response;
        }
    
    
      }
      const unlinkUmbrella = async (umbrella) => {

        const endpoint = `/agencies/umbrella/un-link/${props.id}/${umbrella}`
        try {
          const response = await DELETE_SERVICE(endpoint)
    
          if (response.status === 200) {
            props.success(response.data)
            getAgencyUmbrella()
          }
          else {
           props.error(response.data)
          }
    
    
        }
        catch (e) {
          return e.response;
        }
    
    
      }
      const updateAgencyDetails = async () => {
        setLoading(true)
        const endpoint = `/agencies/${props.id}`
        const body = {
          "companyName": name !== "" ? name : agencyDetails.companyName,
          "companyNumber": companyNo !== "" ? companyNo : agencyDetails.companyNumber,
    
          "town": town !== "" ? town : agencyDetails.town,
          "primaryAddressOne": homeAddress !== "" ? homeAddress : agencyDetails.primaryAddressOne,
          "primaryAddressTwo": homeAddressTwo !== "" ? homeAddressTwo : agencyDetails.primaryAddressTwo,
          "postCode": postcode !== "" ? postcode : agencyDetails.postCode,
          "country": country !== "" ? country : agencyDetails.country,
          "contactPhoneNumber": {
            "countryCode": phoneHead !== "" ? phoneHead : agencyDetails.contactPhoneNumber.countryCode,
            "number": phone !== "" ? phone : agencyDetails.contactPhoneNumber.number
          },
          "contactMobileNumber": {
            "countryCode": mobileHead !== "" ? mobileHead : agencyDetails.contactMobileNumber.countryCode,
            "number": mobile !== "" ? mobile : agencyDetails.contactMobileNumber.number
          },
         
    
    
        }
    
        try {
          const response = await PUT_SERVICE(endpoint, body)
    
    
          if (response.status === 200) {
          
            props.success(response.data);
            window.location.reload();
            getAgencyDetails()
          }
          else {
           props.error(response.data)
          }
    
    
        }
        catch (e) {
          return e.response;
        }
      }
      const getUmbrella = async () => {
    

        try {
          const response = await GET_SERVICE("/umbrellas");
    
          setAllUmbrella(response.data.data); 
   
    
        } catch (err) {
          return err.response;
        }
      };
      useEffect (()=>{
     getAgencyDetails()
     getAgencyUmbrella()
     getUmbrella()
      }, [])
    return (
        <>
        {loading === false ?
               <>
               <Form
                 input_type="input"
                 show_one={true}
                 place={"place"}
                 label_one="Name"
                 placeholder_one={agencyDetails?.companyName}
                 value_one={name}
                 disabled={!props.editable ? "" : "disabled"}
                 input_change={(e) => setName(e.target.value)}
                 input_type_two="input"
                 show_two={true}
                 label_two="Company No"
                 placeholder_two={agencyDetails?.companyNumber}
                 value_two={companyNo}
                 disabled_two={!props.editable ? "" : "disabled"}
                 input_change_two={(e) => setCompanyNo(e.target.value)}
               />
               <Form
                 input_type="select"
                 show_one={true}
                 place={"place"}
                 label_one="Country"
                 select_one_value={country || agencyDetails?.country}
                 select_change={(option) => setCountry(option)}
                 disabled={!props.editable ? "" : "disabled"}
                 value_one={country}
                 select_one={countryData.map((country) =>
                   country.name

                 )}
               />

               <Form
                 show_header={true}
                 header="Primary Address"
                 line={true}
                 input_type="input"
                 show_one={true}
                 place={"place"}
                 label_one="Address 1"
                 placeholder_one={agencyDetails?.primaryAddressOne}
                 value_one={homeAddress}
                 disabled={!props.editable ? "" : "disabled"}
                 input_change={(e) => setHomeAddress(e.target.value)}
                 input_type_two="input"
                 show_two={true}
                 label_two="Address 2"
                 placeholder_two={agencyDetails?.primaryAddressTwo}
                 value_two={homeAddressTwo}
                 disabled_two={!props.editable ? "" : "disabled"}
                 input_change_two={(e) => setHomeAddressTwo(e.target.value)}
               />
               <Form
                 input_type="input"
                 show_one={true}
                 label_one="Post Code"
                 place={"place"}
                 placeholder_one={agencyDetails?.postCode}
                 value_one={postcode}
                 disabled={!props.editable ? "" : "disabled"}
                 input_change={(e) => setPostcode(e.target.value)}
                 input_type_two="input"
                 show_two={true}
                 label_two="County"
                 placeholder_two={agencyDetails?.town}
                 disabled_two={!props.editable ? "" : "disabled"}
                 select_change_two={(e) => setCountry(e.target.value)}
               />
               <Form
                 show_header={true}
                 header="Primary Contact Information"
                 place={"place"}
                 line={true}
                 input_type="tel"
                 show_tel={true}
                 label_tel="Phone"
                 disabled_tel={!props.editable ? "" : "disabled"}
                 select_tel_value={phoneHead === "" ? agencyDetails?.contactPhoneNumber?.countryCode : phoneHead}
                 select_change_tel={(option) => setPhoneHead(option)}
                 select_tel={countryCode.map((code) =>
                   code.code
                 )}
                 select_tel_two_value={mobileHead === "" ? agencyDetails?.contactMobile?.countryCode : mobileHead}
                 select_change_tel_two={(option) => setMobileHead(option)}
                 select_tel_two={countryCode.map((code) =>
                   code.code
                 )}
                 value_tel={phone}
                 placeholder_tel={agencyDetails?.contactPhoneNumber?.number}
                 input_change_tel={(e) => setPhone(e.target.value)}
                 show_tel_two={true}
                 label_tel_two="Mobile"
                 disabled_tel_two={!props.editable ? "" : "disabled"}
                 value_select_tel_two="+234"
                 value_tel_two={mobile}
                 placeholder_tel_two={agencyDetails?.contactMobile?.number}
                 input_change_tel_two={(e) => setMobile(e.target.value)}

               />
               <Form
                 input_type="input"
                 show_one={true}
                 label_one="Email"
                 place={"place"}
                 placeholder_one={agencyDetails?.contactEmailAddress}
     
                 disabled={"disabled"}
  
               />

               {Array.from({ length: componentCount }).map((_, index) => (
                 <Form
                   input_type="select"
                   show_one={true}
                   label_one="Select Umbrella for Agency Portal"

                   select_one_value={
                     index < agencyUmbrella.length
                       ? agencyUmbrella[index].name
                       : ""
                   } // Use umbrella[index] for the corresponding form


                   disabled={"disabled"}

                   image={!props.editable && index === 0 ? true : false}
                   clearButton={!props.editable ? true : false}
                   add={() => {
                     createForm();

                   }}
                   del={() => {
                     unlinkUmbrella(agencyUmbrella[index].id)
                   }}
                   clear={() => {
                     unlinkUmbrella(agencyUmbrella[index].id)
                   }}
                 />
               ))}

               {Array.from({ length: secondComponentCount }).map((_, index) => (
                 <Form
                   input_type="select"
                   show_one={true}
                   label_one="Select Umbrella for Agency Portal"

                   select_one_value={umbrella[index] || ""}  // Use umbrella[index] for the corresponding form

                   select_change={(option) => {
                     setUmbrella((prevUmbrella) => {
                       const updatedUmbrella = [...prevUmbrella];
                       updatedUmbrella[index] = option; // Set umbrella name at the corresponding index
                       return updatedUmbrella;
                     });
                     const selectedUmbrella = allUmbrella.find((item) => item.name === option);
                     if (selectedUmbrella) {
      
                       setListUmbrellaId((prevIds) => {
                         const updatedIds = [...prevIds];
                         updatedIds[index] = selectedUmbrella.id; // Set umbrella ID at the corresponding index
                         return updatedIds;
                       });
                     }
                   }}
                   disabled={!props.editable ? "" : "disabled"}
                   select_one={allUmbrella.map((umbrella) => umbrella.name)} // Pass an array of umbrella names as options
                   saveButton={!props.editable && index >= 0 ? true : false}
                   imagedel={index >= 0 ? true : false}
                   save={() =>
                     linkUmbrella(listUmbrellaId[index])
                   }
                   del={() => {
                     deleteForm()
                   }}
                 />
               ))}
              
                     {!props.editable &&
                <ViewButton 
                cancel = {
                  props.cancel
                }
                save ={updateAgencyDetails}
                loader={props.agencyLoading || loading}
 
               /> 
             }

             </>
             :
             <ViewLoader/>

        }
        </>
 
    );
};

export default AgencyInformation;
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { POST_FILE } from "../Services/Backend";

//import styles
import styles from "./css/add.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import Modal from "../Common/Modal";
import Bulk from "../Common/Bulk";
import countryData from '../Utils/country.json'
import countryCode from '../Utils/country_code.json'
import nationalitiesData from '../Utils/nationalities.json'
import EmailValidate from '../Utils/EmailValidate'
import ProgressBar from "../Utils/ProgressBar";
import error_icon from "../../Assets/caution.svg"
import file_icon from "../../Assets/file.svg"
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";



//import resources
import Form from "../Common/Form";
import upload from "../../Assets/upload.svg";
import process_bar from "../../Assets/process-bar-two.svg";

const Add = () => {
  const [firstname, setFirstname] = useState("");
  const [loading, setLoading] = useState(false)
  const [firstnameError, setFirstnameError] = useState(false)
  const [lastname, setLastname] = useState("");
  const [lastnameError, setLastnameError] = useState(false)
  const [nationality, setNationality] = useState("");
  const [nationalityError, setNationalityError] = useState(false)
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState(false)
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("")
  const [dateError, setdateError] = useState(false)
  const [homeAddress, setHomeAddress] = useState("");
  const [homeAddressError, setHomeAddressError] = useState(false)
  const [homeAddressTwo, setHomeAddressTwo] = useState("");
  const [postcode, setPostcode] = useState("");
  const [postcodeError, setPostcodeError] = useState(false)
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false)
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false)
  const [mobile, setMobile] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNameError, setAccountNameError] = useState(false)
  const [bankName, setBankName] = useState("");
  const [bankNameError, setBankNameError] = useState("")
  const [sortcode, setSortcode] = useState("");
  const [sortcodeError, setSortcodeError] = useState(false)
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState(false)
  const [genderList] = useState(["Male", "Female"]);
  const [emailError, setEmailError] = useState(false)
  const [phoneHead, setPhoneHead] = useState("+44")
  const [mobileHead, setMobileHead] = useState("+44")
  const [toggle, setToggle] = useState();
  const [bulk, setBulk] = useState(false)
  const [cancelBulk, setCancelBulk] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [filename, setFilename] = useState("No file selected")
  const [progress, setProgress] = useState(0);
  const [bulkLoading, setBulkLoading] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [responseSheet, setResponseSheet] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const toggler = () => {
    setToggle(!toggle);
  };
  const setSort = (e) => {
    const value = e.target.value;

    const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
    let formattedValue = '';
    if (sanitizedValue.length > 2) {
      formattedValue = `${sanitizedValue.slice(0, 2)}-${sanitizedValue.slice(2, 4)}`;
      if (sanitizedValue.length > 4) {
        formattedValue += `-${sanitizedValue.slice(4)}`;
      }
    } else {
      formattedValue = sanitizedValue;
    }

    setSortcode(formattedValue);
  }
  const goNext = () => {

    const initial_details = {
      "firstName": firstname,
      "lastName": lastname,
      "nationality": nationality,
      "gender": gender,
      "dob": date,
      "primaryAddress": {
        "homeAddressOne": homeAddress,
        "homeAddressTwo": homeAddressTwo,
        "postCode": postcode,
        "country": country
      },
      "primaryContactInformation": {
        "contactPhoneNumber": {
          "countryCode": phoneHead,
          "number": phone
        },
        "contactMobileNumber": {
          "countryCode": mobileHead,
          "number": mobile
        },
        "emailAddress": email
      },
      "bankDetails": {
        "accountName": accountName,
        "bankName": bankName,
        "accountNumber": accountNumber,
        "sortCode": sortcode
      }
    };

    let hasError = false;

    if (firstname === "") {
      setFirstnameError(true);
      hasError = true;
    } else {
      setFirstnameError(false);
    }
    if (phone === "") {
      setPhoneError(true)
      hasError = true;
    } else {
      setPhoneError(false);
    }
    if (lastname === "") {
      setLastnameError(true);
      hasError = true;
    } else {
      setLastnameError(false);
    }

    if (nationality === "") {
      setNationalityError(true);
      hasError = true;
    } else {
      setNationalityError(false);
    }

    if (gender === "") {
      setGenderError(true);
      hasError = true;
    } else {
      setGenderError(false);
    }

    if (date === "") {
      setdateError(true);
      hasError = true;
    } else {
      setdateError(false);
    }
    if (email === "") {
      setEmailError(true);
      setEmailErrorText("Please fill in your email address")
      hasError = true;
    } else {
      if (EmailValidate(email)) {
        setEmailError(false)
        setEmailErrorText("")
      }
      else {
        setEmailError(true)
        setEmailErrorText("Please enter a valid email")
        setEmail("")
        hasError = true;
      }
    }
    if (homeAddress === "") {
      setHomeAddressError(true);
      hasError = true;
    } else {
      setHomeAddressError(false);
    }

    if (postcode === "") {
      setPostcodeError(true);
      hasError = true;
    } else {
      setPostcodeError(false);
    }

    if (country === "") {
      setCountryError(true);
      hasError = true;
    } else {
      setCountryError(false);
    }

    if (accountName === "") {
      setAccountNameError(true);
      hasError = true;
    } else {
      setAccountNameError(false);
    }

    if (bankName === "") {
      setBankNameError(true);
      hasError = true;
    } else {
      setBankNameError(false);
    }

    if (accountNumber === "") {
      setAccountNumberError(true);
      hasError = true;
    } else {
      setAccountNumberError(false);
    }

    if (sortcode === "") {
      setSortcodeError(true);
      hasError = true;
    } else {
      setSortcodeError(false);
    }

    if (!hasError) {
      setLoading(true)
      setLoading(false)
      navigate("/contractor/next", {
        state: {
          body: initial_details
        }
      });
    }
    else {
      setLoading(false)
    }
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event?.target?.files[0]);
    console.log(event?.target?.files[0])
    setFilename(event?.target?.files[0]?.name);
  };
  const closeError = () => {
    setFirstnameError(false);
    setPhoneError(false);
    setLastnameError(false);
    setNationalityError(false);
    setGenderError(false);
    setdateError(false);
    setEmailError(false);
    setEmailErrorText("");
    setHomeAddressError(false);
    setPostcodeError(false);
    setCountryError(false);
    setAccountNameError(false);
    setBankNameError(false);
    setAccountNumberError(false);
    setSortcodeError(false);
  }
  useEffect(() => {
    window.addEventListener('keydown', closeError);
  }, [])
  const uploadSheet = async (event) => {
    setBulkLoading(true);
    setProgress(0);
    setShowProgress(true)


    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 0));
    }, 200);
    setResponseSheet([])
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await POST_FILE(
        `/contractors/bulk-upload`,
        formData
      );

      if (response.status === 200 || response.status === 201) {
        setResponseSheet(response.data.data)
        clearInterval(interval);
        setShowProgress(false)
        setSuccessMessage("Contractor List uploaded Successfully")
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false);
          setFilename("No file selected")
          setSelectedFile(null)
          setBulk(false)

          setBulkLoading(false)
        }, 2000);



      } else {

        setError(response.data.message)
        setResponseSheet(response.data.data)
        clearInterval(interval);


        setShowProgress(false)
        setError(true);
        setErrorMessage("Upload failed")
        setTimeout(() => {
          setError(false);
          setBulkLoading(false)
        }, 5000);
      }
    } catch (error) {
      console.log(error);


    }
  }
  return (
    <MainContainer
      content={
        <>

<ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />

          <div className={styles.content_header}>
            <div>
              <h1>New Contractors</h1>
              <p>Please fill out the forms below to create add a contractor</p>
            </div>
            <img src={process_bar} alt={process_bar} />
          </div>
          <div className={styles.form_container}>
            <Form
              show_header={true}
              header="Contact Details"
              bulk="Bulk Upload"
              show_bulk={true}
              compulsory_one={true}
              compulsory_two={true}
              bulk_action={() => setBulk(!bulk)}
              line={true}
              bulk_icon={upload}
              input_type="input"
              show_one={true}
              error_one_true={firstnameError}
              error_one="Please fill in your Firstname"
              label_one="First Name"
              placeholder_one="First Name"
              value_one={firstname}
              disabled=""
              input_change={(e) => setFirstname(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Last name"
              placeholder_two="Last Name"
              value_two={lastname}
              error_two_true={lastnameError}
              error_two="Please fill in your Lastname"
              disabled_two=""
              input_change_two={(e) => setLastname(e.target.value)}
            />
            <Form
              input_type="select"
              show_one={true}
              label_one="Nationality"
              select_one_value={nationality}
              select_change={(option) => setNationality(option)}
              disabled=""
              compulsory_one={true}
              compulsory_two={true}
              error_one_true={nationalityError}
              error_one="Please select a nationality"
              value_one={nationality}
              select_one={nationalitiesData.map((nation) =>
                nation
              )}
              input_type_two="select"
              show_two={true}
              label_two="Gender"
              error_two_true={genderError}
              error_two="Please select a gender"
              select_two_value={gender}
              select_two={genderList}
              value_two={gender}
              disabled_two=""
              select_change_two={(option) => setGender(option)}
            />

            <Form
              input_type="input"
              show_one={true}
              label_one="Date of Birth"
              type_one="date"
              compulsory_one={true}

              value_one={date}
              error_one="Please fill in your date of birth"
              error_one_true={dateError}
              disabled=""
              input_change={(e) => setDate(e.target.value)}
            />
            <Form
              show_header={true}
              header="Primary Address"
              line={true}
              bulk_icon={upload}
              input_type="input"
              show_one={true}
              compulsory_one={true}

              label_one="Home Address 1"
              placeholder_one="Home Address"
              error_one="Please fill in your home Address"
              error_one_true={homeAddressError}
              value_one={homeAddress}
              disabled=""
              input_change={(e) => setHomeAddress(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Home Address 2"
              placeholder_two="Home Address"
              value_two={homeAddressTwo}
              disabled_two=""
              input_change_two={(e) => setHomeAddressTwo(e.target.value)}
            />

            <Form
              input_type="input"
              show_one={true}
              label_one="Post Code"
              placeholder_one="Post Code"
              value_one={postcode}
              disabled=""
              compulsory_one={true}
              compulsory_two={true}
              error_one="Please fill in your post code"
              error_one_true={postcodeError}
              input_change={(e) => setPostcode(e.target.value)}
              input_type_two="select"
              show_two={true}
              label_two="Country"
              select_two_value={country}
              select_two={countryData.map((country) =>
                country.name

              )}
              error_two="Please select your country"
              error_two_true={countryError}
              value_two="Nigeria"
              disabled_two=""
              select_change_two={(option) => setCountry(option)}
            />
            <Form
              show_header={true}
              header="Primary Contact Information"
              line={true}
              input_type="tel"
              show_tel={true}
              label_tel="Phone"
              disabled_tel=""
              select_tel_value={phoneHead}
              error_tel_true={phoneError}
              select_change_tel={(option) => setPhoneHead(option)}
              compulsory_tel={true}

              select_tel={countryCode.map((code) =>
                code.code
              )}
              select_tel_two_value={mobileHead}
              select_change_tel_two={(option) => setMobileHead(option)}
              select_tel_two={countryCode.map((code) =>
                code.code
              )}
              value_tel={phone}
              placeholder_tel="Phone"
              input_change_tel={(e) => setPhone(e.target.value)}
              show_tel_two={true}
              label_tel_two="Mobile"
              disabled_tel_two=""

              value_tel_two={mobile}
              placeholder_tel_two="Mobile"
              input_change_tel_two={(e) => setMobile(e.target.value)}
            />
            <Form
              show_one={true}
              input_type="input"
              label_one="Email"
              placeholder_one="Email Address"
              error_one={emailErrorText}
              error_one_true={emailError}
              value_one={email}
              compulsory_one={true}

              disabled=""
              input_change={(e) => setEmail(e.target.value)}
            />
            <Form
              show_header={true}
              header="Bank Details"
              line={true}
              bulk_icon={upload}
              error_one_true={bankNameError}
              error_one="Please fill in your bank name"
              input_type="input"
              show_one={true}
              label_one="Account Name"
              placeholder_one="Account Name"
              value_one={accountName}
              disabled=""
              input_change={(e) => setAccountName(e.target.value)}
              input_type_two="input"
              error_two="Please fill in your account number"
              error_two_true={accountNameError}
              show_two={true}
              compulsory_one={true}
              compulsory_two={true}
              label_two="Bank Name"
              placeholder_two="Bank Name"
              value_two={bankName}
              disabled_two=""
              input_change_two={(e) => setBankName(e.target.value)}
            />
            <Form
              bulk_icon={upload}
              input_type="input"
              show_one={true}
              label_one="Account No"
              error_one_true={accountNumberError}
              error_one="Please fill in your account number"
              type_one="number"
              placeholder_one="Account Number"
              value_one={accountNumber}
              disabled=""
              input_change={(e) => setAccountNumber(e.target.value)}
              input_type_two="input"
              show_two={true}
              label_two="Sort Code"
              compulsory_one={true}
              compulsory_two={true}
              error_two="Please fill in your sort code"
              error_two_true={sortcodeError}
              placeholder_two="Sort Code"
              max={8}

              value_two={sortcode}
              disabled_two=""
              type_two="text"
              input_change_two={(e) => setSort(e)}
            />

            <div className={styles.buttons}>
              <button className={styles.clear} onClick={toggler}>
                Cancel
              </button>

              {loading ?
                <button className="loading_button"><div /></button>
                :


                <button className={styles.solid} onClick={goNext}>
                  <span>Next</span>{" "}
                  <div className={styles.next_icon} />
                </button>

              }

            </div>
          </div>
          <div className="hide">
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Nationality</th>
                  <th>Gender</th>
                  <th>Date of Birth</th>
                  <th>Home Address 1</th>
                  <th>Home Address 2</th>
                  <th>Post Code</th>
                  <th>Country</th>
                  <th>Phone Number</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Name on Account</th>
                  <th>Bank Name</th>
                  <th>Account No</th>
                  <th>Sort Code</th>
                  <th>Pay Frequency</th>
                  <th>Ni Category</th>
                  <th>Ni Number</th>
                  <th>Tax Code</th>
                  <th>Tax Basis</th>
                  <th>Employee NI YTD</th>
                  <th>Employer NI YTD</th>
                  <th>Student Loan Applicable (TRUE/FALSE)</th>
                  <th>Holiday Pay Rule</th>
                  <th>Tax YTD</th>
                  <th>Agency Name</th>
                  <th>Umbrella Linked</th>
                  <th>Enrolled on to Pension (TRUE/FALSE)</th>
                  <th>External Pension Provider (TRUE/FALSE)</th>
                  <th>Apprenticeship Levy (TRUE/FALSE)</th>
                  <th>Student Lan Band</th>
                </tr>
              </thead>
            </table>

          </div>

          <Modal open={toggle}
            header="Warning message."
            content={<span>  Are you sure you want to cancel
            </span>}
            show_yes={true}
            cancel={() => setToggle(false)}
            yes={() => navigate("/contractor")}


          />
          <Modal open={cancelBulk}
            header="Warning message."
            content={<span>  Are you sure you want to cancel bulk upload?
            </span>}
            show_yes={true}
            cancel={() => setCancelBulk(false)}
            yes={() => {
              setBulk(false);
              setCancelBulk(false)

            }}


          />
          <Bulk
            open={bulk}
            name="Contractor"
            bulk_action={() => setCancelBulk(true)}
            tableRef={tableRef}
            table_name="Contractor Template"
            save={uploadSheet}
            selectedFile={selectedFile}

            handleFileSelect={handleFileSelect}
            disabled={selectedFile === null ? true : false}
          >
            <span style={{ display: "flex", alignItems: "center", columnGap: "5px", marginBottom: "10px" }}><img style={{ width: "20px", height: "20px" }} src={file_icon} alt={file_icon} /><p style={{ fontSize: "15px", fontWeight: "400", margin: "5px" }}>{filename}</p></span>
            {showProgress &&
              <ProgressBar progress={progress} />

            }



            {responseSheet?.length > 0 &&
              <div style={{ maxHeight: "100px", overflowY: "scroll", marginTop: "10px", marginBottom: "10px" }}>
                <h3 style={{ fontWeight: "600", margin: 0 }}>Process Log Information</h3>
                <p style={{ color: "#A5A5A5", fontSize: "12px", margin: 0 }}>Various flags recorded while template was being processed</p>
                {responseSheet.map((sheet) =>

                  <span style={{ display: "flex", alignItems: "center", columnGap: "10px" }}><img style={{ width: "20px", height: "20px" }} src={error_icon} alt={error_icon} /><p style={{ color: "#DB504A", fontSize: "15px", fontWeight: "500", margin: "5px" }}>{sheet}</p></span>

                )

                }
              </div>
            }
          </Bulk>

        </>
      }
    />
  );
};


export default Add;

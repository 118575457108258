import React from 'react';

//import style
import styles from './css/auth_image.module.css'


const AuthImage = (props) => {
    return (
        <div className={styles.subContainer} style={{backgroundImage: `url(${props.background})`}}>
            <h1>{props.header}</h1>
 
        </div>
    );
};

export default AuthImage;


import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, POST_SERVICE,  DELETE_SERVICE } from "../Services/Backend";
import Loader from "../Common/Loader";
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';


import styles from "./css/report.module.css";

import white_close from "../../Assets/white_close.svg"


const ViewReport = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [report, setReport] = useState()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [pageCount, setPageCount] = useState(0)
    const [range, setRange] = useState()
    const [pageNumber, setPageNumber] = useState(0)


    const getReport = async () => {
        setLoading(true)
        try {
          const response = await GET_SERVICE(`/tickets-conversation/${location.state.id}?page=${pageNumber}&size=10`);
          console.log(response, "report")
          
          setReport(response.data.data.conversations)
         setPageCount(response.data.data.totalPages)
         
          setLoading(false)    
          const scrollContainer = document.getElementById('scrollContainer');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
        } catch (err) {
          return err.response;
        }    
      }
      const getReportUpadte = async () => {
  
        try {
          const response = await GET_SERVICE(`/tickets-conversation/${location.state.id}?page=${pageNumber}&size=10`);
        
          
          setReport(response.data.data.conversations)
         setPageCount(response.data.data.totalPages)
         const scrollContainer = document.getElementById('scrollContainer');
         if (scrollContainer) {
           scrollContainer.scrollTop = scrollContainer.scrollHeight;
         }
        
        } catch (err) {
          return err.response;
        }    
      }
      const handleScroll = () => {
        const scrollContainer = document.getElementById('scrollContainer');
        if (scrollContainer && scrollContainer.scrollTop === 0) {
          setPageNumber(pageNumber + 1);
          getReportUpadte();
        }
      };
      const pageChange = (e)=>{

        const selectedPage = e.selected;
        const offSet = selectedPage ;
        getReport(offSet)
      }
    const reply =async ()=>{
        const body= {
       "ticketId": location.state.id,
        "message": message
        }
        const endpoint = "/tickets-conversation"
        try{
            const response = await POST_SERVICE(endpoint, body)
            if (response.status===200){
              setSuccess(true);
              setSuccessMessage(response.data.message);
              setTimeout(() => {
                setSuccess(false);
                setMessage("")
                getReport()
              }, 5000);
        
            }
            else{
              setError(true);
              setErrorMessage(response.data.message);
              setTimeout(() => {
                setError(false);
              }, 5000);
            }
        
          
           }
           catch (e) {
            return e.response;
          }
        }
        // useEffect(() => {
        //   window.addEventListener('scroll', handleScroll);
        //   return () => {
        //     // Remove the scroll event listener when the component unmounts
        //     window.removeEventListener('scroll', handleScroll);
        //   };
        // }, []); 

      
      const closeTicket = async ()=>{
        const endpoint=`/tickets/${location.state.id}`
        try{
          const response = await DELETE_SERVICE(endpoint)
      
          if (response.status===200){
            setSuccess(true);
            setSuccessMessage(response.data.message);
            setTimeout(() => {
              setSuccess(false);
              navigate("/report")
            }, 5000);
      
          }
          else{
            setError(true);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setError(false);
            }, 5000);
          }
      
        
         }
         catch (e) {
          return e.response;
        }
      }
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          // Remove the scroll event listener when the component unmounts
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      useEffect(()=>{
       getReport()
      }, [])
    return (
        <>
           <MainContainer
      content={< div className={styles.view_content}>
            <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
          <h1 style={{ color: "#9D9D9D", fontSize: "20px", fontWeight: "600" }}>
          <span onClick={() => navigate("/report")} style={{ cursor: "pointer" }}>Reports</span>{" "}
          <span style={{ color: "#313131" }}>/ {location.state.report_name}
          {location.state.report_status  ==="CLOSED" ?
                    <span style={{color:" #DB504A"}}> ({location.state.report_status})</span> 
                    :
                    <span> ({location.state.report_status})</span> 
          }

          
          
          
          </span>
        </h1>
        <div className={styles.report_container} id="scrollContainer">
        {        loading === true ? 
            <Loader show={loading} />:
            <>

             <div className={styles.conversation_container}>
             {
                    report?.map((reports)=>
                    <div className={reports.senderType==="UMBRELLA"?styles.conversation_left: styles.conversation}>
                       <div className={styles.header}>
                       <h3>
                         {reports.name}
                        </h3>
                        <p>{reports.dateTime}</p>
                        </div>
                        <p>{reports.conversation}</p>
                    
        
        
                        </div>
                    
                    
                    )
        
             }
             </div>
  
             <div className={styles.send_report}>
                <input value={message} onChange={(e)=>setMessage(e.target.value)}/>
                <button onClick={reply} disabled={message!==""? "": "disabled"}>Send</button>
             </div>
             
             <div className={styles.close}>
             <button onClick={closeTicket}>
                    <span>Close Ticket</span> <img src={white_close} alt={white_close}/>
                </button>
             </div>
             </>
             
        }

        </div>
      
       </div>}/>
            
        </>
    );
};

export default ViewReport;
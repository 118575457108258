import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import styles
import styles from "./css/add.module.css";

//component import
import MainContainer from "../Common/MainContainer";
import Modal from '../Common/Modal'
import { POST_SERVICE, GET_SERVICE } from "../Services/Backend"
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';
import Bulk from "../Common/Bulk";
import DateFormat from '../Utils/DateFormat'
//import resources
import Form from "../Common/Form";
import upload from "../../Assets/upload.svg";
import process_bar from "../../Assets/process-bar.svg";

const Next = () => {
  const [bulk, setBulk] = useState(false)
  const [frequency] = useState(["Weekly", "Bi-Weekly", "4 Weekly", "Monthly"]);
  const [payFrequency, setPayFrequency] = useState("");
  const [payFrequencyError, setPayFrequencyError] = useState(false)
  const [ninCategory, setNinCategory] = useState("");
  const [ninCategoryError, setNinCategoryError] = useState(false)
  const [ninumber, setNinumber] = useState("");
  const [ninumberError, setNinumberError] = useState(false)
  const [taxCode, setTaxCode] = useState("");
  const [taxCodeError, setTaxCodeError] = useState(false)
  const [taxBasis, setTaxBasis] = useState("");
  const [taxBasisError, setTaxBasisError] = useState(false)
  const [employeeYTDError, setEmployeeYTDError] = useState(false)
  const [employerYTDError, setEmployerYTDError] = useState(false)
  const [studentLoanError, setStudentLoanError] = useState(false)
  const [holidayPayRuleError, setHolidayPayRuleError] = useState(false)
  const [taxError, setTaxError] = useState(false)
  const [taxList] = useState(["Cumulative", "Week 1/ Month 1"]);
  const [employeeni, setEmployeeni] = useState("");
  const [employerni, setEmployerni] = useState("");
  const [loan, setLoan] = useState("");
  const [enrolled, setEnrolled] = useState(false)
  const [external, setExternal] = useState(false)
  const [opt, setOpt] = useState(false)
  const [levy, setLevy] = useState(false)
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [listUmbrella, setListUmbrella] = useState([])
  const [agencyList, setAgencyList] = useState([])
 const [NIcategory, setNICategories] = useState([])
  const [agency, setAgency] = useState([]);
  const [agencyError, setAgencyError] = useState(false)
  const [umbrellaError, setUmbrellaError] = useState(false)
  const [umbrella, setUmbrella] = useState("");
  const [listUmbrellaId, setListUmbrellaId] = useState("")
  const [agencyId, setAgencyId] = useState([])
  const [taxCodes, setTaxCodes] = useState([])
  const [loadAgency, setLoadAgency] = useState(false)
  const [element, setElement] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const navigate = useNavigate();
  const [holidayPay] = useState([
    "Rolled up on Gross Pay for Tax",
    "Retained on Gross pay for Tax",
    "Rolled up on Total Pay",
    "Retained on Total Pay",
    "Retained Average Holiday Pay",
    "No Holiday Pay",
    "Rolled Up on Total Pay",
  ]);
  const [payRule, setPayRule] = useState("");
  const [tax, setTax] = useState("");
  const [toggle, setToggle] = useState();
  const [studentLoan, setStudentLoan] = useState([])
  const getStudentLoan = async () => {
    try {
      const response = await GET_SERVICE(`/student-loan`);
      if (response.status === 200) {
        setStudentLoan(response?.data?.data);
          
      }



    } catch (err) {
      return err.response
    }
  }
  const getTaxCode = async () => {
    try {
      const response = await GET_SERVICE("/tax-codes")
      if(response.status===200){

      setTaxCodes(response?.data?.data);
      }




    } catch (err) {
      return err.response
    }

  }
  const setNi = (e) => {
    const value = e.target.value;

    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 9);

    let formattedValue = '';
    const lettersOnly = sanitizedValue.slice(0, 2).replace(/[^a-zA-Z]/g, '');
    formattedValue = lettersOnly;

    // Ensure the next four characters are numbers
    const numbersOnly = sanitizedValue.slice(2, 8).replace(/[^0-9]/g, '');
    formattedValue += numbersOnly;
    const lettersOnlyAgain = sanitizedValue.slice(8, 9).replace(/[^a-zA-Z]/g, '');
    formattedValue += lettersOnlyAgain;





    setNinumber(formattedValue);
  }
  const [componentCount, setComponentCount] = useState(1);
  const [isImageVisible, setIsImageVisible] = useState(true);
const getAgency = async () => {

    try {
      const response = await GET_SERVICE("/agencies/name-and-id?page=0&size=10");
      if(response.status===200){
        setAgencyList(response.data.data.agencies);
        setElement(response.data.data.totalElement)

        if(response.data.data.agencies.length < response.data.data.totalElement ){
          setLoadAgency(true)
  
         }
      }
    

    } catch (err) {
      return err.response;
    }
  };
  const getMoreAgencies = async (option) => {
  
    try {
      if (agencyList.length < element) {
         const nextPage = pageNumber + 1
 
      const response = await GET_SERVICE(`/agencies/name-and-id?page=${nextPage}&size=10`);
      
      if (response.status === 200) {
      
      
        const newAgencies = response.data.data.agencies.filter(
          agency => !agencyList.some(existingAgency => existingAgency.id === agency.id)
        );
     
        setAgencyList(prevAgencyList => [...prevAgencyList, ...newAgencies]);
        setPageNumber(nextPage)
     
 
      

      }
     
    }
    else{
      setLoadAgency(false)
    }
    } catch (err) {
      return err.response;
    }
  };
  const getUmbrella = async (id) => {

    try {
      const response = await GET_SERVICE(`/agencies/${id}/umbrellas`);
      if(response.status===200){
      setListUmbrella(response.data.data); 
      }// Update with umbrella objects containing name and ID
    } catch (err) {
      return err.response;
    }
  };
  const closeError = ()=>{
    setPayFrequencyError(false);
    setNinCategoryError(false);
    setNinumberError(false);
    setTaxCodeError(false);
    setTaxBasisError(false);
    setUmbrellaError(false);
    setAgencyError(false);
  }
  const getCategory = async () => {
    try {
        const response = await GET_SERVICE(`/ni-category`);
        setNICategories(response?.data?.data || []);

        
       
      

    } catch (err) {
        return err.response
    }
};
  
  useEffect(() => {
    getAgency()
    getCategory()
    getTaxCode()
    getStudentLoan()
    window.addEventListener('keydown', closeError);
  }, [])

  const createForm = () => {
    if (componentCount < agencyList.length){
    setComponentCount((prevCount) => prevCount + 1);
    }
  };
  const deleteForm = () => {
    setComponentCount((prevCount) => prevCount - 1)
    agencyId.pop()
    agency.pop()
    listUmbrellaId.pop()
    umbrella.pop()
  }
  const toggleImage = () => {
    setIsImageVisible((prevValue) => !prevValue);
  };

  const addContractor = async () => {

    let hasError = false;
    if (payFrequency === "") {
      setPayFrequencyError(true);
      hasError = true;
    } else {
      setPayFrequencyError(false);
    }
    if (ninCategory === "") {
      setNinCategoryError(true);
      hasError = true;
    } else {
      setNinCategoryError(false);
    }
    if (ninumber === "") {
      setNinumberError(true);
      hasError = true;
    } else {
      setNinumberError(false);
    }
    if (taxCode === "") {
      setTaxCodeError(true);
      hasError = true;
    } else {
      setTaxCodeError(false);
    }
    if (taxBasis === "") {
      setTaxBasisError(true);
      hasError = true;
    } else {
      setTaxBasisError(false);
    }
    if (tax === "") {
      setTaxError(true);
      hasError = true;
    } else {
      setTaxError(false);
    }
    if (employeeni === "") {
      setEmployeeYTDError(true);
      hasError = true;
    } else {
      setEmployeeYTDError(false);
    }
    if (employerni === "") {
      setEmployerYTDError(true);
      hasError = true;
    } else {
      setEmployerYTDError(false);
    }
    if (payRule === "") {
      setHolidayPayRuleError(true);
      hasError = true;
    } else {
      setHolidayPayRuleError(false);
    }
    if (loan === "") {
      setStudentLoanError(true);
      hasError = true;
    } else {
      setStudentLoanError(false);
    }
    if (listUmbrellaId ===""){
      setUmbrellaError(true)
      hasError =true
    }else{
      setUmbrellaError(false)
    }
    if (agencyId.length ===0){
      setAgencyError(true)
      hasError =true
    }else{
      setAgencyError(false)
    }

    const body = {
      "firstName": location?.state?.body?.firstName,
      "lastName": location?.state?.body?.lastName,
      "nationality": location?.state?.body?.nationality,
      "gender": location?.state?.body?.gender,
      "dob": DateFormat(location?.state?.body?.dob),
      "agencyUmbrellaList": agencyId.map((id, index) => ({
        agencyId: id,
        umbrellaId: listUmbrellaId[index]
      })),
      "primaryAddress": {
        "homeAddressOne": location?.state?.body?.primaryAddress?.homeAddressOne,
        "homeAddressTwo": location?.state?.body?.primaryAddress?.homeAddressTwo,
        "postCode": location?.state?.body?.primaryAddress?.postCode,
        "country": location?.state?.body?.primaryAddress?.country
      },
      "primaryContactInformation": {
        "phoneNumber": {
          "countryCode": location?.state?.body?.primaryContactInformation.contactPhoneNumber?.countryCode,
          "number": location?.state?.body?.primaryContactInformation.contactPhoneNumber?.number
        },
        "mobileNumber": {
          "countryCode": location?.state?.body?.primaryContactInformation.contactMobileNumber?.countryCode,
          "number": location?.state?.body?.primaryContactInformation.contactMobileNumber?.number
        },

        "emailAddress": location?.state?.body?.primaryContactInformation?.emailAddress
      },
      "bankDetails": {
        "accountName": location?.state?.body?.bankDetails?.accountName,
        "bankName": location?.state?.body?.bankDetails?.bankName,
        "accountNumber": location?.state?.body?.bankDetails?.accountNumber,
        "sortCode": location?.state?.body?.bankDetails?.sortCode
      },
      "pensionInformation": {
        "employeePension": enrolled,
        "pensionProviderIsExternal": external,
        "studentLoan": opt,
        "apprenticeshipLevy": levy
      },
      "taxSettings": {
        "payFrequency": payFrequency,
        "niCategory": ninCategory,
        "niNumber": ninumber,
        "taxCode": taxCode,
        "taxBasis": taxBasis,
        "employeeNiYearToDate": employeeni,
        "employerNiYearToDate": employerni,
        "isStudentLoanApplicable": studentLoan.find(loanItem => loanItem.bandName === loan)?.id,
        "holidayPayRule": payRule,
        "taxYearToDate": tax
      }
    }
    let endpoint = "/contractors"
    if (!hasError) {
      setLoading(true);
      try {
        const response = await POST_SERVICE(endpoint, body)
    

        if (response.status === 201) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
       
          setLoading(false)
          setTimeout(() => {
            setSuccess(false);
            navigate("/contractor");
          }, 3000);

        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setLoading(false)
          setTimeout(() => {
            setError(false);
          }, 5000);
        }


      }
      catch (e) {
        return e.response;
      }
    }
  };


  return (
    <MainContainer
      content={
        <>
          {" "}
          <ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />
          <div className={styles.content_header}>
            <div>
              <h1>New Contractors</h1>
              <p>Please fill out the forms below to create add a contractor</p>
            </div>
            <img src={process_bar} alt={process_bar} />
          </div>
          <div className={styles.form_container}>
            <Form
              show_header={true}
              header="TAX Settings"
              bulk="Bulk Upload"
              show_bulk={true}
              line={true}
              bulk_icon={upload}
              compulsory_one={true}
              compulsory_two={true}
              input_type="select"
              bulk_action={() => setBulk(!bulk)}
              show_one={true}
              label_one="Pay Frequency"
              error_one="Please select pay frequency"
              error_one_true={payFrequencyError}
              disabled=""
              select_one_value={payFrequency}
              select_change={(option) => setPayFrequency(option)}
              select_one={frequency}
              input_type_two="select"
              show_two={true}
              label_two="NI Category"
              error_two="Please fill in your Nin Category"
              error_two_true={ninCategoryError}
              disabled_two=""
              select_two={NIcategory.map((item)=>
                item.categoryTitle
                )}
              select_two_value={ninCategory}
              placeholder_two="Nin Category"
              select_change_two={(option) => setNinCategory(option)}

            />

            <Form
              input_type="input"
              show_one={true}
              type_one="text"
              label_one="NI Number"
              maxLength={9}
              error_one="Please fill in your Ni number"
              error_one_true={ninumberError}
              placeholder_one="Ni Number"
              value_one={ninumber}
              disabled=""
              input_change={(e) => setNi(e)}
              input_type_two="select"
              show_two={true}
              label_two="Tax Code"
              compulsory_one={true}
              compulsory_two={true}
         
              error_two="Please Select your tax code"
              error_two_true={taxCodeError}
              select_two={taxCodes.map((item)=>
                item.name
                )}
              select_two_value={taxCode}
              disabled_two=""
              select_change_two={(option) => setTaxCode(option)}
            />
            <Form
              input_type="select"
              show_one={true}
              label_one="Tax Basis"
              disabled=""
              select_one_value={taxBasis}
              select_change={(option) => setTaxBasis(option)}
              select_one={taxList}
              compulsory_one={true}
              error_one="Please select tax basis"
              error_one_true={taxBasisError}
              input_type_two="input"
              show_two={true}
              label_two="Employee NI YTD"
              disabled_two=""
              compulsory_two={true}
              error_two="Please add your employee NI YTD"
              error_two_true={employeeYTDError}
              type_two="number"
              value_two={employeeni}
              placeholder_two="Employee NI YTD"
              input_change_two={(e) => setEmployeeni(e.target.value)}
            />
            <Form
              input_type="input"
              show_one={true}
              label_one="Employer NI YTD"
              type_one="number"
              placeholder_one="Employer NI YTD"
              value_one={employerni}
              disabled=""
              input_change={(e) => setEmployerni(e.target.value)}
              input_type_two="select"
              show_two={true}
              label_two="Student Loan Applicable"
              select_two_value={loan}
              compulsory_one={true}
              compulsory_two={true}
              error_two_true={studentLoanError}
              error_one="Please add your employer NI YTD"
              error_two="Please select the student loan applicable"
              error_one_true={employerYTDError}
              select_two={studentLoan.map((item)=>
                item.bandName
                )}
              disabled_two=""
              select_change_two={(option) => setLoan(option)}
            />
            <Form
              input_type="select"
              show_one={true}
              label_one="Holiday Pay Rule"
              disabled=""
              compulsory_one={true}
              select_one_value={payRule}
              select_change={(option) => setPayRule(option)}
              select_one={holidayPay}
              input_type_two="input"
              show_two={true}
              label_two="Tax YTD"
              disabled_two=""
              type_two="number"
              compulsory_two={true}
              error_one="Please select the Holiday Pay Rule"
              error_two="Please enter the TAX YTD"
              error_one_true={holidayPayRuleError}
              error_two_true={taxError}
              value_two={tax}
              placeholder_two="Tax YTD"
              input_change_two={(e) => setTax(e.target.value)}
            />
            <Form
              show_header={true}
              header="Agency Information"
              line={true}
              bulk_icon={upload}

            />
            {/* {Array.from({ length: componentCount }).map((_, index) => (
             <Form
        
       
 
             input_type="select"
             show_one={true}
             label_one="Agency"
             select_one_value={agency}
             select_change={(option) => setAgency(option)}
             disabled=""
             select_one={["Altrix Agency"]}
             input_type_two="select"
             show_two={true}
             label_two="Umbrella"
             select_two_value={umbrella}
             select_two={["Violotex Limited"]}
             disabled_two=""
             select_change_two={(option) => setUmbrella(option)}
             image={index=== 0?true:false}
             add={() => toggleImage(index + 1)}

  
        imagedel={true}
        del={()=>setComponentCount(componentCount-1)}
           />
      ))} */}
            {Array.from({ length: componentCount }).map((_, index) => (
              <Form
                input_type="select"
                show_one={true}
                label_one="Agency"
                compulsory_one={index===0 &&true}
                compulsory_two={index=== 0&&true}
                select_one_value={agency[index] || ""} // Use umbrella[index] for the corresponding form
                disabled=""
                select_one={agencyList.map((agency) => agency.name)}
                error_one="Please select an Agency"
                error_one_true={index ===0 && agencyError}
                error_two="Please select an Umbrella"
                
                error_two_true={index ===0 && umbrellaError}
                select_change={(option) => {
                  setAgency((prevAgency) => {
                    const updatedAgency = [...prevAgency];
                    updatedAgency[index] = option; // Set umbrella name at the corresponding index
                    return updatedAgency;
                  });
                  const selectedAgency = agencyList.find((item) => item.name === option);

                  if (selectedAgency) {
                    setAgencyId((prevIds) => {
                      const updatedIds = [...prevIds];
                      getUmbrella(selectedAgency.id)

                      updatedIds[index] = selectedAgency.id; // Set umbrella ID at the corresponding index
                      return updatedIds;
                    });
                  }
                }}
                input_type_two="select"
                show_two={true}
                label_two="Umbrella"
                loadMore={loadAgency}
                click={getMoreAgencies}
                select_two_value={umbrella[index] || ""}
                select_two={listUmbrella.map((umbrellas) =>
                  umbrellas.name
                )}
                disabled_two={agencyId !== "" ? "" : "disabled"}
                select_change_two={(option) => {
                  setUmbrella((prevUmbrella) => {
                    const updatedUmbrella = [...prevUmbrella];
                    updatedUmbrella[index] = option; // Set umbrella name at the corresponding index
                    return updatedUmbrella;
                  });
                  const selectedUmbrella = listUmbrella.find((item) => item.name === option);
                  if (selectedUmbrella) {
                    setListUmbrellaId((prevIds) => {
                      const updatedIds = [...prevIds];
                      updatedIds[index] = selectedUmbrella.id; // Set umbrella ID at the corresponding index
                      return updatedIds;
                    });
                  }
                }}
                image={index === 0 ? true : false}
                imagedel={index >= 1 ? true : false}
                add={() => {
                  createForm();
                  toggleImage();
                }}
                del={() => {
                  deleteForm()
                }}
              />
            ))}

            <Form
              show_header={true}
              header="Pension Information"
              line={true}
              check={true}
              show_check_one={true}
              check_one={enrolled}
              check_one_change={(e) => setEnrolled(e.target.checked)}
              check_two={external}
              check_two_change={(e) => setExternal(e.target.checked)}
              check_three={opt}
              check_four={levy}
              check_three_change={(e) => setOpt(e.target.checked)}
              check_four_change={(e) => setLevy(e.target.checked)}
              check_desc_one="Enrolled on to Pension:"
              show_check_two={true}
              check_desc_two="External Pension Provider:"
              show_check_three={true}
              check_desc_three="Student Loan:"
              show_check_four={true}
              check_desc_four="Apprenticeship Levy:"
            />

            <div className={styles.buttons}>
              <button className={styles.clear} onClick={() => setToggle(true)}>
                Cancel
              </button>
              {loading ?
                <button className="loading_button"><div /></button>
                :


                <button className={styles.solid} onClick={addContractor}>
                  Add Contractor
                </button>
              }
            </div>
          </div>
          <Modal open={toggle}
            header="Warning message."
            content={<span>  Are you sure you want to cancel
            </span>}
            show_yes={true}
            cancel={() => setToggle(false)}
            yes={() => navigate("/contractor/add")}


          />
          <Bulk open={bulk} name="Contractor" bulk_action={() => setBulk(!bulk)} />
        </>
      }
    />
  );
};

export default Next;

import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { POST } from '../Services/Backend';

//import style
import styles from './css/auth.module.css'

//import Components
import AuthForm from './Common/AuthForm';
import AuthImage from './Common/AuthImage';
import ErrorNotification from '../Common/ErrorNotification';
import SuccessNotification from '../Common/SuccessNotification';

//import resources
import loginImage from '../../Assets/reset-image.png'
import profile from "../../Assets/profile-icon.png";
import passwordIcon from "../../Assets/password-icon.png"
import view from "../../Assets/view.svg"
import eye from "../../Assets/eye-slash.svg"


const Register = () => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const  [showPassword, setShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [otp, setOtp] = useState("")
  const [otpError, setOtpError] = useState(false)
  const navigate = useNavigate();


  const onSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();

      
    let hasError = false; 

    if (email === "") {
      setEmailError(true);
      hasError = true;
    }
    else{
      setEmailError(false)
    }
  
    if (password === "") {
      setPasswordError(true);
      hasError = true;
 
    }
    else{
      setPasswordError(false)
    }
    if (newPassword === "") {
        setNewPasswordError(true);
        hasError = true;
   
      }
      else{
        setPasswordError(false)
      }
      if (otp === "") {
        setOtpError(true);
        hasError = true;
   
      }
      else{
        setOtpError(false)
      }
    const endpoint = "/users/activate-account";
    const body = {
      "emailAddress": email,
    "password": password,
    "otp": otp
    };
    if (!hasError) {
    try {
      const response = await POST(endpoint, body);
  
      if (response.status === 201) {
        navigate("/login");
      
        setSuccess(true);
        setSuccessMessage("User activated successfully");
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
        }, 3000);
      } else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
          setLoading(false);
        }, 3000);
      }
    } catch (e) {
      return e.response;
    }
  }
  };
  
    return (
        <div className={styles.container}>
          <ErrorNotification show={error} content={errorMessage}/>
          <SuccessNotification show={success} content={successMessage}/>
            <AuthForm 
                header="Welcome Back!" 
                subText="Lets create some Payslips!!!"    
                label="staff-id"
                labelText="Staff ID"  
                buttonText="Activate" 
                inputIcon={profile} 
                change={(e)=>setEmail(e.target.value)}
                value={email}
                click={onSubmit}
                error={emailError?"Please fill in your email": ""}
                errorBorder={emailError? true: false}
                loading={loading}
               
                password={
                    <>
                      <div className={styles.formControl} style={{margin:"10px 0"}}>
                    <label htmlFor="password">
                    New Password
               
                    </label>
                    <div className={passwordError?styles.errorInput:styles.formInput}>
                           <div className={styles.formInputTwo}>
                        <img src={passwordIcon} alt="icon"/>
                      <input type={showPassword?"text":"password"} name="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                            </div>
                            <img  onClick={()=>setShowPassword(!showPassword)}style={{marginLeft: "10px"}} src ={showPassword? eye:view} alt ={showPassword? eye:view}/>
                  
                    </div>
                  {passwordError &&
                    <p className="error_message"> Please fill in your password</p>

                  }
                  </div>
                  <div className={styles.formControl} style={{margin:"10px 0"}}>
                    <label htmlFor="password">
                    Confirm Password
               
                    </label>
                    <div className={passwordError?styles.errorInput:styles.formInput}>
                           <div className={styles.formInputTwo}>
                        <img src={passwordIcon} alt="icon"/>
                      <input type={showNewPassword?"text":"password"}name="password" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
                            </div>
                            <img onClick={()=>setShowNewPassword(!showNewPassword)} style={{marginLeft: "10px"}} src ={showNewPassword? eye:view} alt ={showNewPassword? eye:view}/>
                  
                    </div>
                  {newPasswordError &&
                    <p className="error_message"> Please confirm your password</p>

                  }
                  </div>
                  <div className={styles.formControl} style={{margin:"10px 0"}}>
                    <label htmlFor="otp">
                 Otp
               
                    </label>
                    <div className={otpError?styles.errorInput:styles.formInput}>

                        <img src={passwordIcon} alt="icon"/>
                      <input type="number"name="otp" value={otp} onChange={(e)=>setOtp(e.target.value)}/>
                         

                  
                    </div>
                  {otpError &&
                    <p className="error_message"> Please fill in the otp </p>

                  }
                  </div>


                    </>
                  
                  
                }  
            />
            <AuthImage 
        
              background={loginImage}
            />
            
        </div>
    );
};

export default Register;
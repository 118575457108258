import React, { useState, useEffect } from 'react';

//component imports
import { GET_SERVICE, POST_SERVICE } from "../Services/Backend";
import ViewLoader from '../Common/ViewLoader';
import Table from '../Common/Table';
import NoContent from '../Common/NoContent';
import Select from '../Common/Select';
import DateFormat from '../Utils/DateFormat';

//resource import
import contractorIcon from "../../Assets/timesheet_green.svg";


// //import styles
import styles from "./css/payroll.module.css";

const Timesheet = (props) => {
  const [timesheet, setTimesheet] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [element, setElement] = useState(10)
  const [totalElement, setTotalElement] = useState(10)
  const [pageNumber, setPageNumber] = useState()
  const [umbrellaFilter, setUmbrellaFilter] = useState("")
  const [umbrellaId, setUmbrellaId] = useState("")
  const [date, setDate] = useState("")
  const [umbrellaList, setUmbrellaList] = useState("")
 

  const timesheetHeaders = [
    { key: 'fullName', label: 'Agency Name' },
    { key: 'niNumber', label: 'Pay Period' },
    { key: 'emailAddress', label: 'Umbrella' },
    { key: 'email', label: 'Contractor(s)' },


  ]
  const getTimesheet = async (page) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/time-sheets-file/unvalidated?page=${page || 0}&size=10`);
 
      if (response.status === 200) {
        const filteredTimesheets = response.data.data.singleTimeSheetDTOS.filter(timesheet => !timesheet.isApproved);


        setPageNumber(page || 0)
        setPageCount(response.data.data.totalPages)
        setTotalElement(response.data.data.totalElement)
        setTimesheet(filteredTimesheets)

        setLoading(false)
      }
      else {
        setTimesheet([])
        setLoading(false)
      }


    } catch (err) {

      return err.response;
    }
  }
  const getMoreSheets = async (option) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/time-sheets-file/unvalidated?page=${pageNumber || 0}&size=${option}`);

      if (response.status === 200) {
        setTimesheet(response.data.data.singleTimeSheetDTOS);

        setPageCount(response.data.data.totalPages)
        setElement(option)


        setLoading(false)
      }
      else {
        setTimesheet([])
        setLoading(false)
      }


    } catch (err) {

      return err.response;
    }
  };
  const nextPage = (e) => {

    getTimesheet(e.selected)



  }
  const getUmbrellaList = async (page) => {

    try {
      const response = await GET_SERVICE(`/umbrellas/un-formatted`);
      



      if (response.status === 200) {



        setUmbrellaList(response.data.data)

      }



    } catch (err) {

      return err.response;
    }
  }

  const clearField = () => {
    setDate("")
    setUmbrellaFilter("")
    setUmbrellaId("")


  }
  const postFilter = async (page) => {

    setLoading(true)
    const url = `/payslips/filters?page=0&size=10&type=ALL`
    const body = {

      "payPeriod": DateFormat(date) || null,
      "umbrellaId": umbrellaId || null,
    }

    try {
      const response = await POST_SERVICE(url, body);


      if (response.status === 200) {
        setTimesheet(response.data.data.payslipDaoList);
        setLoading(false)
        setPageNumber(page || 0)
        clearField()



        setPageCount(response.data.data.numberOfPages)
        setElement(response.data.data.numberOfElements)
      } else {

        setLoading(false)
      }





    } catch (err) {
      return err.response;
    }
  };

  useEffect(() => {
    getTimesheet();
    getUmbrellaList()
  }, []);


  return (
    <>
      {timesheet?.length > 0 ?
        loading === true ?
          <ViewLoader />
          :

          <Table
            columns={timesheetHeaders}
            row={element}
            show_add={false}
            action="Validate All"
            show_add_image={true}
            showSearchBar={true}
            add_image={styles.add_image}
            row_list={
              totalElement >= 30
                ? [10, 20, 30]
                : totalElement >= 20
                  ? [10, 20]
                  : totalElement >= 10
                    ? [10]
                    : []
            }
            row_item_click={(option) => getMoreSheets(option)}
            pageCount={pageCount}
            pageNumber={pageNumber}
            pageChange={nextPage}
            filterContent={
              <div>
                <div className={styles.filter}>
                  <div className={styles.select_input}>
                    <label>Umbrella</label>
                    <div className={styles.custom_select_container}>
                      <Select
                        disabled=""
                        value={umbrellaFilter}


                        onChange={(option) => {
                          setUmbrellaFilter(option)
                          const selectedUmbrella = umbrellaList.find((item) => item.name === option);
                          if (selectedUmbrella) {
                            setUmbrellaId(selectedUmbrella?.id);

                          }

                        }}
                        options={umbrellaList.map((umbrella) =>
                          umbrella.name
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles.form_input}>
                    <label>Pay Period</label>
                    <div className={styles.input_container}>
                      <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button className={styles.clear} onClick={clearField}>Cancel</button>
                  <button className={styles.solid} onClick={postFilter} >
                    Save
                  </button>
                </div>
              </div>
            }
            body={timesheet.map((row, index) =>

              <tr key={index}>


                <td>
                  {row.agencyName}

                </td>
                <td>
                  {row.payPeriod}

                </td>
                <td>
                  {row.umbrellaName}

                </td>
                <td>
                  {row.ContractorsCount}

                </td>
                {row.isApproved === true ?

                  <td className={styles.green}>
                    Validated
                  </td>
                  :
                  <td className={styles.red} onClick={() => props.validate(row.timeSheetFileId)}>
                
                    {!props.validateLoading ?
                       " Validate Timesheet" 
                        :

                      <div className="small_loader_container" >
                        <div className='loader' />

                      </div>

                    }

                  </td>
                }
              </tr>
            )
            }

          />
        :
        loading === true ?
          <ViewLoader />
          :
          <>
            <NoContent
              image={contractorIcon}
              text="No Timesheet To Validate"
              hide={true}
              change={{ margin: "5% 0" }}

            />
          </>
      }
    </>
  );
};

export default Timesheet;
import React, { useState, useRef } from "react";


//component import
import { GET_SERVICE, PUT_SERVICE } from "../Services/Backend";
import MainContainer from "../Common/MainContainer";
import Toggle from "../Common/Toogle";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import Timesheet from "./Timesheet";
import ApprovedPayslips from "./ApprovedPayslips";
import Payslips from "./Payslips";

//styles import
import styles from './css/payroll.module.css'



const Payroll = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [index, setIndex] = useState()
  const [validateLoading, setValidateLoading] = useState(false)


  const validate = async (id)=>{

    const endpoint=`/time-sheets-file/${id}/validate`
    setValidateLoading(true)
    try {
      const response = await GET_SERVICE(endpoint)

      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage(response.data.message);
        
        setTimeout(() => {
          setSuccess(false);
          setIndex(1)
          
   
        }, 2000);
   setValidateLoading(false)
      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);

        }, 5000);
      }
    }
    catch (e) {
      return e.response;
    }

  }
  const approve = async (id)=>{

    const endpoint=`/payslips/approve/${id}`
    try {
      const response = await PUT_SERVICE(endpoint)

      if (response.status === 200) {
        setSuccess(true);

        setSuccessMessage(response.data.message);
        // console.log(response)
        
     
       

        
        setTimeout(() => {
          setSuccess(false);
          setIndex(2)
          
   
        }, 2000);

      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);

        }, 5000);
      }
    }
    catch (e) {
      return e.response;
    }

  }


  return (
    <MainContainer
      content ={
        <>
            <ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />
          <div className={styles.content_header}>
          
              <h1>Payslip</h1>

   

          </div>
          <Toggle
            buttonLabels={[
              "Timesheet Validate",
              "Approve Paysips",
              "Payslips",
            ]}
            initialActiveIndex={index}
            style={{justifyContent:"space-evenly"}}

            contents={[

              <Timesheet validate={(id)=>validate(id)} />,
      
              <Payslips approve={(id)=> approve(id)}/>,
              <ApprovedPayslips />,

            ]}
            />

        </>
      }
     
    />
  );
};

export default Payroll;

import React from 'react';
import styles from './css/modal.module.css'

//Resources Import
import warning from '../../Assets/warning.svg'


const Modal = (props) => {
  


    return (
        <div className={props.open?styles.modal: styles.hideModal}>
            <div className={styles.modalContent}>
                <div className={styles.modal_header}>
                    <img src={warning} alt={warning}/>
                    <h3>{props.header}</h3>

                </div>
                
                <p>  {props.content}</p>  
        
                <div className={styles.buttons}>
                <button className={styles.clear} onClick={props.cancel}>
                Cancel
              </button>
              {props.show_yes &&
                    <button className={styles.solid} onClick={props.yes}>
                    <span>Yes</span>
        
                  </button>

              }
        
                </div>
        
      
            </div>
           
        </div>
    );
};

export default Modal;
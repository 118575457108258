import React, { useState } from "react";
import styles from "./css/table.module.css";
import PropTypes from "prop-types";

// component imports

import ReactPaginate from "react-paginate";

import search from "../../Assets/search-icon.png";
import filter from "../../Assets/filter-icon.png";
import caret from "../../Assets/caret-black.png";
import left from "../../Assets/arrow-left.svg"
import right from "../../Assets/arrow-right.svg"


const Table = (props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const hideOptions = () => {
    setShowOptions(false);
  };
  const handleOptionChange = (option) => {
    props.row_item_click(option);
    setShowOptions(false);

  };
  return (
    <div className={styles.table}>
      <h1>{props.header}</h1>
      <p className={props.show_text ? styles.showText : styles.hide}>
        {props.text}
      </p>
      <div className={styles.content} style={props.style}>
        <div className={styles.contentHeader}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gridColumnGap: "15px",
            }}
          >
            <div className={props.showSearchBar ? styles.hide : styles.searchBar}>
              <img src={search} alt={search} />
             <form onSubmit={props.searchAction}>
             <input
                type="text"
                value={props.search}
                placeholder="Search"
                onChange={props.searchInput}
        
              />
             </form>
            </div>
            <button
              className={props.filter ? styles.hide : styles.filter}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filter} alt={filter} />
              <span>Filter</span>
            </button>
          </div>
          <button

            className={props.show_add ? styles.add : styles.hide}
            onClick={props.action_button}
          >
            <span>{props.action}</span>{" "}
            {props.show_add_image ?
          <div className={props.add_image} />
            :
         
            <div className={styles.add_image} />
            }

          </button>
          
        </div>
        {showFilter && <div>{props.filterContent}</div>}
        <div className={styles.table_container} style={props.tableStyle}>
          <table>
            <thead>
              <tr>
                {props.columns?.map((column) => (
                  <th key={column.key}>{column.label}
                  {props.show_table_head_icon && props.index >1 &&
                  <img style={styles.head_icon} src={props.table_head_icon} alt="icon"/>
                  
                  }
                  </th>
                ))}
                {props.action &&
                  <th style={{ textAlign: "center" }}>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {props.body}
            </tbody>
          </table>
        </div>
        
        <div className={props.footer ? styles.hide : styles.footer}>
          <div className={styles.row}>
            <h3>Showing</h3>
             <div style={{position:"relative"}}>
             <button onClick={() => setShowOptions(!showOptions)}>
              <span>{props.row}</span>
              <span>rows</span>
              <img src={caret} alt={caret} />
            </button>
            {showOptions && (
              <ul className={styles.options} onMouseLeave={hideOptions}>
            
                {props.row_list?.map((option) => (
                  
                  <li key={option} onClick={() => handleOptionChange(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
             </div>






          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel={<span className={styles.paginate_next}><img src={left} alt={left} /></span>} // Use a custom JSX element for the "Next" button
            onPageChange={props.pageChange}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            pageCount={props.pageCount}
            previousLabel={<span className={styles.paginate_next}><img src={right} alt={right} /></span>} // Use a custom JSX element for the "Previous" button
            activeClassName={styles.activePage}
            className={styles.paginate}
            nextLinkClassName={styles.next}
            previousLinkClassName={styles.prev}
            activeLinkClassName={styles.activeLink}
            renderOnZeroPageCount={null}
            forcePage={props.pageNumber}
          />
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show_text: PropTypes.bool,
  search: PropTypes.string,
  show_add: PropTypes.bool,
  action_button: PropTypes.func,
  action: PropTypes.string,
  add_image: PropTypes.string,
  filterContent: PropTypes.node,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  tableContent: PropTypes.arrayOf(PropTypes.object),
  view_button: PropTypes.func,
  tool_one: PropTypes.string,
  view: PropTypes.string,
  tool_view: PropTypes.node,
  edit_button: PropTypes.func,
  tool_two: PropTypes.string,
  change: PropTypes.string,
  tool_edit: PropTypes.node,
  row: PropTypes.number,
};

export default Table;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//import styles
import styles from "../Contractor/css/add.module.css";
 
//import components
import { GET_SERVICE, POST_SERVICE, POST_FILE } from "../Services/Backend";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import Table from "../Common/Table";
import MainContainer from "../Common/MainContainer";
import Form from "../Common/Form";
import SelectSearch from "../Common/SelectSearch";
import Select from "../Common/Select";
import DateInput from "../Common/DateInput";
import ProgressBar from "../Utils/ProgressBar";
import Modal from "../Common/Modal";
import numberFormatter from "../Utils/NumberFormatter";



//import resources
import upload from "../../Assets/upload.svg";
import add from "../../Assets/add-icon.png";
import timesheet_net from "../../Assets/timesheet_net.svg"
import timesheet_vat from "../../Assets/timesheet_vat.svg"
import timesheet_total from "../../Assets/timesheet_total.svg"
import DateFormat from "../Utils/DateFormat";
import upload_t from "../../Assets/upload-t.svg";
import error_icon from "../../Assets/caution.svg"
import file_icon from "../../Assets/file.svg"

const Add = (props) => {
  const [bulk, setBulk] = useState(false)
  const [contractor, setContractor] = useState("")
  const [contractorId, setContractorId] = useState("")
  const [contractorError, setContractorError] = useState(false)
  const [contractors, setContractors] = useState([])
  const [loading, setLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [progress, setProgress] = useState(0);
  const [agencies, setAgencies] = useState([])
  const [agency, setAgency] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [agencyId, setAgencyId] = useState("")
  const [agencyError, setAgencyError] = useState(false)
  const [umbrellas, setUmbrellas] = useState([])
  const [umbrella, setUmbrella] = useState("")
  const [umbrellaId, setUmbrellaId] = useState("")
  const [umbrellaError, setUmbrellaError] = useState(false)
  const [timesheet, setTimesheet] = useState([])
  const [rate, setRate] = useState("")
  const [payRate, setPayRate] = useState()
  const [units, setUnits] = useState()
  const [shiftDate, setShiftDate] = useState("")
  const [payPeriod, setPayPeriod] = useState("")
  const [payPeriodError, setpayPeriodError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [totalAmount, setTotalAmount] = useState("")
  const [allAgency, setAllAgency] = useState([])
  const [allUmbrella, setAllUmbrella] = useState([])
  const [umbrellaBulk, setUmbrellaBulk] = useState("")
  const [agencyBulk, setAgencyBulk] = useState("")
  const [umbrellaBulkId, setUmbrellaBulkId] = useState("")
  const [agencyBulkId, setAgencyBulkId] = useState("")
  const [rateBulk, setRateBulk] = useState("")
  const [payPeriodBulk, setPayPeriodBulk] = useState("")
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState("No file selected");
  const [responseSheet, setResponseSheet] = useState([])
  const [loadMore, seLoadMore] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [element, setElement] = useState(0)
  const [cancelBulk, setCancelBulk] = useState(false)
  const [bulkLoading, setBulkLoading] = useState(false)
  const contractorContainerRef = useRef(null);
  const [loadContractor, setLoadContractor] = useState(false)
  const [searchAgency, setSearchAgency] = useState("")
  const [searchContractor, setSearchContractor] = useState("")
  const handleFileSelect = (event) => {
    setSelectedFile(event?.target?.files[0]);
    setFilename(event?.target?.files[0]?.name);
  };

  const getContractors = async () => {
    try {
      const response = await GET_SERVICE(`/contractors/name-and-id?page=0&size=10`);

      setContractors(response.data.data.contractors);
       setElement(response.data.data.totalElement)
       if(response.data.data.contractors.length< response.data.data.totalElement ){
        setLoadContractor(true)
       }
    



    } catch (err) {
      return err.response;
    }
  };
  const getMoreContractors = async (option) => {
    setLoading(true);
    try {
      if (contractors.length < element) {
        setSearchContractor("")
        setPageNumber(prevPage => prevPage + 1);
      const response = await GET_SERVICE(`/contractors/name-and-id?page=${pageNumber}&size=10`);
      if (response.status === 200) {
        setContractors(prevContractors => {
          const existingIds = prevContractors.map(contractor => contractor.id);
          const newContractors = response.data.data.contractors.filter(contractor => !existingIds.includes(contractor.id));
          return [...prevContractors, ...newContractors];

        });
      }
     
    }
    else{
      setLoadContractor(false)
    }
    } catch (err) {
      return err.response;
    }
  };

  const addTimesheet = () => {
    if (shiftDate !== "" && payRate !== 0 && units !== 0) {
      const newTimesheet = [
        ...timesheet,
        {

          units: units,
          payRate: payRate,
          shiftDate: DateFormat(shiftDate)
        }
      ];
      const totalRate = newTimesheet.reduce((total, entry) => {
        return total + parseInt(entry.units);
      }, 0);

      const total = totalRate * newTimesheet.reduce((total, entry) => {
        return total + parseFloat(entry.payRate);
      }, 0);

      setTotalAmount(total)
      setTimesheet(newTimesheet);

      setPayRate(0)
      setShiftDate('')
      setUnits(0)
    }





  }

  const columns = [

    { key: 'niNumber', label: 'Unit' },
    { key: 'emailAddress', label: 'Pay Rate' },
    { key: 'gender', label: 'Amount' },
    { key: 'agencies', label: 'VAT' },
    { key: 'umbrellas', label: 'Shift Date' },
  ];



  const navigate = useNavigate();
  const startProgress = () => {


    // Stop the progress update after 10 seconds (for example)
    setTimeout(() => {

    }, 5000);
  };
  
  const uploadSheet = async (event) => {
    setBulkLoading(true);
    setProgress(0);
    setShowProgress(true)


    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 0));
    }, 200);
    setResponseSheet([])
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await POST_FILE(
        `/time-sheets-file/upload?agencyId=${agencyBulkId}&umbrellaId=${umbrellaBulkId}&payPeriod=${DateFormat(payPeriodBulk)}&rate=${rateBulk}`,
        formData
      );
  
      if (response.status === 200 || response.status ===201) {
        setResponseSheet(response.data.data)
        clearInterval(interval);
        setShowProgress(false)
         setSuccessMessage("Timesheet uploaded Successfully")
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false);
          setFilename("No file selected")
          setAgencyBulkId("")
          setAgencyBulk("")
          setUmbrellaBulk("")
     
          setUmbrellaBulkId("")
          setRateBulk("")
          setPayPeriodBulk("")
      
          setBulkLoading(false)
        }, 2000);



      } else {

        setError(response.data.message)
        setResponseSheet(response.data.data)
        clearInterval(interval);
        
      
        setShowProgress(false)
        setError(true);
        setErrorMessage("Upload failed")
        setTimeout(() => {
          setError(false);
          setBulkLoading(false)
        }, 5000);
      }
    } catch (error) {
      console.log(error);


    }
  }
  const cancelUpload = () => {
    setAgencyBulkId("")
    setAgencyBulk("")
    setUmbrellaBulk("")
    setProgress(0)
    setShowProgress(false)
    setUmbrellaBulkId("")
    setRateBulk("")
    setPayPeriodBulk("")
    setBulk(!bulk)
    setSelectedFile()
    setFilename("")
    setResponseSheet([])
    setCancelBulk()
  }

  const getAgencySearch = async (e) => {
    e.preventDefault()
 
    try {
      const response = await GET_SERVICE(`/agencies/search?page=0&size=10&propertySortedBy=createdAt&searchBy=${searchAgency}`);
   
     setAgencies(response?.data?.data?.agencies);
    } catch (err) {
      return err.response;
    }
  };
  const getContractorSearch = async (e) => {
    e.preventDefault()
    setContractor("")
 
    try {

      const response = await GET_SERVICE(`/contractors/search/name-and-id?searchBy=${searchContractor}&page=0&size=10`);
     
      if (response.status===200){

        if (response?.data?.data?.contractors.length!==0){
          setContractors(response?.data?.data?.contractors);
        }
      }
      
   
    
    } catch (err) {
      return err.response;
    }
  };


  const getUmbrellaAgency = async (id) => {

    try {
      const response = await GET_SERVICE(`/contractors/${id}/agency-umbrella-data`);

      setAgencies(response.data.data.agencies.map((agency) =>
      

        agency
      ))
      setUmbrellas(response.data.data.umbrellas.map((umbrella) =>
        umbrella
      ))

    } catch (err) {
      return err.response;
    }
  }

  const getAgencyUmbrella = async () => {

    try {
      const response = await GET_SERVICE(`/agencies/umbrellas`);

      setAllUmbrella(response.data.data)
    } catch (err) {
      return err.response;
    }
  }

  const add = async () => {
    setAddLoading(true)

    const body = {
      "payPeriod": DateFormat(payPeriod),
      "agencyId": agencyId,
      "umbrellaId": umbrellaId,
      "contractorId": contractorId,
      "rate": rate,
      "timeSheetDataList": timesheet
    }

    const endpoint = "/time-sheets-file"
    let hasError = false;
    if (contractorId === "") {
      setContractorError(true);
      hasError = true;
    } else {
      setContractorError(false);
    }
    if (agencyId === "") {
      setAgencyError(true);
      hasError = true;
    } else {
      setAgencyError(false);
    }
    if (umbrellaId === "") {
      setUmbrellaError(true);
      hasError = true;
    } else {
      setUmbrellaError(false);
    }
    if (payPeriod === "") {
      setpayPeriodError(true);
      hasError = true;
    } else {
      setpayPeriodError(false);
    }

    if (!hasError) {
     
      try {
        const response = await POST_SERVICE(endpoint, body)

        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            navigate("/timesheet");
          
          }, 2000);

        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
          
          }, 5000);
        }
        setAddLoading(false)

      }
      catch (e) {
        return e.response;
        
      }
    }


  };
  const getAgencyUnderUmbrella = async (agency) => {


    try {
      const response = await GET_SERVICE(`/umbrellas/${agency.id}/agencies-linked`);
     
      if (response.status===200){
        setAllAgency(response?.data?.data)
      }
      else {
        setAllAgency([])
      }

    

    } catch (err) {
      return err.response;
    }
  }

  useEffect(() => {
    getContractors()
    getAgencyUmbrella()

  }, [])
  return (
    <MainContainer
      content={
        <>
          <ErrorNotification show={error} content={errorMessage} />
          <SuccessNotification show={success} content={successMessage} />
          <div className={styles.content_header}>
            <div>
              <h1>New Timesheet</h1>
              <p>
                Please fill out the forms below to create add a new Timesheet
              </p>
            </div>

          </div>
          <div className={styles.form_container}>

            <div className={styles.next_container} >

              <Form
                show_header={true}
                header={
                  <p style={{ color: "black" }}>Contact Details</p>
                }
                bulk="Bulk Upload"
                show_bulk={true}
                line={true}
                bulk_icon={upload}
                input_type="select"
                bulk_action={() => setBulk(!bulk)}

              />


              <div style={{ minHeight: "600px" }}>
                <div style={{ display: "flex", gridColumnGap: "50px", flexWrap: 'wrap', alignItems: "center" }}>
                  <div className={styles.form_input}>
                    <label>
                      Contractor

                    </label>
                    <SelectSearch
                      disabled=""
                      loadMore={loadContractor}
                      click={getMoreContractors}
                      search={searchContractor}
                      searchAction={getContractorSearch}
                
                      searchInput={(e)=>setSearchContractor(e.target.value)}
                      value={contractor}
                      onChange={(option) => {
                        setContractor(option)
                        const selectedContractor = contractors.find((item) => item.name === option);
                        if (selectedContractor) {
                          setContractorId(selectedContractor?.id);
                          getUmbrellaAgency(selectedContractor?.id)

                        }



                      }}
                      options={contractors?.map((contractor) =>
                        contractor?.name
                      )}
                      error={contractorError}
                      className={contractorError ? styles.error_input : ""}
                    />

                    {contractorError &&
                      <p className="error_message_select">Please choose a contractor</p>

                    }
                  </div>
                  {/* <DateInput/> */}

                  <div className={styles.form_input}>
                    <label>
                      Select Agency

                    </label>
                    <SelectSearch
                      disabled={contractor === "" ? "disabled" : ""}
                      search={searchAgency}
                      searchAction={getAgencySearch}
                
                      searchInput={(e)=>setSearchAgency(e.target.value)}
                      value={agency}
                      onChange={(option) => {
                        setAgency(option)
                        const selectedAgency = agencies.find((item) => item.name === option);
                        if (selectedAgency) {
                          setAgencyId(selectedAgency?.id);
                        }
                      }}
                      options={agencies.map((agency) =>
                        agency.name
                      )}
                      error={agencyError}
                      className={agencyError ? styles.error_input : ""}
                    />
                    {agencyError &&
                      <p className="error_message_select">Please choose an agency</p>

                    }
                  </div>
                  <div className={styles.form_input}>
                    <label>
                      Select Umbrella

                    </label>
                    <Select
                      disabled={contractor === "" ? "disabled" : ""}
                      value={umbrella}
                      onChange={(option) => {
                        setUmbrella(option)
                        const selectedUmbrella = umbrellas.find((item) => item.name === option);
                        if (selectedUmbrella) {
                          setUmbrellaId(selectedUmbrella?.id);

                        }
                      }}
                      options={umbrellas.map((umbrella) =>
                        umbrella.name
                      )}
                      error={umbrellaError}
                      className={umbrellaError ? styles.error_input : ""}
                    />

                    {umbrellaError &&
                      <p className="error_message_select">Please choose an umbrella</p>

                    }
                  </div>

                </div>
                <div style={{ display: "flex", columnGap: "25px", flexWrap: "wrap", alignItems: "flex-start" }}>
                  <div className={styles.form_input}>
                    <label>
                      Pay Period

                    </label>
                    <input
                      type="date"
                      value={payPeriod}

                      onChange={(e) => setPayPeriod(e.target.value)}

                    />
                    {/* <span>{props.error_one}</span> */}
                  </div>
                  <div className={styles.form_input} >
                    <label style={{ marginBottom: "0px" }}>
                      Select Rate<span style={{ color: "#DB504A" }}>*</span>

                    </label>
                    <Select
                      disabled={""}
                      value={rate}
                      onChange={(option) => setRate(option)}
                      options={["Standard Hourly Rate", "Standard Day Rate"]}

                    />
                    {/* <span>{props.error_one}</span> */}
                  </div>
                </div>




                <div style={{ display: "flex", gridColumnGap: "20px", flexWrap: 'wrap' }}>


                  <div className={styles.form_input}>
                    <label >
                      Unit<span style={{ color: "#DB504A" }}>*</span>

                    </label>
                    <input

                      value={units}
                      placeholder="0"
                      type="number"
                      onChange={(e) => setUnits(e.target.value)}

                    />
                    {/* <span>{props.error_one}</span> */}
                  </div>
                  <div className={styles.form_input}>
                    <label >
                      Pay Rate<span style={{ color: "#DB504A" }}>*</span>

                    </label>
                    <input
                      value={payRate}
                      placeholder="0"
                      type="number"
                      onChange={(e) => setPayRate(e.target.value)}

                    />
                    {/* <span>{props.error_one}</span> */}
                  </div>
                  <div className={styles.form_input}>
                    <label>
                      Shift Date

                    </label>
                    <input
                      type="date"
                      value={shiftDate}
                      placeholder="Hours Column"
                      onChange={(e) => setShiftDate(e.target.value)}

                    />
                    {/* <span>{props.error_one}</span> */}
                  </div>


                </div>

                <div className={styles.add_rate}>

                  <button className={styles.solid_add} onClick={addTimesheet}>
                    <span>Add</span>
                    <div className={styles.add_image} />
                  </button>
                </div>

                {timesheet.length !== 0 &&
                  <>
                    <Table
                      show_add={false}
                      showSearchBar={true}
                      filter={true}
                      columns={columns}
                      footer={true}
                      style={{ padding: "0", minHeight: "170px" }}
                      tableStyle={{ minHeight: "max-content", padding: 0 }}

                      body={timesheet?.map((row, index) => (
                        <tr key={index}>

                          <td>
                            {row?.units}
                          </td>
                          <td>
                            {numberFormatter(row?.payRate)}
                          </td>
                          <td>
                            {numberFormatter(parseInt(row?.payRate) * parseInt(row?.units))}
                          </td>
                          <td>
                            {numberFormatter(20 * parseInt(row?.payRate) * parseInt(row?.units) / 100)}
                          </td>
                          <td>
                            {row?.shiftDate}
                          </td>
                        </tr>
                      ))
                      }

                    />

                    <div className={styles.timesheet_container}>

                      <div className={styles.item}>
                        <img src={timesheet_net} alt={timesheet_net} />
                        <div>
                          <h1>{numberFormatter(totalAmount)}</h1>
                          <p>Net</p>
                        </div>

                      </div>
                      <div className={styles.item}>
                        <img src={timesheet_vat} alt={timesheet_vat} />
                        <div>
                          <h1>{numberFormatter((totalAmount * 20) / 100)}</h1>
                          <p>VAT</p>
                        </div>

                      </div>
                      <div className={styles.item}>
                        <img src={timesheet_total} alt={timesheet_total} />
                        <div>
                          <h1>{numberFormatter(totalAmount + ((totalAmount * 20) / 100))}</h1>
                          <p>Total</p>
                        </div>

                      </div>
                    </div>
                  </>
                }

              </div>

            </div>
            <div className={styles.buttons}>
              {addLoading ?
              <button className="loading_button"><div /></button>
                  :
                  <button className={styles.solid} onClick={add}>
                  Add Timesheet
                </button>

              }
          
            </div>
          </div>


          {bulk &&
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <h3>Timesheets Bulk Upload</h3>
                <div className={styles.select_input}>
                  <label>
                    Umbrella

                  </label>
                  <div className={styles.custom_select_container}>
                    <Select
                      disabled=""


                      value={umbrellaBulk}
                      onChange={(option) => {
                        setUmbrellaBulk(option)
                        const selectedUmbrella = allUmbrella?.find((item) => item.name === option);
                        if (selectedUmbrella) {
                          setUmbrellaBulkId(selectedUmbrella?.id);
                          getAgencyUnderUmbrella(selectedUmbrella)
                        }
                      }}
                      options={allUmbrella?.map((agency) =>
                        agency.name
                      )}
                    />

                  </div>
                </div>
                <div className={styles.select_input}>
                  <label>
                    Agency

                  </label>

                  <div className={styles.custom_select_container}>
                    <Select
                      disabled={umbrellaBulkId === "" ? "disabled" : ""}
                      value={agencyBulk}
                      onChange={(option) => {
                        setAgencyBulk(option)
                        const selectedUmbrella = allAgency?.find((item) => item.name === option);
                        if (selectedUmbrella) {
                          setAgencyBulkId(selectedUmbrella?.id);

                        }
                      }}
                      options={allAgency?.map((agency) =>
                        agency?.name
                      )}
                    />

                  </div>
                </div>



                <div className={styles.form_input}>
                  <label>
                    Select Rate<span style={{ color: "#DB504A" }}>*</span>

                  </label>
                  <Select
                    disabled={""}
                    value={rateBulk}
                    onChange={(option) => setRateBulk(option)}
                    options={["Standard Hourly Rate", "Standard Day Rate"]}

                  />
                  {/* <span>{props.error_one}</span> */}
                </div>

                <div className={styles.form_input}>
                  <label>
                    Pay Period

                  </label>
                  <input
                    type="date"
                    value={payPeriodBulk}

                    onChange={(e) => setPayPeriodBulk(e.target.value)}

                  />
                  {/* <span>{props.error_one}</span> */}
                </div>
                <span style={{display:"flex", alignItems:"center", columnGap:"5px", marginBottom: "10px"}}><img style={{width:"20px", height:"20px"}}src={file_icon} alt ={file_icon}/><p style={{  fontSize: "15px", fontWeight: "400" , margin:"5px"}}>{filename}</p></span>

                <button className={styles.upload} >
                  <label htmlFor="file" className={styles.addFile}>
                    <span>Upload Excel Template</span><img src={upload_t} alt={upload_t} />
                    <input

                      type="file"
                      name="file"
                      id="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileSelect}
                    />
                  </label>


                </button>

                {showProgress &&
                  <ProgressBar progress={progress} />

                }



              {responseSheet?.length>0 &&
                  <div style={{ maxHeight: "100px", overflowY: "scroll", marginTop: "10px", marginBottom: "10px" }}>
                  <h3  style={{fontWeight:"600", margin:0}}>Process Log Information</h3>
                  <p style={{color:"#A5A5A5", fontSize:"12px", margin:0}}>Various flags recorded while template was being processed</p>
                  {responseSheet.map((sheet) =>

<span style={{display:"flex", alignItems:"center", columnGap:"10px"}}><img style={{width:"20px", height:"20px"}}src={error_icon} alt ={error_icon}/><p style={{ color: "#DB504A", fontSize: "15px", fontWeight: "500" , margin:"5px"}}>{sheet}</p></span>

                  )

                  }
                </div>
              }
                <div className={styles.buttons}>
                  <button className={styles.clear} onClick={()=>setCancelBulk(true)}>
                    Cancel
                  </button>
                  
                  { bulkLoading ?
                                      <button className="loading_button"><div /></button>
                                      :
                  filename !== "" && agencyBulkId !== "" && umbrellaBulkId !== "" && payPeriodBulk !== "" && rateBulk !== "" &&
                
                    <button className={styles.solid} onClick={uploadSheet}>
                      Save
                    </button>

                  }

                </div>

              </div>



            </div>





          }
         <Modal open={cancelBulk}
          header="Warning message."
          content={<span>  Are you sure you want to cancel upload?
          </span>}
          show_yes={true}
          cancel={() => setCancelBulk(false)}
          yes={cancelUpload}


        />


        </>
      }
    />
  );
};

export default Add;

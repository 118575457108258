import React from "react";

//import styles
import styles from "./css/form.module.css";
 
//component imports
import Select from "./Select";
import SelectTel from "./SelectTel";
//import resources
import add from "../../Assets/add-square.svg";
import del from "../../Assets/delete.svg";

const Form = (props) => {
    return (
        <div className={styles.form}>
            <section>
                <div className={props.show_header ? styles.header : styles.hide}>
                    <h3>{props.header}</h3>
                    <button className={props.show_bulk ? styles.bulk : styles.hide} onClick={props.bulk_action}>
                        <span>{props.bulk} </span>
                        <div className={props.edit_option? props.edit_option_style:styles.bulk_icon}/>
                    </button>
                </div>
                {props.smallText &&
                <p style={{color:"#9D9D9D", fontSize:"13px"}}>{props.smallText}</p>
                }
                <hr className={props.line ? styles.show : styles.hide} />
                <div className={styles.form_container}>
                    {props.input_type === "input" &&
                        <div className={props.show_one ? styles.form_input : styles.hide}>
                            <label>
                                {props.label_one}{props.compulsory_one  && <span style={{ color: "#DB504A" }}>*</span>}

                            </label>
                            <input
                                type={props.type_one}
                                value={props.value_one}
                                placeholder={props.placeholder_one}
                                onChange={props.input_change}
                                disabled={props.disabled}
                                maxLength={props.maxLength}
                                max={props.maxDate}
                             
                                className={props.error_one_true? styles.error_input: props.place}
                            />
                        {props.error_one_true &&
                               <span className="error_message">{props.error_one}</span>

                            }
                        </div>
                    }
                    {props.input_type === "select" &&

                        <div className={props.show_one ? styles.select_input : styles.hide}>
                            <p className={styles.label}>
                                {props.label_one}{props.compulsory_one  && <span style={{ color: "#DB504A" }}>*</span>}

                            </p>
                            <div className={styles.custom_select_container}>
                                <Select
                                    disabled={props.disabled}
                                    value={props.select_one_value}
                                    onChange={props.select_change}
                                    options ={props.select_one}
                                    error={props.error_one_true}
                                    className={props.error_one_true? styles.error_input:""}
                                    message ={props.error_one}
                                    loadMore={props.loadMore}
                                    click={props.click}
                                    
                                />
                                  
                            </div>
                           
                         
                            {/* <input type="text" value={props.value_three} placeholder={props.placeholder_three} /> */}
                        </div>
                    }
                    {props.input_type_two === "input" &&
                        <div className={props.show_two ? styles.form_input : styles.hide}>
                            <label>
                                {props.label_two}{props.compulsory_two  && <span style={{ color: "#DB504A" }}>*</span>}

                            </label>
                            <input
                                type={props.type_two}
                                value={props.value_two}
                                placeholder={props.placeholder_two}
                                onChange={props.input_change_two}
                                disabled={props.disabled_two}
                                maxLength={props.max}
                                min={props.minDate}
                                className={props.error_two_true? styles.error_input:props.place}
                            />
                           {props.error_two_true &&
                               <span className="error_message">{props.error_two}</span>

                            }
                        </div>
                    }
                    {props.input_type_two === "select" &&

                        <div className={props.show_two ? styles.select_input : styles.hide}>
                            <p className={styles.label}>
                                {props.label_two}{props.compulsory_two  && <span style={{ color: "#DB504A" }}>*</span>}

                            </p>
                            <div className={styles.custom_select_container}>
                            <Select
                                    disabled={props.disabled_two}
                         value={props.select_two_value}
                         onChange={props.select_change_two}
                         options ={props.select_two}
                         error={props.error_two_true}
                         className={props.error_two_true? styles.error_input:""}
                         message={props.error_two}
                         loadMore={props.loadMoreTwo}
                         click={props.clickTwo}
                     />
                       
                            </div>
                         
                            {/* <input type="text" value={props.value_three} placeholder={props.placeholder_three} /> */}
                        </div>
                    }
                    {props.input_type_two === "tel" &&
                         <div className={props.show_two ? styles.form_input : styles.hide}>
                         <label>
                             {props.label_two}{props.compulsory_two  && <span style={{ color: "#DB504A" }}>*</span>}
   
                         </label>
                         <div className={styles.tel}>
                                    <SelectTel
                                       disabled={props.disabled_two}
                            value={props.select_tel_two_value}
                            onChange={props.select_change_tel_two}
                            options ={props.select_tel_two}
           
                        />
                             <input
                                 type="number"
                                 value={props.value_tel_two}
                                 placeholder={props.placeholder_tel_two}
                                 onChange={props.input_change_tel_two}
                                 disabled={props.disabled_tel_two}
                                 className ={props.place}
                             />
                         </div>
                     </div>

                    }
                  {props.image &&
                   <div onClick={props.add}>
                   <img
                       className={props.image ? styles.add_new : styles.hide}
                       style={{cursor:"pointer"}}
                       src={add }
                       alt={add }
                   />
               </div>

                  }
                 {props.imagedel &&
                     <div onClick={props.del}>
                     <img
                         className={props.imagedel ? styles.add_new : styles.hide}
                         style={{cursor:"pointer"}}
                         src={del}
                         alt={del  }
                     />
                 </div>

                 }
                    {props.clearButton &&
                     <button onClick={props.clear} className={styles.clearBtn}>
                     Clear
                </button>

                 }
                    {props.saveButton &&
                     <button onClick={props.save} className={styles.saveBtn}>
                      Save
                 </button>

                 }
                </div>
                {props.input_type==="tel" &&
                  
                  <div className={styles.form_container}>
                  <div className={props.show_tel ? styles.form_input : styles.hide}>
                      <label>
                          {props.label_tel}{props.compulsory_tel  && <span style={{ color: "#DB504A" }}>*</span>}

                      </label>
                      <div className={props.error_tel_true? styles.error_input_tel : styles.tel}>
                      <SelectTel
                                    disabled={props.disabled_tel}
                         value={props.select_tel_value}
                         onChange={props.select_change_tel}
                         options ={props.select_tel}
        
                     />
                          <input
                              type="number"
                              value={props.value_tel}
                              placeholder={props.placeholder_tel}
                              onChange={props.input_change_tel}
                              disabled={props.disabled_tel}
                              className ={props.place}
                          />
                      </div>
                      {props.error_tel_true &&
                               <span className="error_message">{props.error_tel}</span>

                            }
                  </div>
                  <div className={props.show_tel_two ? styles.form_input : styles.hide}>
                      <label>
                          {props.label_tel_two}{props.compulsory_tel_two  && <span style={{ color: "#DB504A" }}>*</span>}

                      </label>
                      <div className={styles.tel}>
                                 <SelectTel
                                    disabled={props.disabled_tel}
                         value={props.select_tel_two_value}
                         onChange={props.select_change_tel_two}
                         options ={props.select_tel_two}
        
                     />
                          <input
                              type="number"
                              value={props.value_tel_two}
                              placeholder={props.placeholder_tel_two}
                              onChange={props.input_change_tel_two}
                              disabled={props.disabled_tel_two}
                              className ={props.place}
                          />
                      </div>
                  </div>
                  <div onClick={props.add}>
                      <img
                          className={props.image ? styles.add_new : styles.hide}
                          src={props.del_show? props.del :add }
                          alt={props.del_show? props.del :add }
                      />
                  </div>
              </div>

                }

     

            
              
                <div className={props.check ? styles.toggle_container : styles.hide}>
                    <div className={props.show_check_one ? styles.check_input : styles.hide}>
                        <p>{props.check_desc_one}</p>
                        <label className={styles.switch}>
                            <input type="checkbox" checked={props.check_one} onChange={props.check_one_change} disabled={props.disabled_check_one} />
                            <span className={styles.custom_switch}></span>
                        </label>
                        
                    </div>
                    <div className={props.show_check_two ? styles.check_input : styles.hide}>
                        <p>{props.check_desc_two}</p>
                        <label className={styles.switch}>
                            <input type="checkbox" checked={props.check_two} onChange={props.check_two_change} disabled={props.disabled_check_two} />
                            <span className={styles.custom_switch}></span>
                        </label>
                    </div>
                    <div className={props.show_check_three ? styles.check_input : styles.hide}>
                        <p>{props.check_desc_three}</p>
                        <label className={styles.switch}>
                            <input type="checkbox" checked={props.check_three} onChange={props.check_three_change} disabled={props.disabled_check_three} />
                            <span className={styles.custom_switch}></span>
                        </label>
                    </div>
                    <div className={props.show_check_four ? styles.check_input : styles.hide}>
                        <p>{props.check_desc_four}</p>
                        <label className={styles.switch}>
                            <input type="checkbox" checked={props.check_four} onChange={props.check_four_change} disabled={props.disabled_check_four} />
                            <span className={styles.custom_switch}></span>
                        </label>
                    </div>
                    <div className={props.show_five ? styles.form_input : styles.hide}>
                            <label>
                                {props.label_five}{props.disabled === '' && <span style={{ color: "#DB504A" }}>*</span>}
                            </label>
                            <input
                                type={props.type_five}
                                value={props.value_five}
                                placeholder={props.placeholder_five}
                                onChange={props.input_change_five}
                                disabled={props.disabled_five}
                                className ={props.place}
                            />
                            <span>{props.error_one_five}</span>
                        </div>
                </div>
            </section>
        </div>
    );
};

export default Form;

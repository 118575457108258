import React from 'react';

//import style
import styles from './css/auth_image.module.css'


const AuthImageMobile = (props) => {
    return (
        <div className={styles.subContainerMobile} style={{backgroundImage: `url(${props.background})`}}>
         
  
        </div>
    );
};

export default AuthImageMobile;
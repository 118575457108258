import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE } from "../Services/Backend";
import Loader from "../Common/Loader";
import Tooltip from "../Common/Tooltip";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";

//import styles
import styles from "../Agency/css/agency.module.css";



//import resources

import NoContent from "../Common/NoContent";
import agency from "../../Assets/timesheet_green.svg";
import Table from "../Common/Table";
import add from "../../Assets/add-icon.png";
import delete_icon from "../../Assets/delete_icon.svg"
import view from "../../Assets/send_green.svg";
import download from "../../Assets/send_grey.svg";
import contractor from "../../Assets/contractor-green.svg";



const ContractorTimesheet = () => {

  const [timesheet, setTimesheet] = useState([]);
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [pageCount, setPageCount] = useState()
  
  const [pageNumber, setPageNumber] = useState(1)
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [element, setElement] = useState(10)
  const [totalElement, setTotalElement] = useState(10)
  const columns = [
    { key: 'fullName', label: 'Contractors Name' },
    { key: 'niNumber', label: 'Pay Period' },
    { key: 'emailAddress', label: 'Contractors Email' },
    { key: 'gender', label: 'Total Shift' },
    { key: 'agencies', label: 'Email Reminder' },
  
  ];

  const navigate = useNavigate();
  const location = useLocation()

  const [toggle, setToggle] = useState();

  const getTimesheet = async (page) => {
    setLoading(true)

    try {
      const response = await GET_SERVICE(`/time-sheets-file/contractors/${location.state.id}?page=${page||0}&size=10`);
    
      if (response.status===200){
        setPageNumber(page || 0)
      
 
        
        setPageCount(response.data.data.page )
        setTimesheet(response?.data?.data?.contractorTimeSheetFileInformation);
       
        setTotalElement(response.data.data.size)
      
        setLoading(false)
      }
      else{
        setTimesheet([])
        setLoading(false)
      }
   
    } catch (err) {

      return err.response;
    }
  };
  const getMoreSheets = async (option) => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/time-sheets-file/contractors/${location.state.id}?page=${pageNumber ||0}&size=${option}`);
    
      if (response.status===200){
        setTimesheet(response.data.data.singleTimeSheetDTOS);
     
        setPageCount(response.data.data.totalPages)
      setElement(option)
   
      
        setLoading(false)
      }
      else{
        setTimesheet([])
        setLoading(false)
      }
     
    
    } catch (err) {

      return err.response;
    }
  };
  const nextPage =(e)=>{

    let page = e.selected 

   
    getTimesheet(page)
 
      
 
   }

  useEffect(() => {
    getTimesheet();
  }, []);

  const sendEmail =async (row)=>{
    if (row.emailStatus!=="COMPLETED"){
      const endpoint = `/time-sheets-file/${row.contractorTimeSheetFileInfold}/send-mail`
      try {
        const response = await GET_SERVICE(endpoint);
        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            getTimesheet()
          }, 5000);
  
        }
        else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
          }, 5000);
        }
  
       
      
      } catch (err) {
  
        return err.response;
      }
  
    }
  }



  const toggler = () => {
    setToggle(!toggle);
  };

  return (
     
    <MainContainer
    
      content={
        <>{
        timesheet?.length >0 ? (
          loading === true ? (
            <Loader show={loading} />
          ) :
          <Table
       
            add_image={add}
            header={
              <h1 style={{ color: "#9D9D9D",  fontWeight: "600" }}>
              <span onClick={() => navigate("/timesheet")} style={{ cursor: "pointer" }}>Timesheet</span>{" "}
              <span style={{ color: "#313131" }}>/ Contractors</span>
            </h1>
  
            }
            columns={columns}
            showSearchBar={true}
            search={search}
            // searchInput={(e)=>getSearch(e)}
            action={true}
            action_button={() => navigate("/timesheet/add")}
            body={timesheet.map((row, index)=>
              <tr key={index}>

                     <td>
                        {row.name}
          
                  </td>
                  <td>
                        {row.payPeriod}

                  </td>
                  <td>
                        {row.contractorEmail}

                  </td>
                  <td>
                        {row.numberOfShifts}
          
                  </td>
                  <td>
                    {row.emailStatus ==="COMPLETED" &&

                    <span style={{color:"#85CE54", fontWeight:"600"}}>SENT</span>

                    }
                     {row.emailStatus==="NOT_SENT"&&
                   <span style={{color:"#56AACA", fontWeight:"600"}}>SEND</span>

                    }
                             {row.emailStatus==="FAILED"&&
                   <span style={{color:"#DB504A", fontWeight:"600"}}>FAILED</span>

                    }
                  </td>
                
                  <td className={styles.action_icons}>
                    <div
                    >
                      <span>
  
                        <Tooltip text={row.emailStatus ==="COMPLETED" ?"sent": "Send Email"}>
                          <img
                            className={styles.view_icon}
                            src={row.emailStatus !=="COMPLETED"  ?view: download}
                            alt={view}
                            onClick={()=>sendEmail(row)}
                        
                          />
                        </Tooltip>
                
                      </span>
                   
                   
                    </div>
                  </td>

                </tr>
              
              
              
              
              )
            
            }
            row={element}
            row_list={
              totalElement >= 30
              ? [10, 20, 30]
              : totalElement >= 20
              ? [10, 20]
              : totalElement >= 10
              ? [10]
              : []
            }
            row_item_click={(option)=>getMoreSheets(option)}
            pageCount={pageCount}
            pageNumber={pageNumber}
            pageChange= {nextPage}
         
      
            filterContent={
              <>
              </>
            //   <div>
            //          <div className={styles.filter}>
                

            //     <div className={ styles.select_input}>
            //                 <label>
            //                   Agency

            //                 </label>
            //                 <div className={styles.custom_select_container}>
            //                     <Select
            //                         disabled=""
            //                         value={agency}
            //                         onChange={(option)=>setAgency(option)}
            //                         options ={allAgency}
            //                     />
                                  
            //                 </div>
            //             </div>
            //             <div className={ styles.select_input}>
            //                 <label>
            //                   Umbrella

            //                 </label>
            //                 <div className={styles.custom_select_container}>
            //                     <Select
            //                         disabled=""
            //                         value={umbrella}
            //                         onChange={(option)=>setUmbrella(option)}
            //                         options ={allUmbrella}
            //                     />
                                  
            //                 </div>
            //             </div>
            //             <div className={ styles.select_input}>
            //                 <label>
            //                   Status

            //                 </label>
            //                 <div className={styles.custom_select_container}>
            //                     <Select
            //                         disabled=""
            //                         value={status}
            //                         onChange={(option)=>setStatus(option)}
            //                         options ={allStatus}
            //                     />
                                  
            //                 </div>
            //             </div>

            //   </div>
            //   <div className={styles.buttons}>
            //   <button className={styles.clear} >
            //     Cancel
            //   </button>
            //   <button className={styles.solid} disabled>
            //     Save
            //   </button>
            // </div>
            //   </div>
         


            }
          />
        ) : (
          loading === true ? (
            <Loader show={loading} />
          ) :
          <div className="no_content">
            <NoContent
              image={contractor}
              header="No Timesheet Added"
              text="Add a timesheet by clicking the button below"
              buttonText="Add Timesheet"
              next="/timesheet/add"
            />
          </div>
        )
          }
          </>
      }
    />
  );
};

export default ContractorTimesheet;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Component Imports
import Login from "./Components/Auth/Login";
import ResetPassword from "./Components/Auth/ResetPassword"
import Otp from "./Components/Auth/Otp";
import Register from "./Components/Auth/Register";
import PasswordReset from "./Components/Auth/PasswordReset";
import Dashboard from "./Components/Dashboard/Dashboard";
import Contractor from "./Components/Contractor/Contractor";
import View from "./Components/Contractor/View";
import Agency from "./Components/Agency/Agency"
import AgencyPaySlips from "./Components/Agency/AgencyPaySlips"
import AddAgency from "./Components/Agency/Add";
import AddContractors from "./Components/Contractor/Add";
import Next from "./Components/Contractor/Next"
import AgencyNext from "./Components/Agency/Next"
import AgencyView from "./Components/Agency/View"
import MultiEntry from "./Components/Multi-Entry/Multi-Entry"
import MultiEntryAdd from "./Components/Multi-Entry/Add"
import MultiEntryView from "./Components/Multi-Entry/View"
import Payroll from "./Components/Payroll/Payroll"
import Configuartion from "./Components/Payroll/Configuration";
import Timesheet from "./Components/TimeSheet/Timesheet"
import TimesheetAdd from "./Components/TimeSheet/Add"
import ContractorTimesheet from "./Components/TimeSheet/ContractorTimesheet";
import ViewReport from "./Components/Settings/ViewReport"
// import Payslip from "./Components/Payroll/Payslip";


import ProtectedRoute from "./Components/routes/ProtectedRoute";


import Profile from "./Components/Settings/Profile"
import Administrator from "./Components/Settings/Administrator"
import ActivityTimeline from "./Components/Settings/ActivityTimeline"
import AddAdministrator from "./Components/Settings/Add"
import Report from "./Components/Settings/Report"



//styles importß
import './App.css';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
  
    const token = sessionStorage.getItem("token")
    console.log(token)
    if (token!== undefined ) {
      setIsLoggedIn(true)
    }
    else {
      isLoggedIn(false)
    }
    
  }, [])

  

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Login />} /> 
      <Route path="/resetPassword" element={<ResetPassword/>}/>
      <Route path="/Register" element={<Register/>}/>
      <Route path="/overview" element={<ProtectedRoute ><Dashboard /></ProtectedRoute>}/>
      
      <Route path="/otp" element={<Otp/>}/>
      <Route path="/contractor" element={<ProtectedRoute > <Contractor/></ProtectedRoute>
     }/>
      <Route path="/contractor/add" element={<ProtectedRoute >  <AddContractors/></ProtectedRoute>
    }/>
       <Route path="/contractor/next" element={<ProtectedRoute >    <Next/></ProtectedRoute>
   }/>
       <Route path="/contractor/view" element={<ProtectedRoute >       <View/></ProtectedRoute>
}/>
 
        {/* <Route path="/payroll" element={<ProtectedRoute >    <Payroll /></ProtectedRoute>
    } />  
       <Route path="/payroll/configuration" element={<ProtectedRoute >    <Configuartion /></ProtectedRoute>
    } />  */}
            <Route path="/payroll" element={  <Payroll />
    } />  
       <Route path="/payroll/configuration" element={  <Configuartion />
    } /> 
        <Route path="/timesheet" element={<ProtectedRoute > <Timesheet /></ProtectedRoute>
       } />  
        <Route path="/timesheet/add" element={<ProtectedRoute >  <TimesheetAdd /></ProtectedRoute>
      } /> 
        <Route path ="/timesheet/contractors" element={<ProtectedRoute ><ContractorTimesheet/></ProtectedRoute>
        }/> 
        <Route path ="/passwordreset/:user/:id" element={<ProtectedRoute > <PasswordReset/></ProtectedRoute>
       }/>


        <Route path="/agency" element={<ProtectedRoute >  <Agency /></ProtectedRoute>
      } />
        <Route path="/agency/add" element={<ProtectedRoute >  <AddAgency /></ProtectedRoute>
      } />
        <Route path="/agency/view" element={<ProtectedRoute ><AgencyView /></ProtectedRoute>
        } />
        <Route path="/agency/next" element={<ProtectedRoute ><AgencyNext /></ProtectedRoute>
        } />
        <Route path="/umbrella" element={<ProtectedRoute > <MultiEntry /></ProtectedRoute>
       } />  
        <Route path="/umbrella/add" element={<ProtectedRoute >    <MultiEntryAdd /></ProtectedRoute>
    } />  
        <Route path="/umbrella/view" element={<ProtectedRoute > <MultiEntryView /></ProtectedRoute>
       } />  
        <Route path="/profile" element={<ProtectedRoute > <Profile/></ProtectedRoute>
       }/>
        <Route path="/timeline" element={<ProtectedRoute >  <ActivityTimeline/></ProtectedRoute>
      }/>
        <Route path="/administrators" element={<ProtectedRoute ><Administrator/></ProtectedRoute>
        }/>
        <Route path="/administrators/add" element={<ProtectedRoute > <AddAdministrator/></ProtectedRoute>
       }/>
        <Route path="/report" element={<ProtectedRoute > <Report/></ProtectedRoute>
       }/>
        <Route path="/report/view" element={<ProtectedRoute >   <ViewReport/></ProtectedRoute>
     }/>

           {/* <Route path="/payslip" element={<Payslip/>}/> */}
      </Routes>
    </Router>

  );
}

export default App;
import React, {useState, useEffect} from 'react';

//component imports
import { GET_SERVICE } from "../Services/Backend";
import ViewLoader from '../Common/ViewLoader';
import Table from '../Common/Table';
import NoContent from '../Common/NoContent';
import Select from '../Common/Select';

//resource import
import contractorIcon from "../../Assets/contractor-green.svg";


//import styles
import styles from "./css/view.module.css";

const History = (props) => {
    const [agencyHistory, setAgencyHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [historyElement, setHistoryElement] = useState(10)
    const [totalHistoryElement, setTotalHistoryElement] = useState(10)
    const [pageCount, setPageCount] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [date, setDate] = useState("")
    const historyHeaders = [
        { key: 'fullName', label: 'Serial NO' },
        { key: 'niNumber', label: 'Activity' },
        { key: 'emailAddress', label: 'Date Updated' },
    
      ]
      const getHistory =async (page)=>{
        setLoading(true)
        try{
          const response = await GET_SERVICE(`/contractor-activity/contractor/${props.id}?page=${page || 0}&size=10`);
    
          if (response.status === 200) {
    
         
          setAgencyHistory(response?.data?.data?.contractorTimeSheetPayslipActivities        )
          setPageNumber(page || 0)
          setTotalHistoryElement(response.data.data.totalElement);
    
    
    
          setPageCount(response.data.data.page)
    
         
          setLoading(false)
        }
        else {
      
          setLoading(false)
        }
    
    
    
      } catch (err) {
        return err.response;
        
      }
      }
      const nextPage = (e) => {

        let page = e.selected
    
    
    getHistory(page)
    
    
    
      }

      const getMoreHistory = async (option) => {
        setLoading(true)
        try{
          const response = await GET_SERVICE(`/contractor-activity/contractor/${props.id}?page=${pageNumber || 0}&size=${option}`);
    
          if (response.status === 200) {
    
         
          setAgencyHistory(response?.data?.data?.contractorTimeSheetPayslipActivities        )
        
          setHistoryElement(option);
    
    
    
          setPageCount(response.data.data.page)
    
         
          setLoading(false)
        }
        else {
      
          setLoading(false)
        }
    
    
    
      } catch (err) {
        return err.response;
        
      }
      }

      useEffect(()=>{
       getHistory()
      }, [])
    return (
        <>
        {agencyHistory?.length > 0 ?
          loading === true ?
            <ViewLoader />
            :
            <Table
            columns={historyHeaders}
              body={
                agencyHistory?.map((row, index)=>
                <tr key={index}>
                  <td>
                      {row.id}
                  </td>
                  <td>
                      {row.activityDescription}
                  </td>
                  <td>
                      {row.createdAt}
                  </td>
                
                </tr>
                
                
                )

               }
               row={historyElement}
               row_list={
                 totalHistoryElement <= 30 && totalHistoryElement >= 20
                   ? [10, 20, 30]
                   : totalHistoryElement <= 20 && totalHistoryElement >= 10
                   ? [10, 20]
                   : totalHistoryElement <= 10
                   ? [10]
                   : []
               }
               row_item_click={(option) => getMoreHistory(option)}
              pageCount={pageCount}
              pageChange={nextPage}
              pageNumber={pageNumber}

              filterContent={
                <div>
                  <div className={styles.filter}>


                    <div className={styles.select_input}>
                      <label>
                        Date

                      </label>
                      <div className={styles.custom_select_container}>
                        <Select
                          disabled=""
                          value={date}
                          onChange={(option) => setDate(option)}
                          options={[]}
                        />

                      </div>
                    </div>

                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} >
                      Cancel
                    </button>
                    <button className={styles.solid} disabled>
                      Save
                    </button>
                  </div>
                </div>



              }
            />
          :
          loading === true ?
            <ViewLoader />
            :
            <>
              <NoContent
                image={contractorIcon}
                text="No History Available"
                hide={true}
                change={{ margin: "5% 0" }}

              />
            </>
        }
      </>
    );
};

export default History;
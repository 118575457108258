import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//component import
import MainContainer from "../Common/MainContainer";
import { GET_SERVICE, PUT_SERVICE } from "../Services/Backend";
import ErrorNotification from "../Common/ErrorNotification";
import SuccessNotification from "../Common/SuccessNotification";
import Loader from "../Common/Loader";
import NoContent from "../Common/NoContent";
import Tooltip from "../Common/Tooltip";
import active from "../../Assets/activate.svg"
import Modal from "../Common/Modal";

//styles import
import styles from "./css/administrator.module.css"

//import resources
import Table from "../Common/Table";
import view from "../../Assets/x_icon.svg";
import edit_icon from "../../Assets/edit_admin.svg";
import contractor from "../../Assets/contractor-green.svg";
import add from "../../Assets/add-icon.png";
import Select from "../Common/Select";

const Administrator = () => {
  const [admins, setAdmins] = useState([])


  const columns = [
    { key: 'fullName', label: 'Full Name' },
 
    { key: 'emailAddress', label: 'Email' },
    { key: 'gender', label: 'Gender' },
    { key: 'agencies', label: 'Phone Number' },

  ];

  const navigate = useNavigate();
  const [toggle, setToggle] = useState();
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [pageCount, setPageCount] = useState()
  const [pageNumber, setPageNumber] = useState(0)
  const [adminId, setAdminId] = useState()
  const [text, setText] = useState("")
  const [firstName, setFirstname] = useState("")
  const [lastName, setLastname] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneHead, setPhoneHead] = useState("")
  const [mobileHead, setMobileHead] = useState("")
  const [emailAddress, setEmailAddress] = useState('')
  const [mobile, setMobile] = useState("")
  const [gender, setGender] = useState("")
  const [edit, setEdit] = useState(false)
  const [cancelEdit, setCancelEdit] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [userDetails, setUserDetails] = useState()

  const toggler = () => {
    setToggle(!toggle);
  };
  const getAdmins = async (page) => {
    setLoading(true);
    try {
      const response = await GET_SERVICE(`/users/admins?size=10&page=${page || 0}`);
      if (response.status === 200) {
        console.log(response)
        setAdmins(response.data.data.userProfiles);

        setPageCount(response.data.data.numberOfPages)
        setPageNumber(page || 0)

        setLoading(false);

      }
      else {
        setAdmins([])
        setLoading(false)
      }

    } catch (err) {
      return err.response;
    }
  };
  const blockAdminModal = (row) => {
    setAdminId(row)
    setToggle(!toggle)
    if (row.status === "BLOCKED") {
      setText("activate")
    }
    else {
      setText("block")
    }

  }

  const nextPage = (e) => {

    let page = e.selected


    getAdmins(page)



  }
  const cancelEditModal = () => {
    setCancelEdit(false)
    setEdit(false)
    setUserDetails()
  }
  const editModal = (row) => {
    setEdit(true)
    setUserDetails(row)
  }
  const editUserDetails = async () => {
    const endpoint = `/users/${userDetails.id}/edit-profile`
    const body = {
      "firstName": firstName !== "" ? firstName : userDetails.firstName,
      "lastName": lastName !== "" ? lastName : userDetails.lastName,
      "gender": gender !== "" ? gender : userDetails.gender,
      "phoneNumber": {
        "countryCode": "+44",
        "number": phone !== "" ? phone : userDetails.phoneNumber
      },
      "mobileNumber": {
        "countryCode": "+44",
        "number": mobile !== "" ? mobile : userDetails.mobileNumber
      }
    }
    try {
      const response = await PUT_SERVICE(endpoint, body);



      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          setSuccess(false);
          cancelEditModal()
          getAdmins()

        }, 5000);

      }
      else {
        setError(true);
        setErrorMessage(response.data.message);
        setTimeout(() => {
          setError(false);
        }, 5000);
      }

    } catch (err) {
      return err.response;
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);
  const blockAdmin = async (adminList) => {
    if (adminList.status !== "BLOCKED") {
      try {
        const response = await PUT_SERVICE(`/users/block/${adminList.id}`);


        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            setToggle(false)
            getAdmins();
          }, 5000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }
      } catch (e) {
        return e.response;
      }
    } else {
      try {
        const response = await PUT_SERVICE(
          `/users/un-block/${adminList.id}`
        );

        if (response.status === 200) {
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            setSuccess(false);
            setToggle(false)
            getAdmins();
          }, 5000);
        } else {
          setError(true);
          setErrorMessage(response.data.message);
          setTimeout(() => {
            setError(false);
            setToggle(false)
          }, 5000);
        }
      } catch (e) {
        return e.response;
      }
    }
  };


  return (
    <>
      <ErrorNotification show={error} content={errorMessage} />
      <SuccessNotification show={success} content={successMessage} />
      <MainContainer
        content={
          admins.length > 0 ? (
            loading === true ? (
              <Loader show={loading} />
            ) : (
              <Table

                show_add={true}
                add_image={add}
                header="Admin User"
                show_text={true}
                text="List of Admin Managers"
                showSearchBar={true}
                filter={true}
               
                // search={search}
                // searchInput={(e)=>getSearch(e)}
                columns={columns}
                action_button={() => navigate("/administrators/add")}
                action="Add Admin"
                body={admins.map((row, index) => (
                  <tr key={index}>
                    <td>
                      {row.status === "BLOCKED" ? (
                        <span style={{ color: "#9D9D9D" }}>
                          {row.firstName + "  " + row.lastName}
                        </span>
                      ) : (
                        row.firstName + "  " + row.lastName
                      )}
                    </td>

                  
                    <td>
                      {row.status === "BLOCKED" ? (
                        <span style={{ color: "#9D9D9D" }}>{row.emailAddress}</span>
                      ) : (
                        row.emailAddress
                      )}
                    </td>
                    <td>
                      {row.status === "BLOCKED" ? (
                        <span style={{ color: "#9D9D9D" }}>{row.gender}</span>
                      ) : (
                        row.gender
                      )}
                    </td>
                    <td>
                      {row.status === "BLOCKED" ? (
                        <span style={{ color: "#9D9D9D" }}>
                          {row.phoneNumber}
                        </span>
                      ) : (
                        row.phoneNumber
                      )}
                    </td>

                    <td className={styles.action_icons}>
                      <div
                        style={{
                          display: "flex",
                          gridColumnGap: "20px",
                          alignItems: "center",
                          gridRowGap: "20px",
                          flexWrap: "wrap",
                        }}
                      >
                        <span>
                          <Tooltip text={row.status === "BLOCKED" ? "Activate" : "Block"}>
                            <img
                              style={{ width: "25px" }}
                              src={row.status === "BLOCKED" ? active : view}
                              alt={view}
                              onClick={() => blockAdminModal(row)}
                            />
                          </Tooltip>
                          {""}
                        </span>
                        <span>
                          <Tooltip text="Edit">
                            <img
                              style={{ width: "20px" }}
                              src={edit_icon}
                              alt={edit_icon}
                              onClick={() => editModal(row)}
                            />
                          </Tooltip>
                          {""}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
                row="10"
                pageCount={pageCount}
                pageChange={nextPage}
                pageNumber={pageNumber}
              // filterContent={
              //   <div>
              //     <div className={styles.filter}>
              //       <div className={styles.select_input}>
              //         <label>Agency Name</label>
              //         <div className={styles.custom_select_container}>
              //           <Select
              //             disabled=""
              //             // value={agency_name}
              //             // onChange={(option) => setAgency_name(option)}
              //             // options={allAgencyName}
              //           />
              //         </div>
              //       </div>
              //     </div>
              //     <div className={styles.buttons}>
              //       <button className={styles.clear}>Cancel</button>
              //       <button className={styles.solid} disabled>
              //         Save
              //       </button>
              //     </div>
              //   </div>
              // }
              />
            )
          ) : (
            loading === true ? (
              <Loader show={loading} />
            ) : (
              <div className="no_content">
                <NoContent
                  image={contractor}
                  header="No Admin Added"
                  text="Create a new Admin by clicking the button below"
                  buttonText="Create Admin"
                  next="/administrators/add"
                />
              </div>
            )
          )
        }
      />
      <Modal open={toggle}
        header="Warning message."
        content={<span>  Are you sure you want to {text} this Admin
        </span>}
        show_yes={true}
        cancel={() => setToggle(false)}
        yes={() => blockAdmin(adminId)}


      />
      {edit &&
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Edit Admin</h3>
            <div className={styles.flex_input}>
              <div className={styles.form_input}>

                <label>
                  First Name
                </label>
                <input type="text" value={firstName} onChange={(e) => setFirstname(e.target.value)} placeholder={userDetails.firstName} />
              </div>
              <div className={styles.form_input}>

                <label>
                  Last Name
                </label>
                <input type="text" value={lastName} onChange={(e) => setLastname(e.target.value)} placeholder={userDetails.lastName} />
              </div>
            </div>
            <div className={styles.select_input}>
              <label>
                Gender

              </label>
              <div className={styles.custom_select_container}>
                <Select
                  disabled=""
                  placeholder="gender"

                  value={gender || userDetails.gender}
                  onChange={(option) => setGender(option)}
                  options={["Male", "Female"]}
                />

              </div>

            </div>
            <div className={styles.form_input}>

              <label>
                Phone
              </label>
              <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={userDetails.phoneNumber} />
            </div>
            <div className={styles.form_input}>

              <label>
                Mobile
              </label>
              <input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder={userDetails.mobileNumber} />
            </div>
            <div className={styles.form_input}>

              <label>
                Email
              </label>
              <input type="email" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder={userDetails.emailAddress} disabled />
            </div>


            <div className={styles.buttons}>
              <button className={styles.clear} onClick={() => setCancelEdit(true)}>
                Cancel
              </button>

              {editLoading ?
                <button className="loading_button"><div /></button>
                :


                <button className={styles.solid} onClick={editUserDetails}>
                  Save
                </button>

              }

            </div>

          </div>



        </div>





      }
      <Modal open={cancelEdit}
        header="Warning message."
        content={<span>  Are you sure you want to cancel update?
        </span>}
        show_yes={true}
        cancel={() => setCancelEdit(false)}
        yes={cancelEditModal}


      />
    </>
  );
};

export default Administrator;

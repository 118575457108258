import React from "react";
//Import style
import styles from "./css/auth_form.module.css";

//import resources
import logo from "../../../Assets/logo.svg";
import AuthImageMobile from "./AuthImageMobile";

 
const AuthForm = (props) => {
  return (
    <div className={styles.authForm}>
      <div className={styles.logo}>
        <img src={logo} alt="Payroll Easy" />
      </div>
      <AuthImageMobile background={props.image}/>

      <div className={styles.form}>
        <div style={{width:"100%"}}>
        <div className={styles.header}>
        <h1>{props.header}</h1>
        <p>{props.subText}</p>
      </div>
      <form>
        <div className={props.hide?styles.hide:styles.formControl}>
          <label htmlFor={props.label}>{props.labelText}</label>
          <div className={props.errorBorder?styles.errorInput:styles.formInput}>
            <img src={props.inputIcon} alt="icon" />
            <input type="email" name={props.label} value={props.value} onChange={props.change} />
       
          </div>
          <p className="error_message"> {props.error}</p>
        </div>
        {props.password}
    
        {props.loading ?
        
        <button className="button_loader"><div /></button>
        :

        <button onClick={props.click}>{props.buttonText}</button>

        }
        {props.showOtp &&
         <div className={styles.otp_text}>
                  <p style={{color:"#85CE54",  fontWeight:"400", cursor:"pointer", fontSize:"15px"}} onClick={props.otp}> {props.send_text}</p>
         </div>

        }
      </form>
   
        </div>
      </div>
   
    </div>
  );
};

export default AuthForm;

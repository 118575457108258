import React , {useState}from 'react';

//styles import
import styles from "./css/tooltip.module.css"

const Tooltip = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
      setShowTooltip(true);
    };
   
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return (
        <div className={styles.tooltip_container}>
        <div
          className={styles.tooltip_trigger}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
     {showTooltip &&
     <section className={styles.tooltip_text}>

     {text}


     </section>

     }
      </div>
    );
};

export default Tooltip;
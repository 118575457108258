import React, { useState, useEffect } from 'react';


//component import
import Form from "../Common/Form"
import Sort from '../Utils/Sort';
import ViewButton from '../Common/ViewButton';
import Loader from "../Common/Loader"
import ViewLoader from "../Common/ViewLoader"
import { GET_SERVICE, PUT_SERVICE, DELETE_SERVICE, POST_SERVICE } from '../Services/Backend';


//resource import
import del from "../../Assets/delete.svg";

//styles import
import styles from './css/margin.module.css'

const Ni = (props) => {
    const [editable, setEditable] = useState(false)
    const [categoryId, setCategoryId] = useState([]);
    const [category, setCategory] = useState([])
    const [categoryItem, setCategoryItem] = useState([])
    const [editableEmployer, setEditableEmployer] = useState(false)
    const [editableEmployee, setEditableEmployee] = useState(false)
    const [componentCount, setComponentCount] = useState(0);
    const [categories, setCategories] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [employeeRange, setEmployeeRange] = useState([])
    const [employeerRange, setEmployeerRange] = useState([])
    const [secondComponentCount, setSecondComponentCount] = useState(0)
    const [tableCount, setTableCount] = useState(0)
    const [secondTableCount, setSecondTableCount] = useState(0)
    const [tableCountEmployer, setTableCountEmployer] = useState(0)
    const [secondTableCountEmployer, setSecondTableCountEmployer] = useState(0)
    const [minimumValueRange, setMininmumValueRange] = useState([])
    const [maximumValueRange, setMaximumValueRange] = useState([])
    const [minimumValueRangeEmployer, setMininmumValueRangeEmployer] = useState([])
    const [maximumValueRangeEmployer, setMaximumValueRangeEmployer] = useState([])
    const [minValueRange, setMinValueRange] = useState([])
    const [maxValueRange, setMaxValueRange] = useState([])
    const [minValueRangeEmployer, setMinValueRangeEmployer] = useState([])
    const [maxValueRangeEmployer, setMaxValueRangeEmployer] = useState([])
    const [rangeId, setRangeId] = useState([])
    const [rangeIdEmployer, setRangeIdEmployer] = useState([])
    const [loading, setLoading] = useState(false)
    const [categoryLoading, setCategoryLoading] = useState(false)

    const [valueRange, setValueRange] = useState([])
    const [valueId, setValueId] = useState([])
    const [valueList, setValueList] = useState([])
    const [valueRangeEdit, setValueRangeEdit] = useState([])
    const [valueRangeIdCategory, setValueRangeIdCategory] = useState([])
    const [valueRangeIdRange, setValueRangeIdRange] = useState([])

    const [valueRangeEmployer, setValueRangeEmployer] = useState([])
    const [valueIdEmployer, setValueIdEmployer] = useState([])
    const [valueListEmployer, setValueListEmployer] = useState([])
    const [valueRangeEditEmployer, setValueRangeEditEmployer] = useState([])
    const [valueRangeIdCategoryEmployer, setValueRangeIdCategoryEmployer] = useState([])
    const [valueRangeIdRangeEmployer, setValueRangeIdRangeEmployer] = useState([])
    const [employerLoading, setEmployerLoading] = useState(false)
    const [employeeLoading, setEmployeeLoading] = useState(false)


    const getCategory = async () => {
        setLoading(true)
        try {
            const response = await GET_SERVICE(`/ni-category`);

            if (response.status === 200) {
                setCategories(response?.data?.data.reverse() || []);


                setComponentCount(response.data.data.length)
            }
            setLoading(false)


        } catch (err) {
            return err.response
        }
    };
    const getEmployeeRange = async () => {
        setLoading(true)
        try {
            const response = await GET_SERVICE(`/employee-ni-range`);
            if (response.status === 200) {
                setEmployeeRange(response.data.data)

                setTableCount(response.data.data.length)
            }

            setLoading(false)


        } catch (err) {
            return err.response;
        }
    }
    const getEmployeerRange = async () => {
        setLoading(true)
        try {
            const response = await GET_SERVICE(`/employer-ni-range`);
        
            if (response.status === 200) {
                setEmployeerRange(response.data.data)
                setTableCountEmployer(response.data.data.length)
            }
            setLoading(false)


        } catch (err) {
            return err.response;
        }
    }
    const getEmployeeValue = async () => {
        setLoading(true)
        try {
            const response = await GET_SERVICE(`/employee-ni-range-category-percentage`);
            if (response.status === 200) {
                setValueList(response?.data?.data)
            }
            setLoading(false)

        } catch (err) {
            return err.response;
        }
    }
    const getEmployerValue = async () => {
        setLoading(true)
        try {
            const response = await GET_SERVICE(`/employer-ni-range-category-percentage`);
            if (response.status === 200) {
                setValueListEmployer(response?.data?.data)
            }
            setLoading(false)
        } catch (err) {
            return err.response;
        }
    }
    useEffect(() => {
        getCategory();
        getEmployeeRange()
        getEmployeerRange()
        getEmployeeValue()
        getEmployerValue()

    }, []);


    const saveCategories = async () => {
        setCategoryLoading(true)
        try {
            let updatedMessageIds = [];
            // Initialize a local array to store message IDs.

            const sortCategory = Sort(category)
            const sortCategoryId = Sort(categoryId)
            if (category.length > 0) {
                for (let index = 0; index < sortCategory.length; index++) {
                    const body = {
                        title: sortCategory[index],
                    };
                    const endpoint = `/ni-category/${sortCategoryId[index]}`;
                    const response = await PUT_SERVICE(endpoint, body);


                    updatedMessageIds.push(response.status === 200);
                }
            }

            if (categoryItem.length > 0) {
                for (let index = 0; index < categoryItem.length; index++) {
                    const body = {
                        title: categoryItem[index],
                    };
                    const endpoint = `/ni-category`;
                    const response = await POST_SERVICE(endpoint, body);


                    updatedMessageIds.push(response.status === 201);
                }
            }


            if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
                props.error("Not all categories were updated successfully");
                getCategory();
                setEditable(false);
                setCategory([]);
                setCategoryId([]);
                setCategoryItem([]);
                setSecondComponentCount(0)
                setCategoryLoading(false)
            }

            if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
                props.error("Categories were not updated");
                setCategoryLoading(false)
            }

            if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
                props.success("Categories were updated successfully");
                setSecondComponentCount(0)
                getCategory();
                setEditable(false);
                setCategory([]);
                setCategoryId([]);
                setCategoryItem([]);
                setCategoryLoading(false)

            }
        } catch (e) {
            return e.response;
        }
    };

    const saveRanges = async () => {
    setEmployeeLoading(true)
        try {
            let updatedMessageIds = []; // Initialize a local array to store message IDs.
            const newRange = minimumValueRange.map((item, index) => ({
                minimumValueRange: item,
                maximumValueRange: maximumValueRange[index]
            }))

            if (minimumValueRange.length > 0 || maximumValueRange.length > 0) {
                if (newRange.length > 0) {
                    const usedPositions = new Set();

                    for (let index = 0; index < newRange.length; index++) {
                        let randomPosition;

                        do {
                            randomPosition = Math.floor(Math.random() * 1000); // Adjust the upper limit as needed
                        } while (usedPositions.has(randomPosition));

                        usedPositions.add(randomPosition);

                        const body = {
                            "position": randomPosition,
                            "minimumValue": parseFloat(newRange[index].minimumValueRange),
                            "maximumValue": parseFloat(newRange[index].maximumValueRange),
                        };

                        const endpoint = `/employee-ni-range`;
                        const response = await POST_SERVICE(endpoint, body);

                       

                        updatedMessageIds.push(response.status === 201);
                    }
                } else {
                    props.error("Value Range Field cannot be empty");
                }
            }

            if (minValueRange.length > 0 || maxValueRange.length > 0) {


                for (let index = 0; index < rangeId.length; index++) {

                    if (rangeId[index] !== undefined) {

                        const body = {
                            "minimumValue": minValueRange[index] !== undefined ? parseFloat(minValueRange[index]) : employeeRange[index].minimumValue,
                            "maximumValue": maxValueRange[index] !== undefined ? parseFloat(maxValueRange[index]) : employeeRange[index].maximumValue
                        }
                        const endpoint = `/employee-ni-range/${rangeId[index]}`;


                        const response = await PUT_SERVICE(endpoint, body);

                        updatedMessageIds.push(response.status === 200);
                    }




                }
            }
            const valueRangeArrEdit = Object.values(valueRangeEdit)
            const valueIdArr = Object.values(valueId)

            if (valueRangeArrEdit.length > 0) {


                for (let index = 0; index < valueRangeArrEdit.length; index++) {
                    const body = {
                        "percentageValue": parseFloat(valueRangeArrEdit[index])
                    }
                   
                    const endpoint = `/employee-ni-range-category-percentage/${valueIdArr[index]}`

                    const response = await PUT_SERVICE(endpoint, body)
                    updatedMessageIds.push(response.status === 200);
                }

            }
            const valueRangeArr = Object.values(valueRange)
            const valueRangeIdCategoryArr = Object.values(valueRangeIdCategory)
            const valueRangeIdRangeArr = Object.values(valueRangeIdRange)

            if (valueRangeArr.length > 0) {
                for (let index = 0; index < valueRangeArr.length; index++) {
                    const body = {
                        "niRangeId": valueRangeIdRangeArr[index],
                        "niCategoryId": valueRangeIdCategoryArr[index],
                        "percentageValue": parseFloat(valueRangeArr[index])
                    }
               
                    const endpoint = `/employee-ni-range-category-percentage`
                    const response = await POST_SERVICE(endpoint, body)
                    updatedMessageIds.push(response.status === 201);
                }

            }





            if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
                props.error("Not all value ranges updated successfully");
                getEmployeeRange();
                setEditableEmployee(false);
                setMaxValueRange([]);
                setRangeId([]);
                setValueRangeEdit([])
                setValueId([])
                setMinValueRange([]);
                setMaximumValueRange([])
                setMininmumValueRange([])
                setSecondTableCount(0)
                getEmployeeValue()
                setEmployeeLoading(false)
            }

            if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
                props.error("Value Ranges were not updated");
                setEmployeeLoading(false)
            }

            if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
                props.success("All Value Ranges updated successfully");
                getEmployeeRange();
                setEditableEmployee(false);
                setMaxValueRange([]);
                setRangeId([]);
                setMinValueRange([]);
                setMaximumValueRange([])
                setMininmumValueRange([])
                setValueRangeEdit([])
                getEmployeeValue()
                setValueId([])
                setSecondTableCount(0)
                setEmployeeLoading(false)
            }
        }
        catch (e) {
            return e.response
        }
    }
    const saveRangesEmployer = async () => {
        setEmployerLoading(true)
        try {
            let updatedMessageIds = []; // Initialize a local array to store message IDs.
            const newRange = minimumValueRangeEmployer.map((item, index) => ({
                minimumValueRange: item,
                maximumValueRange: maximumValueRangeEmployer[index]
            }))



            if (minimumValueRangeEmployer.length > 0 || maximumValueRangeEmployer.length > 0) {

                if (newRange.length > 0) {

                    const usedPositions = new Set();

                    for (let index = 0; index < newRange.length; index++) {
                        let randomPosition;

                        do {
                            randomPosition = Math.floor(Math.random() * 1000); // Adjust the upper limit as needed
                        } while (usedPositions.has(randomPosition));



                        const body = {
                            "position": randomPosition,

                            "minimumValue": parseFloat(newRange[index].minimumValueRange),
                            "maximumValue": parseFloat(newRange[index].maximumValueRange)
                        };

                        const endpoint = `/employer-ni-range`;
                        const response = await POST_SERVICE(endpoint, body);



                        updatedMessageIds.push(response.status === 201);


                    }
                }
                else {

                    props.error("Value Range Field cannot be empty")
                }
            }



            if (minValueRangeEmployer.length > 0 || maxValueRangeEmployer.length > 0) {


                for (let index = 0; index < rangeIdEmployer.length; index++) {

                    if (rangeIdEmployer[index] !== undefined) {

                        const body = {
                            "minimumValue": minValueRangeEmployer[index] !== undefined ? parseFloat(minValueRangeEmployer[index]) : employeerRange[index].minimumValue,
                            "maximumValue": maxValueRangeEmployer[index] !== undefined ? parseFloat(maxValueRangeEmployer[index]) : employeerRange[index].maximumValue
                        }
              
                        const endpoint = `/employer-ni-range/${rangeIdEmployer[index]}`;
                        console.log(body)
                        console.log(endpoint)


                        const response = await PUT_SERVICE(endpoint, body);
                        

                        updatedMessageIds.push(response.status === 200);
                    }




                }
            }



            const valueRangeArrEdit = Object.values(valueRangeEditEmployer)
            const valueIdArr = Object.values(valueIdEmployer)


            if (valueRangeArrEdit.length > 0) {


                for (let index = 0; index < valueRangeArrEdit.length; index++) {
                    const body = {
                        "percentageValue": parseFloat(valueRangeArrEdit[index])
                    }
                    const endpoint = `/employer-ni-range-category-percentage/${valueIdArr[index]}`

                    const response = await PUT_SERVICE(endpoint, body)
                    updatedMessageIds.push(response.status === 200);
                }

            }


            const valueRangeArr = Object.values(valueRangeEmployer)
            const valueRangeIdCategoryArr = Object.values(valueRangeIdCategoryEmployer)
            const valueRangeIdRangeArr = Object.values(valueRangeIdRangeEmployer)


            if (valueRangeArr.length > 0) {



                for (let index = 0; index < valueRangeArr.length; index++) {
                    const body = {
                        "niRangeId": valueRangeIdRangeArr[index],
                        "niCategoryId": valueRangeIdCategoryArr[index],
                        "percentageValue": parseFloat(valueRangeArr[index])
                    }

                    const endpoint = `/employer-ni-range-category-percentage`


                    const response = await POST_SERVICE(endpoint, body)
                    updatedMessageIds.push(response.status === 201);
                }

            }





            if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
                props.error("Not all value ranges updated successfully");
                getEmployeerRange();
                setEditableEmployer(false);
                setMaxValueRangeEmployer([]);
                setRangeIdEmployer([]);
                setMinValueRangeEmployer([]);
                setMaximumValueRangeEmployer([])
                setMininmumValueRangeEmployer([])
                setSecondTableCountEmployer(0)
                setValueRangeEmployer([])
                getEmployerValue()
                setValueIdEmployer([])
                setValueListEmployer([])
                setValueRangeEditEmployer([])
                setValueRangeIdCategoryEmployer([])
                setValueRangeIdRangeEmployer([])
                setEmployerLoading(false)


            }

            if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
                props.error("Value Ranges were not updated");
                setEmployerLoading(false)
            }

            if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
                props.success("All Value Ranges updated successfully");
                getEmployeerRange();
                setEditableEmployer(false);
                setMaxValueRangeEmployer([]);
                setRangeIdEmployer([]);
                setMinValueRangeEmployer([]);
                setMaximumValueRangeEmployer([])
                setMininmumValueRangeEmployer([])
                setValueRangeEmployer([])
                getEmployerValue()
                setValueIdEmployer([])
                setValueListEmployer([])
                setValueRangeEditEmployer([])
                setValueRangeIdCategoryEmployer([])
                setValueRangeIdRangeEmployer([])



                setSecondTableCountEmployer(0)
                setEmployerLoading(false)
            }
        }
        catch (e) {
            return e.response
        }
    }


    const deleteRange = async (id) => {
        setEmployeeLoading(true)
        try {
            const endpoint = `/employee-ni-range/${id}`;
            const response = await DELETE_SERVICE(endpoint);
            if (response.status === 200) {
                props.success(response.data.message)
                getEmployeeRange()
                setEditableEmployee(false)


            }
            else {
                props.error(response.data.message)
            }
            setEmployeeLoading(false)
        }
        catch (e) {
            return e.response
        }
    }
    const deleteRangeEmployer = async (id) => {
        setEmployerLoading(true)
        try {
            const endpoint = `/employer-ni-range/${id}`;
            const response = await DELETE_SERVICE(endpoint);
            if (response.status === 200) {
                props.success(response.data.message)
                getEmployeerRange()
                setEditableEmployer(false)


            }
            else {
                props.error(response.data.message)
            }
            setEmployerLoading(false)
        }
        catch (e) {
            return e.response
        }
    }
    const deleteCategory = async (index, id) => {

        if (categoryId[index] !== null || index >= categoryData.length) {
            setCategoryLoading(true)
            try {
                if (id) {
                    const endpoint = `/ni-category/${id}`;
                    const response = await DELETE_SERVICE(endpoint);
                    if (response.status === 200) {
                        props.success(response.data.message)
                        getCategory()
                        setEditable(false)


                    }
                    else {
                        props.error(response.data.message)
                    }
                }

                // Remove the category and its corresponding empty form
                setCategoryData((prevData) => {
                    const updatedData = [...prevData];
                    updatedData.splice(index, 1);
                    return updatedData;
                });

                setCategoryId((prevIds) => {
                    const updatedIds = [...prevIds];
                    updatedIds.splice(index, 1);
                    return updatedIds;
                });

                // Decrease the componentCount to reflect the removal
                setComponentCount((prevCount) => prevCount - 1);
                setCategoryLoading(false)
            } catch (e) {
                console.error(e);
            }
        }
    };
    const deleteCategoryItem = () => {
        setComponentCount((prevCount) => prevCount - 1);

        if (categoryItem !== []) {
            setCategoryItem([])
        }

    }
    const deleteTableCount = () => {
        setSecondTableCount((prevCount) => prevCount - 1)
        if (minimumValueRange.length > 0) {
            setMininmumValueRange([])
        }
        if (maximumValueRange.length > 0) {
            setMaximumValueRange([])
        }
    }
    const deleteTableCountEmployer = () => {
        setSecondTableCountEmployer((prevCount) => prevCount - 1)
        if (minimumValueRangeEmployer.length > 0) {
            setMininmumValueRangeEmployer([])
        }
        if (maximumValueRangeEmployer.length > 0) {
            setMaximumValueRangeEmployer([])
        }
    }
    const addCategory = () => {
        setSecondComponentCount((prevCount) => prevCount + 1);


    };
    const addTable = () => {
        setSecondTableCount((prevCount) => prevCount + 1)
    }
    const addTableEmployer = () => {
        setSecondTableCountEmployer((prevCount) => prevCount + 1)
    }
    return (
        loading == true ?
            <Loader show={loading} />
            :
            <div className={styles.content}>

                <>
                    {categoryLoading ?
                        <ViewLoader />
                        :
                        <>

                <Form
                    show_header={true}
                    edit_option={true}
                    edit_option_style={styles.edit_icon}
                    header={
                        <div className={styles.content_header}>
                            <h1>NI Categories</h1>
                            <p>NI categories attached to each contractor</p>
                        </div>
                    }
                    line={true}
                    bulk="Edit NI Categories"
                    show_bulk={!editable}
                    bulk_action={() => setEditable(!editable)}
                />
                            <div className={styles.form_container}>

                                {Array.from({ length: componentCount }).map((_, index) => (
                                    <div className={styles.form_input} key={index}>
                                        <label>Category {index + 1}</label>
                                        <div className={styles.flex}>
                                            <input
                                                type="text"
                                                placeholder={categories[index]?.categoryTitle || ''}
                                                maxlength={1}
                                                value={
                                                    category[index] || ""

                                                }
                                                onChange={
                                                    (e) => {
                                                        setCategory((prevCategory) => {
                                                            const updatedCategory = [...prevCategory];
                                                            updatedCategory[index] = e.target.value; // Set umbrella name at the corresponding 
                                                            return updatedCategory;
                                                        }

                                                        );
                                                        const selectedCategory = categories.find((item) => item.id === categories[index].id);

                                                        if (selectedCategory) {

                                                            setCategoryId((prevIds) => {
                                                                const updatedIds = [...prevIds];
                                                                updatedIds[index] = selectedCategory.id; // Set umbrella ID at the 
                                                                return updatedIds;
                                                            });
                                                        }
                                                    }

                                                }

                                                disabled={editable ? "" : "disabled"}

                                            />
                                            {editable && categories.length > 1 && (
                                                <img src={del} alt="icon" onClick={() => deleteCategory(index, categories[index].id)} />
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {Array.from({ length: secondComponentCount }).map((_, index) => (
                                    <div className={styles.form_input} key={index}>
                                        <label>Category {index + componentCount + 1}</label>
                                        <div className={styles.flex}>
                                            <input
                                                type="text"
                                                placeholder={''}
                                                maxlength={1}
                                                value={
                                                    categoryItem[index] || ""

                                                }
                                                onChange={
                                                    (e) => {
                                                        setCategoryItem((prevCategory) => {
                                                            const updatedCategory = [...prevCategory];
                                                            updatedCategory[index] = e.target.value; // Set umbrella name at the corresponding index
                                                            return updatedCategory;
                                                        }

                                                        );


                                                    }

                                                }

                                                disabled={editable ? "" : "disabled"}

                                            />
                                            {editable && (
                                                <img src={del} alt="icon" onClick={() => deleteCategoryItem(index)} />
                                            )}
                                        </div>
                                    </div>
                                ))}

                            </div>

                            {editable && (
                                <div className={styles.add} style={{ justifyContent: "flex-start" }}>
                                    <button onClick={addCategory}><span>Add NI Category</span></button>
                                </div>
                            )}

                            {editable && (
                                <ViewButton
                                    cancel={() => setEditable(!editable)}
                                    save={saveCategories}
                                />
                            )}
                        </>
                    }
                </>


                <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                    <hr />
                </div>
                {employeeLoading ?
                <ViewLoader/>
                :
                <>
                                <Form
                    show_header={true}
                    edit_option={true}
                    edit_option_style={styles.edit_icon}

                    header={
                        <div className={styles.content_header}>
                            <h1>Employee’s NI </h1>
                            <p>Employee’s NI range deducted from contractors pay </p>
                        </div>
                    }
                    line={true}
                    bulk="Edit Employee’s NI"

                    show_bulk={editableEmployee === false ? true : false}
                    bulk_action={() => setEditableEmployee(!editableEmployee)}
                />
                <div className={styles.table}>
                    <table>
                        <tr>
                            <th>
                                NI Category
                            </th>


                            {Array.from({ length: tableCount }).map((_, index) => (
                                <th key={index}>
                                    <div className={styles.flex}>
                                        {editableEmployee === true ? (
                                            <div className={styles.table_head_input}>
                                                <input
                                                    type="number"
                                                    placeholder={employeeRange[index].minimumValue || "0"}
                                                    value={minValueRange[index] || ""}
                                                    onChange={(e) => {
                                                        setMinValueRange((prevValue) => {
                                                            const updatedValue = [...prevValue];

                                                            updatedValue[index] = e.target.value;
                                                            return updatedValue;
                                                        });

                                                        // Update rangeId at the corresponding index
                                                        const selectedRange = employeeRange[index];
                                                        if (selectedRange) {
                                                            setRangeId((prevIds) => {
                                                                const updatedIds = [...prevIds];
                                                                updatedIds[index] = selectedRange.id;
                                                                return updatedIds;
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span>to</span>
                                                <input
                                                    type="number"
                                                    placeholder={employeeRange[index].maximumValue || "0"}
                                                    value={maxValueRange[index] || ""}
                                                    onChange={(e) => {
                                                        setMaxValueRange((prevValue) => {
                                                            const updatedValue = [...prevValue];
                                                            updatedValue[index] = e.target.value;
                                                            return updatedValue;
                                                        });

                                                        // Update rangeId at the corresponding index
                                                        const selectedRange = employeeRange[index];
                                                        if (selectedRange) {
                                                            setRangeId((prevIds) => {
                                                                const updatedIds = [...prevIds];
                                                                updatedIds[index] = selectedRange.id;
                                                                return updatedIds;
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <span>£{employeeRange[index].minimumValue} to £{employeeRange[index].maximumValue}</span>
                                        )}
                                        {employeeRange.length > 1 && editableEmployee === true && (
                                            <img src={del} alt="icon" onClick={() => deleteRange(employeeRange[index].id)} />
                                        )}
                                    </div>
                                </th>
                            ))}


                            {Array.from({ length: secondTableCount }).map((_, index) => (
                                <th>


                                    <div className={styles.table_head_input} key={index}>
                                        <input
                                            placeholder='0'
                                            type="number" value={minimumValueRange[index] || ""} onChange={(e) =>
                                                setMininmumValueRange((prevValue) => {
                                                    const updatedValue = [...prevValue];
                                                    updatedValue[index] = e.target.value
                                                    return updatedValue
                                                }


                                                )} />
                                        <span>to</span>
                                        <input
                                            placeholder='0'
                                            type="number" value={maximumValueRange[index] || ""} onChange={(e) =>
                                                setMaximumValueRange((prevValue) => {
                                                    const updatedValue = [...prevValue];
                                                    updatedValue[index] = e.target.value
                                                    return updatedValue
                                                }


                                                )} />
                                        <img src={del} alt="icon" onClick={() => deleteTableCount(index)} />
                                    </div>

                                </th>
                            ))}
                        </tr>


                        {categories?.map((item, categoryIndex) => (

                            <tr key={categoryIndex}>
                                <td >{item.categoryTitle}</td>

                                {Array.from({ length: tableCount }).map((_, index) => {
                                    const uniqueKey = index + item.categoryTitle
                                    const valueListIndex = valueList.findIndex(
                                        (valueListItem) =>
                                            valueListItem.niCategoryId === item.id &&
                                            valueListItem.niRangeId === employeeRange[index].id
                                    );



                                    return (
                                        <td >
                                            {editableEmployee ? (
                                                <div className={styles.table_body_input}>

                                                    <input
                                                        type="number"
                                                        value={valueRange[valueListIndex]}
                                                        placeholder={valueList !== undefined && valueList[valueListIndex]?.percentageValue || "0"}
                                                        onChange={(e) => {


                                                            if (valueListIndex === -1 &&
                                                                valueList[valueListIndex]?.percentageValue === undefined) {


                                                                setValueRange((prevValue) => {
                                                                    return {
                                                                        ...prevValue,
                                                                        [uniqueKey]: e.target.value,
                                                                    };
                                                                });


                                                                setValueRangeIdCategory((prevValue) => {
                                                                    return {
                                                                        ...prevValue,
                                                                        [uniqueKey]: item.id
                                                                    };
                                                                })




                                                                setValueRangeIdRange((prevValue) => {
                                                                    return {
                                                                        ...prevValue,
                                                                        [uniqueKey]: employeeRange[index].id
                                                                    };
                                                                })

                                                            }
                                                            else {
                                                                setValueRangeEdit((prevValue) => ({
                                                                    ...prevValue,
                                                                    [valueListIndex]: e.target.value,
                                                                }));
                                                                setValueId((prevValue) => ({
                                                                    ...prevValue,
                                                                    [valueListIndex]: valueList[valueListIndex]?.id
                                                                }))
                                                            }

                                                        }}
                                                    />

                                                </div>
                                            ) : (
                                                <span>
                                                    {valueListIndex !== -1 && valueListIndex !== undefined && valueList[valueListIndex].percentageValue + "%"}
                                                </span>

                                            )}
                                        </td>
                                    )
                                }
                                )}
                                {Array.from({ length: secondTableCount }).map((_, index) => (
                                    <td key={index}></td>
                                ))}
                            </tr>

                        ))}





                    </table>
                </div>
                {editableEmployee && (
                    <div className={styles.add} style={{ justifyContent: "flex-start" }}>
                        <button onClick={addTable}><span>Add Range</span></button>
                    </div>
                )}
                {editableEmployee === true &&
                    <div style={{ marginTop: "50px", marginBottom: "50px" }}>

                        <ViewButton
                            cancel={() => { setEditableEmployee(!editableEmployee); setSecondTableCount(0); setMaximumValueRange([]); setMininmumValueRange([]); setValueRange([]); setValueList([]); setRangeId([]); }}
                            save={saveRanges}
                        />
                    </div>
                }
                </>

                }
                {employerLoading ?
                <ViewLoader/>
                :
                <>

<Form
                    show_header={true}
                    edit_option={true}
                    edit_option_style={styles.edit_icon}

                    header={
                        <div className={styles.content_header}>
                            <h1>Employer’s NI </h1>
                            <p>Employer’s NI range deducted from contractors pay </p>
                        </div>
                    }
                    line={true}
                    bulk="Edit Employer’s NI"

                    show_bulk={editableEmployer === false ? true : false}
                    bulk_action={() => setEditableEmployer(!editableEmployer)}
                />
                <div className={styles.table}>
                    <table>

                        <tr>
                            <th>
                                NI Category
                            </th>

                        
                            {Array.from({ length: tableCountEmployer }).map((_, index) => (
                                <th key={index}>
                                    <div className={styles.flex}>
                                        {editableEmployer === true ? (
                                            <div className={styles.table_head_input}>
                                                <input
                                                    type="number"
                                                    placeholder={employeerRange[index].minimumValue || "0"}
                                                    value={minValueRangeEmployer[index] || ""}
                                                    onChange={(e) => {
                                                        setMinValueRangeEmployer((prevValue) => {
                                                            const updatedValue = [...prevValue];

                                                            updatedValue[index] = e.target.value;
                                                            return updatedValue;
                                                        });

                                                        // Update rangeId at the corresponding index
                                                        const selectedRange = employeerRange[index];
                                                        if (selectedRange) {
                                                            setRangeIdEmployer((prevIds) => {
                                                                const updatedIds = [...prevIds];
                                                                updatedIds[index] = selectedRange.id;
                                                                return updatedIds;
                                                            });
                                                        }
                                                    }}
                                                />
                                                <span>to</span>
                                                <input
                                                    type="number"
                                                    placeholder={employeerRange[index].maximumValue || "0"}
                                                    value={maxValueRangeEmployer[index] || ""}
                                                    onChange={(e) => {
                                                        setMaxValueRangeEmployer((prevValue) => {
                                                            const updatedValue = [...prevValue];
                                                            updatedValue[index] = e.target.value;
                                                            return updatedValue;
                                                        });

                                                        // Update rangeId at the corresponding index
                                                        const selectedRange = employeerRange[index];
                                                        if (selectedRange) {
                                                            setRangeIdEmployer((prevIds) => {
                                                                const updatedIds = [...prevIds];
                                                                updatedIds[index] = selectedRange.id;
                                                                return updatedIds;
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <span>£{employeerRange[index].minimumValue} to £{employeerRange[index].maximumValue}</span>
                                        )}
                                        {employeerRange.length > 1 && editableEmployer === true && (
                                            <img src={del} alt="icon" onClick={() => deleteRangeEmployer(employeerRange[index].id)} />
                                        )}
                                    </div>
                                </th>
                            ))}


                            {Array.from({ length: secondTableCountEmployer }).map((_, index) => (
                                <th>


                                    <div className={styles.table_head_input} key={index}>
                                        <input
                                            placeholder='0'
                                            type="number" value={minimumValueRangeEmployer[index] || ""} onChange={(e) =>
                                                setMininmumValueRangeEmployer((prevValue) => {
                                                    const updatedValue = [...prevValue];
                                                    updatedValue[index] = e.target.value
                                                    return updatedValue
                                                }


                                                )} />
                                        <span>to</span>
                                        <input
                                            placeholder='0'
                                            type="number" value={maximumValueRangeEmployer[index] || ""} onChange={(e) =>
                                                setMaximumValueRangeEmployer((prevValue) => {
                                                    const updatedValue = [...prevValue];
                                                    updatedValue[index] = e.target.value
                                                    return updatedValue
                                                }


                                                )} />
                                        <img src={del} alt="icon" onClick={() => deleteTableCountEmployer(index)} />
                                    </div>

                                </th>
                            ))}

                        </tr>

                        {categories?.map((item, categoryIndex) =>
                            <tr>
                                <td key={categoryIndex}>
                                    {item.categoryTitle

                                    }
                                </td>

                                {Array.from({ length: tableCountEmployer }).map((_, index) => {
                                    const uniqueKey = index + item.categoryTitle
                                    const valueListEmployerIndex = valueListEmployer?.findIndex(
                                        (valueListItem) =>
                                            valueListItem.niCategoryId === item?.id &&
                                            valueListItem.niRangeId === employeerRange[index]?.id
                                    );


                                    return (
                                        <td >
                                            {editableEmployer ? (
                                                <div className={styles.table_body_input}>

                                                    <input
                                                        type="number"
                                                        value={valueRangeEmployer[valueListEmployerIndex]}
                                                        placeholder={valueListEmployerIndex !== -1 && valueListEmployer == undefined ? "" : valueListEmployer[valueListEmployerIndex]?.percentageValue || "0"}
                                                        onChange={(e) => {

                                                            if (valueListEmployerIndex === -1 &&
                                                                valueListEmployer[valueListEmployerIndex]?.percentageValue === undefined) {


                                                                setValueRangeEmployer((prevValue) => {
                                                                    return {
                                                                        ...prevValue,
                                                                        [uniqueKey]: e.target.value,
                                                                    };
                                                                });

                                                                setValueRangeIdCategoryEmployer((prevValue) => {
                                                                    return {
                                                                        ...prevValue,
                                                                        [uniqueKey]: item.id
                                                                    };
                                                                })


                                                                setValueRangeIdRangeEmployer((prevValue) => {
                                                                    return {
                                                                        ...prevValue,
                                                                        [uniqueKey]: employeerRange[index].id
                                                                    };

                                                                })

                                                            }
                                                            else {
                                                                setValueRangeEditEmployer((prevValue) => ({
                                                                    ...prevValue,
                                                                    [valueListEmployerIndex]: e.target.value,
                                                                }));
                                                                setValueIdEmployer((prevValue) => ({
                                                                    ...prevValue,
                                                                    [valueListEmployerIndex]: valueListEmployer[valueListEmployerIndex]?.id
                                                                }))
                                                            }

                                                        }}
                                                    />

                                                    {/* } */}
                                                </div>
                                            ) : (
                                                <span>
                                                    {valueListEmployerIndex !== -1 && valueListEmployer[valueListEmployerIndex]?.percentageValue + "%"}
                                                </span>

                                            )}
                                        </td>
                                    )
                                }
                                )}
                                {Array.from({ length: secondTableCount }).map((_, index) => (
                                    <td key={index}></td>
                                ))}
                            </tr>
                        )

                        }


                    </table>
                </div>
                {editableEmployer && (
                    <div className={styles.add} style={{ justifyContent: "flex-start" }}>
                        <button onClick={addTableEmployer}><span>Add Range</span></button>
                    </div>
                )}
                {editableEmployer === true &&
                    <div style={{ marginTop: "50px", marginBottom: "50px" }}>


                        <ViewButton
                            cancel={() => {

                                setEditableEmployer(!editableEmployer);
                                setSecondTableCountEmployer(0);
                                setMaxValueRangeEmployer([]);
                                setMinValueRangeEmployer([]);
                                setMaximumValueRangeEmployer([]);
                                setMininmumValueRangeEmployer([])
                            }}
                            save={saveRangesEmployer}
                        />
                    </div>
                }
                </>

                }

            </div>



    );
};

export default Ni;
import React, {useState, useEffect} from 'react';

//component imports
import { GET_SERVICE,POST_SERVICE } from "../Services/Backend";
import ViewLoader from '../Common/ViewLoader';
import Table from '../Common/Table';
import NoContent from '../Common/NoContent';
import Tooltip from '../Common/Tooltip';
import Select from '../Common/Select';
 
//resource import
import contractorIcon from "../../Assets/payslip_icon_green.svg";
import DateFormat from '../Utils/DateFormat';
// import view from "../../Assets/view.svg";
// import download from "../../Assets/download_icon.svg";

//styles import
import styles from './css/payroll.module.css'


const Payslips = (props) => {
    const [payslips, setPayslips] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [umbrella, setUmbrella] = useState("")
    const [umbrellaList, setUmbrellaList] = useState("")
    const [umbrellaId, setUmbrellaId] = useState("")
    const [date, setDate] = useState("")
    const [element, setElement] = useState(10)
    const [totalElement, setTotalElement] = useState(10)
    const [pageNumber, setPageNumber] = useState()
  
    const payslipsHeaders = [
        { key: 'fullName', label: 'Contractor Name' },
        { key: 'niNumber', label: 'Pay Period' },
        { key: 'emailAddress', label: 'Umbrella' },
        { key: 'Timesheets', label: 'Timesheet(s)' },
        { key: 'Gross', label: 'Gross' },
     
 
      ]
      const getPayslips = async (page) => {
        setLoading(true)
        try {
          const response = await GET_SERVICE(`/payslips?page=${page || 0}&size=10`);

     
     
    
          if (response.status === 200) {
            setPayslips(response.data.data.payslipDaoList)
            // const filteredTimesheets = response.data.data.payslipDaoList.filter(timesheet => !timesheet.isApproved);
    
        
            setPageNumber(page || 0)
            setPageCount(response.data.data.page)
            setTotalElement(response.data.data.size)
            setLoading(false)
           
    
           
          }
          else {
          
            setLoading(false)
          }
    
    
        } catch (err) {
    
          return err.response;
        }
      }
      const getUmbrellaList = async (page) => {
          
        try {
          const response = await GET_SERVICE(`/umbrellas/un-formatted`);
          console.log(response, "umbrella")
     
     
    
          if (response.status === 200) {
      
    
        
            setUmbrellaList(response.data.data)
    
          }
         
    
        } catch (err) {
    
          return err.response;
        }
      }
  const clearField = ()=>{
    setDate("")
    setUmbrella("")
    setUmbrellaId("")



  }
      const postFilter = async (page) => {
        console.log('dd')
        setLoading(true)
        const url =`/payslips/filters?page=0&size=10&type=ALL`
        const body={
 
          "payPeriod": DateFormat(date) || null,
          "umbrellaId": umbrellaId || null,
        }
   
        try {
          const response = await POST_SERVICE(url, body);
      
          
          if (response.status===200){
            setPayslips(response.data.data.payslipDaoList);
            setLoading(false)
            setPageNumber(page || 0)
            
           
       
            setPageCount(response.data.data.numberOfPages )
            setElement(response.data.data.numberOfElements)
          } else{
         
            setLoading(false)
          }
     
     
    
    
     
        } catch (err) {
          return err.response;
        }
      };
      const getMorePayslips = async (option) => {
        setLoading(true)
        try {
          const response = await GET_SERVICE(`/payslips?page=${pageNumber || 0}&size=${option}`);
    
          if (response.status === 200) {
            setPayslips(response.data.data.payslipDaoList);
    
            setPageCount(response.data.data.totalPages)
            setElement(option)
    
    
            setLoading(false)
          }
          else {
            setPayslips([])
            setLoading(false)
          }
    
    
        } catch (err) {
    
          return err.response;
        }
      };
      const nextPage = (e) => {
    
        getPayslips(e.selected)
    
    
    
      }
     
      useEffect(() => {
        getPayslips();
        getUmbrellaList()
      }, []);
    return (
        <>
        {payslips?.length > 0 ?
          loading === true ?
            <ViewLoader />
            :
            <Table
            row={element}
            show_add={false}
            action="Approve All"
            showSearchBar={true}
            add_image={styles.add_image}
            row_list={
              totalElement >= 30
              ? [10, 20, 30]
              : totalElement >= 20
              ? [10, 20]
              : totalElement >= 10
              ? [10]
              : []
            }
            row_item_click={(option) => getMorePayslips(option)}
            pageCount={pageCount}
            pageNumber={pageNumber}
            pageChange={nextPage}
            columns={payslipsHeaders}
            filterContent={
              <div>
                <div className={styles.filter}>
                  <div className={styles.select_input}>
                    <label>Umbrella</label>
                    <div className={styles.custom_select_container}>
                    <Select
                          disabled=""
                          value={umbrella}
                     
                        
                          onChange={(option) => {
                            setUmbrella(option)
                            const selectedUmbrella = umbrellaList.find((item) => item.name === option);
                            if (selectedUmbrella) {
                              setUmbrellaId(selectedUmbrella?.id);
                     
                            }
                          
                          }}
                          options={umbrellaList.map((umbrella) =>
                            umbrella.name
                          )}
                        />
                    </div>
                  </div>
                  <div className={styles.form_input}>
                    <label>Pay Period</label>
                    <div className={styles.input_container}>
                      <input type="date" value={date} onChange={(e)=>setDate(e.target.value)}/>
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button className={styles.clear} onClick={clearField}>Cancel</button>
                  <button className={styles.solid} onClick={postFilter} >
                    Save
                  </button>
                </div>
              </div>
            }
            body={payslips.map((row, index) =>
            
              <tr key={index}>


              <td>
                {row.contractorsName}

              </td>
              <td>
                {row.payPeriod}

              </td>
              <td>
                {row.umbrella}

              </td>
              <td>
                {row.numberOfTimeSheets}

              </td>
              <td>
                £{row.gross}
              </td>
              {row.isApproved===true?
              
              <td className={styles.green}>
                Approved
              </td>
              :
              <td className={styles.red} onClick={()=>props.approve(row.id)}>
                Approve Payslip
              </td>
              }
         
            </tr>
            )
            }
      
            />
          :
          loading === true ?
            <ViewLoader />
            :
            <>
              <NoContent
                image={contractorIcon}
                text="No Payslips"
                hide={true}
                change={{ margin: "5% 0" }}

              />
            </>
        }
      </>
    );
};

export default Payslips;
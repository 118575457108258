import React, {useEffect, useState} from "react";
import { NavLink, useLocation } from "react-router-dom";

//import resources

import overview from "../../Assets/overview.svg";
import overview_white from "../../Assets/overview-white.svg";
import contractor_white from "../../Assets/contractors-white.svg";
import contractor from "../../Assets/contractors.svg";
import agency from "../../Assets/agency-icon.svg";
import agency_white from "../../Assets/agency-white.svg";
import multi from "../../Assets/multi.svg";
import multi_white from "../../Assets/multi-white.svg"
import timesheet_white from "../../Assets/timesheet-white.svg"
import payroll_white from "../../Assets/payroll-white.svg"
import settings_white from "../../Assets/settings-white.svg"
import timesheet from "../../Assets/timesheet-black.svg";
import payroll from "../../Assets/payroll.svg";
import settings from "../../Assets/settings.svg";
import back from "../../Assets/side.svg";
import down from "../../Assets/down-white.svg";

//import styles
import styles from "./css/sidebar.module.css";

const Sidebar = (props) => {


  const location = useLocation();
  const [hover, setHover] = useState(false)
  return (
    <div className={props.toggle ? styles.half_sidebar : styles.sidebar}>

      <ul>
        <NavLink
          to="/overview"
          className={({ isActive }) =>
            isActive ? styles.active : styles.inactive
          }
        >
          <li>

            <img
              className={styles.icon}
              src={
                location.pathname === "/overview" ? overview_white : overview
              }
              alt="overview"
            />
            <span>
              <p>Overview</p>
            </span>
            <img
              src={location.pathname === "/overview"  ? down : back}
              alt="<"
              className={
                location.pathname === "/overview"
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
      
          </li>
        </NavLink>

        <NavLink
          to="/contractor"
          className={({ isActive }) =>
            isActive ? styles.active : styles.inactive
          }
          onMouseEnter={()=>setHover(true)} 
        >
          <li>
            <img
              className={styles.icon}
              src={
                location.pathname === "/contractor" || location.pathname === "/contractor/add" || location.pathname === "/contractor/next" || location.pathname === "/contractor/view"
                  ? contractor_white
                  : contractor
              }
              alt="contactor"
            />
            <span>
              <p>Contractors</p>
            </span>
            <img
              src={location.pathname === "/contractor" || location.pathname === "/contractor/add" || location.pathname === "/contractor/next" || location.pathname === "/contractor/view" ? down : back}
              alt="<"
              className={
                location.pathname === "/contractor" || location.pathname === "/contractor/add" || location.pathname === "/contractor/next" || location.pathname === "/contractor/view"
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
          </li>
        </NavLink>

        {(location.pathname === "/contractor" || location.pathname === "/contractor/add" || location.pathname === "/contractor/next" || location.pathname === "/contractor/view") && (
          <ul className={hover?styles.hoverlist:styles.sidelist} onMouseLeave={()=>setHover(false)}>
            <NavLink
              to="/contractor"
              className={({ isActive }) =>
                (isActive && location.pathname !== "/contractor/add" && location.pathname !== "/contractor/next")
                  ? styles.side_active
                  : styles.side_inactive
              }
            >
              <li>Contractors List</li>
            </NavLink>
            <NavLink
              to="/contractor/add"
              className={({ isActive }) =>
                (isActive || location.pathname === "/contractor/add" || location.pathname === "/contractor/next")
                  ? styles.side_active
                  : styles.side_inactive
              }
            >

              <li>New Contractors</li>
            </NavLink>
          </ul>
        )}


        <NavLink
          to="/agency"
          className={({ isActive }) =>
            isActive ? styles.active : styles.inactive
          }
          onMouseEnter={()=>setHover(true)} 
        >
          <li>
            <img
              className={styles.icon}
              src={
                location.pathname === "/agency" || location.pathname === "/agency/add" || location.pathname === "/agency/next" || location.pathname === "/agency/view"
                  ? agency_white
                  : agency
              }
              alt="agency"
            />
            <span>
              <p>Agencies</p>
            </span>
            <img
              src={location.pathname === "/agency" || location.pathname === "/agency/add" || location.pathname === "/agency/next" || location.pathname === "/agency/view" ? down : back}
              alt="<"
              className={
                location.pathname === "/agency" || location.pathname === "/agency/add" || location.pathname === "/agency/next" || location.pathname === "/agency/view"
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
          </li>

        </NavLink>
        {(location.pathname === "/agency" || location.pathname === "/agency/add" || location.pathname === "/agency/next" || location.pathname === "/agency/view") && (
          <ul className={hover?styles.hoverlist:styles.sidelist} onMouseLeave={()=>setHover(false)}>
            <NavLink
              to="/agency"
              className={({ isActive }) =>
                (isActive && location.pathname !== "/agency/add" && location.pathname !== "/agency/next")
                  ? styles.side_active
                  : styles.side_inactive
              }
            >
              <li>Agency List</li>
            </NavLink>
            <NavLink
              to="/agency/add"
              className={({ isActive }) =>
                (isActive || location.pathname === "/agency/add" || location.pathname === "/agency/next")
                  ? styles.side_active
                  : styles.side_inactive
              }
            >

              <li>New Agency</li>
            </NavLink>
          </ul>
        )}

        <NavLink
          to="/umbrella"
          className={({ isActive }) =>
            isActive ? styles.active : styles.inactive
          }
          onMouseEnter={()=>setHover(true)} 
        >
          <li>
            <img className={styles.icon} src={location.pathname === "/umbrella" || location.pathname === "/umbrella/add" || location.pathname === "/umbrella/view" ? multi_white : multi} alt="multi-entry" />
            <span>
              <p>Multi-Entity</p>
            </span>

            <img
              src={location.pathname === "/umbrella" || location.pathname === "/umbrella/add" || location.pathname === "/umbrella/view" ? down : back}
              alt="<"
              className={
                location.pathname === "/umbrella" || location.pathname === "/umbrella/add" || location.pathname === "/umbrella/view"
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
          </li>
        </NavLink>
        {(location.pathname === "/umbrella" || location.pathname === "/umbrella/add" || location.pathname === "/umbrella/view") &&
          <ul className={hover?styles.hoverlist:styles.sidelist} onMouseLeave={()=>setHover(false)}>
            <NavLink
              to="/umbrella"
              className={({ isActive }) =>
                (isActive && location.pathname !== "/umbrella/add")
                  ? styles.side_active
                  : styles.side_inactive
              }
            >
              <li>Umbrella</li>
            </NavLink>
            <NavLink
              to="/umbrella/add"
              className={({ isActive }) =>
                isActive ? styles.side_active : styles.side_inactive
              }
            >
              <li>New Umbrella</li>
            </NavLink>
          </ul>

        }
        <NavLink
          to="/timesheet"
          className={({ isActive }) =>
            isActive ? styles.active : styles.inactive
          }
          onMouseEnter={()=>setHover(true)} 
        >
          <li>
            <img className={styles.icon} src={location.pathname === "/timesheet" || location.pathname === "/timesheet/contractors" ||location.pathname === "/timesheet/add" || location.pathname === "/timesheet/view"?timesheet_white:timesheet} alt="timesheet" />
            <span>
              <p>Timesheet</p>
            </span>
            <img
              src={location.pathname === "/timesheet"|| location.pathname === "/timesheet/contractors"  || location.pathname === "/timesheet/add" || location.pathname === "/timesheet/view" ? down : back}
              alt="<"
              className={
                location.pathname === "/timesheet" || location.pathname === "/timesheet/add" || location.pathname === "/timesheet/view" || location.pathname === "/timesheet/contractors" 
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
          </li>
        </NavLink>
        {(location.pathname === "/timesheet" || location.pathname === "/timesheet/add" || location.pathname === "/timesheet/view" ||location.pathname=== "/timesheet/contractors") &&
          <ul className={hover?styles.hoverlist:styles.sidelist} onMouseLeave={()=>setHover(false)}>
            <NavLink
              to="/timesheet"
              className={({ isActive }) =>
                (isActive && location.pathname !== "/timesheet/add" ||location.pathname==="/timesheet/contractors" )
                  ? styles.side_active
                  : styles.side_inactive
              }
            >
              <li>Timesheet List</li>
            </NavLink>
            <NavLink
              to="/timesheet/add"
              className={({ isActive }) =>
                isActive ? styles.side_active : styles.side_inactive
              }
            >
              <li>New Timesheet</li>
            </NavLink>
          </ul>

        }
           <NavLink
          to="/payroll"
          className={({ isActive }) =>
            isActive ? styles.active : styles.inactive
          }
          onMouseEnter={()=>setHover(true)} 
        >
          <li>
            <img className={styles.icon} src={location.pathname === "/payroll" || location.pathname==="/payroll/configuration" ?payroll_white:payroll} alt="payroll" />
            <span>
              <p>Payroll</p>
            </span>
            <img
              src={location.pathname === "/payroll" || location.pathname==="/payroll/configuration"  ? down : back}
              alt="<"
              className={
                location.pathname === "/payroll" || location.pathname==="/payroll/configuration" 
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
          </li>
        </NavLink>
        {(location.pathname === "/payroll" || location.pathname === "/payroll/configuration" ) &&
          <ul className={hover?styles.hoverlist:styles.sidelist} onMouseLeave={()=>setHover(false)}>
            <NavLink
              to="/payroll"
              className={({ isActive }) =>
                (isActive && location.pathname !== "/payroll/configuration"  )
                  ? styles.side_active
                  : styles.side_inactive
              }
            >
              <li>Payslip</li>
            </NavLink>
            <NavLink
              to="/payroll/configuration"
              className={({ isActive }) =>
                isActive ? styles.side_active : styles.side_inactive
              }
            >
              <li>Payroll Configuration</li>
            </NavLink>
          </ul>

        }

        <NavLink
          to="/profile"
          className={({ isActive }) =>
            (isActive || location.pathname === "/profile" || location.pathname === "/administrators" || location.pathname === "/timeline" || location.pathname === "/administrators/add" || location.pathname === "/report"|| location.pathname === "/report/view") ? styles.active : styles.inactive
          }
          onMouseEnter={()=>setHover(true)} 
        >
          <li>
            <img className={styles.icon} src={location.pathname === "/profile" || location.pathname === "/administrators" || location.pathname === "/timeline" || location.pathname === "/administrators/add" ||  location.pathname === "/report/view"||location.pathname === "/report"? settings_white:settings} alt="settings" />
            <span>
              <p>Settings</p>
            </span>
            <img
              src={location.pathname === "/profile" || location.pathname === "/administrators" || location.pathname === "/timeline" || location.pathname === "/administrators/add" || location.pathname === "/report" || location.pathname === "/report/view" ? down : back}
              alt="<"
              className={
                location.pathname === "/profile" || location.pathname === "/administrators" || location.pathname === "/timeline" || location.pathname === "/administrators/add" || location.pathname === "/report"  || location.pathname === "/report/view"
                  ? styles.backIcon_white
                  : styles.backIcon
              }
            />
          </li>
        </NavLink>

        {(location.pathname === "/profile" || location.pathname === "/administrators" || location.pathname === "/timeline" || location.pathname === "/administrators/add" || location.pathname === "/report" || location.pathname === "/report/view") && (
          <ul className={hover?styles.hoverlist:styles.sidelist} onMouseLeave={()=>setHover(false)}>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive
                  ? styles.side_active
                  : styles.side_inactive
              }
            >
              <li>Profile</li>
            </NavLink>
            <NavLink
              to="/administrators"
              isActive={(match, location) =>
                location.pathname === "/administrators"
              }
              className={({ isActive }) =>
                isActive ? styles.side_active : styles.side_inactive
              }
            >
              <li>Administrators</li>
            </NavLink>
            <NavLink
              to="/timeline"
              isActive={(match, location) =>
                location.pathname === "/timeline"
              }
              className={({ isActive }) =>
                isActive ? styles.side_active : styles.side_inactive
              }
            >
              <li>Activity Timeline</li>
            </NavLink>
            <NavLink
              to="/report"
              isActive={(match, location) =>
                location.pathname === "/report" || location.pathname === "/report/view"
              }
              className={({ isActive }) =>
                isActive ? styles.side_active : styles.side_inactive
              }
            >
              <li>Reports</li>
            </NavLink>
          </ul>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState , useRef} from 'react';

// style import
import styles from "./css/selectsearch.module.css";
import search_icon from "../../Assets/search-icon.png";

const SelectSearch = ({ options, value, onChange, error, disabled, search, searchInput, loadMore, click, searchAction }) => {
  const [showOptions, setShowOptions] = useState(false);



  const toggle = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionChange = (option) => {
    onChange(option);
    setShowOptions(false);
  };
  const nothing = ()=>{

  }

  const hideOptions = () => {
    setShowOptions(false);
  };

  return (
    <div className={styles.custom_select}>
      {error ? (
        <>
          <div className={disabled === "disabled" ? styles.disabled_option : styles.error_input} onClick={disabled === "disabled" ? nothing : toggle}>
            {value || <span style={{ color: "#8e8e8e" }}>Select an option</span>}
          </div>

          {showOptions && (
            <ul className={styles.options} onMouseLeave={hideOptions}>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionChange(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <>
          <div className={disabled === "disabled" ? styles.disabled_option : styles.selected_option} onClick={disabled === "disabled" ? nothing : toggle} >
            {value || <span style={{ color: "#8e8e8e" }}>Select an option</span>}
          </div>
 
          {showOptions && (
            <ul className={styles.options} onMouseLeave={hideOptions} >
              <div className={styles.searchBar} style={{marginTop:"10px"}}>
                <img src={search_icon} alt={search_icon} />
                 <form onSubmit={searchAction}
>
  
<input
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={searchInput}
                />
  </form>              </div>
              {options.map((option) => (
                <>
                  <li key={option} onClick={() => handleOptionChange(option)}>
                    {option}
                  </li>
                </>
              ))}
              {loadMore ===true &&
              <li style={{ fontWeight: "600", color:"gray", fontSize:"13px", cursor:"pointer", paddingLeft:"10px"}} onClick={click}>Load More...</li>

              }
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default SelectSearch;

import React from 'react';

//import styles
import styles from "./css/card.module.css"

const Card = (props) => {
    return (
        <div className={styles.card}>
            <img src={props.icon} className={styles.icon} alt={props.icon}/>
            <div className={styles.cardContent}> 
                <h1>{props.number}</h1>
                <p>{props.text}</p>
            </div>
        </div>
    );
};

export default Card;
import React, { useState, useEffect } from 'react';


//component import
import Form from "../Common/Form"
import Table from "../Common/Table"
import { GET_SERVICE, POST_SERVICE, PUT_SERVICE, DELETE_SERVICE } from '../Services/Backend';
import ViewButton from '../Common/ViewButton';
import Loader from '../Common/Loader';
import ViewLoader from '../Common/ViewLoader';
import del from "../../Assets/delete.svg";
import Sort from '../Utils/Sort';
//styles import
import styles from './css/margin.module.css'

const Tax = (props) => {
  const [taxRanges, setTaxRanges] = useState([


  ])
 
  const [componentCount, setComponentCount] = useState(0);
  const [editable, setEditable] = useState(false)
  const [editableTax, setEditableTax] = useState(false)
  const [editableMinimum, setEditableMinimum] = useState(false)
  const [editableDate, setEditableDate] = useState(false)
  const [minimumWage, setMinimumWage] = useState([])
  const [minimumWageValue, setMinimumWageValue] = useState([])
  const [brandName, setBrandName] = useState([])
  const [minimumTaxableIncome, setMinimumTaxableIncome] = useState([])
  const [maximumTaxableIncome, setMaximumTaxableIncome] = useState([])
  const [percentageTaxRate, setPercentageTaxRate] = useState([])
  const [brandNameEdit, setBrandNameEdit] = useState([])
  const [minimumTaxableIncomeEdit, setMinimumTaxableIncomeEdit] = useState([])
  const [maximumTaxableIncomeEdit, setMaximumTaxableIncomeEdit] = useState([])
  const [percentageTaxRateEdit, setPercentageTaxRateEdit] = useState([])
  const [taxRangeId, setTaxRangeId] = useState([])
  const [taxCodes, setTaxCodes] = useState([])
  const [taxCode, setTaxCode] = useState([])
  const [taxCodeId, setTaxCodeId] = useState([])
  const [taxItem, setTaxItem] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [secondComponentCount, setSecondComponentCount] = useState(0)
  const [tableCount, setTableCount] = useState(1)
  const [secondTableCount, setSecondTableCount] = useState(0)
  const [startDateEdit, setStartDateEdit] = useState("")
  const [endDateEdit, setEndDateEdit] = useState("")

  const [taxRangeLoading, setTaxRangeLoading] = useState(false)
const [taxCodeLoading, setTaxCodeLoading] = useState(false)
const [minimumLoading,setMinimumLoading] = useState(false)
const [loading,setLoading] = useState(false)
const [dateLoading,setDateLoading] = useState(false)
  const getTaxCode = async () => {
    setLoading(true)
    try {
      const response = await GET_SERVICE("/tax-codes")
      if (response.status === 200) {
        setTaxCodes(response?.data?.data);
        
       
          setComponentCount(response.data.data.length)
  
       
      
      }
      else{
        setSecondComponentCount(1)
      }
   setLoading(false)


    } catch (err) {
      return err.response
    }

  }
  const saveTaxCodes = async () => {
    setTaxCodeLoading(true)
    try {
      let updatedMessageIds = []; // Initialize a local array to store message IDs.

      if (taxCode.length > 0) {
        for (let index = 0; index < taxCode.length; index++) {
          const body = {
            name: taxCode[index],
          };
          const endpoint = `/tax-codes/${taxCodeId[index]}`;
          const response = await PUT_SERVICE(endpoint, body);

          updatedMessageIds.push(response.status === 200);
        }
      }

      if (taxItem.length > 0) {
        for (let index = 0; index < taxItem.length; index++) {
          const body = {
            "name": taxItem[index],
          };
          const endpoint = `/tax-codes`;
          const response = await POST_SERVICE(endpoint, body);


          // Update the local array with true or false based on response.status.
          updatedMessageIds.push(response.status === 201);
        }
      }
 

      if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
        props.error("Not all Tax Codes were updated successfully");
        getTaxCode()
        setEditableTax(false);
        setTaxItem([])
        setTaxCode([])
        setTaxCodeId([])
        setSecondComponentCount(0)
        setTaxCodeLoading(false)
      }

      if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
        props.error("Tax Codes were not updated");
        setTaxCodeLoading(false)
      }

      if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
        props.success("Tax Codes were updated successfully");
        getTaxCode()
        setEditableTax(false);
        setTaxItem([])
        setTaxCode([])
        setTaxCodeId([])
        setSecondComponentCount(0)
        setTaxCodeLoading(false)

      }
    } catch (e) {
      return e.response;
    }
  };
  const getStartDate = async () => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/base-configuration/tax-year-start-date`);
      if (response.status === 200) {
        setStartDateEdit(response?.data?.data?.value)

      }

      setLoading(false)

    } catch (err) {
      return err.response
    }
  }
  const getEndDate = async () => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/base-configuration/tax-year-end-date`);
      if (response.status === 200) {
        setEndDateEdit(response?.data?.data?.value)

      }

     setLoading(false)

    } catch (err) {
      return err.response
    }
  }
  const getTaxRange = async () => {
    setLoading(true)
    try {
      const response = await GET_SERVICE(`/tax-range`);
      if (response.status === 200) {
        setTaxRanges(response?.data?.data);
        setTableCount(response?.data?.data.length)
      }

     setLoading(false)

    } catch (err) {
      return err.response
    }
  }

  const addSecondTable = () => {
    setSecondTableCount((prevCount) => prevCount + 1)
  }

  const getMinimumWage = async () => {
    setLoading(true)
    try {

      const response = await GET_SERVICE(`/base-configuration/minimum-wage`);
      if (response.status === 200) {
        setMinimumWage(response?.data?.data?.value);

      }
      setLoading(false)

    } catch (err) {
      return err.response
    }
  };

  const saveMinimumWage = async () => {
    setMinimumLoading(true)
    const body = {
      "value": minimumWageValue
    };
    const endpoint = `/base-configuration/minimum-wage`;
    try {
      const response = await POST_SERVICE(endpoint, body);
      if (response.status === 200) {
        props.success(response.data.message)
        getMinimumWage()
        setMinimumWageValue("")
        setEditableMinimum(false)
      }
      else {
        props.error(response.data.message)
      }
      setMinimumLoading(false)
    }
    catch (err) {
      return err.response
    }
  }
  const deleteTaxRangeItem = async (index, id) => {
    setTaxRangeLoading(true)

    if (taxRangeId[index] !== null) {
      try {
        if (id) {
          const endpoint = `/tax-range/${id}`;
          const response = await DELETE_SERVICE(endpoint);
          if (response.status === 200) {
            props.success(response.data.message)
            getTaxRange()
            setEditable(false)


          }
          else {
            props.error(response.data.message)
          }
        }

        // Remove the category and its corresponding empty form

         setTaxRangeLoading(false)


      } catch (e) {
        console.error(e);
      }
    }
  }
  const deleteTaxCode = async (index, id) => {
    setTaxCodeLoading(true)

    if (taxCodeId[index] !== null) {
      try {
        if (id) {
          const endpoint = `/tax-codes/${id}`;
          const response = await DELETE_SERVICE(endpoint);
          if (response.status === 200) {
            props.success(response.data.message)
            getTaxCode()
            setEditableTax(false)


          }
          else {
            props.error(response.data.message)
          }
        }

        // Remove the category and its corresponding empty form


        setTaxCodeId((prevIds) => {
          const updatedIds = [...prevIds];
          updatedIds.splice(index, 1);
          return updatedIds;
        });

        // Decrease the componentCount to reflect the removal
        setSecondComponentCount((prevCount) => prevCount - 1);
        setTaxCodeLoading(false)
      } catch (e) {
        console.error(e);
      }
    }
  };

  const deleteTaxItem = (index) => {
    setSecondComponentCount((prevCount) => prevCount - 1)
    if (taxItem.length > 0) {
      taxItem.pop(index)
    }

  }
  const deleteTaxRange = (index) => {
    if (brandName.length > 0) {
      brandName.pop()
    }
    if (minimumTaxableIncome.length > 0) {
      minimumTaxableIncome.pop()
    }
    if (maximumTaxableIncome.length > 0) {
      maximumTaxableIncome.pop()
    }
    if (percentageTaxRate.length > 0) {
      percentageTaxRate.pop()
    }
    setSecondTableCount((prevCount) => prevCount - 1)
  }
  const addTaxCode = () => {
    setSecondComponentCount((prevCount) => prevCount + 1);


  };

  const addTaxRanges = async () => {
    setTaxRangeLoading(true)

    let updatedMessageIds = [];

    try {

      const mergedData = brandName.map((item, index) => ({
        brandName: item,
        minimumTaxableIncome: minimumTaxableIncome[index],
        maximumTaxableIncome: maximumTaxableIncome[index],
        percentageTaxRate: percentageTaxRate[index],

      }));
      console.log(mergedData)



      if (mergedData.length > 0) {
       
        const endpoint = `/tax-range`;
        for (let index = 0; index < mergedData.length; index++) {

          const body = {
            "bandName": mergedData[index].brandName,
            "minimumTaxableIncome": parseFloat(mergedData[index].minimumTaxableIncome),
            "maximumTaxableIncome": parseFloat(mergedData[index].maximumTaxableIncome),
            "percentageTaxRate": parseFloat(mergedData[index].percentageTaxRate)
          };

         

          const response = await POST_SERVICE(endpoint, body);

        
          
          updatedMessageIds.push(response.status === 201);


        }
      }
      

      const taxRangeIdSort = Sort(taxRangeId)
      const brandNameEditSort = Sort(brandNameEdit)
      const minimumTaxableIncomeEditSort = Sort(minimumTaxableIncomeEdit)
      const maximumTaxableIncomeEditSort = Sort(maximumTaxableIncomeEdit)
      const percentageTaxRateEditSort = Sort(percentageTaxRateEdit)
      
      const mergedDataEdit = taxRangeIdSort.map((item, index) => {
        const correspondingTaxRange = taxRanges.find(taxRange => taxRange.id === item);
      
        return {
          brandName: brandNameEditSort[index] !== undefined ? brandNameEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.brandName : ''),
          minimumTaxableIncome: minimumTaxableIncomeEditSort[index] !== undefined ? minimumTaxableIncomeEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.minimumTaxableIncome : 0),
          maximumTaxableIncome: maximumTaxableIncomeEditSort[index] !== undefined ? maximumTaxableIncomeEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.maximumTaxableIncome : 0),
          percentageTaxRate: percentageTaxRateEditSort[index] !== undefined ? percentageTaxRateEditSort[index] : (correspondingTaxRange ? correspondingTaxRange.percentageTaxRate : 0),
          id: item
        };
      });
  
    
   
      if (mergedDataEdit.length > 0) {

        for (let index = 0; index < mergedDataEdit.length; index++) {
          const endpoint = `/tax-range/${mergedDataEdit[index].id}`;
          const body = {
            "bandName": mergedDataEdit[index].brandName,
            "minimumTaxableIncome": parseFloat(mergedDataEdit[index].minimumTaxableIncome),
            "maximumTaxableIncome": parseFloat(mergedDataEdit[index].maximumTaxableIncome),
            "percentageTaxRate": parseFloat(mergedDataEdit[index].percentageTaxRate)
          };

       
       

          const response = await PUT_SERVICE(endpoint, body);
         

          updatedMessageIds.push(response.status === 200);


        }
      }





      if (updatedMessageIds.includes(false) && updatedMessageIds.includes(true)) {
        props.error("Not all tax ranges updated successfully");
        getTaxRange()
        setEditable(false)
        setBrandName([])
        setMinimumTaxableIncome([])
        setMaximumTaxableIncome([])
        setPercentageTaxRate([])
        setSecondTableCount(0)
        setBrandNameEdit([])
        setMinimumTaxableIncomeEdit([])
        setMaximumTaxableIncomeEdit([])
        setPercentageTaxRateEdit([])
        setTaxRangeLoading(false)

      }

      if (updatedMessageIds.includes(false) && !updatedMessageIds.includes(true)) {
        props.error("tax ranges were not updated");
        setTaxRangeLoading(false)
      }

      if (updatedMessageIds.includes(true) && !updatedMessageIds.includes(false)) {
        props.success("All tax ranges updated successfully");
        getTaxRange()
        setEditable(false)
        setBrandName([])
        setMinimumTaxableIncome([])
        setMaximumTaxableIncome([])
        setPercentageTaxRate([])
        setSecondTableCount(0)
        setBrandNameEdit([])
        setMinimumTaxableIncomeEdit([])
        setMaximumTaxableIncomeEdit([])
        setPercentageTaxRateEdit([])     
        setTaxRangeLoading(false)   
      }
    }
    catch (err) {
      return err.response
    }
  }

  const saveTaxDate=async ()=>{
    setDateLoading(true)
    try{
      if(startDate!==""){
     
          const body={
            "value": startDate       
          }
          const endpoint = `/base-configuration/tax-year-start-date`
  
          const response = await POST_SERVICE(endpoint, body)
          if (response.status===200){
       
              props.success(response.data.message)
              getStartDate()
              setEditableDate(false)
  
  
            }
            else {
              props.error(response.data.message)
            
          }
      
   

      }
      if(endDate!==""){
  
        const body={
          "value": endDate       
        }
        const endpoint = `/base-configuration/tax-year-end-date`

        const response = await POST_SERVICE(endpoint, body)
        if (response.status===200){
     
            props.success(response.data.message)
            getEndDate()
            setEditableDate(false)


          }
          else {
            props.error(response.data.message)
          
        }
      }
      setDateLoading(false)
    }
    catch(err){
      return err.response
    }
  }
  useEffect(() => {
    getMinimumWage()
    getTaxRange()
    getTaxCode()
    getEndDate()
    getStartDate()
  }, [])

  return (
    loading === true ?
     <Loader show={loading}/>
     :
     <div className={styles.content}>
    {taxRangeLoading ?
    <ViewLoader/>
    :
    <>

<Form
       show_header={true}
       header={
         <div className={styles.content_header}>
           <h1>Tax Ranges</h1>
           <p>Range of taxable income for each band</p>
         </div>
       }
       line={true}
       bulk="Edit Tax Ranges"
       edit_option={true}
       edit_option_style={styles.edit_icon}

       show_bulk={editable === false ? true : false}
       bulk_action={() => setEditable(!editable)}
     />
     <div className={styles.table}>
       <table>
         <tr>
           <th>
             Band
           </th>
           <th>
             Taxable Income
           </th>
           <th>
             Tax Rate
           </th>
           {editable && (
             <th>
               Action
             </th>

           )}
         </tr>

         {Array.from({ length: tableCount }).map((_, index) => (
           <tr>
             <td key={index}>
               {editable ? (
                 <div className={styles.table_body_input}>

                   <input style={{ maxWidth: "120px" }}
                     type="text"
                     placeholder={taxRanges[index]?.brandName || ""}
                     value={brandNameEdit[index] || ""}
                     onChange={(e) => {

                       setBrandNameEdit((prevValue) => {
                         const updatedValue = [...prevValue];
                         updatedValue[index] = e.target.value;

                         return updatedValue;
                       }

                       )
                    
                       if (taxRangeId[index] === undefined) {
                         const selectedTaxRange = taxRanges.find((item) => item.id === taxRanges[index].id);

                         if (selectedTaxRange) {

                           setTaxRangeId((prevIds) => {
                             const updatedIds = [...prevIds];
                             updatedIds[index] = selectedTaxRange.id;
                             return updatedIds;
                           });
                         }
                       }

                     }

                     }


                   />




                 </div>
               ) : (
                 <span>{taxRanges[index]?.brandName}</span>
               )}
             </td>
             <td key={index}>
               {editable ? (
                 <div className={styles.table_body_input}>

                   <input style={{ maxWidth: "120px" }}
                     type="number"
                     placeholder={taxRanges[index]?.minimumTaxableIncome || 0}
                     value={minimumTaxableIncomeEdit[index] || ""}
                     onChange={(e) => {

                       setMinimumTaxableIncomeEdit(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                  
                       if (taxRangeId[index] === undefined) {
                         const selectedTaxRange = taxRanges.find((item) => item.id === taxRanges[index].id);

                         if (selectedTaxRange) {

                           setTaxRangeId((prevIds) => {
                             const updatedIds = [...prevIds];
                             updatedIds[index] = selectedTaxRange.id; // Set umbrella ID at the 
                             return updatedIds;
                           });
                         }
                       }

                     }

                     }

                   />

                   <span>   to   </span>

                   <input style={{ maxWidth: "120px" }}
                     type="number"
                     placeholder={taxRanges[index]?.maximumTaxableIncome || 0 }
                     value={maximumTaxableIncomeEdit[index] || ""}
                     onChange={(e) => {

                       setMaximumTaxableIncomeEdit(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                      
                      
                       if (taxRangeId[index] === undefined) {
                         const selectedTaxRange = taxRanges.find((item) => item.id === taxRanges[index].id);

                         if (selectedTaxRange) {

                           setTaxRangeId((prevIds) => {
                             const updatedIds = [...prevIds];
                             updatedIds[index] = selectedTaxRange.id; // Set umbrella ID at the 
                             return updatedIds;
                           });
                         }
                       }

                     }

                     }

                   />
                 </div>
               ) : (
                 <span>{taxRanges[index]?.maximumTaxableIncome === undefined || !taxRanges[index]?.maximumTaxableIncome ?
                   "0"
                   :
                   "£" + taxRanges[index]?.minimumTaxableIncome + " to " + "£" + taxRanges[index]?.maximumTaxableIncome
                 }</span>
               )}
             </td>
             <td key={index}>
               {editable ? (
                 <div className={styles.table_body_input}>

                   <input style={{ maxWidth: "120px" }}
                     type="number"
                     placeholder={taxRanges[index]?.percentageTaxRate || "0"}
                     value={percentageTaxRateEdit[index] || ""}
                     onChange={(e) => {

                       setPercentageTaxRateEdit(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                     
                       if (taxRangeId[index] === undefined) {
                         const selectedTaxRange = taxRanges.find((item) => item.id === taxRanges[index].id);

                         if (selectedTaxRange) {

                           setTaxRangeId((prevIds) => {
                             const updatedIds = [...prevIds];
                             updatedIds[index] = selectedTaxRange.id; // Set umbrella ID at the 
                             return updatedIds;
                           });
                         }
                       }

                     }

                     }


                   />




                 </div>
               ) : (
                 <span>{
                   taxRanges[index]?.percentageTaxRate !== undefined ?
                     taxRanges[index]?.percentageTaxRate + "%"

                     :
                     "0"
                 }</span>
               )}
             </td>
             {editable && taxRanges.length > 1 && (
               <td className={styles.delete_icon}>
                 <img src={del} alt="icon" onClick={() => deleteTaxRangeItem(index, taxRanges[index].id)} />
               </td>

             )}


           </tr>

         ))}
         {Array.from({ length: secondTableCount }).map((_, index) => (
           <tr>
             <td key={index}>
               {editable && (
                 <div className={styles.table_body_input}>

                   <input style={{ maxWidth: "120px" }}
                     type="text"
                     

                     value={brandName[index] || ""}
                     onChange={(e) => {
                       setBrandName(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                     }}

                   />




                 </div>
               )}
             </td>
             <td key={index}>
               {editable && (
                 <div className={styles.table_body_input}>

                   <input style={{ maxWidth: "120px" }}

                     value={minimumTaxableIncome[index] || ""}
                     placeholder= "0"
                     onChange={(e) => {
                       setMinimumTaxableIncome(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                     }}

                   />

                   <span>   to   </span>

                   <input style={{ maxWidth: "120px" }}
                     type="number"
                     placeholder= "0"

                     value={maximumTaxableIncome[index] || ""}
                     onChange={(e) => {
                       setMaximumTaxableIncome(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                     }}

                   />
                 </div>
               )}
             </td>
             <td key={index}>
               {editable && (
                 <div className={styles.table_body_input}>

                   <input style={{ maxWidth: "120px" }}
                     type="number"
                     placeholder= "0"

                     value={percentageTaxRate[index] || ""}
                     onChange={(e) => {
                       setPercentageTaxRate(


                         (prevValue) => {
                           const updatedValue = [...prevValue];
                           updatedValue[index] = e.target.value;
                           return updatedValue;
                         }

                       )
                     }}

                   />




                 </div>
               )}
             </td>
             {editable && (
               <td className={styles.delete_icon}>
                 <img src={del} alt="icon" onClick={() => deleteTaxRange(index)} />
               </td>

             )}


           </tr>

         ))}



       </table>
     </div>
     {editable && (
       <div className={styles.add} style={{ justifyContent: "flex-start" }}>
         <button onClick={addSecondTable}><span>Add Tax Range</span></button>
       </div>
     )}
     {editable === true &&
       <div style={{ marginTop: "50px", marginBottom: "50px" }}>

         <ViewButton
           cancel={() => { setEditable(false); setBrandName([]); setMinimumTaxableIncome(); setPercentageTaxRate(); setMaximumTaxableIncome(); setSecondTableCount(0) }}
           save={addTaxRanges}
         />
       </div>
     }
    </>

    }


     <div style={{ marginTop: "50px", marginBottom: "50px" }}>
       <hr />
     </div>

         {taxCodeLoading ?
         <ViewLoader/>
         :
         <>

     <Form
       show_header={true}
       header={
         <div className={styles.content_header}>
           <h1>Tax Codes</h1>
           <p>Tax coded used to determine taxable income for each band</p>
         </div>
       }
       line={true}
       bulk="Edit Tax Code"
       edit_option={true}
       edit_option_style={styles.edit_icon}

       show_bulk={editableTax === false ? true : false}
       bulk_action={() => setEditableTax(!editableTax)}




     />
     <div className={styles.form_container}>
       {Array.from({ length: componentCount }).map((_, index) => (

         <div className={styles.form_input} key={index}>


           <label>Tax Code {index + 1}</label>
           <div className={styles.flex}>
             <input placeholder={index < taxCodes?.length
               ? taxCodes[index].name
               : ""}
               value={taxCode[index] || ""
               }
               onChange={
                 (e) => {
                   setTaxCode((prevTaxCode) => {
                     const updatedTaxCode = [...prevTaxCode];
                     updatedTaxCode[index] = e.target.value; // Set umbrella name at the corresponding 
                     return updatedTaxCode;
                   }

                   );
                   const selectedTaxCode = taxCodes.find((item) => item.id === taxCodes[index].id);

                   if (selectedTaxCode) {

                     setTaxCodeId((prevIds) => {
                       const updatedIds = [...prevIds];
                       updatedIds[index] = selectedTaxCode.id; // Set umbrella ID at the 
                       return updatedIds;
                     });
                   }
                 }

               }
               disabled={editableTax ? "" : "disabled"}

             />
             {editableTax && taxCodes.length > 1 && (
               <img src={del} alt="icon" onClick={() => deleteTaxCode(index, taxCodes[index].id)} />
             )}

           </div>
         </div>




       ))}

       {Array.from({ length: secondComponentCount }).map((_, index) => (
         <div className={styles.form_input} key={index}>
           <label>Tax Code {index + componentCount + 1}</label>
           <div className={styles.flex}>
             <input
               type="text"
               placeholder={''}

               value={
                 taxItem[index] || ""

               }
               onChange={
                 (e) => {
                   setTaxItem((prevCategory) => {
                     const updatedCategory = [...prevCategory];
                     updatedCategory[index] = e.target.value; // Set umbrella name at the corresponding index
                     return updatedCategory;
                   }

                   );


                 }

               }

               disabled={editableTax ? "" : "disabled"}

             />
             {editableTax && (
               <img src={del} alt="icon" onClick={() => deleteTaxItem(index)} />
             )}
           </div>
         </div>
       ))}
     </div>
     {editableTax === true &&
       <>
         <div className={styles.add} style={{ justifyContent: "flex-start" }}>
           <button onClick={addTaxCode} ><span>Add Tax Code </span><div className={styles.add_icon} /></button>
         </div>

         <ViewButton
           cancel={() => setEditableTax(!editableTax)}
           save={saveTaxCodes}
         />
       </>
     }

         </>

         }
            {minimumLoading ? 
         <ViewLoader/>
         :
         <>
          <Form
       show_header={true}
       place={"place"}
       header={
         <div className={styles.content_header}>
           <h1>Minimum Wage</h1>
           <p>This is the minimum wage set by the government</p>
         </div>
       }
       line={true}
       bulk="Edit Min Wage"
       edit_option={true}
       edit_option_style={styles.edit_icon}

       show_bulk={editableMinimum === false ? true : false}
       bulk_action={() => setEditableMinimum(!editableMinimum)}

       input_type="input"
       type_one="number"
       show_one={true}
       label_one="Minimum wage"
       value_one={minimumWageValue}
       input_change={(e) => setMinimumWageValue(e.target.value)}
       placeholder_one={minimumWage || "0"}
       disabled={editableMinimum ? "" : "disabled"}
     />
     {editableMinimum === true &&


       <ViewButton
         cancel={() => setEditableMinimum(!editableMinimum)}
         save={saveMinimumWage}
       />

     }
         </>

         }
    
    {dateLoading ?
    <ViewLoader/>
    :
    <>
     <Form
       show_header={true}
       header={
         <div className={styles.content_header}>
           <h1>Tax Year</h1>
           <p>Start Date and End Date of Tax Year</p>
         </div>
       }
       line={true}
       bulk="Edit Date"
       edit_option={true}
       edit_option_style={styles.edit_icon}

       show_bulk={editableDate === false ? true : false}
       bulk_action={() => setEditableDate(!editableDate)}

       input_type="input"
       type_one="date"
       show_one={true}
       label_one="Start Date"
  
       value_one={startDate === "" ? startDateEdit : startDate}
       input_change={(e) => setStartDate(e.target.value)}
       input_type_two="input"
       show_two={true}
       label_two="End Date"
       type_two="date"
       minDate= {startDate === "" ? startDateEdit : startDate}
       maxDate={endDate === "" ? endDateEdit : endDate}
   
       value_two={endDate === "" ? endDateEdit : endDate}       

       input_change_two={(e) => setEndDate(e.target.value)}
 
       disabled={editableDate ? "" : "disabled"}
       disabled_two ={editableDate ? "" : "disabled"}
     />
     {editableDate === true &&


       <ViewButton
         cancel={() => setEditableDate(!editableDate)}
         save={saveTaxDate}
       />

     }</>

    }

   </div>
  
  );
};

export default Tax;
import React, {useState, useEffect} from 'react';

//component import
import { GET_SERVICE, POST_SERVICE } from "../Services/Backend";
import ViewLoader from '../Common/ViewLoader';
import Table from '../Common/Table';
import NoContent from '../Common/NoContent';
import Select from '../Common/Select';

//styles import 
import styles from "../Contractor/css/view.module.css";

//resources import
import agency from "../../Assets/agency-green.svg";

const AgencyContractor = (props) => {
    const [agencyContractor, setAgencyContractor] = useState([])
    const [loading, setLoading] = useState(false)
    const [contractorElement, setContractorElement] = useState(10)
    const [totalContractorElement, setTotalContractorElement] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageCount, setPageCount] = useState()
    const [fullnames, setFullnames] = useState("")
    const [emails, setEmails] = useState("")
    const [ninNumbers, setNinNumbers] = useState("")
    const [genders, setGenders] = useState("")
    const [search, setSearch] = useState("")
    const [allUmbrella, setAllUmbrella] = useState([])
    const [umbrellaId, setUmbrellaId] = useState("")
  const [umbrella, setUmbrella] = useState("");
    const contractorHeaders = [
        { key: 'fullName', label: 'Full Name' },
        { key: 'niNumber', label: 'NI Number' },
        { key: 'emailAddress', label: 'Email' },
        { key: 'gender', label: 'Gender' },
  
        { key: 'umbrellas', label: 'Umbrella' },
      ];

      const getContractors = async (page) => {
        setLoading(true)
        const endpoint = `/agencies/${props.id}/contractors?page=${page || 0}&size=10`
        try {
          const response = await GET_SERVICE(endpoint);
          if (response.status===200){

            setAgencyContractor(response.data.data.contractors)
            setTotalContractorElement(response.data.data.totalElement);
      
            setPageNumber(page || 0)
            setPageCount(response.data.data.totalPages)
          }
      
          setLoading(false)
    
        } catch (err) {
          return err.response;
        }
      }
      const getSearch = async () => {


        try {
          // const response = await GET_SERVICE(`/contractors/search?page=0&size=10&propertySortedBy=createdAt&searchBy=${search}`)
          // setAgencyContractor(response.data.data.contractors);
        }
        catch (err) {
          return err.response;
        }
    
      }
      const getUmbrella = async ()=>{
        try{
        const response = await GET_SERVICE (`/umbrellas`)
        setAllUmbrella(response.data.data)
        }
        catch (err){
          return err.response
        }
      }
      const filter = async ()=>{

        const body ={
          "agencyId": props.id,
          "umbrellaId": umbrellaId,
          "status": null
        }
        const endpoint = `/contractors/filter?page=0&size=10`
        setLoading(true)
        try{ 
          const response = await POST_SERVICE(endpoint, body)
          console.log(response)
          if (response.status === 200) {
      
            if (response.data.data.contractors.length>0){
              setAgencyContractor(response.data.data.contractors);
              setPageCount(response.data.data.totalPages)
              setTotalContractorElement(response.data.data.totalElement)
              
            }
            clearField()
          setLoading(false);
        }
        else {
      
          setLoading(false)
        }
      } catch (err) {
        return err.response;
      }
      }
      const clearField = ()=>{
        
        setUmbrella("")
        setUmbrellaId(null)
       
      }
      const getMoreContractor = async (option) => {
        const endpoint = `/agencies/${props.id}/contractors?page=${pageNumber || 0}&size=${option}`
        setLoading(true)
        try {
          const response = await GET_SERVICE(endpoint);
          if (response.status===200){
    
          setAgencyContractor(response.data.data.contractors)
          setContractorElement(option)
          setPageCount(response.data.data.totalPages)
          }
          setLoading(false)

    
        } catch (err) {
          return err.response;
        }
      }
    
    
      const nextPage = (e) => {
    
        let page = e.selected
    
    
        getContractors(page)
      }
      useEffect (()=>{
        getContractors()
        getUmbrella()
      }, [])
    return (
        <>
        {agencyContractor.length > 0 ?
                loading === true?
                <ViewLoader />
            :
            <Table

              columns={contractorHeaders}
              search={search}
              searchInput={(e)=>setSearch(e.target.value)}
              searchAction= {(e)=>getSearch(e)}
              body={
                agencyContractor.map((row, index) =>
                  <tr key={index}>
                    <td>
                      {row.fullName}
                    </td>
                    <td>
                      {row.niNumber}
                    </td>
                    <td>
                      {row.emailAddress}
                    </td>
                    <td>
                      {row.gender}
                    </td>
                
                    <td>
                      {row.umbrella}
                    </td>
                  </tr>


                )

              }
              row={contractorElement}
              row_list={
                totalContractorElement <= 30 && totalContractorElement >= 20
                  ? [10, 20, 30]
                  : totalContractorElement <= 20 && totalContractorElement >= 10
                    ? [10, 20]
                    : totalContractorElement <= 10
                      ? [10]
                      : []
              }
              row_item_click={(option) => getMoreContractor(option)}
              pageCount={pageCount}
              pageChange={nextPage}
              pageNumber={pageNumber}
              filterContent={
                <div>
                  <div className={styles.filter}>


                  
                    <div className={styles.select_input}>
                      <label>
                        Umbrella

                      </label>
                      <div className={styles.custom_select_container}>
                      <Select
                            // disabled={agencyId!==""? "": "disabled"}
                            value={umbrella}
                            onChange={(option) => {
                              setUmbrella(option)
                              const selectedUmbrella = allUmbrella.find((item)=> item.name === option);
                              if(selectedUmbrella){
                                setUmbrellaId(selectedUmbrella?.id)
                              }

                            }}
                            options={allUmbrella.map((umbrella)=>
                              umbrella.name
                              )}
                          />

                      </div>
                    </div>

                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.clear} onClick={clearField} >
                      Cancel
                    </button>
                    <button className={styles.solid} onClick={filter}>
                      Save
                    </button>
                  </div>
                </div>



              }
            />
          :
       loading === true ?
            <ViewLoader  />
            :
            < >
              <NoContent
                image={agency}
                text="No Contractors Available"
                hide={true}
                change={{ margin: "5% 0" }}

              />
            </>

        }

      </>
    );
};

export default AgencyContractor;